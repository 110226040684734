import React from "react";
import DefaultButton from "@components/DefaultButton";
import "@styles/components.scss";

const LoginPageBottomBox = ({
  button_label,
  button_fn,
  button_disabled,
  first_text,
  second_text,
  second_text_fn = () => {},
}) => {
  return (
    <div className="login-page-contents-bottom">
      <DefaultButton
        label={button_label}
        onClick={button_fn}
        disabled={button_disabled}
      />
      <div className="includes-link-text-box">
        <div className="normal-text">{first_text}</div>
        <div className="under-line-text" onClick={second_text_fn}>
          {second_text}
        </div>
      </div>
    </div>
  );
};

export default LoginPageBottomBox;
