import React from "react";
import { TextField } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import InputAdornment from "@mui/material/InputAdornment";
import { labelInputTheme } from "@styles/muiThemes";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "@styles/components.scss";

const IconInput = ({
  value = "",
  onChange = () => {},
  placeholder = "",
  disabled = false,
  leftIcon = <SearchOutlinedIcon fontSize="small" />,
  rightIcon = <CloseOutlinedIcon fontSize="small" />,
}) => {
  const createInputProps = () => {
    const result = {};

    if (leftIcon) {
      result.startAdornment = (
        <InputAdornment position="start">{leftIcon}</InputAdornment>
      );
    }

    if (rightIcon) {
      result.endAdornment = (
        <InputAdornment position="end">{rightIcon}</InputAdornment>
      );
    }

    return result;
  };

  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme}>
        <TextField
          value={value}
          InputProps={createInputProps()}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          sx={{ height: "40px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default IconInput;
