import { setRecoil } from "recoil-nexus";
import { UserSetting, UserGeneralMenu } from "@atoms/userInfo";
import { MaterialCategory } from "@atoms/material";
import { userPermAxios } from "./customAxios";
import { timeZoneList } from "./userTimeZone";

export const isLoginCheck = async () => {
  try {
    const requestLoginCheck = await userPermAxios
      .post(
        "/is_logged_in",
        {},
        {
          withCredentials: true,
        }
      )
      .catch((err) => console.log(err));

    const { isLoggedIn, data } = requestLoginCheck;

    if (isLoggedIn) {
      const {
        userId,
        name,
        email,
        phone,
        country,
        language,
        timeZone,
        timeFormat,
        admin_user,
        menu,
        approver,
        mat_category,
      } = data;

      const findTimeZoneUfc = timeZoneList.find((c) => c.name === timeZone);

      setRecoil(UserSetting, {
        userId,
        name: name,
        email: email,
        phone: phone,
        country: country,
        language: language,
        timeZoneName: timeZone,
        timeZone: findTimeZoneUfc?.ufc_value ?? 0,
        timeFormat: timeFormat,
        admin_user,
        approver,
        menu,
      });

      setRecoil(MaterialCategory, mat_category);

      await getUserGeneralMenu();
    }

    return { isLoggedIn };
  } catch (err) {
    console.log(err);
    return { isLoggedIn: false };
  }
};

export const getUserGeneralMenu = async () => {
  const requestUserGeneralMenu = await userPermAxios
    .get("/get-user-menu-list")
    .catch((err) => {
      return { success: false, data: [] };
    });

  const { success, data } = requestUserGeneralMenu;

  if (success) {
    setRecoil(UserGeneralMenu, data);
    return data;
  }

  return [];
};
