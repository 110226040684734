import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import ManpowerRequestTable from "./ManpowerRequestTable";
import { manpowerRequestColum, candidateRecordColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";

const ManpowerRequest = ({
  tabValue,
  handleTabValue,
  manpower_request_logs = [],
  candidate_record_list = [],
  add_button_use = false,
  list_query_key,
}) => {
  const { height } = useCostTableHeight(false, false, true);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabValue}
          aria-label="basic tabs example"
        >
          <Tab
            label="Manpower Request"
            {...a11yProps(0)}
            sx={{ textTransform: "none" }}
          />

          <Tab
            label="Candidate"
            {...a11yProps(1)}
            sx={{ textTransform: "none" }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        {height > 0 && (
          <div className="cost-menu-body">
            <div
              className="cost-menu-detail-body"
              style={{ maxHeight: height }}
            >
              <div className="cost-menu-table-title-box">
                <div className="cost-menu-table-title">Request Logs</div>
              </div>
              <ManpowerRequestTable
                rows={manpower_request_logs}
                column={manpowerRequestColum(list_query_key).filter((c) => {
                  const { field } = c;

                  if (!add_button_use) {
                    if (field === "row_fixed_cell") {
                      return false;
                    }
                  }

                  return true;
                })}
              />
            </div>
          </div>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        {height > 0 && (
          <div className="cost-menu-body">
            <div
              className="cost-menu-detail-body"
              style={{ maxHeight: height }}
            >
              <div className="cost-menu-table-title-box">
                <div className="cost-menu-table-title">Record Logs</div>
              </div>
              <ManpowerRequestTable
                rows={candidate_record_list}
                column={candidateRecordColumn(list_query_key)}
              />
            </div>
          </div>
        )}
      </CustomTabPanel>
    </Box>
  );
};

export default ManpowerRequest;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "calc(100% - 69px)",
        marginTop: "20px",
        // boxSizing: "border-box",
      }}
    >
      {value === index && children}
    </div>
  );
}
