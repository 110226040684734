import React from "react";
import { FileUploader } from "react-drag-drop-files";

const DragFileUploader = ({ handleChange = () => {} }) => {
  const child = (
    <div className="drag-file-uploader-contents-box">
      <UploadIcon />
      <div className="drag-file-uploader-contents-title-box">
        <div className="drag-file-uploader-contents-title">
          Select file to upload
        </div>
        <div className="drag-file-uploader-contents-subtitle">
          or drag and drop it here
        </div>
      </div>
    </div>
  );

  return (
    <FileUploader
      classes="drag-file-uploader-box"
      multiple={false}
      handleChange={handleChange}
      name="file"
      types={["xlsx"]}
      styles={{
        dropArea: {
          border: "1px dashed var(--Gray-400, #98A2B3)",
          borderRadius: "4px",
          padding:
            "var(--7, 28px) var(--5, 20px) var(--10, 40px) var(--5, 20px);",
        },
        dropAreaActive: {
          borderColor: "#6c757d",
        },
        inputLabel: {
          color: "#000",
        },
      }}
      label="Select file to upload or drag and drop it here"
      children={child}
    />
  );
};

export default DragFileUploader;

const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
    >
      <path
        d="M2.3125 50.6376C2.3125 60.3235 11.0946 68.0879 21.7969 68.0879H70.3125C79.8547 68.0879 87.6875 61.171 87.6875 52.5209C87.6875 45.1422 81.9706 39.0104 74.3737 37.3857C74.7972 36.2254 75.0312 34.9794 75.0312 33.6872C75.0312 27.1206 69.0837 21.887 61.875 21.887C59.3633 21.887 57.0027 22.5253 55.0053 23.6269C51.1983 18.0462 44.357 14.3535 36.5625 14.3535C24.6868 14.3535 14.9688 22.9537 14.9688 33.6872C14.9688 33.8868 14.9738 34.0858 14.9806 34.2819C7.61563 36.7453 2.3125 43.1139 2.3125 50.6376Z"
        fill="white"
        stroke="#D0D5DD"
      />
      <path
        d="M60.8203 59.3262C60.8203 68.0635 53.7373 75.1465 45 75.1465C36.2627 75.1465 29.1797 68.0635 29.1797 59.3262C29.1797 50.5889 36.2627 43.5059 45 43.5059C53.7373 43.5059 60.8203 50.5889 60.8203 59.3262Z"
        fill="#D0D5DD"
      />
      <path
        d="M50.4295 56.6887C50.7246 56.989 50.7246 57.4732 50.4295 57.7735C50.1405 58.0738 49.6587 58.0738 49.3697 57.7796L45.707 54.0518V66.5175C45.707 66.9145 45.3908 67.2363 45.0007 67.2363C44.6107 67.2363 44.2945 66.9145 44.2945 66.5175V54.0569L40.6378 57.7735C40.3427 58.0738 39.867 58.0738 39.5719 57.7735C39.2769 57.4732 39.2769 56.989 39.5719 56.6887L44.5762 51.5953C44.811 51.3563 45.1904 51.3563 45.4253 51.5953L50.4295 56.6887Z"
        fill="white"
      />
    </svg>
  );
};
