import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { BudgetTransferRowModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import ModalBody from "../ModalBody";
import LabelSelect from "../LabelSelect";
import LabelInput from "../LabelInput";

const EditRowModal = ({ setBudgetTransferRow, cost_code_list }) => {
  const {
    open,
    onClose,
    id,
    from_cost_code,
    to_cost_code,
    budget_transfer_cost,
    remark: initialRemark,
    modal_type,
  } = useRecoilValue(BudgetTransferRowModalStatus);

  const [fromCostCodeId, setFromCostCodeId] = useState(null);
  const [toCostCodeId, setToCostCodeId] = useState(null);
  const [fromCostCode, setFromCostCode] = useState(from_cost_code);
  const [toCostCode, setToCostCode] = useState(to_cost_code);
  const [budgetCost, setSetBudgetCost] = useState(budget_transfer_cost);
  const [remark, setRemark] = useState(initialRemark);
  const [fromDescription, setFromDescription] = useState("");
  const [toDescription, setToDescription] = useState("");

  useEffect(() => {
    if (fromCostCode === "") {
      setFromDescription("");
      setFromCostCode("");
      setFromCostCodeId(null);
    } else {
      const targetFromCostCode = cost_code_list.find(
        (c) => c.code === fromCostCode
      );

      setFromDescription(targetFromCostCode?.lv_2_name ?? "");

      if (targetFromCostCode) {
        const { id: cost_code_id } = targetFromCostCode;

        setFromCostCodeId(cost_code_id);
      } else {
        setFromDescription("");
        setFromCostCode("");
        setFromCostCodeId(null);
      }
    }
  }, [fromCostCode, cost_code_list]);

  useEffect(() => {
    if (toCostCode === "") {
      setToDescription("");
      setToCostCode("");
      setToCostCodeId(null);
    } else {
      const targetToCostCode = cost_code_list.find(
        (c) => c.code === toCostCode
      );

      setToDescription(targetToCostCode?.lv_2_name ?? "");

      if (targetToCostCode) {
        const { id: cost_code_id } = targetToCostCode;
        setToCostCodeId(cost_code_id);
      } else {
        setToDescription("");
        setToCostCode("");
        setToCostCodeId(null);
      }
    }
  }, [toCostCode, cost_code_list]);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*(\.\d{0,2})?$/;

    // if (value === "") {
    //   setValue(0);
    //   return;
    // }

    if (isValidNumber.test(value)) {
      setValue(Number(value));
    }
  };

  const saveRowData = () => {
    if (modal_type === "create") {
      setBudgetTransferRow((prev) => {
        return [
          ...prev,
          {
            id,
            from_cost_code_id: fromCostCodeId,
            to_cost_code_id: toCostCodeId,
            from_cost_code: fromCostCode,
            to_cost_code: toCostCode,
            from_description: fromDescription,
            to_description: toDescription,
            budget_transfer_cost: budgetCost,
            remark: remark,
            notAccept: false,
          },
        ];
      });

      onClose();
    }
    if (modal_type === "fixed") {
      setBudgetTransferRow((prev) => {
        return prev.map((c) => {
          if (c.id === id) {
            return {
              id,
              from_cost_code_id: fromCostCodeId,
              to_cost_code_id: toCostCodeId,
              from_cost_code: fromCostCode,
              to_cost_code: toCostCode,
              from_description: fromDescription,
              to_description: toDescription,
              budget_transfer_cost: budgetCost,
              remark: remark,
              notAccept: false,
            };
          }
          return c;
        });
      });

      onClose();
    }

    return;
  };

  const button_disabled = useMemo(() => {
    if (fromCostCode === "" || toCostCode === "") {
      return true;
    }

    if (budgetCost <= 0 || budgetCost === "") {
      return true;
    }

    if (fromCostCodeId === null || toCostCodeId === null) {
      return true;
    }

    return false;
  }, [fromCostCode, toCostCode, budgetCost, fromCostCodeId, toCostCodeId]);

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Budget Reallocation Row Edit"
      disableEscapeKeyDown={true}
      modalWidth={790}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              From Cost Code
            </div>
            <LabelSelect
              value={fromCostCode}
              list={[
                { name: "", value: "" },
                ...cost_code_list
                  .filter((c) => c.code !== toCostCode)
                  .map((c) => {
                    const { code, lv_2_name } = c;
                    return { name: `${code}_${lv_2_name}`, value: code };
                  }),
              ]}
              setValue={setFromCostCode}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              To Cost Code
            </div>
            <LabelSelect
              value={toCostCode}
              list={[
                { name: "", value: "" },
                ...cost_code_list
                  .filter((c) => c.code !== fromCostCode)
                  .map((c) => {
                    const { code, lv_2_name } = c;
                    return { name: `${code}_${lv_2_name}`, value: code };
                  }),
              ]}
              setValue={setToCostCode}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Reallocation Amount
            </div>
            <NumberInput
              width="100%"
              value={budgetCost}
              onChange={handleNumberInputValue}
              setValue={setSetBudgetCost}
            />
          </div>
          <div className="work-policy-modal-time-picker-box">
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelInput value={remark} setValue={setRemark} />
            </div>
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => saveRowData()}
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default EditRowModal;

const NumberInput = ({
  value,
  setValue,
  onChange,
  disabled = false,
  width = "100%",
}) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{
        width,
        boxSizing: "border-box",
        marginTop: 4,
        height: 40,
        // borderColor: "rgb(118, 118, 118)",
      }}
    />
  );
};
