import React from "react";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

const ModalBody = ({
  open,
  title,
  onClose,
  modalHeight,
  modalWidth,
  children,
  disableEscapeKeyDown = false,
}) => {
  const modalSize = () => {
    const result = {};

    if (modalWidth) {
      result.width = modalWidth;
    }

    if (modalHeight) {
      result.height = modalHeight;
    }

    return result;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <div className="default-modal-total-body" style={{ ...modalSize() }}>
        <div className="default-modal-header-box">
          <div className="default-modal-header-title">{title}</div>
          <IconButton onClick={onClose} className="default-modal-close-btn">
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalBody;
