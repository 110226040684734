import { atom } from "recoil";

export const UserInfo = atom({
  key: "UserInfo",
  default: null,
  // default: { userId: 1 },
});

export const UserSetting = atom({
  key: "UserSetting",
  default: null,
  // default: {
  //   userId: 1,
  //   name: "Kiman.Kim",
  //   email: "rlaks5757@gmail.com",
  //   phone: "010-8840-6505",
  //   country: "Korea, Republic of",
  //   language: "en",
  //   timeZoneName: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
  //   timeZone: 7,
  //   timeFormat: "YY/MM/DD HH:mm",
  //   admin_user: false,
  //   menu: [],
  // },
});

export const UserAuthorization = atom({
  key: "UserAuthorization",
  default: false,
});

export const UserTargetProject = atom({
  key: "UserTargetProject",
  // default: null,
  default: {
    project_name: "Overall",
    project_code: "overall",
  },
});

export const UserGeneralMenu = atom({
  key: "UserGeneralMenu",
  default: {},
});
