import React, { useState } from "react";
import { useQuery } from "react-query";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import dayjs from "dayjs";
import Btn from "@/components/CustomIconBtn";
import MonthPicker from "@/components/MonthPicker";
import AnnualLeaveLog from "@/components/annualLeaveLog/AnnualLeaveLog";
import VerticalSolidLine from "@/components/VerticalSolidLine";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

const AnnualLeaveLogPage = () => {
  const [startMonth, setStartMonth] = useState(dayjs().subtract(1, "year"));
  const [endMonth, setEndMonth] = useState(dayjs());

  const query_annual_leave_log_data = useQuery(
    [
      "annual_leave_log_data",
      startMonth.format("YYYY-MM"),
      endMonth.format("YYYY-MM"),
    ],
    () => {
      return query_fetch_data(startMonth, endMonth);
    }
  );

  const [refresh] = useRefreshReactQuery([
    "annual_leave_log_data",
    startMonth.format("YYYY-MM"),
    endMonth.format("YYYY-MM"),
  ]);

  const handleMonthRange = (e, setValue) => {
    setValue(e);
  };

  const { data = {}, isLoading } = query_annual_leave_log_data;

  const { list = [] } = data;

  if (isLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Dayoff Logs</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />
          <VerticalSolidLine height={24} />
          <MonthPicker
            value={startMonth}
            onChange={(e) => handleMonthRange(e, setStartMonth)}
          />
          <div>-</div>
          <MonthPicker
            value={endMonth}
            onChange={(e) => handleMonthRange(e, setEndMonth)}
          />
        </div>
      </div>
      <div className="company-setting-contents-body">
        <AnnualLeaveLog
          annual_leave_row={list}
          startMonth={startMonth}
          endMonth={endMonth}
        />
      </div>
    </div>
  );
};

export default AnnualLeaveLogPage;

const query_fetch_data = async (startDate, endDate) => {
  let result = { list: [] };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");

    const requestAnnualLeaveLog = await userPermAxios.get(
      `/dayoff/get_annual_leave_log_data?start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}`
    );

    const { success, data } = requestAnnualLeaveLog;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
