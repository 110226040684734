import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserGeneralMenu } from "@/atoms/userInfo";
import { UserAddMenuModalStatus } from "@/atoms/modalStatus";
import { Modal, IconButton, Button, Tabs, Tab, Box } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import AssignMenu from "./AssignMenu";
import AssignProject from "./AssignProject";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import "./userAddPermModal.scss";
import WorkPolicy from "./WorkPolicy";
import dayjs from "dayjs";

const UserAddPermModal = () => {
  const { permissionMenu: permission_menu_list } =
    useRecoilValue(UserGeneralMenu);
  const [modalStatus, setModalStatus] = useRecoilState(UserAddMenuModalStatus);
  const { open, target_id, user_id, username } = modalStatus;
  const [tabValue, setTabValue] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);
  const [menuTotalSelectItems, setMenuTotalSelectItems] = useState([]);
  const [readSelectItems, setReadSelectItems] = useState([]);
  const [editSelectItems, setEditSelectItems] = useState([]);
  const [projectTotalSelectItems, setProjectTotalSelectItems] = useState([]);
  const [permProjectSelectItems, setPermProjectSelectItems] = useState([]);
  const [orgValue, setOrgValue] = useState("");
  const [jobPositionValue, setJobPositionValue] = useState("");
  const [jobGradeValue, setJobGradeValue] = useState("");
  const [reflectionDate, setReflectionDate] = useState(dayjs(new Date()));

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      user_id: null,
    }));
  };

  const query_project_user_add_perm_data = useQuery(
    ["project_user_add_perm_data", user_id, permission_menu_list],
    () => {
      return query_fetch_data(user_id, permission_menu_list);
    },
    {
      initialData: {
        existing_menu_data: [],
        existing_project_data: [],
        sales_data: [],
        orgList: [],
        jobPositionList: [],
        jobGradeList: [],
        userJobStatus: {},
      },
      onSuccess: (result) => {
        const { userJobStatus } = result;

        const {
          org_setting_id = "",
          job_level_setting_id = "",
          job_fn_setting_id = "",
          reflection_date = dayjs(new Date()),
        } = userJobStatus;

        setOrgValue(org_setting_id);
        setJobPositionValue(job_level_setting_id);
        setJobGradeValue(job_fn_setting_id);
        setReflectionDate(dayjs(reflection_date));
      },
    }
  );

  const [refresh] = useRefreshReactQuery([
    "project_user_add_perm_data",
    user_id,
  ]);

  const {
    existing_menu_data,
    existing_project_data,
    sales_data,
    orgList,
    jobPositionList,
    jobGradeList,
    userJobStatus,
  } = query_project_user_add_perm_data.data;

  const {
    org_name = "",
    job_level_name = "",
    job_fn_name = "",
    reflection_date = null,
  } = userJobStatus;

  useEffect(() => {
    const existingReadItems = existing_menu_data.filter(
      (c) => c.permission_level === "read"
    );

    const existingEditItems = existing_menu_data.filter(
      (c) => c.permission_level === "edit"
    );

    const noneSelectmenuList = _.differenceBy(
      permission_menu_list,
      existing_menu_data,
      "id"
    );

    setMenuTotalSelectItems(noneSelectmenuList);
    setReadSelectItems(existingReadItems);
    setEditSelectItems(existingEditItems);
  }, [existing_menu_data, existing_project_data, sales_data]);

  useEffect(() => {
    setProjectTotalSelectItems(sales_data);

    setPermProjectSelectItems(existing_project_data);
  }, [existing_project_data, sales_data]);

  const button_disabled = useMemo(() => {
    if (tabValue === 0) {
      if (
        menuTotalSelectItems.length === 0 &&
        readSelectItems.length === 0 &&
        editSelectItems.length === 0
      ) {
        return true;
      }
    }

    if (tabValue === 1) {
      if (
        projectTotalSelectItems.length === 0 &&
        permProjectSelectItems.length === 0
      ) {
        return true;
      }
    }

    if (tabValue === 2) {
      if (
        orgValue.length === 0 ||
        jobPositionValue.length === 0 ||
        jobGradeValue.length === 0
      ) {
        return true;
      }

      if (
        org_name === orgValue &&
        job_level_name === jobPositionValue &&
        job_fn_name === jobGradeValue
      ) {
        return true;
      }
    }
    return false;
  }, [
    menuTotalSelectItems,
    readSelectItems,
    editSelectItems,
    projectTotalSelectItems,
    permProjectSelectItems,
  ]);

  const addUsertoProject = async () => {
    setDubleClick(false);
    try {
      const requestAssgineUser = await userPermAxios.post(
        `/${tabName[tabValue]}/${user_id}/assign-user-perm`,
        {
          menuTotalSelectItems,
          readSelectItems,
          editSelectItems,
          projectTotalSelectItems,
          permProjectSelectItems,
        }
      );
      const { success } = requestAssgineUser;
      if (success) {
        refresh();
        create_alert_fn(
          "success",
          "Successfully Assign User Permission.",
          () => {}
        );
      }
    } catch (err) {
      create_alert_fn("warning", "Failed Assign User to Project", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const submitUserJobStatus = async () => {
    setDubleClick(false);

    let result = {
      success: "",
      message: "",
      fn: "",
    };

    try {
      const requestSubmitUserJobStatus = await userPermAxios.post(
        `/hr/${user_id}/update-user-job-status`,
        {
          orgValue,
          jobPositionValue,
          jobGradeValue,
          reflectionDate: reflectionDate.format("YYYY-MM-DD"),
        }
      );

      const { success, message } = requestSubmitUserJobStatus;
      if (success) {
        result.success = "success";
        result.message = message;
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        result.success = "warning";
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.success, result.message);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        if (tabValue === 0 || tabValue === 1) {
          await addUsertoProject();
        }

        if (tabValue === 2) {
          await submitUserJobStatus();
        }
      },
      true
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="usre-add-perm-modal-total-body">
        <div className="usre-add-perm-modal-header-box">
          <div className="usre-add-perm-modal-header-title">
            Assign User Perm
          </div>
          <IconButton
            onClick={closeModal}
            className="usre-add-perm-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div className="usre-add-perm-modal-main-box">
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Menu Permission"
                {...a11yProps(0)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Project Permission"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Organization Setting"
                {...a11yProps(2)}
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <div className="usre-add-perm-modal-main-tab-box">
              <AssignMenu
                left={menuTotalSelectItems}
                setLeft={setMenuTotalSelectItems}
                readSelectItems={readSelectItems}
                setReadSelectItems={setReadSelectItems}
                editSelectItems={editSelectItems}
                setEditSelectItems={setEditSelectItems}
                existing_menu_data={existing_menu_data}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <div className="usre-add-perm-modal-main-tab-box">
              <AssignProject
                projectTotalSelectItems={projectTotalSelectItems}
                setProjectTotalSelectItems={setProjectTotalSelectItems}
                permProjectSelectItems={permProjectSelectItems}
                setPermProjectSelectItems={setPermProjectSelectItems}
              />
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <div className="usre-add-perm-modal-main-tab-box">
              <WorkPolicy
                orgValue={orgValue}
                jobPositionValue={jobPositionValue}
                jobGradeValue={jobGradeValue}
                reflectionDate={reflectionDate}
                orgList={orgList}
                jobPositionList={jobPositionList}
                jobGradeList={jobGradeList}
                setOrgValue={setOrgValue}
                setJobPositionValue={setJobPositionValue}
                setJobGradeValue={setJobGradeValue}
                setReflectionDate={setReflectionDate}
              />
            </div>
          </CustomTabPanel>
        </div>

        <div className="usre-add-perm-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="usre-add-perm-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="usre-add-perm-modal-update-btn"
            disabled={button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UserAddPermModal;

const query_fetch_data = async (user_id, permission_menu_list) => {
  const result = {
    table_data: [],
    existing_menu_data: [],
    existing_project_data: [],
    sales_data: [],
    orgList: [],
    jobPositionList: [],
    jobGradeList: [],
    userJobStatus: {},
  };

  try {
    if (!user_id) {
      return;
    }

    const requestUserList = await userPermAxios.get(
      `/get-menu-assign-user-perm-data/${user_id}`
    );

    const { success, data } = requestUserList;

    if (success) {
      const {
        existingMenu = [],
        existingProject = [],
        salesList = [],
        orgList = [],
        jobPositionList = [],
        jobGradeList = [],
        userJobStatus = {},
      } = data;

      result.existing_menu_data = existingMenu.map((c) => {
        const { id, menu_url, menu } = c;

        const findMenuId = permission_menu_list.find(
          (c2) => c2.menu_column_name === menu_url
        );

        return {
          ...c,
          id: findMenuId?.id,
          existing_menu_id: id,
          menu_name: menu,
          menu_column_name: menu_url,
        };
      });

      result.existing_project_data = existingProject.map((c) => {
        const { sales_id, id } = c;

        return { ...c, existing_project_id: id, id: sales_id };
      });

      result.sales_data = salesList;
      result.orgList = orgList;
      result.jobPositionList = jobPositionList;
      result.jobGradeList = jobGradeList;
      result.userJobStatus = userJobStatus;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

function a11yProps(index) {
  return {
    id: `user-setting-tab-${index}`,
    "aria-controls": `user-setting-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-setting-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && children}
    </div>
  );
}

const tabName = {
  0: "menu",
  1: "project",
};
