import React from "react";
import ModalBody from "@/components/ModalBody";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const MaterialItemDetailModal = ({ title, open, onClose, rows, columns }) => {
  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title={title}
      modalHeight={600}
      modalWidth={840}
    >
      <div className="initial-budget-detail-modal-body">
        <DataGridPro
          rows={rows}
          columns={columns}
          rowHeight={28}
          columnHeaderHeight={28}
          sx={{
            width: "calc(100% - 48px)",
            ...materialTableStyle,
            ...TableScrollbarStyle,
          }}
          hideFooter
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
        />
      </div>
    </ModalBody>
  );
};

export default MaterialItemDetailModal;
