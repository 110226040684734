import { atom } from "recoil";

const initialWeekData = Array.from({ length: 7 }).map((c, i) => {
  return {
    value: i,
    bool: i === 0 || i === 6 ? false : true,
  };
});

export const PwSettingModalStatus = atom({
  key: "PwSettingModalStatus",
  default: {
    open: false,
  },
});

export const UserSettingModalStatus = atom({
  key: "UserSettingModalStatus",
  default: {
    open: false,
  },
});

export const DataConsolidationModalStatus = atom({
  key: "DataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
    prev_table_name: null,
  },
});

export const PackingListDataConsolidationModalStatus = atom({
  key: "PackingListDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
    prev_table_name: null,
  },
});

export const ReleaseDataConsolidationModalStatus = atom({
  key: "ReleaseDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
  },
});

export const RequestDataConsolidationModalStatus = atom({
  key: "RequestDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
  },
});

export const QtyControlDataConsolidationModalStatus = atom({
  key: "QtyControlDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    inv_name: null,
  },
});

export const UserAddMenuModalStatus = atom({
  key: "UserAddMenuModalStatus",
  default: {
    open: false,
    target_id: null,
    user_id: null,
    username: null,
  },
});

export const MenuAddUserModalStatus = atom({
  key: "MenuAddUserModalStatus",
  default: {
    open: false,
    target_id: null,
    menu_url: null,
    menu_name: null,
  },
});

export const ProjectAddUserModalStatus = atom({
  key: "ProjectAddUserModalStatus",
  default: {
    open: false,
    sales_id: null,
    sales_lead: null,
  },
});

export const MaterialModalStatus = atom({
  key: "MaterialModalStatus",
  default: {
    open: false,
    table_name: null,
    parent_id: null,
    query_key: null,
  },
});

export const ContactPointStatus = atom({
  key: "ContactPointStatus",
  default: {
    open: false,
    table_name: null,
    sales_id: null,
    customer_id: null,
  },
});

export const JobNumberStatus = atom({
  key: "JobNumberStatus",
  default: {
    open: false,
    sales_id: null,
    query_key: null,
  },
});

export const OrgSettingStatus = atom({
  key: "OrgSettingStatus",
  default: { open: false },
});

export const JobLevelSettingStatus = atom({
  key: "JobLevelSettingStatus",
  default: { open: false, type: "" },
});

export const WorkPolicyStatus = atom({
  key: "WorkPolicyStatus",
  default: {
    open: false,
    type: "",
    title: "Work Policy Setting",
    id: null,
    onClose: () => {},
    initialPolicyName: "Policy Name",
    initialDayoffWeekData: initialWeekData,
    initialWorkingTime: "08:00",
    initialAttendanceTime: "09:00",
    initialBreakTime: { start: "12:00", end: "13:00" },
    initialUsers: [],
  },
});

export const WorkTypePolicyStatus = atom({
  key: "WorkTypePolicyStatus",
  default: {
    open: false,
    type: "",
    title: "Work Type Policy Setting",
    id: null,
    onClose: () => {},
    initialWorkTypeName: "Work Type Poliy",
    initialOvertimeUse: false,
    initialOffdayCountUse: false,
    initialApproveUse: false,
  },
});

export const AnnualLeavePolicyStatus = atom({
  key: "AnnualLeavePolicyStatus",
  default: {
    open: false,
    type: "",
    title: "Annual Leave Policy Setting",
    id: null,
    origin_id: null,
    onClose: () => {},
    initialAnnualLeavePolicyName: "Annual Leave Policy",
    initialApproveUse: false,
    initialCancelApproveUse: false,
    initialHalfDayoffUse: false,
    initialRechargeConditionId: "",
    initialPostponeMonth: "0",
    initialUsers: [],
  },
});

export const DayoffPolicyStatus = atom({
  key: "DayoffPolicyStatus",
  default: {
    open: false,
    type: "",
    title: "Dayoff Policy Setting",
    id: null,
    origin_id: null,
    onClose: () => {},
    initialDayoffPolicyName: "Dayoff Policy",
    initialApproveUse: false,
    initialCancelApproveUse: false,
    initialMaxDuration: 0,
    initialPayorNot: false,
    initialAttachmentRequired: false,
    initialActiveAfterAnnualleave: true,
    initialRechargeConditionId: [],
  },
});

export const RechargeConditionStatus = atom({
  key: "RechargeConditionStatus",
  default: {
    open: false,
    //create or update
    action_type: "",
    title: "Recharge Condition Setting",
    id: "",
    onClose: () => {},
    initialAnnualLeaveRechargeConditionName: "Recharge Condition",
    initialCreateStartDateOption: "accounting date",
    initialRechargeDays: 0,
    initialNewcomerStartOption: "",
    initialDefaultAnnualLeaveCount: 0,
    initialAnnualLeaveAdditionalCount: 0,
    initialAnnualLeaveAdditionalYear: 0,
    initialAnnualLeaveAdditionalCycle: 0,
    initialUnderDecimalOption: "",
    newcomerStartOptionsList: [],
    underDecimalOptionsList: [],
    reFresh: () => {},
  },
});

export const HrWorkPolicyData = atom({
  key: "HrWorkPolicyData",
  default: {
    annual_policy_setting: true,
    working_time: "",
    attendance_time: "",
    break_time_start: "",
    break_time_end: "",
    day_off_weekday_list: [],
    annual_leave_approve_use: true,
    half_dayoff_use: true,
    work_type_policy_list: [],
    dayoff_policy_list: [],
    approval_user_list: [],
    annual_leave_policy_id: 0,
  },
});

export const HrCalendarUsingModalStatus = atom({
  key: "HrCalendarUsingModalStatus",
  default: {
    open: false,
    onClose: () => {},
    query_value: [],
    title: "",
    startYear: "",
    startMonth: "",
    startDay: "",
    endYear: "",
    endMonth: "",
    endDay: "",
  },
});

export const ApprovalLineModalStatus = atom({
  key: "ApprovalLineModalStatus",
  default: {
    open: false,
    onClose: () => {},
    onConfirm: () => {},
    user_list: [],
  },
});

export const ApprovalModalStatus = atom({
  key: "ApprovalModalStatus",
  default: {
    open: false,
    onClose: () => {},
    list_refresh: () => {},
    count_query_key: "approval_count",
    id: null,
    modal_type: null,
  },
});

export const PayrollDeductionPolicyModalStatus = atom({
  key: "PayrollDeductionPolicyModalStatus",
  default: {
    open: false,
    onClose: () => {},
    title: "Deduction Policy Setting",
    type: "create",
    id: null,
    origin_id: null,
    initialPolicyName: "Deduction Policy",
    initialDeductionMethod: "cost",
    initialDeductionPercentage: 0,
    initialDeductionPrice: 0,
    initialNote: "",
  },
});

export const AnnualIncomeAddModalStatus = atom({
  key: "AnnualIncomeAddModalStatus",
  default: {
    open: false,
    onClose: () => {},
    query_key: "",
    title: "Add Annual Salary",
    user_id: null,
  },
});

export const SalaryAdditionalModalStatus = atom({
  key: "SalaryAdditionalModalStatus",
  default: {
    open: false,
    onClose: () => {},
    query_key: "",
    title: "Additional Allowance Setting",
    type: null,
    user_id: null,
    year: null,
    month: null,
    confirm: 0,
    btn_use: true,
  },
});

export const SalaryDeductionModalStatus = atom({
  key: "SalaryDeductionModalStatus",
  default: {
    open: false,
    onClose: () => {},
    query_key: "",
    title: "Deduction Setting",
    type: null,
    user_id: null,
    year: null,
    month: null,
    confirm: 0,
    btn_use: true,
  },
});

export const CostApprovalModalStatus = atom({
  key: "CostApprovalModalStatus",
  default: {
    open: false,
    onClose: () => {},
    list_refresh: () => {},
    count_query_key: "approval_count",
    id: null,
    table_name: null,
    modal_type: null,
    // table_name: "initial_budget",
    // modal_type: "approval",
  },
});

export const InitialBudgetRowModalStatus = atom({
  key: "InitialBudgetRowModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    cost_code: "",
    budget_cost: 0,
    remark: "",
    modal_type: "create",
  },
});

export const BudgetTransferRowModalStatus = atom({
  key: "BudgetTransferRowModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    from_cost_code: "",
    to_cost_code: "",
    budget_transfer_cost: 0,
    remark: "",
    modal_type: "create",
  },
});

export const BudgetChangeRowModalStatus = atom({
  key: "BudgetChangeRowModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    cost_code: "",
    budget_change_cost: 0,
    remark: "",
    modal_type: "create",
  },
});

export const BudgetUsageRowModalStatus = atom({
  key: "BudgetUsageRowModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    cost_code: "",
    expenses_cost: 0,
    remark: "",
    modal_type: "create",
  },
});

export const InvoiceRowModalStatus = atom({
  key: "InvoiceRowModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    cost_code: "",
    total_amount: 0,
    prev_invoice_amount: 0,
    invoice_cost: 0,
    remark: "",
    modal_type: "create",
  },
});

export const ManpowerReqestModalStatus = atom({
  key: "ManpowerReqestModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    modal_type: "create",
  },
});

export const MaterialApprovalRequestModalStatus = atom({
  key: "MaterialApprovalRequestModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
    table_name: null,
  },
});

export const MaterialApprovalModalStatus = atom({
  key: "MaterialApprovalModalStatus",
  default: {
    open: false,
    onClose: () => {},
    count_query_key: "approval_count",
    id: null,
    table_name: null,
    modal_type: "create",
  },
});

export const ManpowerRequestApprovalModalStatus = atom({
  key: "ManpowerRequestApprovalModalStatus",
  default: {
    open: false,
    onClose: () => {},
    count_query_key: "approval_count",
    id: null,
    table_name: null,
    modal_type: "create",
  },
});

export const EditManpowerRequestModalStatus = atom({
  key: "EditManpowerRequestModalStatus",
  default: {
    open: false,
    onClose: () => {},
    list_query_key: "",
    id: null,
    modal_type: "update",
  },
});

export const CandidateAddModalStatus = atom({
  key: "CandidateAddModalStatus",
  default: {
    open: false,
    onClose: () => {},
    list_query_key: "",
    id: null,
    modal_type: "create",
  },
});

export const CandidateListModalStatus = atom({
  key: "CandidateListModalStatus",
  default: {
    open: false,
    onClose: () => {},
    id: null,
  },
});
