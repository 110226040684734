import React, { useMemo, useState } from "react";
import PolicyModalBody from "../PolicyModalBody";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const AddDetailModal = ({
  type,
  modal_type,
  confirm,
  open,
  onClose = () => {},
  user_id,
  year,
  month,
  refresh = () => {},
  detailFresh = () => {},
}) => {
  const initialName =
    type === "deduction" ? "Deduction Item" : "Additional Item";
  const [name, setName] = useState(initialName);
  const [price, setPrice] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);

  const save_btn_disabled = useMemo(() => {
    if (name.length === 0 || price <= 0 || price === "") {
      return true;
    }

    return false;
  }, [name, price]);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*\.?\d*$/;

    // if (value === "") {
    //   setValue(0);
    //   return;
    // }

    if (isValidNumber.test(value)) {
      setValue(value);
    }
  };

  const addItem = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestaddItem = await userPermAxios.post(
        `/payroll/${modal_type}/add_detail_salary_list`,
        {
          type,
          name,
          price,
          user_id,
          year,
          month,
        }
      );

      const { success, message } = requestaddItem;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        detailFresh();
        onClose();
      }
    }
  };

  const confirmAdditem = () => {
    if (!dubleClick) {
      return;
    }

    const msg = `Are you sure you want to new ${initialName}?`;

    return create_alert_fn("info", msg, () => addItem(), true);
  };

  return (
    <PolicyModalBody
      policyName={name}
      setPolicyName={setName}
      title={initialName}
      open={open}
      onClose={onClose}
      button_disabled={confirm === 1}
      save_btn_disabled={save_btn_disabled}
      saveFn={confirmAdditem}
      disableEscapeKeyDown={!dubleClick}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{ height: 44 }}
          >
            <div className="work-policy-modal-detail-contents-title">
              {initialName} Price
            </div>
            <NumberInput
              value={price}
              setValue={setPrice}
              onChange={handleNumberInputValue}
              disabled={confirm === 1}
            />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default AddDetailModal;

const NumberInput = ({ value, setValue, onChange, disabled = false }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{ width: 167, boxSizing: "border-box" }}
    />
  );
};
