import React from "react";
import DailyWageManagement from "@/components/payrollManagement/DailyWageManagement";

const DailyWageManagementPage = () => {
  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title">
        Daily Wage Management
      </div>
      <div className="company-setting-contents-body">
        <DailyWageManagement />
      </div>
    </div>
  );
};

export default DailyWageManagementPage;
