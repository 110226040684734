import React from "react";
import ApprovalManagementBody from "@/components/approvalManagement/ApprovalManagementBody";

const ApprovalManagementPage = () => {
  return (
    <div className="memu-total-body hr-menu-total-body">
      <div className="hr-menu-total-title-box">
        <div className="hr-menu-total-title">Approval Management</div>
      </div>
      <div className="hr-menu-body">
        <ApprovalManagementBody />
      </div>
    </div>
  );
};

export default ApprovalManagementPage;
