import React from "react";
import { Button } from "@mui/material";

const ModalBtnGroup = ({
  onClose = () => {},
  onAction = () => {},
  actionLabel = "Save",
  actionDisabled = false,
}) => {
  return (
    <div className="default-modal-btn-group-box">
      <Button
        role={undefined}
        variant="outlined"
        tabIndex={-1}
        onClick={onClose}
        className="default-modal-btn-group-cancel-btn"
      >
        Cancel
      </Button>
      <Button
        onClick={onAction}
        className="default-modal-btn-group-action-btn"
        disabled={actionDisabled}
      >
        {actionLabel}
      </Button>
    </div>
  );
};

export default ModalBtnGroup;
