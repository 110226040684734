import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const SelectTable = ({
  rows = [],
  columns = [],
  checkboxSelection = true,
  selectModel = [],
  handleSelectionChange = () => {},
}) => {
  return (
    <DataGridPro
      rows={rows}
      columns={columns}
      checkboxSelection={checkboxSelection}
      rowSelectionModel={selectModel}
      onRowSelectionModelChange={handleSelectionChange}
      rowHeight={28}
      columnHeaderHeight={28}
      sx={{
        ...materialTableStyle,
        ...TableScrollbarStyle,
        // "& .already-selection-row": {
        //   backgroundColor: "#F9FAFB",
        //   color: "#D0D5DD",
        // },
      }}
      // isRowSelectable={(params) => {
      //   if (isRowSelectableField === undefined) {
      //     return true;
      //   }

      //   const row_status = params?.row?.[isRowSelectableField];

      //   if (row_status === 1) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // }}
      hideFooter
      disableColumnPinning
      disableColumnSelector
      disableDensitySelector
    />
  );
};

export default SelectTable;
