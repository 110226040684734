import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ApprovalLineModalStatus } from "@/atoms/modalStatus";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import _ from "lodash";
import ModalBody from "@components/ModalBody";
import SelectDataTable from "@components/SelectDataTable";
import { CustomNormalTextTypeCell } from "@components/Cells";

const ApprovalLineModal = ({ approvalLineData }) => {
  const approvalLineModalStatus = useRecoilValue(ApprovalLineModalStatus);

  const [selectModel, setSelectModel] = useState([]);
  const [approvalList, setApprovalList] = useState(
    approvalLineData.approval_list
  );

  const [carbonCopyList, setCarbonCopyList] = useState(
    approvalLineData.carbon_copy_list
  );

  const { open, onClose, onConfirm, user_list } = approvalLineModalStatus;

  const deleteApprovalList = (id) => {
    setApprovalList((prev) => {
      return prev
        .filter((c) => c.id !== id)
        .map((c, i) => {
          return { ...c, order: i + 1 };
        });
    });
  };

  const deleteCarbonCopyList = (id) => {
    setCarbonCopyList((prev) => {
      return prev.filter((c) => c.id !== id);
    });
  };

  const orderApprovalList = (id, type) => {
    if (type === "up") {
      const findTargetListIndex = approvalList.findIndex((c) => c.id === id);

      if (findTargetListIndex <= 0) {
        return;
      }

      const prevListIndex = findTargetListIndex - 1;

      const copy_list = _.sortBy([...approvalList], "order");

      const tempOrder = copy_list[findTargetListIndex].order;

      copy_list[findTargetListIndex].order = copy_list[prevListIndex].order;
      copy_list[prevListIndex].order = tempOrder;

      setApprovalList([..._.sortBy(copy_list, "order")]);
    }

    if (type === "down") {
      const findTargetListIndex = approvalList.findIndex((c) => c.id === id);

      if (
        findTargetListIndex < 0 ||
        findTargetListIndex === approvalList.length - 1
      ) {
        return;
      }

      const nextListIndex = findTargetListIndex + 1;

      const copy_list = _.sortBy([...approvalList], "order");

      const tempOrder = copy_list[findTargetListIndex].order;

      copy_list[findTargetListIndex].order = copy_list[nextListIndex].order;
      copy_list[nextListIndex].order = tempOrder;

      setApprovalList([..._.sortBy(copy_list, "order")]);
    }

    return;
  };

  const selectUser = (type) => {
    const customSlectModal = selectModel.map((c) => {
      return { id: c };
    });

    if (type === "approval_line") {
      const differenceModel = _.differenceBy(
        customSlectModal,
        approvalList,
        "id"
      );

      setApprovalList((prev) => [
        ...prev,
        ...differenceModel.map((c, i) => {
          const { id } = c;
          const findUserInfo = user_list.find((c2) => c2.id === id);

          return {
            id: id,
            name: findUserInfo?.username,
            lv_name: findUserInfo?.lv_name,
            order: approvalList.length + 1 + i,
          };
        }),
      ]);
    }

    if (type === "carbon_copy") {
      const differenceModel = _.differenceBy(
        customSlectModal,
        carbonCopyList,
        "id"
      );

      setCarbonCopyList((prev) => [
        ...prev,
        ...differenceModel.map((c, i) => {
          const { id } = c;
          const findUserInfo = user_list.find((c2) => c2.id === id);

          return {
            id: id,
            name: findUserInfo?.username,
            lv_name: findUserInfo?.lv_name,
          };
        }),
      ]);
    }

    return;
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Approval Line Setting"
      disableEscapeKeyDown={true}
      modalWidth={840}
      modalHeight={800}
    >
      <div className="approval-line-modal-contents-body">
        <SelectDataTable
          title="User List"
          rows={user_list}
          setSelectModel={setSelectModel}
          selectModel={selectModel}
          table_height={250}
          columns={createUserListColumn()}
        />
        <div className="approval-line-select-btn-box">
          <SelectBtn onClick={() => selectUser("approval_line")} />
          <SelectBtn onClick={() => selectUser("carbon_copy")} />
        </div>
        <div className="approval-line-select-user-body">
          <SelectedUserList
            title="Approval Line"
            user_list={approvalList}
            delete_fn={deleteApprovalList}
            order_fn={orderApprovalList}
            order_use={true}
          />

          <SelectedUserList
            title="Carbon Copy"
            user_list={carbonCopyList}
            delete_fn={deleteCarbonCopyList}
          />
        </div>
      </div>
      <div className="hr-calendar-using-modal-btn-body">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="hr-calendar-using-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm(approvalList, carbonCopyList);
          }}
          className="hr-calendar-using-modal-update-btn"
          // disabled={approvalList.length === 0 && carbonCopyList.length === 0}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default ApprovalLineModal;

const UserCard = ({
  name = "",
  job_position = "",
  id = 0,
  order_use = false,
  order_fn = () => {},
  delete_fn = () => {},
}) => {
  return (
    <div className="user-card-body">
      <div className="user-card-inform-box">
        <div className="user-card-inform-name">{name}</div>
        <div className="user-card-inform-job-position">{job_position}</div>
      </div>
      <div className="user-card-btn-box">
        {order_use && (
          <>
            <div
              className="user-card-btn rotate"
              onClick={() => order_fn(id, "up")}
            >
              <BtnIcon />
            </div>
            <div className="user-card-btn" onClick={() => order_fn(id, "down")}>
              <BtnIcon />
            </div>
          </>
        )}
        <div className="user-card-btn" onClick={() => delete_fn(id)}>
          <BtnIcon2 />
        </div>
      </div>
    </div>
  );
};

const SelectedUserList = ({
  title,
  user_list,
  delete_fn,
  order_use,
  order_fn,
}) => {
  return (
    <div className="approval-line-selected-user-body">
      <div className="approval-line-selected-user-title">{title}</div>
      <div className="approval-line-selected-user-box">
        {user_list.map((c) => {
          const { id, name, lv_name } = c;

          return (
            <UserCard
              key={id}
              id={id}
              name={name}
              job_position={lv_name}
              delete_fn={delete_fn}
              order_use={order_use}
              order_fn={order_fn}
            />
          );
        })}
      </div>
    </div>
  );
};

const BtnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M14.9858 7.40355C15.2469 7.66467 15.2469 8.08567 14.9858 8.34679L10.5573 12.7752C10.3495 12.9831 10.0138 12.9831 9.80594 12.7752L5.37748 8.34679C5.11636 8.08567 5.11636 7.66467 5.37748 7.40355C5.63861 7.14242 6.05961 7.14242 6.32073 7.40355L10.1843 11.2618L14.0479 7.39822C14.3037 7.14242 14.73 7.14242 14.9858 7.40355Z"
        fill="#344054"
      />
    </svg>
  );
};

const BtnIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10 8.81641C9.6131 8.81641 9.60125 8.81647 9.30556 8.81647H4.44941C4.0625 8.81647 3.75 9.23534 3.75 9.75394C3.75 10.2725 4.0625 10.6914 4.44941 10.6914L9.3006 10.6914C10 10.6914 9.6131 10.6914 10 10.6914C10.3869 10.6914 7.40524 10.6914 10.6994 10.6914H15.5506C15.9375 10.6914 16.25 10.2725 16.25 9.75394C16.25 9.23534 15.9375 8.81647 15.5506 8.81647L10.6994 8.81647C9.43935 8.81643 10.377 8.81641 10 8.81641Z"
        fill="#344054"
      />
    </svg>
  );
};

const SelectBtn = ({ onClick = () => {} }) => {
  return (
    <div className="approval-line-select-body">
      <button className="approval-line-select-btn" onClick={onClick}>
        <BtnIcon />
      </button>
    </div>
  );
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const createUserListColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "org_setting_name",
      headerName: "Department",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "lv_name",
      headerName: "Job Position",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "fn_name",
      headerName: "Job Function",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};
