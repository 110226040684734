import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import ModalBody from "@components/ModalBody";
import { transferHistoryColumn } from "./Columns";

const TransferHistoryModal = ({ open, onClose, rows = [] }) => {
  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Transfer History"
      modalWidth={840}
      modalHeight={410}
    >
      <div
        className="my-info-modal-body select-data-table-table-box"
        style={{ width: "100%", height: "100%", boxSizing: "border-box" }}
      >
        <div style={{ width: "100%", height: 307, boxSizing: "border-box" }}>
          <DataGridPro
            rows={rows}
            columns={transferHistoryColumn}
            rowHeight={28}
            columnHeaderHeight={28}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </div>
    </ModalBody>
  );
};

export default TransferHistoryModal;
