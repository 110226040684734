import React from "react";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import {
  ManpowerRequestApprovalModalStatus,
  EditManpowerRequestModalStatus,
  CandidateAddModalStatus,
  CandidateListModalStatus,
} from "@/atoms/modalStatus";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { IconButton } from "@mui/material";
import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const manpowerRequestColum = (list_query_key) => [
  {
    field: "approval_id",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <ApprovedCell params={params} />;
    },
  },
  {
    field: "CandidateListCell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <CandidateListCell params={params} />;
    },
  },

  {
    field: "id",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },

  {
    field: "org_setting_name",
    headerName: "Nec. Department",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_name",
    headerName: "Nec. Job Position",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "fn_name",
    headerName: "Nec. Job Grade",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "reason_for_hiring",
    headerName: "Reason for Hiring",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "request_username",
    headerName: "Req. User",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "request_date",
    headerName: "Date of Request",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  // {
  //   field: "approval_date",
  //   headerName: "Date of Approval",
  //   flex: 1,
  //   data_type: "string",
  //   editable: false,
  //   renderCell: (params) => {
  //     return <CustomNormalTextTypeCell params={params} />;
  //   },
  // },
  {
    field: "hiring_date",
    headerName: "Date of Hired",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approval_status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "draft_username",
    headerName: "Draft User",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approved_status",
    headerName: "Draft Status",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "row_fixed_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <FiexedCell
          params={params}
          list_query_key={list_query_key}
          field="id"
        />
      );
    },
  },
];

export const selectManpowerReqColumn = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 40,
    renderCell: (params) => {
      return <CustomDetailCheckRow params={params} />;
    },
  },
  {
    field: "request_username",
    headerName: "Req. User",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "nec_org_setting_name",
    headerName: "Nec. Department",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "nec_lv_name",
    headerName: "Nec. Job Position",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "nec_fn_name",
    headerName: "Nec. Job Grade",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const candidateRecordColumn = (list_query_key) => [
  {
    field: "approval_id",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <ApprovedCell params={params} />;
    },
  },
  // {
  //   field: "row_fixed_cell",
  //   headerName: "",
  //   width: 40,
  //   editable: false,
  //   resizable: false,
  //   filterable: false,
  //   sortable: false,
  //   disableColumnMenu: true,
  //   renderCell: (params) => {
  //     return (
  //       <FiexedCell
  //         params={params}
  //         list_query_key={list_query_key}
  //         field="manpower_request_id"
  //       />
  //     );
  //   },
  // },
  {
    field: "id",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "manpower_request_id",
    headerName: "Req No.",
    width: 100,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_date",
    headerName: "Interview Date",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_time",
    headerName: "Interview Time",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_status",
    headerName: "Interview Status",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_result",
    headerName: "Interview Result",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "joining_date",
    headerName: "Expected Date of Joining",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "row_fixed_cell2",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <FiexedCell2 params={params} list_query_key={list_query_key} />;
    },
  },
];

export const candidateListColumn = [
  {
    field: "item_no",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_date",
    headerName: "Interview Date",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_time",
    headerName: "Interview Time",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_status",
    headerName: "Interview Status",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "interview_result",
    headerName: "Interview Result",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "joining_date",
    headerName: "Expected Date of Joining",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const ApprovedCell = ({ params }) => {
  const { value } = params;

  const setStatus = useSetRecoilState(ManpowerRequestApprovalModalStatus);
  const onCloseModal = useResetRecoilState(ManpowerRequestApprovalModalStatus);

  const openModal = () => {
    setStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id: value,
        table_name: "material_request",
        modal_type: "carbon_copy",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <DocumentIcon />
    </IconButton>
  );
};

const FiexedCell = ({ params, list_query_key, field = "id" }) => {
  const { row = {} } = params;

  const { approved_status } = row;

  const setStatus = useSetRecoilState(EditManpowerRequestModalStatus);
  const onCloseModal = useResetRecoilState(EditManpowerRequestModalStatus);

  const openModal = () => {
    if (approved_status === "Approved") {
      setStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: onCloseModal,
          list_query_key,
          id: row[field],
          modal_type: "update",
        };
      });
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      disabled={approved_status !== "Approved"}
    >
      <EditIcon />
    </IconButton>
  );
};

const FiexedCell2 = ({ params, list_query_key }) => {
  const { id, row = {} } = params;

  const { approved_status } = row;

  const setStatus = useSetRecoilState(CandidateAddModalStatus);
  const onCloseModal = useResetRecoilState(CandidateAddModalStatus);

  const openModal = () => {
    if (approved_status === "Approved") {
      setStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: onCloseModal,
          list_query_key,
          id: id,
          modal_type: "update",
        };
      });
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      disabled={approved_status !== "Approved"}
    >
      <EditIcon />
    </IconButton>
  );
};

const CandidateListCell = ({ params }) => {
  const { id, row = {} } = params;

  const { approved_status } = row;

  const setStatus = useSetRecoilState(CandidateListModalStatus);
  const onCloseModal = useResetRecoilState(CandidateListModalStatus);

  const openModal = () => {
    if (approved_status === "Approved") {
      setStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: onCloseModal,
          id,
        };
      });
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      onClick={openModal}
      disabled={approved_status !== "Approved"}
    >
      <CandidateIcon />
    </IconButton>
  );
};

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M4.8 14.9004V17.9H7.79959L16.6464 9.0532L13.6468 6.05361L4.8 14.9004ZM18.9661 6.73352C19.0402 6.65952 19.0991 6.57162 19.1392 6.47485C19.1793 6.37809 19.2 6.27435 19.2 6.16959C19.2 6.06483 19.1793 5.9611 19.1392 5.86434C19.0991 5.76757 19.0402 5.67967 18.9661 5.60567L17.0943 3.73392C17.0203 3.65977 16.9324 3.60094 16.8357 3.5608C16.7389 3.52066 16.6352 3.5 16.5304 3.5C16.4256 3.5 16.3219 3.52066 16.2251 3.5608C16.1284 3.60094 16.0405 3.65977 15.9665 3.73392L14.5027 5.19772L17.5023 8.19732L18.9661 6.73352Z"
        fill="#344054"
      />
      <path d="M3 19.7H21V21.5H3V19.7Z" fill="#344054" />
    </svg>
  );
};

const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.68238 7.17865C5.44304 7.17865 5.24902 7.37267 5.24902 7.612V8.26203C5.24902 8.50137 5.44304 8.69539 5.68238 8.69539H6.33241C6.57174 8.69539 6.76576 8.50137 6.76576 8.26203V7.612C6.76576 7.37267 6.57174 7.17865 6.33241 7.17865H5.68238Z"
        fill="#344054"
      />
      <path
        d="M8.79557 7.29384C8.50625 7.29384 8.27171 7.52419 8.27171 7.80834V8.0656C8.27171 8.34975 8.50625 8.5801 8.79557 8.5801H18.2252C18.5145 8.5801 18.749 8.34975 18.749 8.0656V7.80834C18.749 7.52419 18.5145 7.29384 18.2252 7.29384H8.79557Z"
        fill="#344054"
      />
      <path
        d="M8.79557 11.3093C8.50625 11.3093 8.27171 11.5397 8.27171 11.8238V12.0811C8.27171 12.3652 8.50625 12.5956 8.79557 12.5956H18.2252C18.5145 12.5956 18.749 12.3652 18.749 12.0811V11.8238C18.749 11.5397 18.5145 11.3093 18.2252 11.3093H8.79557Z"
        fill="#344054"
      />
      <path
        d="M8.27171 15.8868C8.27171 15.6026 8.50625 15.3723 8.79557 15.3723H18.2252C18.5145 15.3723 18.749 15.6026 18.749 15.8868V16.144C18.749 16.4282 18.5145 16.6585 18.2252 16.6585H8.79557C8.50625 16.6585 8.27171 16.4282 8.27171 16.144V15.8868Z"
        fill="#344054"
      />
      <path
        d="M5.24902 11.675C5.24902 11.4356 5.44304 11.2416 5.68238 11.2416H6.33241C6.57174 11.2416 6.76576 11.4356 6.76576 11.675V12.325C6.76576 12.5643 6.57174 12.7583 6.33241 12.7583H5.68238C5.44304 12.7583 5.24902 12.5643 5.24902 12.325V11.675Z"
        fill="#344054"
      />
      <path
        d="M5.68238 15.3046C5.44304 15.3046 5.24902 15.4986 5.24902 15.7379V16.3879C5.24902 16.6273 5.44304 16.8213 5.68238 16.8213H6.33241C6.57174 16.8213 6.76576 16.6273 6.76576 16.3879V15.7379C6.76576 15.4986 6.57174 15.3046 6.33241 15.3046H5.68238Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99902 2.7C2.99902 2.03726 3.53628 1.5 4.19902 1.5H19.799C20.4618 1.5 20.999 2.03726 20.999 2.7V21.3C20.999 21.9627 20.4618 22.5 19.799 22.5H3.69902C3.31242 22.5 2.99902 22.1866 2.99902 21.8V2.7ZM4.12402 2.925C4.12402 2.75931 4.25834 2.625 4.42402 2.625H19.574C19.7397 2.625 19.874 2.75931 19.874 2.925V21.075C19.874 21.2407 19.7397 21.375 19.574 21.375H4.24069C4.17626 21.375 4.12402 21.3228 4.12402 21.2583V2.925Z"
        fill="#344054"
      />
    </svg>
  );
};

const CandidateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
    >
      <path
        d="M4.41914 17.808C4.41914 17.808 3.74414 17.808 3.74414 17.133C3.74414 16.458 4.41914 14.433 7.79414 14.433C11.1691 14.433 11.8441 16.458 11.8441 17.133C11.8441 17.808 11.1691 17.808 11.1691 17.808H4.41914Z"
        fill="#344054"
      />
      <path
        d="M7.79414 13.758C8.91252 13.758 9.81914 12.8514 9.81914 11.733C9.81914 10.6146 8.91252 9.70801 7.79414 9.70801C6.67576 9.70801 5.76914 10.6146 5.76914 11.733C5.76914 12.8514 6.67576 13.758 7.79414 13.758Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 5.79688V16.4844C18.75 17.727 17.6307 18.7344 16.25 18.7344H15.75V19.4844C15.75 20.727 14.6307 21.7344 13.25 21.7344H3.25C1.86929 21.7344 0.75 20.727 0.75 19.4844V5.98437C0.75 4.74173 1.86929 3.73438 3.25 3.73438H3.75V2.98437C3.75 1.74173 4.86929 0.734375 6.25 0.734375H13.125L18.75 5.79688ZM5 3.73438H10.125L15.75 8.79688V17.6094H16.25C16.9404 17.6094 17.5 17.1057 17.5 16.4844V5.79688H15C13.9645 5.79688 13.125 5.04136 13.125 4.10938V1.85938H6.25C5.55964 1.85938 5 2.36305 5 2.98437V3.73438ZM10.125 7.10938C10.125 8.04136 10.9645 8.79688 12 8.79688H14.5V19.4844C14.5 20.1057 13.9404 20.6094 13.25 20.6094H3.25C2.55964 20.6094 2 20.1057 2 19.4844V5.98437C2 5.36305 2.55964 4.85938 3.25 4.85938H10.125V7.10938Z"
        fill="#344054"
      />
    </svg>
  );
};
