import React, { useMemo, useState } from "react";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { FileUploader } from "react-drag-drop-files";
import { defaultAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import { downloadBlobFile } from "@/utils/downloadFns";
import * as XLSX from "xlsx";
import "../ExcelImportBtn/excelImportBtn.scss";

const ImportModal = ({
  open,
  onClose = () => {},
  setBudgetTransferRow,
  cost_code_list = [],
}) => {
  const [file, setFile] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const handleChange = (file) => {
    setFile(file);
  };

  const downloadTempleteFile = async () => {
    try {
      const requestTempleteFile = await defaultAxios.get(
        "/download/budget_transfer_templete",
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      downloadBlobFile(
        requestTempleteFile,
        "budget_reallocation_import_temp.xlsx"
      );
    } catch (err) {
      create_alert_fn(
        "warning",
        "Error Download Budget Reallocation Import Templete File"
      );
    }
  };

  const createMaterialMaster = async () => {
    if (!dubleClick) {
      return;
    }
    setDubleClick(false);

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets.Form;

        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        if (!jsonData || jsonData.length === 0) {
          return;
        }

        const changeJsonData = jsonData.map((c, i) => {
          const result = {
            id: i,
            from_cost_code_id: null,
            from_cost_code: "",
            from_description: "",
            to_cost_code_id: null,
            to_cost_code: "",
            to_description: "",
            budget_transfer_cost: 0,
            remark: "",
            notAccept: false,
          };

          fieldMatching.forEach((c2) => {
            const { excel_header, table_header } = c2;

            result[table_header] = c[excel_header];
          });

          const { from_cost_code, to_cost_code } = result;

          const findFromDescription = cost_code_list.find((c2) => {
            return c2.code === from_cost_code;
          });

          const findToDescription = cost_code_list.find((c2) => {
            return c2.code === to_cost_code;
          });

          if (findFromDescription) {
            const { id, lv_2_name } = findFromDescription;
            result.from_description = lv_2_name;
            result.from_cost_code_id = id;
          } else {
            result.remark = "Can Not Found Code Code Description";
            result.notAccept = true;
          }

          if (findToDescription) {
            const { id, lv_2_name } = findToDescription;
            result.to_description = lv_2_name;
            result.to_cost_code_id = id;
          } else {
            result.remark = "Can Not Found Code Code Description";
            result.notAccept = true;
          }

          return result;
        });

        setBudgetTransferRow(changeJsonData);
      };

      reader.readAsArrayBuffer(file);
    } catch (err) {
    } finally {
      setDubleClick(true);

      onClose();
    }
  };

  const button_disabled = useMemo(() => {
    if (!file) {
      return true;
    }

    return false;
  }, [file]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="excel-import-modal-total-body">
        <div className="excel-import-modal-header-box">
          <div className="excel-import-modal-header-title">
            Budget Reallocation Excel Import
          </div>
          <IconButton
            onClick={onClose}
            className="excel-import-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>
        <div className="excel-import-modal-main-box">
          <IconButton
            className="excel-import-templete-download-btn"
            onClick={downloadTempleteFile}
          >
            <img src="/icons/excel_icon.png" alt="excel icon" /> Template File
            Download
          </IconButton>

          {!file ? (
            <FileUploader
              classes="excel-import-drag-drop-box"
              multiple={false}
              handleChange={handleChange}
              name="file"
              types={["xlsx"]}
              styles={{
                dropArea: {
                  border: "2px dashed #4a90e2",
                  borderRadius: "10px",
                  padding: "20px",
                  backgroundColor: "#red",
                },
                dropAreaActive: {
                  borderColor: "#6c757d",
                },
                inputLabel: {
                  color: "#000",
                },
              }}
            />
          ) : (
            <div className="excel-import-info-box">
              <div className="excel-import-info-name">{file && file?.name}</div>
              <IconButton
                className="excel-import-info-btn"
                onClick={() => {
                  setFile(null);
                }}
              >
                <CloseOutlined />
              </IconButton>
            </div>
          )}
        </div>
        <div className="excel-import-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="excel-import-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={createMaterialMaster}
            className="excel-import-modal-update-btn"
            disabled={button_disabled}
          >
            Import
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;

const fieldMatching = [
  { excel_header: "From Cost Code", table_header: "from_cost_code" },
  { excel_header: "To Cost Code", table_header: "to_cost_code" },
  { excel_header: "Reallocation Amount", table_header: "budget_transfer_cost" },
  { excel_header: "Remark", table_header: "remark" },
];
