import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { BudgetChangeRowModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import ModalBody from "../ModalBody";
import LabelSelect from "../LabelSelect";
import LabelInput from "../LabelInput";

const EditRowModal = ({ setBudgetChangeRow, cost_code_list }) => {
  const {
    open,
    onClose,
    id,
    cost_code,
    budget_change_cost,
    remark: initialRemark,
    modal_type,
  } = useRecoilValue(BudgetChangeRowModalStatus);

  const [costCodeId, setCostCodeId] = useState(null);
  const [costCode, setCostCode] = useState(cost_code);
  const [budgetCost, setSetBudgetCost] = useState(budget_change_cost);
  const [description, setDescription] = useState("");
  const [remark, setRemark] = useState(initialRemark);

  useEffect(() => {
    if (costCode === "") {
      setDescription("");
      setCostCode("");
      setCostCodeId(null);
    } else {
      const targetCostCode = cost_code_list.find((c) => c.code === costCode);

      setDescription(targetCostCode?.lv_2_name ?? "");

      if (targetCostCode) {
        const { id: cost_code_id } = targetCostCode;

        setCostCodeId(cost_code_id);
      } else {
        setDescription("");
        setCostCode("");
        setCostCodeId(null);
      }
    }
  }, [costCode, cost_code_list]);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*(\.\d{0,2})?$/;

    // if (value === "") {
    //   setValue(0);
    //   return;
    // }

    // `isValidNumber`로 입력값 검증
    if (isValidNumber.test(value)) {
      // 숫자 변환 전에 '-'만 입력된 상태를 처리
      if (value === "-") {
        setValue(value); // 값 그대로 유지
      } else {
        setValue(Number(value)); // 유효한 숫자 변환
      }
    }
  };

  const saveRowData = () => {
    if (modal_type === "create") {
      setBudgetChangeRow((prev) => {
        return [
          ...prev,
          {
            id,
            cost_code_id: costCodeId,
            cost_code: costCode,
            description: description,
            budget_change_cost: budgetCost,
            remark: remark,
            notAccept: false,
          },
        ];
      });

      onClose();
    }
    if (modal_type === "fixed") {
      setBudgetChangeRow((prev) => {
        return prev.map((c) => {
          if (c.id === id) {
            return {
              id,
              cost_code_id: costCodeId,
              cost_code: costCode,
              description: description,
              budget_change_cost: budgetCost,
              remark: remark,
              notAccept: false,
            };
          }
          return c;
        });
      });

      onClose();
    }

    return;
  };

  const button_disabled = useMemo(() => {
    if (costCode === "") {
      return true;
    }

    if (budgetCost === 0 || budgetCost === "") {
      return true;
    }

    if (costCodeId === null) {
      return true;
    }

    return false;
  }, [costCode, budgetCost, costCodeId]);

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Budget Change Row Edit"
      disableEscapeKeyDown={true}
      modalWidth={790}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Cost Code
            </div>
            <LabelSelect
              value={costCode}
              list={[
                { name: "", value: "" },
                ...cost_code_list.map((c) => {
                  const { code, lv_2_name, type } = c;
                  return {
                    name: `${type} | ${code}_${lv_2_name}`,
                    value: code,
                  };
                }),
              ]}
              setValue={setCostCode}
            />
          </div>

          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Change Amount
            </div>
            <NumberInput
              width="100%"
              value={budgetCost}
              onChange={handleNumberInputValue}
              setValue={setSetBudgetCost}
            />
          </div>
          <div className="work-policy-modal-time-picker-box">
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelInput value={remark} setValue={setRemark} />
            </div>
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => saveRowData()}
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default EditRowModal;

const NumberInput = ({
  value,
  setValue,
  onChange,
  disabled = false,
  width = "100%",
}) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{
        width,
        boxSizing: "border-box",
        marginTop: 4,
        height: 40,
        // borderColor: "rgb(118, 118, 118)",
      }}
    />
  );
};
