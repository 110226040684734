import React from "react";

const DecreaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5884 5.86974C11.7973 6.07864 11.7973 6.41543 11.5884 6.62433L8.04568 10.1671C7.87941 10.3334 7.61082 10.3334 7.44456 10.1671L3.90179 6.62433C3.69289 6.41543 3.69289 6.07864 3.90179 5.86974C4.11069 5.66084 4.44749 5.66084 4.65639 5.86974L7.74725 8.95633L10.8381 5.86547C11.0427 5.66084 11.3838 5.66084 11.5884 5.86974Z"
        fill="#344054"
      />
    </svg>
  );
};

export default DecreaseIcon;
