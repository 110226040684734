import React from "react";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SwicthBtn from "@components/SwicthBtn";
import { userPermAxios } from "@utils/customAxios";
dayjs.extend(customParseFormat);

const AnnualList = ({ items }) => {
  const query_annual_leave_sub_data = useQuery(
    [
      "annual_leave_sub_data",
      dayjs().format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ],
    () => {
      return query_fetch_data(dayjs(), dayjs());
    }
  );

  const { data: annualSubData = {}, isLoading } = query_annual_leave_sub_data;

  const { remaining_annal_leave_count = 0 } = annualSubData;

  if (isLoading) {
    return null;
  }

  return (
    <div className="approval-modal-items-body">
      <div className="approval-modal-items-title-box">
        <div className="approval-modal-items-title">
          Detailed Schedule (Remaining Annual Leave Count:{" "}
          {remaining_annal_leave_count})
        </div>
      </div>
      <div className="approval-modal-items-box">
        {items.map((c, i) => {
          const {
            id,
            date_year,
            date_month,
            date_day,
            morning_date_half,
            afternoon_date_half,
          } = c;

          const date = `${date_year}-${date_month}-${date_day}`;

          const week = dayjs(date).format("ddd");

          return (
            <div
              key={id}
              className="approval-modal-items-work-list-card align-center"
            >
              <div className="approval-modal-items-default-text">{`${
                i + 1
              }. ${date} (${week})`}</div>
              <div className="approval-modal-items-work-list-range-box">
                <div style={{ width: 180 }}>
                  <SwicthBtn
                    checked={morning_date_half === 1 ? true : false}
                    label={`Morning ${morning_date_half ? "ON" : "OFF"}`}
                    // onClick={() => handleHalfUse(c, "morning")}
                  />
                </div>
                <div style={{ width: 180 }}>
                  <SwicthBtn
                    checked={afternoon_date_half === 1 ? true : false}
                    label={`Afternoon ${afternoon_date_half ? "ON" : "OFF"}`}
                    // onClick={() => handleHalfUse(c, "afternoon")}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnnualList;

const query_fetch_data = async (startDate, endDate) => {
  let result = {
    remaining_annal_leave_count: 0,
    hoily_day_list: [],
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const startDay = startDate.format("DD");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");
    const endDay = endDate.format("DD");

    const requestAnnualSubData = await userPermAxios.get(
      `/hr/get_annual_sub_data?startYear=${startYear}&startMonth=${startMonth}&startDay=${startDay}&endYear=${endYear}&endMonth=${endMonth}&endDay=${endDay}`
    );

    const { success, data } = requestAnnualSubData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
