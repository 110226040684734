import React from "react";
import { useQuery } from "react-query";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  WorkPolicyStatus,
  WorkTypePolicyStatus,
  AnnualLeavePolicyStatus,
  DayoffPolicyStatus,
} from "@/atoms/modalStatus";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SettingMenuCard from "@/components/settingMenuCard/SettingMenuCard";
import PlusIcon from "@/components/Icons/PlusIcon";
import WorkPolicyModal from "@/components/dayOffSettingModals/WorkPolicyModal";
import WorkTypePolicyModal from "@/components/dayOffSettingModals/WorkTypePolicyModal";
import AnnualLeavePolicyModal from "@/components/dayOffSettingModals/AnnualLeavePolicyModal";
import DayoffPolicyModal from "@/components/dayOffSettingModals/DayoffPolicyModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";

dayjs.extend(customParseFormat);

const DayoffPolicySettingPage = () => {
  const [workPolicyModalStatus, setWorkPolicyModalStatus] =
    useRecoilState(WorkPolicyStatus);
  const [workTypePolicyModalStatus, setWorkTypePolicyModalStatus] =
    useRecoilState(WorkTypePolicyStatus);

  const [annualLeavePolicyModalStatus, setAnnualLeavePolicyModalStatus] =
    useRecoilState(AnnualLeavePolicyStatus);
  const [dayoffPolicyModalStatus, setDayoffPolicyModalStatus] =
    useRecoilState(DayoffPolicyStatus);

  const query_dayoff_policy_list = useQuery(
    "dayoff_policy_list",
    () => {
      return query_fetch_data();
    },
    {
      initialData: {
        work_policy: [],
        work_type_policy: [],
        annual_leave_policy: [],
        dayoff_policy: [],
      },
    }
  );

  const { data, isLoading } = query_dayoff_policy_list;

  const { work_policy, work_type_policy, annual_leave_policy, dayoff_policy } =
    data;

  const [refresh] = useRefreshReactQuery("dayoff_policy_list");

  const closeWorkPolicyModal = useResetRecoilState(WorkPolicyStatus);
  const closeWorkTypePolicyModal = useResetRecoilState(WorkTypePolicyStatus);
  const closeAnnualLeavePolicyModal = useResetRecoilState(
    AnnualLeavePolicyStatus
  );
  const closeDayoffPolicyModal = useResetRecoilState(DayoffPolicyStatus);

  const openModal = (type, setValue, onClose, title, initialData = {}) => {
    const typeValues = ["create", "update", "view"];

    if (!typeValues.includes(type)) {
      return;
    }

    setValue((prev) => {
      return {
        ...prev,
        open: true,
        type,
        title,
        onClose,
        ...initialData,
      };
    });
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="dayoff-policy-setting-page-body">
      <div className="dayoff-policy-setting-page-contents-box">
        <div className="dayoff-policy-setting-page-title-box">
          <div className="dayoff-policy-setting-page-title">Work Policy</div>
          <AddIconButton
            label="Add New Policy"
            onClick={() =>
              openModal(
                "create",
                setWorkPolicyModalStatus,
                closeWorkPolicyModal,
                "Work Policy Setting"
              )
            }
          />
        </div>
        {work_policy.map((c) => {
          const {
            id,
            work_policy_name,
            working_time,
            attendance_time,
            break_time_start,
            break_time_end,
            user_list,
            day_off_weekday_list,
          } = c;

          const dayjsWorkingTime = dayjs(working_time, "HH:mm").format("HH:mm");

          const dayjsAttendanceTime = dayjs(attendance_time, "HH:mm").format(
            "HH:mm"
          );

          const dayjsBreakStart = dayjs(break_time_start, "HH:mm").format(
            "HH:mm"
          );

          const dayjsBreakEnd = dayjs(break_time_end, "HH:mm").format("HH:mm");

          const initialData = {
            id,
            initialPolicyName: work_policy_name,
            initialDayoffWeekData:
              day_off_weekday_list.length === 0
                ? workPolicyModalStatus.initialDayoffWeekData
                : day_off_weekday_list.map((c) => {
                    return {
                      ...c,
                      bool: c.bool === 1 ? true : false,
                      value: Number(c.value),
                    };
                  }),
            initialWorkingTime: dayjsWorkingTime,
            initialAttendanceTime: dayjsAttendanceTime,
            initialBreakTime: { start: dayjsBreakStart, end: dayjsBreakEnd },
            initialUsers: user_list,
          };

          return (
            <SettingMenuCard
              key={id}
              icon={<WorkIcon />}
              title={work_policy_name}
              titlePart={`(Working Hour: ${dayjsWorkingTime})`}
              subtitle={`Breaking Time: ${dayjsBreakStart} ~ ${dayjsBreakEnd}`}
              onClick={() => {
                openModal(
                  "update",
                  setWorkPolicyModalStatus,
                  closeWorkPolicyModal,
                  "Work Policy Setting",
                  initialData
                );
              }}
              approvedReqUse={false}
              approvedReqValue={true}
            />
          );
        })}
      </div>

      <div className="dayoff-policy-setting-page-contents-box">
        <div className="dayoff-policy-setting-page-title-box">
          <div className="dayoff-policy-setting-page-title">
            Work Type Policy
          </div>
          <AddIconButton
            label="Add New Policy"
            onClick={() =>
              openModal(
                "create",
                setWorkTypePolicyModalStatus,
                closeWorkTypePolicyModal,
                "Work Type Policy Setting"
              )
            }
          />
        </div>
        {work_type_policy.map((c) => {
          const {
            id,
            work_type_name,
            overtime_use,
            offday_count_use,
            approve_use,
          } = c;

          const initialData = {
            id,
            initialWorkTypeName: work_type_name,
            initialOvertimeUse: overtime_use === 1 ? true : false,
            initialOffdayCountUse: offday_count_use === 1 ? true : false,
            initialApproveUse: approve_use === 1 ? true : false,
          };

          return (
            <SettingMenuCard
              key={id}
              icon={<WorkTypeIcon />}
              title={work_type_name}
              onClick={() => {
                openModal(
                  "update",
                  setWorkTypePolicyModalStatus,
                  closeWorkTypePolicyModal,
                  "Work Type Policy Setting",
                  initialData
                );
              }}
              approvedReqUse={true}
              approvedReqValue={approve_use === 1 ? true : false}
            />
          );
        })}
      </div>

      <div className="dayoff-policy-setting-page-contents-box">
        <div className="dayoff-policy-setting-page-title-box">
          <div className="dayoff-policy-setting-page-title">
            Annual Leave Policy
          </div>
          <AddIconButton
            label="Add New Policy"
            onClick={() => {
              openModal(
                "create",
                setAnnualLeavePolicyModalStatus,
                closeAnnualLeavePolicyModal,
                "Annual Leave Policy Setting"
              );
            }}
            width={166}
          />
        </div>
        {annual_leave_policy.map((c) => {
          const {
            id,
            annual_leave_policy_name,
            approve_use,
            cancel_approve_use,
            half_dayoff_use,
            recharge_condition_id,
            postpone_month,
            reflection_date,
            origin_id,
            user_list,
          } = c;

          const userListParse = user_list;

          const initialData = {
            initialAnnualLeavePolicyName: annual_leave_policy_name,
            initialApproveUse: approve_use === 1 ? true : false,
            initialCancelApproveUse: cancel_approve_use === 1 ? true : false,
            initialHalfDayoffUse: half_dayoff_use === 1 ? true : false,
            initialRechargeConditionId: [recharge_condition_id],
            initialPostponeMonth: postpone_month,
            initialUsers: userListParse,
            origin_id,
          };

          const dayjsReflectionDate =
            dayjs(reflection_date).format("YYYY-MM-DD");

          return (
            <SettingMenuCard
              key={id}
              icon={<AnnualLeaveIcon />}
              title={annual_leave_policy_name}
              subtitle={`Reflection Date : ${dayjsReflectionDate}`}
              onClick={() => {
                openModal(
                  "update",
                  setAnnualLeavePolicyModalStatus,
                  closeAnnualLeavePolicyModal,
                  "Annual Leave Policy Setting",
                  initialData
                );
              }}
              // toggleUse={true}
              // toggleValue={true}
              // toggleFn={() => {}}
              approvedReqUse={true}
              approvedReqValue={true}
            />
          );
        })}
      </div>
      <div className="dayoff-policy-setting-page-contents-box">
        <div className="dayoff-policy-setting-page-title-box">
          <div className="dayoff-policy-setting-page-title">Dayoff Policy</div>
          <AddIconButton
            label="Add New Policy"
            onClick={() => {
              openModal(
                "create",
                setDayoffPolicyModalStatus,
                closeDayoffPolicyModal,
                "Dayoff Policy Setting"
              );
            }}
            width={166}
          />
        </div>
        {dayoff_policy.map((c) => {
          const {
            id,
            dayoff_policy_name,
            approve_use,
            cancel_approve_use,
            max_duration,
            pay_or_not,
            attachment_required,
            active_after_annualleave,
            recharge_condition_id,
            reflection_date,
            origin_id,
          } = c;

          const initialData = {
            initialDayoffPolicyName: dayoff_policy_name,
            initialApproveUse: approve_use === 1 ? true : false,
            initialCancelApproveUse: cancel_approve_use === 1 ? true : false,
            initialMaxDuration: max_duration,
            initialPayorNot: pay_or_not === 1 ? true : false,
            initialAttachmentRequired: attachment_required === 1 ? true : false,
            initialActiveAfterAnnualleave:
              active_after_annualleave === 1 ? true : false,
            initialRechargeConditionId: [recharge_condition_id],
            origin_id,
          };

          const dayjsReflectionDate =
            dayjs(reflection_date).format("YYYY-MM-DD");

          return (
            <SettingMenuCard
              key={id}
              icon={<DayoffIcon />}
              title={dayoff_policy_name}
              subtitle={`Reflection Date : ${dayjsReflectionDate}`}
              onClick={() => {
                openModal(
                  "update",
                  setDayoffPolicyModalStatus,
                  closeDayoffPolicyModal,
                  "Dayoff Policy Setting",
                  initialData
                );
              }}
              // toggleUse={true}
              // toggleValue={true}
              // toggleFn={() => {}}
              approvedReqUse={true}
              approvedReqValue={approve_use === 1 ? true : false}
            />
          );
        })}
      </div>
      {workPolicyModalStatus.open && <WorkPolicyModal refresh={refresh} />}
      {workTypePolicyModalStatus.open && (
        <WorkTypePolicyModal refresh={refresh} />
      )}
      {annualLeavePolicyModalStatus.open && (
        <AnnualLeavePolicyModal refresh={refresh} />
      )}
      {dayoffPolicyModalStatus.open && <DayoffPolicyModal refresh={refresh} />}
    </div>
  );
};

export default DayoffPolicySettingPage;

const query_fetch_data = async () => {
  let result = {
    work_policy: [],
    work_type_policy: [],
    annual_leave_policy: [],
    dayoff_policy: [],
  };

  try {
    const requestDayoffPolicyList = await userPermAxios.get(
      "/dayoff/get_dayoff_policy_list"
    );

    const { success, data } = requestDayoffPolicyList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const AddIconButton = ({ label, onClick }) => {
  return (
    <IconButton
      className="dayoff-policy-setting-page-add-btn"
      onClick={onClick}
      // sx={{ width }}
    >
      <PlusIcon />
      <div className="dayoff-policy-setting-page-add-btn-label">{label}</div>
    </IconButton>
  );
};

const WorkIcon = () => {
  return;
};

const WorkTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M2.5 13.0554C2.5 12.4606 2.98215 11.9784 3.57692 11.9784H36.4231C37.0178 11.9784 37.5 12.4606 37.5 13.0554V32.44C37.5 33.0347 37.0178 33.5169 36.4231 33.5169H3.57692C2.98215 33.5169 2.5 33.0347 2.5 32.44V13.0554Z"
        fill="#E0BE87"
      />
      <path
        d="M31.3424 11.9784H36.4231C37.0178 11.9784 37.5 12.4606 37.5 13.0554V32.44C37.5 33.0347 37.0178 33.5169 36.4231 33.5169H31.3424V11.9784Z"
        fill="#D4B178"
      />
      <path
        d="M2.5 29.8189H37.5V32.44C37.5 33.0347 37.0178 33.5169 36.4231 33.5169H3.57692C2.98215 33.5169 2.5 33.0347 2.5 32.44V29.8189Z"
        fill="#D4B178"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6073 11.9784H3.57692C2.98215 11.9784 2.5 12.4606 2.5 13.0554V18.7092H37.5V13.0554C37.5 12.4606 37.0178 11.9784 36.4231 11.9784H27.3937C27.1946 11.0364 26.772 9.97781 26.0139 9.03768C24.8403 7.58232 22.9196 6.4834 20.0005 6.4834C17.0815 6.4834 15.1608 7.58232 13.9872 9.03768C13.2291 9.97781 12.8065 11.0364 12.6073 11.9784ZM14.2691 11.9784H25.7319C25.5567 11.3455 25.2486 10.6621 24.7564 10.0517C23.9108 9.00307 22.4661 8.09878 20.0005 8.09878C17.535 8.09878 16.0903 9.00307 15.2446 10.0517C14.7525 10.6621 14.4443 11.3455 14.2691 11.9784Z"
        fill="#B88E4B"
      />
      <path
        d="M31.3424 11.9784H36.4231C37.0178 11.9784 37.5 12.4606 37.5 13.0554V18.7092H31.3424V11.9784Z"
        fill="#B88E4B"
      />
      <path
        d="M16.6346 17.9015C16.6346 17.6041 16.8757 17.3631 17.1731 17.3631H22.8269C23.1243 17.3631 23.3654 17.6041 23.3654 17.9015V20.3246C23.3654 20.622 23.1243 20.8631 22.8269 20.8631H17.1731C16.8757 20.8631 16.6346 20.622 16.6346 20.3246V17.9015Z"
        fill="#E8E8E8"
      />
      <path
        d="M22.8269 17.3631H22.7396V19.9647H16.6346V20.3246C16.6346 20.622 16.8757 20.8631 17.1731 20.8631H22.8269C23.1243 20.8631 23.3654 20.622 23.3654 20.3246V17.9015C23.3654 17.6041 23.1243 17.3631 22.8269 17.3631Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};

const AnnualLeaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M2.50098 9.9262C2.50098 9.15917 3.12278 8.53736 3.88982 8.53736H36.1099C36.877 8.53736 37.4988 9.15917 37.4988 9.9262V33.3131C37.4988 34.0801 36.877 34.702 36.1099 34.702H3.88982C3.12278 34.702 2.50098 34.0801 2.50098 33.3131V9.9262Z"
        fill="#FCFCFD"
      />
      <path
        d="M2.50098 8.87169C2.50098 8.10465 3.12278 7.48284 3.88982 7.48284H36.111C36.8781 7.48284 37.4999 8.10465 37.4999 8.87169V13.0436H2.50098V8.87169Z"
        fill="#FDA29B"
      />
      <path
        d="M36.111 7.48284H35.9211V11.4408H2.50208L2.50098 13.0436H37.4999L37.501 11.4408L37.4999 8.87169C37.4999 8.10465 36.8781 7.48284 36.111 7.48284Z"
        fill="#F18181"
      />
      <path
        d="M7.50027 5.85339C7.50027 5.54657 7.749 5.29785 8.05581 5.29785H10.278C10.5848 5.29785 10.8335 5.54657 10.8335 5.85339V11.6865C10.8335 11.9934 10.5848 12.2421 10.278 12.2421H8.05581C7.749 12.2421 7.50027 11.9934 7.50027 11.6865V5.85339Z"
        fill="#F2F4F7"
      />
      <path
        d="M10.278 5.29785H10.0365V11.4442H7.50027V11.6865C7.50027 11.9934 7.749 12.2421 8.05581 12.2421H10.278C10.5848 12.2421 10.8335 11.9934 10.8335 11.6865V5.85339C10.8335 5.54657 10.5848 5.29785 10.278 5.29785Z"
        fill="#D5DAE0"
      />
      <path
        d="M29.1662 5.85339C29.1662 5.54657 29.415 5.29785 29.7218 5.29785H31.9439C32.2508 5.29785 32.4995 5.54657 32.4995 5.85339V11.6865C32.4995 11.9934 32.2508 12.2421 31.9439 12.2421H29.7218C29.415 12.2421 29.1662 11.9934 29.1662 11.6865V5.85339Z"
        fill="#F2F4F7"
      />
      <path
        d="M31.9439 5.29785H31.7025V11.4442H29.1662V11.6865C29.1662 11.9934 29.415 12.2421 29.7218 12.2421H31.9439C32.2508 12.2421 32.4995 11.9934 32.4995 11.6865V5.85339C32.4995 5.54657 32.2508 5.29785 31.9439 5.29785Z"
        fill="#D5DAE0"
      />
      <g filter="url(#filter0_i_2451_22483)">
        <path
          d="M27.8337 17.5867C28.3261 18.3213 28.1135 19.1799 27.7886 19.8067C27.4534 20.4534 26.9124 21.0428 26.4046 21.4142L23.1069 23.8266L24.0318 30.3429C24.1227 30.9833 23.8595 31.6224 23.3519 31.9937L22.1172 32.897C21.9858 32.993 21.8207 33.0239 21.6657 32.9813C21.5106 32.9387 21.3814 32.827 21.3123 32.6759L18.6872 26.9359L16.2962 27.9007L16.0863 30.1497C16.048 30.3766 15.8827 30.5571 15.6673 30.607C15.452 30.6568 15.2291 30.5663 15.1026 30.3775L11.9746 25.7104C11.848 25.5217 11.8428 25.2719 11.9613 25.0775C12.0799 24.8832 12.2988 24.7826 12.516 24.8226L14.5182 25.1914L16.2962 23.3684L12.0311 18.8281C11.9188 18.7086 11.8626 18.5435 11.8773 18.3767C11.892 18.21 11.9762 18.0584 12.1076 17.9623L13.3423 17.0591C13.8499 16.6878 14.5148 16.6479 15.0597 16.9562L20.6045 20.0929L23.9022 17.6806C24.41 17.3091 25.1251 16.9795 25.8215 16.8717C26.4963 16.7671 27.3414 16.852 27.8337 17.5867Z"
          fill="#F2F4F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2451_22483"
          x="11.875"
          y="16.75"
          width="16.25"
          height="16.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.555538" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.557731 0 0 0 0 0.553937 0 0 0 0 0.553937 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2451_22483"
          />
        </filter>
      </defs>
    </svg>
  );
};

const DayoffIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill="none"
    >
      <path
        d="M0.5 4.9262C0.5 4.15917 1.12181 3.53736 1.88884 3.53736H34.109C34.876 3.53736 35.4978 4.15917 35.4978 4.9262V28.3131C35.4978 29.0802 34.876 29.702 34.109 29.702H1.88884C1.12181 29.702 0.5 29.0802 0.5 28.3131V4.9262Z"
        fill="#FCFCFD"
      />
      <path
        d="M0.5 3.87169C0.5 3.10465 1.12181 2.48284 1.88884 2.48284H34.11C34.8771 2.48284 35.4989 3.10465 35.4989 3.87169V8.04364H0.5V3.87169Z"
        fill="#FDA29B"
      />
      <path
        d="M34.11 2.48284H33.9202V6.44084H0.501102L0.5 8.04364H35.4989L35.5 6.44084L35.4989 3.87169C35.4989 3.10465 34.8771 2.48284 34.11 2.48284Z"
        fill="#F18181"
      />
      <path
        d="M5.4993 0.85339C5.4993 0.546575 5.74802 0.297852 6.05484 0.297852H8.27699C8.5838 0.297852 8.83252 0.546574 8.83252 0.853389V6.68654C8.83252 6.99335 8.5838 7.24207 8.27699 7.24207H6.05483C5.74802 7.24207 5.4993 6.99335 5.4993 6.68654V0.85339Z"
        fill="#F2F4F7"
      />
      <path
        d="M8.27699 0.297852H8.03557V6.44424H5.4993V6.68654C5.4993 6.99335 5.74802 7.24207 6.05483 7.24207H8.27699C8.5838 7.24207 8.83252 6.99335 8.83252 6.68654V0.853389C8.83252 0.546574 8.5838 0.297852 8.27699 0.297852Z"
        fill="#D5DAE0"
      />
      <path
        d="M27.1653 0.85339C27.1653 0.546575 27.414 0.297852 27.7208 0.297852H29.943C30.2498 0.297852 30.4985 0.546574 30.4985 0.853389V6.68654C30.4985 6.99335 30.2498 7.24207 29.943 7.24207H27.7208C27.414 7.24207 27.1653 6.99335 27.1653 6.68654V0.85339Z"
        fill="#F2F4F7"
      />
      <path
        d="M29.943 0.297852H29.7015V6.44424H27.1653V6.68654C27.1653 6.99335 27.414 7.24207 27.7208 7.24207H29.943C30.2498 7.24207 30.4985 6.99335 30.4985 6.68654V0.853389C30.4985 0.546574 30.2498 0.297852 29.943 0.297852Z"
        fill="#D5DAE0"
      />
    </svg>
  );
};
