export const DocumentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13 5.375V12.5C13 13.3284 12.2538 14 11.3333 14H4.66667C3.74619 14 3 13.3284 3 12.5V3.5C3 2.67157 3.74619 2 4.66667 2H9.25L13 5.375ZM10.5 5.375C9.80964 5.375 9.25 4.87132 9.25 4.25V2.75H4.66667C4.20643 2.75 3.83333 3.08579 3.83333 3.5V12.5C3.83333 12.9142 4.20643 13.25 4.66667 13.25H11.3333C11.7936 13.25 12.1667 12.9142 12.1667 12.5V5.375H10.5Z"
        fill="#344054"
      />
      <path
        d="M5 6.9376C5 6.71668 5.17909 6.5376 5.4 6.5376H9.6C9.82091 6.5376 10 6.71668 10 6.9376V7.1376C10 7.35851 9.82091 7.5376 9.6 7.5376H5.4C5.17909 7.5376 5 7.35851 5 7.1376V6.9376Z"
        fill="#344054"
      />
      <path
        d="M5 8.9376C5 8.71668 5.17909 8.5376 5.4 8.5376H10.6C10.8209 8.5376 11 8.71668 11 8.9376V9.1376C11 9.35851 10.8209 9.5376 10.6 9.5376H5.4C5.17909 9.5376 5 9.35851 5 9.1376V8.9376Z"
        fill="#344054"
      />
      <path
        d="M5 10.9376C5 10.7167 5.17909 10.5376 5.4 10.5376H9.6C9.82091 10.5376 10 10.7167 10 10.9376V11.1376C10 11.3585 9.82091 11.5376 9.6 11.5376H5.4C5.17909 11.5376 5 11.3585 5 11.1376V10.9376Z"
        fill="#344054"
      />
    </svg>
  );
};
