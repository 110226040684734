import React from "react";
import ChangeOrderTable from "./ChangeOrderTable";
import { contractAmountColumn, changeOrderListColumn } from "./Column";
import "./changeOrder.scss";

const ChangeOrder = ({
  contract_amount = [
    {
      id: 0,
      initial_contract_amount: 0,
      change_order_amount: 0,
      current_contract_amount: 0,
    },
  ],
  change_order_list = [],
}) => {
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-contract-amount-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Summary</div>
        </div>
        <ChangeOrderTable
          rows={contract_amount}
          column={contractAmountColumn}
        />
      </div>
      <div className="cost-menu-detail-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>
        <ChangeOrderTable
          rows={change_order_list}
          column={changeOrderListColumn}
        />
      </div>
    </div>
  );
};

export default ChangeOrder;
