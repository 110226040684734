import {
  CustomNormalTextTypeCell,
  CustomNumberTypeCell,
  CustomDateTypeCell,
  CustomSubPriceCell,
  CustomTaxPriceCell,
  CustomSumTaxPriceCell,
  CustomDiscountPriceCell,
  CustomAmountTypeCell,
} from "@/components/Cells";
import { amount_currnecy_list } from "@utils/amountCurrency";

const amount_sybol_list = Object.keys(amount_currnecy_list).map((c) => {
  return { value: c, name: amount_currnecy_list[c] };
});

export const initialBudgetColumn = [
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "project_lead",
    headerName: "Lead",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "contract_amount",
    headerName: "Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "initial_budget",
    headerName: "Initial Budget",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "budget_contract_ratio",
    headerName: "Rate(%)",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const initialBudgetDetailColumn = [
  {
    field: "item_number",
    headerName: "No.",
    width: 40,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "cost_code",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_2_name",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const budgetTransferColumn = [
  {
    field: "item_number",
    headerName: "No.",
    width: 40,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "from_cost_code",
    headerName: "From Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "to_cost_code",
    headerName: "To Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "budget_transfer_cost",
    headerName: "Reallocated budget",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const budgetChangeColumn = [
  {
    field: "item_number",
    headerName: "No.",
    width: 40,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "cost_code",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "current_budget",
    headerName: "Current Budget",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "revised_budget",
    headerName: "Revised Budget",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "total_budget",
    headerName: "Total",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const expensesColumn = [
  {
    field: "item_number",
    headerName: "No.",
    width: 40,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "cost_code",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "expenses_cost",
    headerName: "Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const changeOrderColumn = [
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "initial_contract_amount",
    headerName: "Initial Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "prev_change_order_amount",
    headerName: "Prev Change Order Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_change_order_amount",
    headerName: "Current Change Order Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "total_amount",
    headerName: "Total",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const contractAwardColumn = [
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "contract_amount",
    headerName: "Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "progress_payment_amount",
    headerName: "Progress Payment Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "cumulative_progress_payment_amount",
    headerName: "Cumulative Progress Payment Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "balance",
    headerName: "Balance",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const vendorInvoiceColumn = [
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "po_no",
    headerName: "PO Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "vendor_name",
    headerName: "Vendor Name",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "po_amount",
    headerName: "po_amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "prev_po_invoice",
    headerName: "Prev Invoice",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_invoice",
    headerName: "Current Invoice",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "balance",
    headerName: "Balance",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const vendorInvoiceDetailColumn = [
  {
    field: "item_number",
    headerName: "No.",
    width: 40,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "cost_code",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const purchase_request_column = [
  {
    field: "pr_no",
    headerName: "PR Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "bid_sortation",
    headerName: "Bid Sortation",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },

  {
    field: "customer",
    headerName: "Customer",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },

  {
    field: "pr_date",
    headerName: "Date",
    data_type: "string",
    type: "date",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
];

export const purchase_request_lineitems_column = [
  {
    field: "pr_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_category",
    headerName: "Category",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "order_qnty",
    headerName: "Order Qty",
    width: 150,
    data_type: "string",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];
export const request_for_quotation_column = [
  {
    field: "rfq_no",
    headerName: "RFQ Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "bid_sortation",
    headerName: "Bid Sortation",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "vendor_name",
    headerName: "Vendor Name",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "amount_currency",
    headerName: "Currency",
    width: 100,
    data_type: "string",
    editable: false,
    list: amount_sybol_list,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const amount_sybol = amount_sybol_list.find((c) => c.value === params);
      return amount_sybol?.name ?? params;
    },
  },
  {
    field: "tax_percent",
    headerName: "Tax Pct(%)",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_amount",
    headerName: "RFQ Amount",
    width: 150,
    data_type: "number",

    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "tax_amount",
    headerName: "Tax Amount",
    width: 150,
    data_type: "number",

    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "total_amount",
    headerName: "Total Amount",
    width: 150,
    data_type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "rfq_date",
    headerName: "RFQ Date",
    data_type: "string",
    type: "date",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
  {
    field: "val_date",
    headerName: "Validation Date",
    data_type: "string",
    type: "date",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
];

export const request_for_quotation_lineitems_column = [
  {
    field: "rfq_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "request_order_qnty",
    headerName: "Request Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_qnty",
    headerName: "Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_unitprice",
    headerName: "Unit Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "sub_total_price",
    headerName: "Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomSubPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
        />
      );
    },
  },
  {
    field: "tax_price",
    headerName: "Tax Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomTaxPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
          tax_percent_field="tax_percent"
          discount_percent_field="discount_percent"
        />
      );
    },
  },
  {
    field: "sub_total",
    headerName: "Sub Total",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomSumTaxPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
          tax_percent_field="tax_percent"
          discount_percent_field="discount_percent"
        />
      );
    },
  },
];

export const purchase_order_column = [
  {
    field: "po_no",
    headerName: "PO Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    width: 180,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "vendor_name",
    headerName: "Vendor Name",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "amount_currency",
    headerName: "Currency",
    width: 100,
    data_type: "string",
    editable: false,
    list: amount_sybol_list,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const amount_sybol = amount_sybol_list.find((c) => c.value === params);

      return amount_sybol?.name ?? params;
    },
  },
  {
    field: "tax_percent",
    headerName: "Tax Pct(%)",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "withholding_tax_percent",
    headerName: "Withholding Tax Pct(%)",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_amount",
    headerName: "PO Amount",
    width: 150,
    data_type: "number",
    // type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "discount_amount",
    headerName: "Discounted Amount",
    width: 150,
    data_type: "number",
    // type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "tax_amount",
    headerName: "Tax Amount",
    width: 150,
    data_type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "withhold_tax_amount",
    headerName: "Withholding Tax Amount",
    width: 150,
    data_type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "total_amount",
    headerName: "Total Amount",
    width: 150,
    data_type: "number",
    // type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "po_date",
    headerName: "Submitted Date",
    data_type: "string",
    type: "date",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
];
export const purchase_order_lineitems_column = [
  {
    field: "po_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "request_order_qnty",
    headerName: "Request Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "prev_order_qnty",
    headerName: "Prev Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "available_qnty",
    headerName: "Available Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_qnty",
    headerName: "Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "order_unitprice",
    headerName: "Unit Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "discount_percent",
    headerName: "Discount Pct(%)",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "sub_total_price",
    headerName: "Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomSubPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
        />
      );
    },
  },

  {
    field: "discount_price",
    headerName: "Discount Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomDiscountPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
          discount_percent_field="discount_percent"
        />
      );
    },
  },
  {
    field: "tax_price",
    headerName: "Tax Price",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomTaxPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
          tax_percent_field="tax_percent"
          discount_percent_field="discount_percent"
        />
      );
    },
  },
  {
    field: "sub_total",
    headerName: "Sub Total",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return (
        <CustomSumTaxPriceCell
          params={params}
          qty_field="order_qnty"
          unit_price_field="order_unitprice"
          tax_percent_field="tax_percent"
          discount_percent_field="discount_percent"
        />
      );
    },
  },
];
export const packing_list_column = [
  {
    field: "pl_no",
    headerName: "PL Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    width: 180,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "vendor_name",
    headerName: "Vendor Name",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "receive_date",
    headerName: "Plan Receive Date",
    width: 150,
    data_type: "string",
    type: "date",
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
  {
    field: "inv_name",
    headerName: "Inventory Name",
    data_type: "string",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "import_date",
    headerName: "Import Date",
    width: 150,
    data_type: "string",
    type: "date",
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
  {
    field: "import_type",
    headerName: "Import Type",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "import_declaration_number",
    headerName: "Import Declaration Number",
    width: 150,
    data_type: "",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const packing_list_lineitems_column = [
  {
    field: "pl_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "order_qnty",
    headerName: "Order Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "receive_qnty",
    headerName: "Packing Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "mat_supplied",
    headerName: "Supplied",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const material_release_column = [
  {
    field: "mr_no",
    headerName: "MRE Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    width: 180,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "shop_name",
    headerName: "Shop Name",
    data_type: "string",
    width: 150,
    editable: false,

    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },

  {
    field: "release_date",
    headerName: "Release Date",
    width: 150,
    data_type: "string",
    type: "date",
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
];
export const material_release_lineitems_column = [
  {
    field: "mr_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "inv_qnty",
    headerName: "Inventory Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "release_qnty",
    headerName: "Release Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "inv_name",
    headerName: "Inventory Name",
    data_type: "string",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];
export const product_export_column = [
  {
    field: "pe_no",
    headerName: "PE Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lead",
    headerName: "Project Name",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "job_number",
    headerName: "Job Number",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "export_type",
    headerName: "Export Type",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,

    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },

  {
    field: "unit",
    headerName: "Unit",
    width: 150,
    data_type: "",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "unit_qty",
    headerName: "Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
    data_type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomAmountTypeCell params={params} />;
    },
  },
  {
    field: "tax_percent",
    headerName: "Tax Pct(%)",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "weight_currency",
    headerName: "Weight Currency",
    width: 150,
    data_type: "",
    editable: false,

    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "weight",
    headerName: "Weight",
    width: 150,
    data_type: "",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "tax_payment_number",
    headerName: "Tax Payment Number",
    width: 150,
    data_type: "",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "export_declaration_number",
    headerName: "Export Declaration Number",
    width: 150,
    data_type: "",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "export_date",
    headerName: "Export Date",
    width: 150,
    data_type: "string",
    type: "date",
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
];

export const product_export_lineitems_column = [
  {
    field: "pe_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "release_qnty",
    headerName: "Release Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "prev_export_qnty",
    headerName: "Prev Export Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "available_export_qnty",
    headerName: "Available Export Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "export_qnty",
    headerName: "Export Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const inventory_quantity_control_column = [
  {
    field: "iqc_no",
    headerName: "IQC Number",
    data_type: "string",
    width: 200,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "inv_name",
    headerName: "Inventory Name",
    data_type: "string",
    width: 150,
    editable: false,

    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "control_date",
    headerName: "Date",
    width: 150,
    data_type: "string",
    type: "date",
    editable: false,
    renderCell: (params) => {
      return <CustomDateTypeCell params={params} />;
    },
    valueGetter: (params) => {
      const dateValue = params;
      return dateValue ? new Date(params) : null;
    },
  },
  {
    field: "loss_qnty",
    headerName: "Loss Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "destroyed_qnty",
    headerName: "Destroyed Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const inventory_quantity_control_lineitems_column = [
  {
    field: "iqc_itm_no",
    headerName: "Item Number",
    data_type: "number",
    width: 150,
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_code",
    headerName: "Material Code",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "mat_uom",
    headerName: "Unit",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "stock_qnty",
    headerName: "Stock Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "loss_qnty",
    headerName: "Loss Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "destroyed_qnty",
    headerName: "Destroyed Qty",
    width: 150,
    data_type: "number",
    type: "number",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];
