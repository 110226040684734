import React, { useState } from "react";
import { useQuery } from "react-query";
import CostCodeTable from "@/components/costCode/CostCodeTable";
import CodeAddModal from "@/components/costCode/CodeAddModal";
import Btn from "@/components/CustomIconBtn";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import { downloadBase64File } from "@/utils/downloadFns";

const CostCodeSettingPage = ({ add_button_use = true }) => {
  const query_cost_code_data = useQuery("cost_code_data", () =>
    query_fetch_data()
  );

  const { data = {}, isLoading } = query_cost_code_data;
  const { list = [] } = data;

  const [refresh] = useRefreshReactQuery("cost_code_data");

  const [addModalToggle, setAddModalToggle] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);

  const handleAddModal = () => {
    setAddModalToggle((prev) => !prev);
  };

  const exportCostCode = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestDownloadSalaryList = await userPermAxios.get(
        `/cost/export_cost_code`
      );

      const { success, message, data } = requestDownloadSalaryList;

      if (success) {
        msgStatus.status = "success";
        downloadBase64File(data, `cost code.xlsx`);
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);
    }
  };

  const confirmExportCostCode = () => {
    if (!dubleClick) {
      return;
    }

    const msg = `Are you sure you want to export cost code?`;

    return create_alert_fn("info", msg, () => exportCostCode(), true);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Cost Code</div>
        <div className="work-policy-setting-page-btn-box">
          {add_button_use && (
            <>
              <Btn
                icon={<PlusIcon />}
                label="Add"
                width={100}
                onClick={handleAddModal}
              />
              <div className="vertical-line" />
            </>
          )}
          <Btn
            icon={<img src="/icons/excel_icon.png" alt="export_icon" />}
            label="Export"
            width={139}
            disabled={list.length === 0}
            onClick={confirmExportCostCode}
          />
        </div>
      </div>
      <div className="company-setting-contents-body">
        <CostCodeTable row={list} />
      </div>
      {addModalToggle && (
        <CodeAddModal
          open={addModalToggle}
          onClose={handleAddModal}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default CostCodeSettingPage;

const query_fetch_data = async () => {
  let result = { list: [] };

  try {
    const requestCostCodeList = await userPermAxios.get("/cost/get_cost_code");

    const { success, data } = requestCostCodeList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.006 4.5C11.5417 4.5 11.1667 4.87515 11.1667 5.33962L11.1607 11.1574H5.33929C4.875 11.1574 4.5 11.5326 4.5 11.997C4.5 12.4615 4.875 12.8366 5.33929 12.8366L11.1607 12.8366L11.1607 18.6604C11.1607 19.1249 11.5357 19.5 12 19.5C12.4643 19.5 12.8393 19.1249 12.8393 18.6604V12.8366H18.6607C19.125 12.8366 19.5 12.4615 19.5 11.997C19.5 11.5326 19.125 11.1574 18.6607 11.1574L12.8393 11.1574V5.33366C12.8393 4.8811 12.4583 4.5 12.006 4.5Z"
        fill="#344054"
      />
    </svg>
  );
};
