import React, { useState, useMemo } from "react";
import ModalBody from "../ModalBody";
import ModalBtnGroup from "../ModalBtnGroup";
import LabelInput from "../LabelInput";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const CodeDataSetAddModal = ({
  open,
  onClose = () => {},
  type = "",
  refresh = () => {},
}) => {
  const [codeName, setCodeName] = useState("");
  const [code, setCode] = useState("");
  const [dubleClick, setDubleClick] = useState(true);

  const button_disabled = useMemo(() => {
    if (
      codeName.replaceAll(" ", "") === "" ||
      code.replaceAll(" ", "") === ""
    ) {
      return true;
    }

    return false;
  }, [codeName, code]);

  const createDataSet = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestaddItem = await userPermAxios.post(
        `/cost/create_cost_code_data_set`,
        {
          type,
          codeName,
          code,
        }
      );

      const { success, message } = requestaddItem;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmCreateDataSet = () => {
    if (!dubleClick) {
      return;
    }

    const msg = `Are you sure you want to new ${modalname?.[type]} data set?`;

    return create_alert_fn("info", msg, () => createDataSet(), true);
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title={`${modalname?.[type] ?? ""} Cost Code Data Set Setting`}
      disableEscapeKeyDown={true}
      modalWidth={840}
    >
      <div className="code-add-modal-contents-body">
        <div className="code-add-modal-contents-box">
          <div className="code-add-modal-input-box">
            <LabelInput
              label="Cost Code Name"
              value={codeName}
              setValue={setCodeName}
            />
          </div>
          <div className="code-add-modal-input-box">
            <LabelInput label="Cost Code" value={code} setValue={setCode} />
          </div>
        </div>
      </div>
      <ModalBtnGroup
        onClose={onClose}
        onAction={confirmCreateDataSet}
        actionLabel="Add"
        actionDisabled={button_disabled}
      />
    </ModalBody>
  );
};

export default CodeDataSetAddModal;

const modalname = {
  lv0: "Level 0",
  lv1: "Level 1",
  lv2: "Level 2",
};
