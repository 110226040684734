import React from "react";

const OrgSettingIcon = ({ width = "40", height = "40" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0251 15.9387C23.3755 15.4626 25.1449 13.385 25.1449 10.8941C25.1449 8.05149 22.8405 5.74707 19.998 5.74707C17.1554 5.74707 14.8511 8.05149 14.8511 10.8941C14.8511 13.3834 16.6182 15.46 18.9663 15.9378V18.8153H9.7028C7.99723 18.8153 6.61465 20.1979 6.61465 21.9035V24.0617C4.26679 24.5397 2.5 26.6161 2.5 29.1052C2.5 31.9478 4.80435 34.2523 7.6469 34.2523C10.4895 34.2523 12.7938 31.9478 12.7938 29.1052C12.7938 26.6141 11.0241 24.5363 8.67344 24.0605V21.9035C8.67344 21.3349 9.13433 20.874 9.7028 20.874H30.2904C30.8589 20.874 31.3198 21.3349 31.3198 21.9035V24.0621C28.9729 24.5408 27.207 26.6168 27.207 29.1052C27.207 31.9478 29.5114 34.2523 32.3539 34.2523C35.1965 34.2523 37.5008 31.9478 37.5008 29.1052C37.5008 26.6134 35.7302 24.5352 33.3786 24.0601V21.9035C33.3786 20.1979 31.996 18.8153 30.2904 18.8153H21.0251V15.9387ZM19.998 13.9824C21.7034 13.9824 23.0861 12.5998 23.0861 10.8941C23.0861 9.18847 21.7034 7.80586 19.998 7.80586C18.2925 7.80586 16.9099 9.18847 16.9099 10.8941C16.9099 12.5998 18.2925 13.9824 19.998 13.9824ZM10.735 29.1052C10.735 30.8109 9.35236 32.1935 7.6469 32.1935C5.94145 32.1935 4.55879 30.8109 4.55879 29.1052C4.55879 27.3995 5.94145 26.0169 7.6469 26.0169C9.35236 26.0169 10.735 27.3995 10.735 29.1052ZM35.442 29.1052C35.442 30.8109 34.0594 32.1935 32.3539 32.1935C30.6485 32.1935 29.2658 30.8109 29.2658 29.1052C29.2658 27.3995 30.6485 26.0169 32.3539 26.0169C34.0594 26.0169 35.442 27.3995 35.442 29.1052Z"
        fill="#344054"
      />
    </svg>
  );
};

export default OrgSettingIcon;
