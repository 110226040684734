import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import ModalBody from "../ModalBody";
import { selectManpowerReqColumn } from "./Column";

const ManpowerReqSelectModal = ({
  open,
  onClose = () => {},
  manpower_request_list = [],
  select_model = [],
  handlehandleSelectValue = () => {},
  rowHeight = 28,
}) => {
  const [selectModal, setSelectModel] = useState(select_model);

  const handleSelect = (value) => {
    setSelectModel(value);
  };

  const button_disabled = useMemo(() => {
    if (selectModal.length === 0) {
      return true;
    }

    return false;
  }, [selectModal]);
  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Select Manpower Request"
      modalWidth={600}
      modalHeight={500}
    >
      <div className="work-policy-modal-contents-box">
        <div
          className="cost-menu-table-body"
          style={{ height: 500 - 76 - 70 - 16 }}
        >
          <DataGridPremium
            rows={manpower_request_list}
            columns={selectManpowerReqColumn}
            rowHeight={rowHeight}
            columnHeaderHeight={rowHeight}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
            // disableRowSelectionOnClick
            checkboxSelection
            disableMultipleRowSelection
            rowSelectionModel={selectModal}
            onRowSelectionModelChange={handleSelect}
          />
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
          onClick={() => {
            handlehandleSelectValue(selectModal);
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default ManpowerReqSelectModal;
