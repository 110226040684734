import React from "react";
import InterimPaymentTable from "./InterimPaymentTable";
import { contractAmountColumn, interimPaymentListColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";
// import "./interimPayment.scss";

const InterimPayment = ({
  contract_amount = [
    {
      id: 0,
      current_contract_amount: 0,
      interim_payment_amount: 0,
      rate: "0%",
    },
  ],
  interim_payment_list = [],
}) => {
  const { height } = useCostTableHeight(true, true);

  return (
    <div className="cost-menu-body">
      <div className="cost-menu-contract-amount-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Summary</div>
        </div>
        <InterimPaymentTable
          rows={contract_amount}
          column={contractAmountColumn}
        />
      </div>
      <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>

        <InterimPaymentTable
          rows={interim_payment_list}
          column={interimPaymentListColumn}
        />
      </div>
    </div>
  );
};

export default InterimPayment;
