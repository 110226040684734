import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";
import ModalBody from "@components/ModalBody";
import LabelSelect from "@components/LabelSelect";
import LabelInput from "@components/LabelInput";
import ModalBtnGroup from "@components/ModalBtnGroup";
import Btn from "@components/CustomIconBtn";
import CodeDataSetAddModal from "./CodeDataSetAddModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import "./costCode.scss";

const CodeAddModal = ({ open, onClose = () => {}, refresh = () => {} }) => {
  const query_lv0_data_set = useQuery("lv0_data_set", () => {
    return query_fetch_data("lv0");
  });

  const query_lv1_data_set = useQuery("lv1_data_set", () => {
    return query_fetch_data("lv1");
  });

  const query_lv2_data_set = useQuery("lv2_data_set", () => {
    return query_fetch_data("lv2");
  });

  const { data: lv0_data = {}, isLoading: lv0_loading } = query_lv0_data_set;
  const { data: lv1_data = {}, isLoading: lv1_loading } = query_lv1_data_set;
  const { data: lv2_data = {}, isLoading: lv2_loading } = query_lv2_data_set;

  const { list: lv0_list = [] } = lv0_data;
  const { list: lv1_list = [] } = lv1_data;
  const { list: lv2_list = [] } = lv2_data;

  const [[lv0_refrsh], [lv1_refrsh], [lv2_refrsh]] = [
    useRefreshReactQuery("lv0_data_set"),
    useRefreshReactQuery("lv1_data_set"),
    useRefreshReactQuery("lv2_data_set"),
  ];

  const [lv0Value, setLv0Value] = useState("");
  const [lv1Value, setLv1Value] = useState("");
  const [lv2Value, setLv2Value] = useState("");
  const [remark, setRemark] = useState("");
  const [dataSetAddToggle, setDataSetAddToggle] = useState(false);
  const [dataSetAddType, setDataSetAddType] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const button_disabled = useMemo(() => {
    if (lv0Value === "" || lv1Value === "" || lv2Value === "") {
      return true;
    }

    return false;
  }, [lv0Value, lv1Value, lv2Value, remark]);

  if (lv0_loading || lv1_loading || lv2_loading) {
    return null;
  }

  const openDataSetAddToggle = (type, refresh) => {
    setDataSetAddToggle(true);
    setDataSetAddType({ type, refresh });
  };

  const closeDataSetAddToggle = () => {
    setDataSetAddToggle(false);
    setDataSetAddType(null);
  };

  const createCostCode = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestaddItem = await userPermAxios.post(
        `/cost/create_cost_code`,
        {
          lv0Value,
          lv1Value,
          lv2Value,
          remark,
        }
      );

      const { success, message } = requestaddItem;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmCreateCostCode = () => {
    if (!dubleClick) {
      return;
    }

    const msg = `Are you sure you want to new cost code?`;

    return create_alert_fn("info", msg, () => createCostCode(), true);
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Add Cost Item・Code"
      disableEscapeKeyDown={true}
      modalWidth={840}
    >
      <div className="code-add-modal-contents-body">
        <div className="code-add-modal-contents-box">
          <div className="code-add-modal-input-box">
            <LabelSelect
              label="Level 0"
              list={[{ name: "", value: "" }, ...lv0_list]}
              value={lv0Value}
              setValue={setLv0Value}
            />
            <Btn
              icon={<PlusIcon />}
              onClick={() => openDataSetAddToggle("lv0", lv0_refrsh)}
            />
          </div>
          <div className="code-add-modal-input-box">
            <LabelSelect
              label="Level 1"
              list={[{ name: "", value: "" }, ...lv1_list]}
              value={lv1Value}
              setValue={setLv1Value}
            />
            <Btn
              icon={<PlusIcon />}
              onClick={() => openDataSetAddToggle("lv1", lv1_refrsh)}
            />
          </div>
          <div className="code-add-modal-input-box">
            <LabelSelect
              label="Level 2"
              list={[{ name: "", value: "" }, ...lv2_list]}
              value={lv2Value}
              setValue={setLv2Value}
            />
            <Btn
              icon={<PlusIcon />}
              onClick={() => openDataSetAddToggle("lv2", lv2_refrsh)}
            />
          </div>
          <div className="code-add-modal-input-box">
            <LabelInput label="Remark" value={remark} setValue={setRemark} />
          </div>
        </div>
      </div>
      <ModalBtnGroup
        onClose={onClose}
        onAction={confirmCreateCostCode}
        actionLabel="Add"
        actionDisabled={button_disabled}
      />
      {dataSetAddToggle && (
        <CodeDataSetAddModal
          open={dataSetAddToggle}
          onClose={closeDataSetAddToggle}
          type={dataSetAddType.type}
          refresh={dataSetAddType.refresh}
        />
      )}
    </ModalBody>
  );
};

export default CodeAddModal;

const query_fetch_data = async (type) => {
  let result = { list: [] };

  try {
    const requestCostCodeDataSet = await userPermAxios.get(
      `/cost/get_cost_code_data_set?type=${type}`
    );

    const { success, data } = requestCostCodeDataSet;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.006 4.5C11.5417 4.5 11.1667 4.87515 11.1667 5.33962L11.1607 11.1574H5.33929C4.875 11.1574 4.5 11.5326 4.5 11.997C4.5 12.4615 4.875 12.8366 5.33929 12.8366L11.1607 12.8366L11.1607 18.6604C11.1607 19.1249 11.5357 19.5 12 19.5C12.4643 19.5 12.8393 19.1249 12.8393 18.6604V12.8366H18.6607C19.125 12.8366 19.5 12.4615 19.5 11.997C19.5 11.5326 19.125 11.1574 18.6607 11.1574L12.8393 11.1574V5.33366C12.8393 4.8811 12.4583 4.5 12.006 4.5Z"
        fill="#344054"
      />
    </svg>
  );
};
