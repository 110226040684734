import React, { useState } from "react";
import { useRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import {
  HrCalendarUsingModalStatus,
  HrWorkPolicyData,
} from "@/atoms/modalStatus";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import HRBigCalendarToolbar from "./HRBigCalendarToolbar";
import {
  HRBigCalendarHeader,
  HRBigCalendarDateCellHeader,
} from "./HRBigCalendarHeader";
import HRCalendarUsingModal from "./HRCalendarUsingModal";
import "react-big-calendar/lib/sass/styles.scss";
import "./hrBigCalendar.scss";

const localizer = dayjsLocalizer(dayjs);

const HRBigCalendar = ({
  targetDate,
  setTargetDate,
  hoily_day_list = [],
  events_list = [],
}) => {
  const [calendarView, setCalendarView] = useState("month");
  const { day_off_weekday_list } = useRecoilValue(HrWorkPolicyData);

  const [hrCalendarUsingModalStatus, setHrCalendarUsingModalStatus] =
    useRecoilState(HrCalendarUsingModalStatus);

  const onCloseUsingModal = useResetRecoilState(HrCalendarUsingModalStatus);

  const handleSelectEvent = (event) => {};

  const handleSelectSlot = (slotInfo) => {
    const { slots, start, end } = slotInfo;

    const slotStartYear = dayjs(start).format("YYYY");
    const slotStartMonth = dayjs(start).format("MM");
    const slotStartDay = dayjs(start).format("DD");

    const slotEndYear = dayjs(end).add(-1, "day").format("YYYY");
    const slotEndMonth = dayjs(end).add(-1, "day").format("MM");
    const slotEndDay = dayjs(end).add(-1, "day").format("DD");

    const slotEventsDays = slots.map((c) => {
      const slotDayjs = dayjs(c);

      return {
        slotDate: slotDayjs.format("YYYY-MM-DD"),
        slotDay: slotDayjs.day(),
      };
    });

    const workingDays = slotEventsDays.filter(
      (c) =>
        !day_off_weekday_list.includes(c.slotDay) &&
        !hoily_day_list.includes(c.slotDate)
    );

    setHrCalendarUsingModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseUsingModal,
        title: `${slotStartYear}-${slotStartMonth}-${slotStartDay} ~ ${slotEndYear}-${slotEndMonth}-${slotEndDay}`,
        startYear: slotStartYear,
        startMonth: slotStartMonth,
        startDay: slotStartDay,
        endYear: slotEndYear,
        endMonth: slotEndMonth,
        endDay: slotEndDay,
        slotDuration: slotEventsDays,
        workingDayDuration: workingDays,
      };
    });
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        date={targetDate}
        defaultDate={targetDate}
        defaultView={calendarView}
        onNavigate={(date) => {
          setTargetDate(date);
        }}
        events={events_list.map((c) => {
          const { start, end } = c;
          return { ...c, start: new Date(start), end: new Date(end) };
        })}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable={true}
        components={{
          toolbar: (props) =>
            HRBigCalendarToolbar(props, setTargetDate, setCalendarView),
          month: {
            header: HRBigCalendarHeader,
            dateHeader: (props) =>
              HRBigCalendarDateCellHeader(
                props,
                targetDate,
                day_off_weekday_list
              ),
          },
          week: { header: HRBigCalendarHeader },
          day: {
            header: HRBigCalendarHeader,
          },
          // dateCellWrapper: HRBigCalendarDateCellHeader,
        }}
        eventPropGetter={customEventStyle}
        dayPropGetter={(date) =>
          weekendStyleGetter(date, targetDate, day_off_weekday_list)
        }
      />
      {hrCalendarUsingModalStatus.open && <HRCalendarUsingModal />}
    </>
  );
};

export default HRBigCalendar;

const customEventStyle = (event) => {
  const { type = "" } = event;

  const createbackgroundColor = (type) => {
    if (type === "work") {
      return "#A560FF";
    }

    if (type === "holiday") {
      return "#039855";
    }
    return "#0080DB";
  };

  return {
    className: "big-calendar-event-text",
    style: {
      backgroundColor: createbackgroundColor(type),
      borderRadius: "8px",
      border: "none",
      // padding: "8px",
    },
  };
};

const weekendStyleGetter = (date, targetDate, day_off_weekday_list = []) => {
  const day = date.getDay();

  const targetMonth = targetDate.getMonth();
  const month = date.getMonth();

  if (targetMonth !== month) {
    return {};
  }

  if (day_off_weekday_list.includes(day)) {
    return {
      className: "weekend",
      style: {
        backgroundColor: "#f0f0f0",
      },
    };
  }

  return {};
};

// const sampleEvents = [
//   {
//     id: 0,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title1",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-26 14:00:00"),
//   },
//   {
//     id: 1,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-28 14:00:00"),
//   },
//   {
//     id: 2,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-28 14:00:00"),
//   },
//   {
//     id: 3,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-28 14:00:00"),
//   },
//   {
//     id: 4,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-28 14:00:00"),
//   },
//   {
//     id: 5,
//     value: "asdasd",
//     test: "asd",
//     title: "All Day Event very long title2",
//     start: new Date("2024-10-26 08:00:00"),
//     end: new Date("2024-10-26 14:00:00"),
//   },
// ];
