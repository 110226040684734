import React, { useState, useMemo } from "react";
import ModalBody from "../ModalBody";
import { IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import Btn from "../CustomIconBtn";
import { DownLoadIcon } from "./Icon";
import DragFileUploader from "../DragFileUploader";
import { downloadBlobFile } from "@/utils/downloadFns";
import { defaultAxios, userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const ImportModal = ({
  open,
  onClose = () => {},
  downloadFilePath,
  downloadFileName,
  uploadPath,
  uploadData,
  refresh = () => {},
}) => {
  const [file, setFile] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const handleChange = (file) => {
    setFile(file);
  };

  const downloadTempleteFile = async () => {
    try {
      const requestTempleteFile = await defaultAxios.get(
        `/download/${downloadFilePath}`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      downloadBlobFile(requestTempleteFile, `${downloadFileName}.xlsx`);
    } catch (err) {
      create_alert_fn("warning", "Error Download Import Templete File");
    }
  };

  const createMaterialMaster = async () => {
    if (!dubleClick) return;

    setDubleClick(false);

    let result = {
      success: "",
      message: "",
      fn: "",
    };

    try {
      const formData = new FormData();

      formData.append("file", file);

      if (uploadData) {
        formData.append("additionalData", JSON.stringify(uploadData));
      }

      const requestCreateMaterialMaster = await userPermAxios.post(
        uploadPath,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateMaterialMaster;

      if (success) {
        result.success = "success";
        result.message = message;
        refresh();
        onClose();
      }
    } catch (err) {
      result.success = "warning";
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.success, result.message);
    }
  };

  const confitmImport = () => {
    if (!dubleClick) {
      return;
    }

    const msg = "Are you sure you want to import the file?";

    return create_alert_fn("info", msg, createMaterialMaster, true);
  };

  const button_disabled = useMemo(() => {
    if (!file) {
      return true;
    }

    return false;
  }, [file]);

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={false}
      title="import"
      modalHeight={460}
      modalWidth={640}
    >
      <div className="payroll-import-modal-main-box">
        <div className="payroll-import-modal-temp-down-box">
          <div className="payroll-import-modal-temp-title-box">
            <img src="/icons/excel_icon.png" alt="excel icon" />
            <div className="payroll-import-modal-temp-title">
              Excel Template
            </div>
          </div>
          <Btn
            label="Download"
            icon={<DownLoadIcon />}
            width={120}
            onClick={downloadTempleteFile}
          />
        </div>

        {!file ? (
          <DragFileUploader handleChange={handleChange} />
        ) : (
          <div className="payroll-import-info-box">
            <div className="payroll-import-info-name">{file && file?.name}</div>
            <IconButton
              className="payroll-import-info-btn"
              onClick={() => {
                setFile(null);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        )}
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={confitmImport}
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default ImportModal;
