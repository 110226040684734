import React from "react";
import "@styles/components.scss";

const CustomCheckBox = ({ checked, onChange = () => {}, disabled = false }) => {
  return (
    <input
      className="custom-check-box"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default CustomCheckBox;
