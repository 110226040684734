import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const contractAmountColumn = [
  {
    field: "initial_contract_amount",
    headerName: "Initial Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "change_contract_amount",
    headerName: "Change Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_contract_amount",
    headerName: "Currenct Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "initial_budget_amount",
    headerName: "Initial Budget Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "change_budget_amount",
    headerName: "Change Budget Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_budget_amount",
    headerName: "Currenct Budget Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "rate",
    headerName: "Rate(%)",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const budgetSheetTableColumn = (topLvGroupSum = []) => [
  {
    field: "lv_0_code",
    headerName: "Lv.0 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_1_code",
    headerName: "Lv.1 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_2_code",
    headerName: "Lv.2 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "costcode",
    headerName: "Cost Code",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 250,
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "initial_budget_cost",
    headerName: "Initial Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "transfer_budget_cost",
    headerName: "Reallocation Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "change_budget_cost",
    headerName: "Change Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_budget_cost",
    headerName: "Current Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "usage_budget_cost",
    headerName: "Usage Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remaining_budget_cost",
    headerName: "Remaining Budget Amount",
    width: 200,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  // {
  //   field: "remark",
  //   headerName: "Remark",
  //   width: 250,
  //   data_type: "string",
  //   editable: false,
  //   renderCell: (params) => {
  //     return <CustomNormalTextTypeCell params={params} />;
  //   },
  // },
];

const CustomGroupNumberTypeCell = ({ params, topLvGroupSum = [] }) => {
  const { id, field } = params;

  if (id === Infinity) {
    return null;
  }

  const idSplit = id.split("/");

  if (idSplit.length === 2) {
    const topLvCode = idSplit.at(-1);

    const targetTotalAmount = topLvGroupSum.find((c) => c.key === topLvCode);

    return (
      <div className="table-text-input-box" style={{ fontWeight: "bold" }}>
        <div>{Number(targetTotalAmount?.[field] ?? 0).toLocaleString()}</div>
      </div>
    );
  }

  const total = params.rowNode.children.reduce((sum, childId) => {
    const childRow = params.api.getRow(childId); // 자식 데이터 가져오기
    return sum + (childRow?.[field] || 0); // budgetAmount 합산
  }, 0);

  return (
    <div className="table-text-input-box" style={{ fontWeight: "bold" }}>
      <div>{Number(total).toLocaleString()}</div>
    </div>
  );
};
