import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const OtherCostContents = ({ title, items = {}, column }) => {
  const { rows = [] } = items;

  return (
    <div className="cost-approval-modal-contents-body">
      <div className="cost-approval-modal-contents-title-box">
        <div className="cost-approval-modal-contents-title">{title}</div>
      </div>
      <div className="cost-approval-modal-contents-box">
        <DataGridPro
          rows={rows}
          columns={column}
          rowHeight={28}
          columnHeaderHeight={28}
          sx={{
            ...materialTableStyle,
            ...TableScrollbarStyle,
          }}
          hideFooter
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
        />
      </div>
    </div>
  );
};

export default OtherCostContents;
