import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  useGridApiRef,
  GridLogicOperator,
} from "@mui/x-data-grid-pro";
import useTableLazyLoading from "@hooks/useTableLazyLoading";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const InvenTable = ({
  row = [],
  column = [],
  isLoading,
  filter_items = [],
}) => {
  const apiRef = useGridApiRef();

  const [initialRows, rowCount, debouncedHandleFetchRows] = useTableLazyLoading(
    row,
    apiRef,
    column
  );

  const [filterModel, setFilterModel] = useState({
    items: [],
    logicOperator: GridLogicOperator.Or,
  });

  useEffect(() => {
    setFilterModel((prev) => {
      return {
        ...prev,
        items: filter_items,
        logicOperator: GridLogicOperator.Or,
      };
    });
  }, [filter_items]);

  return (
    <div className="material-inventroy-table-total-body">
      <DataGridPro
        rows={row}
        columns={column}
        initialState={{}}
        hideFooter
        apiRef={apiRef}
        loading={isLoading}
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        // rowCount={rowCount}
        // sortingMode="server"
        // filterMode="server"
        // rowsLoadingMode="server"
        // onFetchRows={debouncedHandleFetchRows}
        // onSortModelChange={(newSortModel) => {
        //   debouncedHandleFetchRows({
        //     sortModel: newSortModel,
        //   });
        // }}
        // onFilterModelChange={(newSortModel) => {
        //   debouncedHandleFetchRows({
        //     filterModel: newSortModel,
        //   });
        // }}
        disableRowSelectionOnClick
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        filterModel={filterModel}
        onFilterModelChange={(e) => setFilterModel(e)}
      />
    </div>
  );
};

export default InvenTable;
