import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { useState, useRef, useLayoutEffect } from "react";
import { IconButton } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import {
  CostApprovalModalStatus,
  BudgetUsageRowModalStatus,
} from "@/atoms/modalStatus";
import { DocumentIcon } from "./Icons";
import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";
import { create_alert_fn } from "@/utils/createAlert";
import { downloadBlobFile } from "@/utils/downloadFns";
import { defaultAxios } from "@/utils/customAxios";

export const contractAmountColumn = [
  {
    field: "current_contract_amount",
    headerName: "Current Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "Usage_amount",
    headerName: "Usage Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "balence",
    headerName: "Balence",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const usageListColumn = [
  {
    field: "item_no",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "data_consolidation_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DetailExpensesViewCell params={params} />;
    },
  },
  {
    field: "usage_amount",
    headerName: "Usage Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "date",
    headerName: "Draft Date",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Drafter",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approved_status",
    headerName: "Status",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const inVoiceistColumn = [
  {
    field: "item_no",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "data_consolidation_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DetailInvoiceViewCell params={params} />;
    },
  },
  {
    field: "data_consolidation_cell2",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DownLoadInvoice params={params} />;
    },
  },
  {
    field: "po_no",
    headerName: "PO Number",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "vendor_name",
    headerName: "Vendor Name",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "usage_amount",
    headerName: "Usage Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "date",
    headerName: "Draft Date",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Drafter",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approved_status",
    headerName: "Status",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const usageTableColumn = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 40,
    renderCell: (params) => {
      return <CustomDetailCheckRow params={params} />;
    },
  },
  {
    field: "cost_code",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "expenses_cost",
    headerName: "Usage Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "row_fixed_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return null;
      }

      return <FiexedCell params={params} />;
    },
  },
];

export const invoiceTableColumn = (setValue) => {
  return [
    {
      field: "cost_code",
      headerName: "Cost Code",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "prev_invoice_amount",
      headerName: "Prev Invoice Amount",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "remain_invoice_amount",
      headerName: "Remaining Invoice Amount",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
    {
      field: "invoice_amount",
      headerName: "Invoice Amount",
      flex: 1,
      data_type: "string",
      editable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNumberTypeCell
            params={params}
            setValue={setValue}
            target_field_name="remain_invoice_amount"
          />
        );
      },
    },
  ];
};

const DetailExpensesViewCell = ({ params }) => {
  const { id } = params;
  const setCostApprovalModalStatus = useSetRecoilState(CostApprovalModalStatus);
  const onCloseModal = useResetRecoilState(CostApprovalModalStatus);

  const openModal = () => {
    setCostApprovalModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        modal_type: "carbon_copy",
        list_refresh: () => {},
        table_name: "expenses",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <DocumentIcon />
    </IconButton>
  );
};

const DetailInvoiceViewCell = ({ params }) => {
  const { id } = params;
  const setCostApprovalModalStatus = useSetRecoilState(CostApprovalModalStatus);
  const onCloseModal = useResetRecoilState(CostApprovalModalStatus);

  const openModal = () => {
    setCostApprovalModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        modal_type: "carbon_copy",
        list_refresh: () => {},
        table_name: "vendor_invoice",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <DocumentIcon />
    </IconButton>
  );
};

const DownLoadInvoice = ({ params }) => {
  const { id, row = {} } = params;
  const { po_id, po_no, approved_status } = row;

  const openModal = async () => {
    try {
      const requestTempleteFile = await defaultAxios.get(
        `/cost/get_vendor_invoice?id=${id}&po_id=${po_id}`,
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      downloadBlobFile(requestTempleteFile, `${po_no}_vendor_invoice.pdf`);
    } catch (err) {
      create_alert_fn("warning", "Error Download Vendor Invoice File");
    }
  };

  if (approved_status === "Approved") {
    return (
      <IconButton className="line-delete-cell-btn" onClick={openModal}>
        <FileDownloadOutlinedIcon />
      </IconButton>
    );
  }

  return (
    <div
      className="line-delete-cell-btn"
      style={{ display: "flex", alignItems: "center" }}
    >
      <FileDownloadOutlinedIcon sx={{ color: "#d0d5dd" }} />
    </div>
  );
};

const FiexedCell = ({ params }) => {
  const { id, row = {} } = params;

  const { cost_code = "", expenses_cost = 0, remark = "" } = row;

  const setStatus = useSetRecoilState(BudgetUsageRowModalStatus);
  const onCloseModal = useResetRecoilState(BudgetUsageRowModalStatus);

  const openModal = () => {
    setStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        cost_code,
        expenses_cost,
        remark: remark,
        modal_type: "fixed",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <EditIcon />
    </IconButton>
  );
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M4.8 14.9004V17.9H7.79959L16.6464 9.0532L13.6468 6.05361L4.8 14.9004ZM18.9661 6.73352C19.0402 6.65952 19.0991 6.57162 19.1392 6.47485C19.1793 6.37809 19.2 6.27435 19.2 6.16959C19.2 6.06483 19.1793 5.9611 19.1392 5.86434C19.0991 5.76757 19.0402 5.67967 18.9661 5.60567L17.0943 3.73392C17.0203 3.65977 16.9324 3.60094 16.8357 3.5608C16.7389 3.52066 16.6352 3.5 16.5304 3.5C16.4256 3.5 16.3219 3.52066 16.2251 3.5608C16.1284 3.60094 16.0405 3.65977 15.9665 3.73392L14.5027 5.19772L17.5023 8.19732L18.9661 6.73352Z"
        fill="#344054"
      />
      <path d="M3 19.7H21V21.5H3V19.7Z" fill="#344054" />
    </svg>
  );
};

const CustomEditNumberTypeCell = ({ params, setValue, target_field_name }) => {
  const { id, value, field, row, hasFocus } = params;

  const [inputValue, setInputValue] = useState(Number(value) ?? 0);

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    const numberChange = Number(Number(inputValue).toFixed(2));

    if (inputValue.toString().replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(numberChange);
    } else {
      if (inputValue === value) {
        return;
      }
      setValue((prev) => {
        return prev.map((c) => {
          if (c.id === id) {
            const result = { ...c, [field]: numberChange };

            return result;
          }

          return { ...c };
        });
      });
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  const handleChange = (e) => {
    const value = e.target.value;

    if (target_field_name) {
      const target_value = Number(row?.[target_field_name] ?? 0);
      const correction_value = value === "" ? 0 : value;

      if (correction_value > target_value) {
        create_alert_fn(
          "warning",
          "It is larger than the number of requests and orders."
        );
        return setInputValue("");
      }
    }

    return setInputValue(value);
  };

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        type="number"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};
