import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import ModalBody from "@components/ModalBody";

import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import DayPicker from "@components/DatePicker";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";
import "./companySettingModal.scss";

const CompanySettingModal = ({
  open,
  onClose,
  initialData = {},
  refresh = () => {},
}) => {
  const {
    id,
    representative: initialRepresentative,
    address: initialAddress,
    date_of_establishment: initialDateofEstablishment,
    fiscal_month: initialFiscalMonth,
    fiscal_day: initialFiscalDay,
    business_registration_number: initialBusinessRegistrationNumber,
    corporate_registration_number: initialCorporateRegistrationNumber,
    pay_day: initialPayday,
  } = initialData;

  const [representative, setRepresentative] = useState(initialRepresentative);
  const [address, setAddress] = useState(initialAddress);
  const [dateOfEstablishment, setDateofEstablishment] = useState(
    dayjs(initialDateofEstablishment)
  );
  const [fiscalMonth, setFiscalMonth] = useState(initialFiscalMonth);
  const [fiscalDay, setFiscalDay] = useState(initialFiscalDay);
  const [payday, setPayday] = useState(initialPayday);
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState(
    initialBusinessRegistrationNumber
  );
  const [corporateRegistrationNumber, setCorporateRegistrationNumber] =
    useState(initialCorporateRegistrationNumber);
  const [dubleClick, setDubleClick] = useState(true);

  const updateCompanyInfo = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const requestUpdateCompanyInfo = await userPermAxios.post(
        "/dayoff/update_company_info",
        {
          id,
          representative,
          address,
          dateOfEstablishment: dateOfEstablishment.format("YYYY-MM-DD"),
          fiscalMonth,
          fiscalDay,
          businessRegistrationNumber,
          corporateRegistrationNumber,
          payday,
        }
      );

      const { success, message } = requestUpdateCompanyInfo;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const submitConfirm = () => {
    if (!dubleClick) {
      return;
    }

    let message = "Do you want to update your company information?";

    return create_alert_fn("info", message, updateCompanyInfo, true);
  };

  const button_disabled = useMemo(() => {
    if (dateOfEstablishment.format("YYYY-MM-DD") === "Invalid Date") {
      return true;
    }

    return ![
      representative,
      address,
      fiscalMonth,
      fiscalDay,
      businessRegistrationNumber,
      corporateRegistrationNumber,
      payday,
    ].every((c) => {
      const repleaceC = c.replaceAll(" ", "");

      return repleaceC.length > 0;
    });
  }, [
    representative,
    address,
    dateOfEstablishment,
    fiscalMonth,
    fiscalDay,
    businessRegistrationNumber,
    corporateRegistrationNumber,
    payday,
  ]);

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Edit Company Basic Information"
      modalWidth={720}
      modalHeight={730}
      disableEscapeKeyDown={true}
    >
      <div className="company-info-setting-modal-contents-body">
        <div
          className="company-info-setting-modal-contents-box"
          style={{ maxHeight: 521 - 39, overflowY: "auto" }}
        >
          <LabelInput
            label="CEO"
            value={representative}
            setValue={setRepresentative}
          />
          <LabelInput label="Address" value={address} setValue={setAddress} />
          <div style={{ gap: 6, display: "flex", flexDirection: "column" }}>
            <div
              className="work-policy-modal-detail-contents-title"
              style={{ fontSize: "14px", lineHeight: "20px" }}
            >
              Date of Establishment
            </div>
            <DayPicker
              value={dateOfEstablishment}
              onChange={(e) => setDateofEstablishment(e)}
              width={607}
              dateFormat="YYYY-MM-DD"
            />
          </div>

          <div className="company-info-setting-modal-contents-half">
            <LabelSelect
              label="Fiscal Month"
              value={fiscalMonth}
              setValue={setFiscalMonth}
              list={createMonthDayArr(12)}
            />
            <LabelSelect
              label="Fiscal Day"
              value={fiscalDay}
              setValue={setFiscalDay}
              list={createMonthDayArr(31)}
            />
          </div>
          <LabelSelect
            label="Pay Day"
            value={payday}
            setValue={setPayday}
            list={createMonthDayArr(31)}
          />

          <LabelInput
            label="Business Registration Number"
            value={businessRegistrationNumber}
            setValue={setBusinessRegistrationNumber}
          />
          <LabelInput
            label="Corporate Registration Number"
            value={corporateRegistrationNumber}
            setValue={setCorporateRegistrationNumber}
          />
        </div>
      </div>
      <div className="company-info-setting-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="company-info-setting-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={submitConfirm}
          className="company-info-setting-modal-update-btn"
          disabled={button_disabled}
        >
          Submit
        </Button>
      </div>
    </ModalBody>
  );
};

export default CompanySettingModal;

const createMonthDayArr = (length) => {
  return Array.from({ length }).map((c, i) => {
    if (i === 0) {
      return { name: "", value: "" };
    }
    const value = i < 10 ? `0${i}` : i;

    return {
      name: value.toString(),
      value: value.toString(),
    };
  });
};
