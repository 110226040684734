import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { DataConsolidationModalStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SelectDataTable from "@components/SelectDataTable";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import {
  createPRTableColumn,
  createPRLineTableColumn,
  createPOTableColumn,
  createPOLineTableColumn,
  createPLTableColumn,
  createPLLineTableColumn,
  createRFQTableColum,
  createRFQLineTableColumn,
  createMRETableColum,
  createMRELineTableColum,
} from "./Columns";

import "./dataConsolidationModal.scss";

const DataConsolidationModal = () => {
  const { width, height } = useWindowDimensions();

  const [modalStatus, setModalStatus] = useRecoilState(
    DataConsolidationModalStatus
  );

  const { open, target_id, sales_id, table_name, prev_table_name } =
    modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [upperSelectionModel, setUpperSelectionModel] = useState([]);
  const [lineSelectionModel, setLineSelectionModel] = useState([]);

  const query_pr_list = useQuery(
    [
      "data_consolidation_qr_list",
      sales_id,
      prev_table_name,
      table_name,
      target_id,
    ],
    () => {
      return query_qr_fetch_data(
        sales_id,
        prev_table_name,
        table_name,
        target_id
      );
    },
    {
      initialData: { totalUpperList: [] },
    }
  );

  const query_pr_line_list = useQuery(
    [
      "data_consolidation_qr_line_list",
      target_id,
      sales_id,
      upperSelectionModel,
      prev_table_name,
    ],
    () => {
      return query_qr_line_fetch_data(
        target_id,
        sales_id,
        upperSelectionModel,
        prev_table_name
      );
    },
    {
      initialData: { totalLineList: [] },
    }
  );

  const { totalUpperList } = query_pr_list.data;
  const { totalLineList } = query_pr_line_list.data;

  useEffect(() => {
    setUpperSelectionModel([]);
    setLineSelectionModel([]);
  }, [open]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      sales_id: null,
      table_name: null,
      prev_table_name: null,
    }));
  };

  const button_disabled = useMemo(() => {
    if (!target_id || !sales_id || lineSelectionModel.length === 0) {
      return true;
    }

    return false;
  }, [lineSelectionModel, target_id, sales_id]);

  const table_columns = useMemo(() => {
    let result = { upper: [], line: [] };

    if (table_name === "request_for_quotation") {
      result.upper = createPRTableColumn();
      result.line = createPRLineTableColumn();
    }

    if (table_name === "purchase_order") {
      result.upper = createRFQTableColum();
      result.line = createRFQLineTableColumn();
    }

    if (table_name === "packing_list") {
      result.upper = createPOTableColumn();
      result.line = createPOLineTableColumn();
    }

    if (table_name === "material_receive") {
      result.upper = createPLTableColumn();
      result.line = createPLLineTableColumn();
    }

    if (table_name === "product_export") {
      result.upper = createMRETableColum();
      result.line = createMRELineTableColum();
    }
    return result;
  }, [table_name]);

  const createDataConsolidationListItem = async () => {
    setDubleClick(false);
    try {
      const requestDataConsolidation = await userPermAxios.post(
        `/${table_name}/${sales_id}/${target_id}/consolidation-lineitem`,
        {
          lineData: lineSelectionModel.map((c) => {
            const findLineData = totalLineList.find((c2) => c2.id === c);

            return findLineData;
          }),
        }
      );

      const { success } = requestDataConsolidation;

      if (success) {
        closeModal();
      }
    } catch (err) {
      create_alert_fn("warning", "Data Consolidation Error", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await createDataConsolidationListItem();
      },
      true
    );
  };

  const modalHeight = height * 0.9;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="data-consolidation-modal-total-body"
        style={{ width: width * 0.9, height: modalHeight }}
      >
        <div className="data-consolidation-modal-header-box">
          <div className="data-consolidation-modal-header-title">
            Data Consolidation
          </div>
          <IconButton
            onClick={closeModal}
            className="data-consolidation-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div
          className="data-consolidation-modal-main-box"
          style={{ height: modalHeight - 70 - 76 - 32 }}
        >
          <SelectDataTable
            title={upper_table_title(table_name)}
            rows={totalUpperList}
            columns={table_columns.upper}
            selectModel={upperSelectionModel}
            setSelectModel={setUpperSelectionModel}
            table_height={(modalHeight - 70 - 76 - 32 - 40 - 32) / 2}
          />

          <SelectDataTable
            title="Line Item List"
            rows={totalLineList}
            columns={table_columns.line}
            selectModel={lineSelectionModel}
            setSelectModel={setLineSelectionModel}
            table_height={(modalHeight - 70 - 76 - 32 - 40 - 32) / 2}
            isRowSelectableField="material_use"
          />
        </div>

        <div className="data-consolidation-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="data-consolidation-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="data-consolidation-modal-update-btn"
            disabled={button_disabled}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DataConsolidationModal;

const query_qr_fetch_data = async (
  sales_id,
  table_name,
  target_table_name,
  target_id
) => {
  const result = { totalUpperList: [] };

  try {
    if (!sales_id) {
      return result;
    }

    const request_pr_list = await userPermAxios.get(
      `/${table_name}/${sales_id}/${target_table_name}/${target_id}/get_data_consolidation_upper_list`
    );

    const { success, data } = request_pr_list;

    if (success) {
      result.totalUpperList = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_qr_line_fetch_data = async (
  target_id,
  sales_id,
  upperSelectionModel = [],
  table_name
) => {
  const result = { totalLineList: [] };

  try {
    if (upperSelectionModel.length === 0) {
      return result;
    }

    const request_pr_list = await userPermAxios.post(
      `/${table_name}/${target_id}/${sales_id}/get_data_consolidation_lineitem_list`,
      {
        ids: upperSelectionModel,
      }
    );

    const { success, data } = request_pr_list;

    if (success) {
      result.totalLineList = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const upper_table_title = (table_name) => {
  if (table_name === "request_for_quotation") {
    return "Purchase Requisition List";
  }
  if (table_name === "purchase_order") {
    return "Request for Quotation List";
  }
  if (table_name === "packing_list") {
    return "Purchase Order List";
  }
  if (table_name === "material_receive") {
    return "Packing List List";
  }
  if (table_name === "Product Export") {
    return "Material Release List";
  }

  return "";
};
