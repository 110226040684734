import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { CustomNormalTextTypeCell } from "../Cells";

export const createNationalTableColumn = () => {
  return [
    {
      field: "holiday_name",
      headerName: "Holiday Name",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "holiy_day_date",
      headerName: "Date",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const createDesignatedTableColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "holiday_name",
      headerName: "Holiday Name",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "holiy_day_date",
      headerName: "Date",
      data_type: "string",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};
