import React, { useEffect, useMemo, useState } from "react";
import { useResetRecoilState, useRecoilValue } from "recoil";
import {
  ApprovalLineModalStatus,
  InvoiceRowModalStatus,
} from "@/atoms/modalStatus";
import _ from "lodash";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import LoadingModal from "@components/LoadingModal";
import LabelSelect from "@components/LabelSelect";
import LabelTextArea from "@components/LabelTextArea";
import EditRowModal from "./EditRowModal";
import { invoiceTableColumn } from "./Column";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import BudgetUsageTable from "./BudgetUsageTable";

const AddVendorInvoiceModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  project_code,
  project_name,
  approval_user_list = [],
  cost_code_list = [],
  purchase_order_list = [],
}) => {
  const [approvalName, setApprovalName] = useState(
    "Add Vendor Invoice Description"
  );
  const [selectPo, setSelectPo] = useState("");
  const [selectVatTaxCostCode, setSelectVatTaxCostCode] = useState("");
  const [selectWithHoldingTaxCostCode, setSelectWithHoldingTaxCostCode] =
    useState("");

  const [invoiceRow, setInvoiceRow] = useState([]);
  const [attachmentList, setAttachmentList] = useState(null);
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });

  const [remark, setRemark] = useState("");

  const [dubleClick, setDubleClick] = useState(true);
  const invoiceRowModalStatus = useRecoilValue(InvoiceRowModalStatus);

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);

  useEffect(() => {
    setInvoiceRow([]);
    setSelectVatTaxCostCode("");
    setSelectWithHoldingTaxCostCode("");

    if (selectPo !== "") {
      const targetItem = purchase_order_list.find((c) => c.id === selectPo);

      const { line_items = [] } = targetItem ?? {};

      setInvoiceRow(line_items);
    }
  }, [selectPo, purchase_order_list]);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const button_disabled = useMemo(() => {
    if (invoiceRow.filter((c) => c.notAccept).length > 0) {
      return true;
    }

    if (_.sumBy(invoiceRow, "invoice_amount") <= 0) {
      return true;
    }

    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    if (
      selectPo === "" ||
      selectVatTaxCostCode === "" ||
      selectWithHoldingTaxCostCode === ""
    ) {
      return true;
    }

    if (!attachmentList) {
      return true;
    }

    return false;
  }, [
    invoiceRow,
    approvalLineData,
    approvalName,
    attachmentList,
    selectPo,
    selectVatTaxCostCode,
    selectWithHoldingTaxCostCode,
  ]);

  const createVendorInvoice = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const formData = new FormData();

      for (const file in attachmentList) {
        if (file !== "length" && file !== "item") {
          const targetFile = attachmentList[file];

          formData.append(`files`, targetFile);
        }
      }

      const targetItem = purchase_order_list.find((c) => c.id === selectPo);

      const { tax_percent = 0, withholding_tax_percent = 0 } = targetItem ?? {};

      const vat = tax_percent / 100;
      const withHolding = withholding_tax_percent / 100;

      const totalAmount = _.sumBy(invoiceRow, "invoice_amount");

      const materialTotal = totalAmount / (1 + vat - withHolding);

      const vatAmount = materialTotal * vat;
      const withAmount = materialTotal * withHolding;

      formData.append(
        "additionalData",
        JSON.stringify({
          project_code,
          po_id: selectPo,
          approvalName,
          invoiceRow: invoiceRow.map((c) => {
            const { invoice_amount } = c;

            return {
              ...c,
              invoice_amount: invoice_amount / (1 + vat - withHolding),
            };
          }),
          taxRow: [
            {
              cost_cost_id: selectVatTaxCostCode,
              amount: vatAmount,
              remark: "VAT",
            },
            {
              cost_cost_id: selectWithHoldingTaxCostCode,
              amount: withAmount,
              remark: "Withholding Tax",
            },
          ],
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        })
      );

      const requestCreateBudgetTransfer = await userPermAxios.post(
        `/cost/create_vendor_invoice`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateBudgetTransfer;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }
      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmVendorInvoice = () => {
    if (!dubleClick || selectPo === "") {
      return;
    }
    let message = "Do you want to create a new vendor invoice?";
    return create_alert_fn(
      "info",
      message,
      async () => {
        createVendorInvoice();
      },
      true
    );
  };

  const costCodeList = cost_code_list
    .filter((c) => c.type === "Using")
    .map((c) => {
      const { id, code, lv_2_name, type } = c;
      return {
        name: `${type} | ${code}_${lv_2_name}`,
        value: id,
      };
    });

  const purchaseOrderList = purchase_order_list.map((c) => {
    const { id, po_no, vendor_name, tax_percent, withholding_tax_percent } = c;

    return {
      name: `${po_no} | ${vendor_name} | Vat Tax: ${tax_percent}% | With Holiding Tax: ${withholding_tax_percent}%`,
      value: id,
    };
  });

  return (
    <>
      {!dubleClick && <LoadingModal />}

      {invoiceRowModalStatus.open && (
        <EditRowModal
          setInvoiceRow={setInvoiceRow}
          cost_code_list={cost_code_list}
        />
      )}
      <PolicyModalBody
        policyName={approvalName}
        setPolicyName={setApprovalName}
        open={open}
        onClose={onClose}
        title={`Add ${project_name} Vendor Invoice`}
        width={1140}
        disableEscapeKeyDown={true}
        save_btn_disabled={button_disabled}
        saveFn={confirmVendorInvoice}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                gap: 16,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="work-policy-modal-time-picker-detail"
                style={{ width: "100%" }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Select Purchase Order
                </div>
                <LabelSelect
                  list={[{ name: "", value: "" }, ...purchaseOrderList]}
                  value={selectPo}
                  setValue={setSelectPo}
                />
              </div>
              <div
                style={{
                  height: "200px",
                }}
              >
                <BudgetUsageTable
                  rowHeight={28}
                  rows={invoiceRow}
                  column={invoiceTableColumn(setInvoiceRow)}
                />
              </div>
              <div
                className="work-policy-modal-time-picker-detail"
                style={{ width: "100%" }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Select Vat Tax Cost Code
                </div>
                <LabelSelect
                  list={[{ name: "", value: "" }, ...costCodeList]}
                  value={selectVatTaxCostCode}
                  setValue={setSelectVatTaxCostCode}
                />
              </div>
              <div
                className="work-policy-modal-time-picker-detail"
                style={{ width: "100%" }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Select With Holding Tax Cost Code
                </div>
                <LabelSelect
                  list={[{ name: "", value: "" }, ...costCodeList]}
                  value={selectWithHoldingTaxCostCode}
                  setValue={setSelectWithHoldingTaxCostCode}
                />
              </div>
            </div>

            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>

            <div
              className="work-policy-modal-swicth-btn-box"
              style={{ padding: "var(--1, 4px) var(--2, 8px)" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                {`Required Attachment (${
                  attachmentList ? attachmentList.length : 0
                })`}
              </div>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                sx={{
                  fontSize: 14,
                  textTransform: "none",
                  width: 300,
                }}
              >
                Upload File
                <input
                  type="file"
                  multiple
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    const fileCount = e.target.files.length;
                    if (fileCount > 0) {
                      setAttachmentList(e.target.files);
                    } else {
                      setAttachmentList(null);
                    }
                  }}
                />
              </Button>
            </div>
            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default AddVendorInvoiceModal;
