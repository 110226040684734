import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { AnnualLeavePolicyStatus } from "@/atoms/modalStatus";
import SwicthBtn from "@components/SwicthBtn";
import WorkPolicyUserSelectModal from "./WorkPolicyUserSelectModal";
import RechargeConditionSelectModal from "./RechargeConditionSelectModal";
import PolicyModalBtn from "./PolicyModalBtn";
import PolicyModalBody from "@components/PolicyModalBody";
import LabelSelect from "@components/LabelSelect";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

import "./dayOffSettingModals.scss";

const AnnualLeavePolicyModal = ({ refresh }) => {
  const {
    open,
    id,
    origin_id,
    type = "create",
    initialAnnualLeavePolicyName = "Annual Leave Policy",
    initialApproveUse = false,
    initialCancelApproveUse = false,
    initialHalfDayoffUse = false,
    initialRechargeConditionId = [],
    initialUsers = [],
    initialPostponeMonth = "0",
    title,
    onClose,
  } = useRecoilValue(AnnualLeavePolicyStatus);

  const button_disabled = type === "view" || type === "" ? true : false;

  const query_annual_leave_policy_data = useQuery(
    "annual_leave_policy_data",
    () => {
      return query_fetch_data();
    },
    {
      initialData: {
        user_list: [],
        annual_leave_recharge_condition: {
          recharge_condition_list: [],
          newcomer_start_options: [],
          under_decimal_options: [],
        },
      },
    }
  );

  const [annual_leave_refresh] = useRefreshReactQuery(
    "annual_leave_policy_data"
  );

  const { data, isLoading } = query_annual_leave_policy_data;

  const { user_list, annual_leave_recharge_condition } = data;

  const {
    recharge_condition_list,
    newcomer_start_options,
    under_decimal_options,
  } = annual_leave_recharge_condition;

  const [policyName, setPolicyName] = useState(initialAnnualLeavePolicyName);
  const [approveUse, setApproveUse] = useState(initialApproveUse);
  const [cancelApproveUse, setCancelApproveUse] = useState(
    initialCancelApproveUse
  );
  const [halfDayoffUse, setHalfDayoffUse] = useState(initialHalfDayoffUse);
  const [rechargeConditionId, setRechargeConditionId] = useState(
    initialRechargeConditionId
  );
  const [postponeMonth, setPostponeMonth] = useState(initialPostponeMonth);
  const [useUsers, setUseUsers] = useState(initialUsers);
  const [dubleClick, setDubleClick] = useState(true);
  const [userSelectModalStatus, setUserSelectModalStatus] = useState(false);
  const [rechargeSelectModalToggle, setRechargeSelectModalToggle] =
    useState(false);

  const handleRechargeSelectModal = () => {
    setRechargeSelectModalToggle((prev) => !prev);
  };

  const handleSwicthBtnValue = (setValue) => {
    setValue((prev) => !prev);
  };

  const handleUserSelectModal = () => {
    setUserSelectModalStatus((prev) => !prev);
  };

  const selectUserList = (selectModel) => {
    setUseUsers(selectModel);
    handleUserSelectModal();
  };

  const selectRechargeCondition = (selectModel) => {
    setRechargeConditionId(selectModel);
    handleRechargeSelectModal();
  };

  const handleHalfDay = (type) => {
    if (type === "half") {
      setHalfDayoffUse(true);
    }

    if (type === "day") {
      setHalfDayoffUse(false);
    }
  };

  const rechargeConditionName = useMemo(() => {
    if (rechargeConditionId.length === 0) {
      return "";
    }

    const findRechargeItem = recharge_condition_list.find(
      (c) => c.id === rechargeConditionId[0]
    );

    return findRechargeItem?.annual_leave_recharge_condition_name ?? "";
  }, [rechargeConditionId, recharge_condition_list]);

  const saveBtnDisabled = useMemo(() => {
    if (rechargeConditionId.length === 0) {
      return true;
    }

    return false;
  }, [rechargeConditionId]);

  const submitAnnualLeavePolicy = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const submitData = {
        policyName,
        approveUse,
        cancelApproveUse,
        halfDayoffUse,
        postponeMonth: Number(postponeMonth),
        rechargeConditionId: rechargeConditionId[0],
        useUsers,
      };

      if (type === "update") {
        submitData.id = id;
        submitData.origin_id = origin_id;
      }

      const requestCreatePolicy = await userPermAxios.post(
        `/dayoff/${type}_annual_leave_policy`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreatePolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const saveAnnualLeavePolicy = () => {
    if (!dubleClick) {
      return;
    }

    let message;

    if (type === "create") {
      message = "Do you want to create a new annual leave policy?";
    }

    if (type === "update") {
      message = "Do you want to update a annual leave policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await submitAnnualLeavePolicy();
      },
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={792}
      button_disabled={button_disabled}
      save_btn_disabled={saveBtnDisabled}
      dubleClick={dubleClick}
      saveFn={saveAnnualLeavePolicy}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval Required
            </div>
            <SwicthBtn
              checked={approveUse}
              onClick={() => {
                handleSwicthBtnValue(setApproveUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval Required for Cancellation
            </div>
            <SwicthBtn
              checked={cancelApproveUse}
              onClick={() => {
                handleSwicthBtnValue(setCancelApproveUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Half Dayoff Use
            </div>
            <HalfGroupButton
              onClick={handleHalfDay}
              selectValue={halfDayoffUse}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Annual Leave Expires After (Month)
            </div>
            <LabelSelect
              value={postponeMonth}
              setValue={setPostponeMonth}
              list={Array.from({ length: 13 }).map((c, i) => {
                return { name: `${i.toString()} Month`, value: i.toString() };
              })}
              width={200}
            />
          </div>
          <div className="work-policy-modal-another-box">
            <div className="work-policy-modal-detail-contents-title-box">
              <div className="work-policy-modal-detail-contents-title">
                Recharge Condition:
              </div>
              <div className="work-policy-modal-detail-contents-subtitle">
                {rechargeConditionName}
              </div>
            </div>

            <PolicyModalBtn
              onClick={handleRechargeSelectModal}
              label="Recharge Condition List"
              width="320px"
              icon={false}
            />
          </div>
          <div className="work-policy-modal-another-box">
            <div className="work-policy-modal-detail-contents-title-box">
              <div className="work-policy-modal-detail-contents-title">
                Policy Apply Users:
              </div>
              <div className="work-policy-modal-detail-contents-subtitle">
                {useUsers.length}
              </div>
            </div>
            <PolicyModalBtn
              onClick={handleUserSelectModal}
              label="Assigned User"
              width="320px"
            />
          </div>
        </div>
      </div>
      {rechargeSelectModalToggle && (
        <RechargeConditionSelectModal
          title="Recharge Condition Select Modal"
          open={rechargeSelectModalToggle}
          onClose={handleRechargeSelectModal}
          tableRow={recharge_condition_list}
          initialSelectList={rechargeConditionId}
          newcomerStartOptions={newcomer_start_options}
          underDecimalOptions={under_decimal_options}
          onConfirm={selectRechargeCondition}
          disabled={button_disabled}
          refresh={annual_leave_refresh}
        />
      )}
      {userSelectModalStatus && (
        <WorkPolicyUserSelectModal
          title="Work Policy User List"
          open={userSelectModalStatus}
          onClose={handleUserSelectModal}
          tableRow={user_list}
          initialSelectList={useUsers}
          onConfirm={selectUserList}
          disabled={button_disabled}
        />
      )}
    </PolicyModalBody>
  );
};

export default AnnualLeavePolicyModal;

const query_fetch_data = async () => {
  let result = {
    user_list: [],
    annual_leave_recharge_condition: {
      recharge_condition_list: [],
      newcomer_start_options: [],
      under_decimal_options: [],
    },
  };

  try {
    const requestUserList = await userPermAxios.get(
      "/dayoff/get_annual_leave_data"
    );
    const { success, data } = requestUserList;

    if (success) {
      result = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const HalfGroupButton = ({ onClick, selectValue }) => {
  return (
    <div className="half-dayoff-group-btn-box">
      <button
        className={`half-dayoff-group-btn ${
          selectValue ? "active" : "non-active"
        }`}
        onClick={() => onClick("half")}
      >
        <div className="half-dayoff-group-btn-label">Half-Day</div>
      </button>
      <button
        className={`half-dayoff-group-btn ${
          selectValue ? "non-active" : "active"
        }`}
        onClick={() => onClick("day")}
      >
        <div className="half-dayoff-group-btn-label">Day</div>
      </button>
    </div>
  );
};
