import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import MyInfo from "@/components/myInfo/MyInfo";
import Payroll from "@/components/myInfo/Payroll";

const MyInformation = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div className="memu-total-body hr-menu-total-body">
      <div className="hr-menu-total-title-box">
        <div className="hr-menu-total-title">My Information</div>
      </div>
      <div className="hr-menu-body">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="My Information"
                {...a11yProps(0)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Payroll"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <MyInfo />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <Payroll />
          </CustomTabPanel>
        </Box>
      </div>
    </div>
  );
};

export default MyInformation;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "calc(100% - 69px)",
        paddingTop: "20px",
        overflowY: "auto",
      }}
    >
      {value === index && children}
    </div>
  );
}
