export const downloadBase64File = (base64String, fileName) => {
  // Base64 문자열을 디코딩
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  // Uint8Array로 변환
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Blob 생성 (엑셀 파일 형식 지정)
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Blob을 URL로 변환
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", fileName); // 파일명 설정

  // 링크를 클릭해서 다운로드 실행
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadBlobFile = (requestTempleteFile, fileName) => {
  const blob = new Blob([requestTempleteFile.data], {
    type: requestTempleteFile.headers["content-type"], // 서버에서 보낸 MIME 타입 사용
  });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();
  link.remove();
};
