import React, { useState } from "react";
import { IconButton } from "@mui/material";
import ImportModal from "./ImportModal";

const ExcelImportBtn = ({ table_name, disabled }) => {
  const [importModalToggle, setImportModalToggle] = useState(false);

  const handleModalToggle = () => {
    setImportModalToggle((prev) => !prev);
  };

  return (
    <>
      <IconButton
        className="excel_import_export_btn"
        sx={{
          width: "134px",
          padding: "10px 16px",
          gap: "8px",
          color: "#98a2b3",
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #d0d5dd)",
          background: "var(--White, #fff)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          textTransform: "none",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px,",
        }}
        onClick={handleModalToggle}
      >
        <img src="/icons/excel_icon.png" alt="import_icon" />
        Import
      </IconButton>
      {importModalToggle && (
        <ImportModal open={importModalToggle} onClose={handleModalToggle} />
      )}
    </>
  );
};

export default ExcelImportBtn;
