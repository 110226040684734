import React, { useState, useRef, useLayoutEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserSetting, UserTargetProject } from "@atoms/userInfo";
import {
  DataConsolidationModalStatus,
  MenuAddUserModalStatus,
  ProjectAddUserModalStatus,
  ReleaseDataConsolidationModalStatus,
  RequestDataConsolidationModalStatus,
  UserAddMenuModalStatus,
  ContactPointStatus,
  JobNumberStatus,
  QtyControlDataConsolidationModalStatus,
  PackingListDataConsolidationModalStatus,
  RechargeConditionStatus,
} from "@atoms/modalStatus";
import { MaterialModalStatus } from "@atoms/modalStatus";
import { TargetComment } from "@atoms/commnet";
import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Input, InputAdornment, Tooltip } from "@mui/material";
import Popover from "@mui/material/Popover";
import { IconButton, TextField, Select, MenuItem } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import CustomCheckBox from "@components/CustomCheckBox";
import DeleteIcon from "./Icons/DeleteIcon";
import useQRCodeGenerator from "@hooks/useQRCodeGenerator";
import changeGlobalPhoneNumber from "@utils/changeGlobalPhoneNumber";
import { validateEmail } from "@utils/valueRegex";
import { userPermAxios } from "@utils/customAxios";
import { amount_currnecy_list } from "@utils/amountCurrency";
import {
  updateCellData,
  addCellData,
  deleteCellData,
  deleteRowData,
  updatePickerCellData,
  permDeleteRowData,
} from "@utils/adminTableCellFn";
import { create_alert_fn } from "@utils/createAlert";
import { tableLockStatusField } from "@/utils/tableInitialData";
import { labelSelectTheme } from "@styles/muiThemes";
import CommentIcon from "./Icons/CommentIcon";
import CommentNumIcon from "./Icons/CommentNumIcon";
import PermissuonBtnIcon from "./Icons/PermissuonBtnIcon";
import DetailViewIcon from "./Icons/DetailViewIcon";
import DayPicker from "./DatePicker";

dayjs.extend(utc);
dayjs.extend(timezone);

export const CustomLeadCell = ({ params, table_name, query_key, add_text }) => {
  const { id, value, field, row = {}, colDef = {} } = params;

  const { parent_table_name, parent_lead_value } = colDef;

  const setTargetComment = useSetRecoilState(TargetComment);

  const comment_count = row?.comments_count ?? 0;

  const lineitem_count = row?.lineitem_count ?? 0;

  return (
    <div className="table-text-input-box">
      {id !== Infinity ? (
        <>
          <div>{value}</div>

          {lineitem_count > 0 && (
            <div className="table-line-item-count">({lineitem_count})</div>
          )}
        </>
      ) : (
        <div className="table-text-input-lead-box">
          <AddOutlinedIcon
            sx={{ width: "24px", height: "24px", color: "#344054" }}
          />
          <div className="table-text-input-lead-label">{add_text}</div>
        </div>
      )}

      {id !== Infinity && (
        <IconButton
          onClick={(e) => {
            setTargetComment({
              query_key,
              parent_table_name,
              parent_lead_value,
              table_name,
              field,
              value,
              id,
              column: colDef,
            });
          }}
          sx={{ position: "relative" }}
        >
          <MapsUgcOutlinedIcon
            sx={{
              "&:hover": {
                color: "#1976d2",
              },
              color: comment_count > 0 ? "#1976d2" : "#6A6A6A",
            }}
          />
          {comment_count > 0 && (
            <div
              style={{
                fontSize: 8,
                position: "absolute",
                width: 18,
                height: 18,
                borderRadius: 18,
                zIndex: 999,
                right: 5,
                bottom: 5,
                background: "#0073ea",
                color: "#fff",
              }}
            >
              {comment_count}
            </div>
          )}
        </IconButton>
      )}
    </div>
  );
};

export const CustomEditLeadCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const { parent_id, table_group_name } = colDef;

  const [inputValue, setInputValue] = useState(value);
  const [addRowValue, setAddRowValue] = useState("");

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      } else {
        addRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    if (!inputValue || inputValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(value);
    } else {
      if (value === inputValue) {
        return;
      }

      const requestUpdateCellData = await updateCellData(
        id,
        field,
        inputValue,
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdateCellData;

      if (success) {
        apiRef.current.setEditCellValue({ id, field, value: inputValue });
        // apiRef.current.setCellFocus(id, field);
      }
    }
  };

  const addRows = async () => {
    if (!addRowValue || addRowValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
    } else {
      const requestAddRowData = await addCellData(
        field,
        addRowValue,
        table_name,
        table_group_name.toString(),
        parent_id
      );

      const { success } = requestAddRowData;

      if (success) {
        if (hasFocus) {
          apiRef.current.stopCellEditMode({ id: Infinity, field });
        }
      }
    }
  };

  const handleKeyPress = (type, e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      e.stopPropagation();
      if (type === "add") {
        addRows();
      } else {
        updateRows();
      }
    }
  };

  if (id === Infinity) {
    return (
      <div className="table-text-input-box" style={{ padding: "0px 10px" }}>
        <input
          ref={ref}
          type="text"
          value={addRowValue}
          onChange={(e) => setAddRowValue(e.target.value)}
          onKeyDown={(e) => handleKeyPress("add", e)}
        />
      </div>
    );
  }

  return (
    <div className="table-text-input-box" style={{ padding: "0px 10px" }}>
      <input
        ref={ref}
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress("update", e)}
      />
      <div>
        <MapsUgcOutlinedIcon
          sx={{
            "&:hover": {
              color: "#1976d2",
            },
          }}
        />
      </div>
    </div>
  );
};

export const CustomAddLineCell = ({
  params,
  table_name,
  query_key,
  type,
  next_focus_cell_field,
  add_text,
}) => {
  const userTargetProject = useRecoilValue(UserTargetProject);
  const [dubleClick, setDubleClick] = useState(true);
  const { id, value, field, row = {}, colDef = {} } = params;

  const {
    parent_id,
    table_group_name,
    parent_table_name,
    parent_lead_value,
    edit_mode,
  } = colDef;

  const apiRef = useGridApiContext();

  const setTargetComment = useSetRecoilState(TargetComment);

  const comment_count = row?.comments_count ?? 0;

  const lineitem_count = row?.lineitem_count ?? 0;

  const addRow = async () => {
    if (type) {
      if (!dubleClick) return;
      setDubleClick(false);

      try {
        const request_url = `/${table_name}/${userTargetProject?.project_code}/new-auto-row-no`;
        let request_body;

        if (type === "upper") {
          request_body = {
            type,
            keyName: field,
            group_name: table_group_name.toString(),
          };
        }

        if (type === "lineitem") {
          if (!edit_mode) {
            return;
          }

          request_body = {
            type,
            keyName: field,
            parent_id,
            group_name: table_group_name.toString(),
          };
        }

        if (!request_url) {
          return;
        }

        const reqeustAddRow = await userPermAxios.post(
          request_url,
          request_body
        );

        const { success, insertId } = reqeustAddRow;

        if (success) {
          if (next_focus_cell_field) {
            apiRef.current.startCellEditMode({
              id: insertId,
              field: next_focus_cell_field,
            });
            // apiRef.current.setCellFocus({
            //   id: insertId,
            //   field: next_focus_cell_field,
            // });
          }
        }
      } catch (err) {
      } finally {
        setDubleClick(true);
      }
    }
  };

  if (id === Infinity) {
    return (
      <IconButton
        className="select-add-new-item-btn"
        onClick={addRow}
        sx={{
          textTransform: "none",
          display: "flex",
          padding: "var(--2, 8px) 14px",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          width: "100%",
          height: "100%",
          color: "var(--Gray-700, #344054)",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "18px",
          borderRadius: "8px",
          background: "var(--White, #fff)",
        }}
      >
        <AddOutlinedIcon fontSize="small" /> {add_text}
      </IconButton>
    );
  }

  return (
    <div className="table-text-input-box">
      <div>{value}</div>

      {lineitem_count > 0 && (
        <div className="table-line-item-count">({lineitem_count})</div>
      )}

      <IconButton
        onClick={(e) => {
          setTargetComment({
            query_key,
            parent_table_name,
            parent_lead_value,
            table_name,
            field,
            value,
            id,
          });
        }}
        sx={{ position: "relative" }}
      >
        {comment_count > 0 ? <CommentNumIcon /> : <CommentIcon />}

        {comment_count > 0 && (
          <div
            style={{
              fontSize: 8,
              position: "absolute",
              width: 16,
              height: 16,
              borderRadius: 16,
              zIndex: 999,
              right: 5,
              bottom: 5,
              background: "#00A6FF",
              color: "#fff",
            }}
          >
            {comment_count}
          </div>
        )}
      </IconButton>
    </div>
  );
};

export const CustomDateTypeCell = ({ params }) => {
  const { id, value } = params;

  const userSetting = useRecoilValue(UserSetting);

  const valueType = typeof value;

  const valueLength =
    valueType === "object" ? 1 : valueType === "string" ? value.length : 0;

  const dayjs_timezone =
    valueLength === 0
      ? null
      : dayjs(value)
          .utcOffset(userSetting.timeZone, false)
          .format(userSetting.timeFormat);

  if (id === Infinity) {
    return null;
  }

  if (value === null || value === undefined) {
    return null;
  }

  return (
    <div className="table-date-box">
      <div>{dayjs_timezone}</div>
    </div>
  );
};

export const CustomEditDateTypeCell = ({ params, table_name }) => {
  const { id, value, field, colDef = {} } = params;

  const { parent_id } = colDef;

  const apiRef = useGridApiContext();

  const userInfo = useRecoilValue(UserSetting);

  const valueType = typeof value;

  if (id === Infinity) {
    return null;
  }

  const updateRows = async (updateValue) => {
    if (updateValue === "" || value === updateValue) {
      apiRef.current.stopCellEditMode({ id, field });
      return;
    }

    const requestUpdate = await updateCellData(
      id,
      field,
      new Date(updateValue),
      table_name,
      "date",
      parent_id
    );

    const { success } = requestUpdate;

    if (success) {
      apiRef.current.setEditCellValue({ id, field, value: updateValue });
    }

    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <div className="table-edit-date-box">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={dayjs(valueType === "string" ? null : value).utcOffset(
            userInfo.timeZone,
            false
          )}
          // format={userInfo.timeFormat}
          format="YYYY. MM. DD HH:mm:ss"
          ampm={false}
          onAccept={(e) => {
            updateRows(e.utc().toDate());
          }}
          sx={{
            "& .MuiInputBase-input": { padding: "10px", fontSize: "14px" },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export const CustomEditPhoneNumberTypeCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const { parent_id } = colDef;

  const [inputValue, setInputValue] = useState(value ?? "");

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    if (!inputValue || inputValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(value);
    } else {
      if (inputValue === value) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        changeGlobalPhoneNumber(inputValue),
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: changeGlobalPhoneNumber(inputValue),
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};

export const CustomEditEmailTypeCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const { parent_id } = colDef;

  const [inputValue, setInputValue] = useState(value);

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    if (!inputValue || inputValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(value);
    } else if (validateEmail(inputValue) !== null) {
      create_alert_fn("warning", "Please enter it in the form of an email.");
      setInputValue(value);
    } else {
      if (inputValue === value) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        inputValue,
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdate;
      if (success) {
        apiRef.current.setEditCellValue({ id, field, value: inputValue });
      }
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};

export const CustomEditNormalTextTypeCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const { parent_id } = colDef;

  const [inputValue, setInputValue] = useState(value ?? "");

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    if (!inputValue || inputValue.replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(value);
    } else {
      if (inputValue === value) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        inputValue,
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: inputValue,
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};

export const CustomEditDayPickerTypeCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;

  const { parent_id } = colDef;

  const apiRef = useGridApiContext();

  const updateRows = async (e) => {
    const updateValue = e.format("YYYY-MM-DD");

    if (updateValue === value) {
      return;
    }

    const requestUpdate = await updateCellData(
      id,
      field,
      updateValue,
      table_name,
      "string",
      parent_id
    );

    const { success } = requestUpdate;

    if (success) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: updateValue,
      });
    }
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-input-box">
      <DayPicker
        value={dayjs(value)}
        onChange={updateRows}
        dateFormat="YYYY-MM-DD"
        width="100%"
      />
    </div>
  );
};

export const CustomEditNumberTypeCell = ({
  params,
  table_name,
  target_field_name,
}) => {
  const { id, value, field, row, hasFocus, colDef = {} } = params;

  const { parent_id } = colDef;

  const [inputValue, setInputValue] = useState(Number(value) ?? 0);

  const apiRef = useGridApiContext();

  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus) {
      ref?.current?.focus();
    } else {
      if (id !== Infinity) {
        updateRows();
      }
    }
    // eslint-disable-next-line
  }, [hasFocus, id]);

  const updateRows = async () => {
    const numberChange = Number(Number(inputValue).toFixed(2));

    if (inputValue.toString().replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(numberChange);
    } else {
      if (inputValue === value) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        numberChange,
        table_name,
        "number",
        parent_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: numberChange,
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  const handleChange = (e) => {
    const value = e.target.value;

    if (target_field_name) {
      const target_value = Number(row?.[target_field_name] ?? 0);
      const correction_value = value === "" ? 0 : value;

      if (correction_value > target_value) {
        create_alert_fn(
          "warning",
          "It is larger than the number of requests and orders."
        );
        return setInputValue("");
      }
    }

    return setInputValue(value);
  };

  return (
    <div className="table-text-input-box">
      <input
        ref={ref}
        type="number"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={(e) => handleKeyPress(e)}
      />
    </div>
  );
};

export const CustomStageTypeCell = ({ params, blank_add_cell, list = [] }) => {
  const { id, value } = params;

  const targetStage = list.find((c) => c.name === value);

  if (id === Infinity) {
    return null;
  }

  return (
    <div
      className="table-select-input-box"
      style={{
        background: targetStage?.color ?? "#B7B7B7",
        color: targetStage?.fontColor ?? "#fff",
      }}
    >
      <div>{value}</div>
    </div>
  );
};

export const CustomNormalTextTypeCell = ({ params, blank_add_cell }) => {
  const { id, value } = params;

  if (id === Infinity) {
    return null;
  }

  const clearValue = value?.toString()?.replaceAll(" ", "");

  if (!clearValue || clearValue.length === 0) {
    return blank_add_cell;
  }

  return (
    <div className="table-text-input-box">
      <div>{value}</div>
    </div>
  );
};

export const CustomNumberTypeCell = ({ params, blank_add_cell }) => {
  const { id, value } = params;

  if (id === Infinity) {
    return null;
  }

  const clearValue = value?.toString()?.replaceAll(" ", "");

  if (!clearValue || clearValue.length === 0) {
    return blank_add_cell;
  }

  return (
    <div className="table-text-input-box">
      <div>{Number(Number(value ?? 0).toFixed(2)).toLocaleString()}</div>
    </div>
  );
};

export const CustomAmountTypeCell = ({ params, blank_add_cell }) => {
  const { id, value, row, field } = params;

  // const { amount_currency } = row;

  const amount_currency = row[`${field}_currency`];

  if (id === Infinity) {
    return null;
  }

  if (value === undefined) {
    return null;
  }

  return (
    <div className="table-text-amount-input-box">
      <div className="table-text-amount-symbol">
        {amount_currnecy_list[amount_currency] ?? ""}
      </div>
      <div className="table-text-amount-number">
        {Number(value).toLocaleString()}
      </div>
    </div>
  );
};

export const CustomEditAmountTypeCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, row, colDef = {} } = params;
  const { parent_id } = colDef;
  const { amount_currency } = row;
  const [inputValue, setInputValue] = useState(value ?? "");
  const [symbolValue, setSymbolValue] = useState(amount_currency);
  const [anchorElValue, setAnchorElValue] = useState(null);
  const apiRef = useGridApiContext();
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      setAnchorElValue(ref.current);
    }
  }, [hasFocus, ref]);

  const closePopover = () => {
    setAnchorElValue(null);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const updateRows = async () => {
    const numberChange = Number(Number(inputValue).toFixed(2));

    if (inputValue.toString().replaceAll(" ", "").length === 0) {
      create_alert_fn("warning", "This is a required value.");
      setInputValue(numberChange);
    } else {
      if (inputValue === value && symbolValue === amount_currency) {
        return;
      }

      const requestUpdate = await updateCellData(
        id,
        field,
        numberChange,
        table_name,
        "number",
        parent_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: numberChange,
        });
      }
    }
  };

  const updateAmountSymbol = async (value) => {
    try {
      const requestUpdate = await updateCellData(
        id,
        `${field}_currency`,
        value,
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({
          id,
          field: `${field}_currency`,
          value: value,
        });
        setSymbolValue(value);
      }
    } catch (err) {}
  };

  const handleKeyPress = (e) => {
    const eKey = e.key;

    if (eKey === "Enter" || eKey === "Tab") {
      updateRows();
    }
  };

  if (id === Infinity) {
    return null;
  }

  const amount_sybol_list = Object.keys(amount_currnecy_list).map((c) => {
    return { value: c, name: amount_currnecy_list[c] };
  });

  return (
    <div className="table-text-amount-input-box" ref={ref}>
      <div className="table-text-amount-symbol">
        {amount_currnecy_list[amount_currency] ?? ""}
      </div>
      <div className="table-text-amount-number">{value}</div>
      <Popover
        anchorEl={anchorElValue}
        open={Boolean(anchorElValue)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="table-amount-popover-box">
          <div className="table-amount-popover-symbol">
            <Select
              value={symbolValue}
              placeholder=""
              onChange={(e) => updateAmountSymbol(e.target.value)}
            >
              {amount_sybol_list.map((c, i) => {
                return (
                  <MenuItem key={i} value={c.value}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <TextField
            ref={ref}
            label="amount"
            type="number"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
          />
        </div>
      </Popover>
    </div>
  );
};

export const CustomEditSelectCell = ({
  params,
  table_name,
  required_fields,
  approval_list = [],
}) => {
  const { id, value, field, hasFocus, colDef = {}, row = {} } = params;

  const { parent_id, width } = colDef;
  const [anchorElValue, setAnchorElValue] = useState(null);
  const apiRef = useGridApiContext();
  const ref = useRef(null);

  const popover_width = useMemo(() => {
    if (width <= 150) {
      return 150;
    }

    if (width >= 250) {
      return 250;
    }

    return width;
  }, [width]);

  const targetStage = approval_list.find((c) => c.name === value);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      setAnchorElValue(ref.current);
    }
  }, [hasFocus, ref]);

  const closePopover = () => {
    setAnchorElValue(null);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const updateRows = async (value) => {
    const approvedField = tableLockStatusField(table_name);

    if (required_fields && required_fields.length > 0) {
      if (
        approvedField &&
        approvedField.status_field === field &&
        approvedField.lock_status_list.includes(value)
      ) {
        let result = {
          success: true,
          message: "These fields are required values.",
        };

        required_fields.forEach((c, i) => {
          if (!row?.[c.field]) {
            result.success = false;
          }
        });

        if (!result.success) {
          result.message += ` (${required_fields
            .map((c) => c.headerName)
            .join(", ")})`;

          apiRef.current.stopCellEditMode({ id, field });

          create_alert_fn("warning", result.message);
          return;
        }
      }
    }

    const requestUpdate = await updateCellData(
      id,
      field,
      value,
      table_name,
      "string",
      parent_id
    );

    const { success } = requestUpdate;

    if (success) {
      apiRef.current.setEditCellValue({ id, field, value: value });
    }

    apiRef.current.stopCellEditMode({ id, field });
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div
      className="table-select-input-box"
      style={{
        background: targetStage?.color ?? "#B7B7B7",
        color: targetStage?.fontColor ?? "#fff",
      }}
      ref={ref}
    >
      <div>{value}</div>
      <Popover
        anchorEl={anchorElValue}
        open={Boolean(anchorElValue)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          className="table-select-popover-box"
          style={{ width: popover_width }}
        >
          {approval_list.map((c, i) => {
            return (
              <div
                key={i}
                className="table-select-popover-item"
                onClick={() => updateRows(c.name)}
                style={{ background: c.color }}
              >
                {c.name}
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export const CustomCustomerContactPointCell = ({
  params,
  blank_add_cell,
  query_key,
  admin_user,
  user_perm,
}) => {
  const { id, value, colDef = {}, row = {} } = params;
  const setContactPointStatus = useSetRecoilState(ContactPointStatus);

  const { width } = colDef;

  const OpenContactPointModal = () => {
    const { customer_id } = row;

    if (customer_id === undefined || customer_id === null) {
      create_alert_fn("warning", "Please select Customer.");
    } else {
      setContactPointStatus((prev) => {
        return {
          ...prev,
          open: true,
          sales_id: id,
          customer_id,
          query_key,
          admin_user,
          user_perm,
        };
      });
    }
  };

  if (id === Infinity) {
    return null;
  }

  if (!value) {
    return (
      <div
        style={{ display: "flex", height: "100%", alignItems: "center" }}
        onClick={OpenContactPointModal}
      >
        {blank_add_cell}
      </div>
    );
  }

  const boxWidth = width - 22;

  const newUserArr = createUserIconList(value, boxWidth);

  const iconBoxWidth = 30 + (newUserArr.length - 1) * 18;

  return (
    <div className="table-user-icon-box">
      <div
        className="table-user-icon-box-2"
        style={{ width: iconBoxWidth }}
        onClick={OpenContactPointModal}
      >
        {newUserArr.map((c, i) => {
          const arrBool = Array.isArray(c);

          if (arrBool) {
            return (
              <div
                key={i}
                className="table-user-icon"
                style={{
                  left: `calc(15px + ${18 * i}px)`,
                  zIndex: "100px",
                }}
              >
                +{c.length}
              </div>
            );
          } else {
            return (
              <AccountCircleIcon
                key={i}
                className="table-user-icon2"
                sx={{
                  left: `calc(15px + ${18 * i}px)`,
                  zIndex: "100px",
                }}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export const CustomUserTypeCell = ({ params }) => {
  const { id, value, colDef = {} } = params;

  const { width } = colDef;

  if (id === Infinity) {
    return null;
  }

  if (!value) {
    return (
      <div className="table-user-icon-box">
        <div className="table-user-icon-box-2">
          <AccountCircleOutlinedIcon sx={{ width: 30, height: 30 }} />
        </div>
      </div>
    );
  }

  const boxWidth = width - 22;

  const newUserArr = createUserIconList(value, boxWidth);

  const iconBoxWidth = 30 + (newUserArr.length - 1) * 18;

  return (
    <div className="table-user-icon-box">
      <div className="table-user-icon-box-2" style={{ width: iconBoxWidth }}>
        {newUserArr.map((c, i) => {
          const arrBool = Array.isArray(c);

          if (arrBool) {
            return (
              <div
                key={i}
                className="table-user-icon"
                style={{
                  left: `calc(15px + ${18 * i}px)`,
                  zIndex: "100px",
                }}
              >
                +{c.length}
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="table-user-icon"
                style={{
                  left: `calc(15px + ${18 * i}px)`,
                  zIndex: "100px",
                }}
              >
                {c.id}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export const CustomEditUserTypeCell = ({ params, table_name }) => {
  const { id, field, hasFocus, colDef = {} } = params;
  const { width, parent_id } = colDef;
  // eslint-disable-next-line
  const value = params.value ?? [];
  // eslint-disable-next-line

  const query_user_list_data = useQuery(
    ["user_list_search"],
    () => {
      return query_fetch_user_list_data();
    },
    {
      initialData: { list: [] },
    }
  );

  const { list } = query_user_list_data.data;

  const [searchValue, setSearchValue] = useState("");
  const [anchorElValue, setAnchorElValue] = useState(null);
  const apiRef = useGridApiContext();

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      setAnchorElValue(ref.current);
    }
  }, [hasFocus, ref]);

  const filterUserList = useMemo(() => {
    const notSelectUserList = _.differenceBy(list, value, "id");

    const searchValueReplace = searchValue.replaceAll(" ", "").toUpperCase();

    return notSelectUserList.filter((c) => {
      const { name } = c;

      if (searchValueReplace.length === 0) {
        return true;
      }

      return name.includes(searchValueReplace);
    });
  }, [searchValue, list, value]);

  const boxWidth = width - 22;

  const newUserArr = createUserIconList(value, boxWidth);

  const iconBoxWidth = 30 + (newUserArr.length - 1) * 18;

  const closePopover = () => {
    setAnchorElValue(null);
    apiRef.current.stopCellEditMode({ id, field });
  };

  const updateRows = async (item) => {
    const requestUpdate = await updateCellData(
      id,
      field,
      item,
      table_name,
      "string",
      parent_id
    );

    const { success } = requestUpdate;

    if (success) {
      apiRef.current.setEditCellValue({ id, field, value: [...value, item] });
    }
    apiRef.current.stopCellEditMode({ id, field });
  };

  const deleteRows = async (item) => {
    await deleteCellData(id, field, item, table_name);

    const newValue = value.filter((c) => c.id !== item.id);

    apiRef.current.setEditCellValue({ id, field, value: newValue });
    apiRef.current.stopCellEditMode({ id, field });
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div
      className="table-user-icon-box"
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <div
        className="table-user-icon-box-2"
        style={{
          width: iconBoxWidth,
          display: "flex",
          justifyContent: "center",
        }}
        ref={ref}
      >
        {!value ? (
          <AccountCircleOutlinedIcon sx={{ width: 30, height: 30 }} />
        ) : (
          newUserArr.map((c, i) => {
            const arrBool = Array.isArray(c);

            if (arrBool) {
              return (
                <div
                  key={i}
                  className="table-user-icon"
                  style={{
                    left: `calc(15px + ${18 * i}px)`,
                    zIndex: "100px",
                  }}
                >
                  +{c.length}
                </div>
              );
            } else {
              return (
                <div
                  key={i}
                  className="table-user-icon"
                  style={{
                    left: `calc(15px + ${18 * i}px)`,
                    zIndex: "100px",
                  }}
                >
                  {c.id}
                </div>
              );
            }
          })
        )}
      </div>
      <Popover
        anchorEl={anchorElValue}
        open={Boolean(anchorElValue)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="table-user-select-popover-box">
          {value && value.length > 0 && (
            <div className="select-user-box">
              {value.map((c, i) => {
                return (
                  <div key={i} className="select-user-box-item">
                    <div className="select-user-box-item-icon">{c.id}</div>
                    <div className="select-user-box-item-name">{c.name}</div>
                    <div
                      className="select-user-box-item-close"
                      onClick={() => deleteRows(c)}
                    >
                      x
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="select-user-search-box">
            <Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search User Name"
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
            />
          </div>

          <div className="select-user-list-box">
            {filterUserList.map((c, i) => {
              return (
                <div
                  key={i}
                  className="select-user-list-box-item"
                  onClick={() => updateRows(c)}
                >
                  <div className="select-user-list-box-item-icon">{c.id}</div>
                  <div className="select-user-list-box-item-text">
                    {c.username}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export const CustomEidtorPickerTypeCell = ({
  params,
  table_name,
  picker_table_name,
  picker_column_list,
  picker_main_column_field,
  picker_update_column_list,
  fk_key,
  add_btn_use,
  release_picker,
}) => {
  const { id, field, hasFocus, colDef = {}, value = [] } = params;
  // const value = params.value ?? [];
  const { parent_id, parent_lead_id } = colDef;

  const userTargetValue = useRecoilValue(UserTargetProject);
  const [searchValue, setSearchValue] = useState("");
  const [anchorElValue, setAnchorElValue] = useState(null);
  const setMaterialModalStatus = useSetRecoilState(MaterialModalStatus);
  const apiRef = useGridApiContext();

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      setAnchorElValue(ref.current);
    }
  }, [hasFocus, ref]);

  const query_picker_data = useQuery(
    [
      "user_picker_data",
      picker_table_name,
      picker_column_list,
      release_picker,
      userTargetValue,
      parent_lead_id,
      id,
    ],
    () => {
      return query_fetch_picker_data(
        picker_table_name,
        picker_column_list,
        release_picker,
        userTargetValue,
        parent_lead_id,
        id
      );
    },
    {
      initialData: { list: [] },
    }
  );

  const { list } = query_picker_data.data;

  const closePopover = () => {
    setAnchorElValue(null);
    // apiRef.current.stopCellEditMode({ id, field });
  };

  const filterUserList = useMemo(() => {
    const notSelectUserList = _.differenceBy(list, value, "id");

    const searchValueReplace = searchValue.replaceAll(" ", "").toUpperCase();

    return notSelectUserList.filter((c) => {
      if (searchValueReplace.length === 0) {
        return true;
      }

      let field_data = "";

      picker_column_list.forEach((c2) => {
        const target_data = c[c2] ?? "";

        field_data += target_data.toString()?.replaceAll(" ", "").toUpperCase();
      });

      return field_data.includes(searchValueReplace);
    });
  }, [searchValue, list, value, picker_column_list]);

  const updateRows = async (value) => {
    let request_stop = { status: false, message: "" };

    try {
      const requestUpdate = await updatePickerCellData(
        table_name,
        id,
        fk_key,
        picker_update_column_list,
        value,
        parent_id
      );

      const { success, message } = requestUpdate;

      if (success) {
        picker_update_column_list.forEach((c) => {
          apiRef.current.setEditCellValue({
            id,
            field: c,
            value: value[c],
          });
        });
      } else {
        request_stop.status = true;
        request_stop.message = message;
      }
    } catch (err) {
      request_stop.status = true;
    }

    if (request_stop.status) {
      create_alert_fn("warning", request_stop.message, () => {});
    }

    apiRef.current.stopCellEditMode({ id, field });

    // if (fk_key) {
    //   let request_stop = { status: false, message: "" };

    //   try {
    //     const requestUpdate = await updateCellData(
    //       id,
    //       fk_key,
    //       value.id,
    //       table_name,
    //       "string",
    //       parent_id
    //     );

    //     const { success, message } = requestUpdate;

    //     if (success) {
    //       apiRef.current.setEditCellValue({
    //         id,
    //         field: fk_key,
    //         value: value.id,
    //       });
    //     } else {
    //       request_stop.status = true;
    //       request_stop.message = message;
    //     }
    //   } catch (err) {
    //     request_stop.status = true;
    //   }

    //   if (request_stop.status) {
    //     return create_alert_fn("warning", request_stop.message, () => {});
    //   }
    // }

    // for (const update_field of picker_update_column_list) {
    //   try {
    //     const requestUpdate = await updateCellData(
    //       id,
    //       update_field,
    //       value[update_field],
    //       table_name,
    //       "string",
    //       parent_id
    //     );

    //     const { success } = requestUpdate;

    //     if (success) {
    //       apiRef.current.setEditCellValue({
    //         id,
    //         field: update_field,
    //         value: value[update_field],
    //       });
    //     }
    //   } catch (err) {}
    // }

    // apiRef.current.stopCellEditMode({ id, field });
  };

  const openMaterialAddModal = () => {
    setMaterialModalStatus({
      open: true,
      table_name: table_name,
      parent_id: parent_id,
      query_key: ["user_picker_data", picker_table_name, picker_column_list],
    });

    return apiRef.current.stopCellEditMode({ id, field });
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-picker-box" ref={ref}>
      <div>{value}</div>
      <Popover
        anchorEl={anchorElValue}
        open={Boolean(anchorElValue)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="table-user-select-popover-box">
          <div className="select-user-search-box">
            <Input
              className="select-user-search-input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search Item..."
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              disableUnderline
            />
          </div>

          <div className="select-user-list-box">
            {filterUserList.map((c, i) => {
              return (
                <div
                  key={i}
                  className="select-user-list-box-item"
                  onClick={() => updateRows(c)}
                >
                  <div
                    className="select-user-list-box-item-icon"
                    style={{ paddingLeft: 10 }}
                  >
                    {c.id}
                  </div>
                  {picker_main_column_field.map((c2, i) => {
                    return (
                      <div key={i} className="select-user-list-box-item-text">
                        {c[c2] ?? ""}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {add_btn_use && (
            <>
              <div className="select-add-new-hori-line" />
              <IconButton
                className="select-add-new-item-btn"
                onClick={openMaterialAddModal}
                sx={{
                  textTransform: "none",
                  display: "flex",
                  padding: "var(--2, 8px) 14px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "18px",
                  borderRadius: "8px",
                  border: "1px solid var(--Gray-300, #d0d5dd)",
                  background: "var(--White, #fff)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <AddOutlinedIcon sx={{ width: "20px", height: "20px" }} />
                Add New
              </IconButton>
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

export const CustomEidtorSalesPickerTypeCell = ({
  params,
  table_name,
  picker_table_name,
  picker_column_list,
  picker_main_column_field,
  picker_update_column_list,
  fk_key,
}) => {
  const { id, field, hasFocus, colDef = {}, value = [], row = {} } = params;
  const { parent_id, parent_lead_id } = colDef;
  const { bid_sortation } = row;
  const userTargetValue = useRecoilValue(UserTargetProject);
  const [searchValue, setSearchValue] = useState("");
  const [anchorElValue, setAnchorElValue] = useState(null);
  const apiRef = useGridApiContext();

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      if (bid_sortation === undefined) {
        setAnchorElValue(ref.current);
      }

      if (bid_sortation !== null) {
        setAnchorElValue(ref.current);
      } else {
        create_alert_fn("warning", "Please select bid sortation first.");
      }
    }
  }, [hasFocus, ref, bid_sortation]);

  const query_picker_data = useQuery(
    [
      "user_picker_data",
      picker_table_name,
      userTargetValue,
      parent_lead_id,
      id,
      table_name,
    ],
    () => {
      return query_fetch_sales_picker_data(
        picker_table_name,
        userTargetValue,
        parent_lead_id,
        id,
        table_name
      );
    },
    {
      initialData: { list: [] },
    }
  );

  const { list } = query_picker_data.data;

  const closePopover = () => {
    setAnchorElValue(null);
    // apiRef.current.stopCellEditMode({ id, field });
  };

  const filterUserList = useMemo(() => {
    const notSelectUserList = _.differenceBy(list, value, "id");

    const searchValueReplace = searchValue.replaceAll(" ", "").toUpperCase();

    return notSelectUserList.filter((c) => {
      if (searchValueReplace.length === 0) {
        return true;
      }

      let field_data = "";

      ["sales_job_number", "lead"].forEach((c2) => {
        const target_data = c[c2] ?? "";

        field_data += target_data.toString()?.replaceAll(" ", "").toUpperCase();
      });

      return field_data.includes(searchValueReplace);
    });
  }, [searchValue, list, value, picker_column_list]);

  const updateRows = async (value) => {
    let request_stop = { status: false, message: "" };

    try {
      const requestUpdate = await updatePickerCellData(
        table_name,
        id,
        fk_key,
        picker_update_column_list,
        value,
        parent_id
      );

      const { success, message } = requestUpdate;

      if (success) {
        picker_update_column_list.forEach((c) => {
          apiRef.current.setEditCellValue({
            id,
            field: c,
            value: value[c],
          });
        });
      } else {
        request_stop.status = true;
        request_stop.message = message;
      }
    } catch (err) {
      request_stop.status = true;
    }

    if (request_stop.status) {
      create_alert_fn("warning", request_stop.message, () => {});
    }

    apiRef.current.stopCellEditMode({ id, field });
  };

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-picker-box" ref={ref}>
      <div>{value}</div>
      <Popover
        anchorEl={anchorElValue}
        open={Boolean(anchorElValue)}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="table-user-select-popover-box"
          style={{ width: 450, height: "auto" }}
        >
          <div className="select-user-search-box">
            <Input
              className="select-user-search-input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search Item..."
              sx={{
                fontSize: "12px",
                width: "100%",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              disableUnderline
            />
          </div>

          <div className="select-user-list-box" style={{}}>
            {filterUserList.map((c, i) => {
              const { id, lead, sales_job_number } = c;

              return (
                <div
                  key={id}
                  className="select-user-list-box-item"
                  onClick={() => updateRows(c)}
                >
                  <div
                    className="select-user-list-box-item-icon"
                    style={{
                      width: 120,
                      textAlign: "left",
                    }}
                  >
                    {sales_job_number}
                  </div>
                  <div
                    key={i}
                    className="select-user-list-box-item-text"
                    style={{
                      textAlign: "left",
                      width: 250,
                      wordBreak: "break-word",
                    }}
                  >
                    {lead}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export const CustomSubPriceCell = ({ params, qty_field, unit_price_field }) => {
  const { id, row } = params;

  // const currency_field = `${unit_price_field}_currency`;

  const unit_price = row?.[unit_price_field] ?? 0;
  const qty = row?.[qty_field] ?? 0;

  if (id === Infinity) {
    return null;
  }

  return (
    <div className="table-text-amount-input-box" style={{ width: "100%" }}>
      <div className="table-text-amount-number" style={{ textAlign: "center" }}>
        {Number(unit_price * qty).toLocaleString()}
      </div>
    </div>
  );
};

export const CustomDiscountPriceCell = ({
  params,
  qty_field,
  unit_price_field,
  discount_percent_field,
}) => {
  const { id, row } = params;

  // const currency_field = `${unit_price_field}_currency`;

  const unit_price = Number(row?.[unit_price_field] ?? 0);
  const qty = Number(row?.[qty_field] ?? 0);
  const discount_percent = Number(row?.[discount_percent_field] ?? 0);

  if (id === Infinity) {
    return null;
  }

  const price = (unit_price * qty * ((100 - discount_percent) / 100)).toFixed(
    0
  );

  return (
    <div className="table-text-amount-input-box" style={{ width: "100%" }}>
      <div className="table-text-amount-number" style={{ textAlign: "center" }}>
        {Number(price).toLocaleString()}
      </div>
    </div>
  );
};

export const CustomDisCountPriceCell = ({
  params,
  qty_field,
  unit_price_field,
  discount_percent_field,
}) => {
  const { id, row } = params;

  // const currency_field = `${unit_price_field}_currency`;

  const unit_price = Number(row?.[unit_price_field] ?? 0);
  const qty = Number(row?.[qty_field] ?? 0);
  const discount_percent = Number(row?.[discount_percent_field] ?? 0);

  if (id === Infinity) {
    return null;
  }

  const price = (unit_price * qty * ((100 - discount_percent) / 100)).toFixed(
    0
  );

  return (
    <div className="table-text-amount-input-box" style={{ width: "100%" }}>
      <div className="table-text-amount-number" style={{ textAlign: "center" }}>
        {Number(price).toLocaleString()}
      </div>
    </div>
  );
};

export const CustomTaxPriceCell = ({
  params,
  qty_field,
  unit_price_field,
  tax_percent_field,
  discount_percent_field,
}) => {
  const { id, row } = params;

  // const currency_field = `${unit_price_field}_currency`;

  const unit_price = Number(row?.[unit_price_field] ?? 0);
  const qty = Number(row?.[qty_field] ?? 0);
  const tax_percent = Number(row?.[tax_percent_field] ?? 0);
  const discount_percent = Number(row?.[discount_percent_field] ?? 0);

  if (id === Infinity) {
    return null;
  }

  const price = (
    unit_price *
    qty *
    ((100 - discount_percent) / 100) *
    (tax_percent / 100)
  ).toFixed(2);

  // const price = (unit_price * qty * (tax_percent / 100)).toFixed(0);

  return (
    <div className="table-text-amount-input-box" style={{ width: "100%" }}>
      <div className="table-text-amount-number" style={{ textAlign: "center" }}>
        {Number(price).toLocaleString()}
      </div>
    </div>
  );
};

export const CustomSumTaxPriceCell = ({
  params,
  qty_field,
  unit_price_field,
  tax_percent_field,
  discount_percent_field,
}) => {
  const { id, row } = params;

  // const currency_field = `${unit_price_field}_currency`;

  const unit_price = Number(row?.[unit_price_field] ?? 0);
  const qty = Number(row?.[qty_field] ?? 0);
  const tax_percent = Number(row?.[tax_percent_field] ?? 0);
  const discount_percent = Number(row?.[discount_percent_field] ?? 0);

  if (id === Infinity) {
    return null;
  }

  const price = (
    unit_price * qty * ((100 - discount_percent) / 100) +
    unit_price * qty * ((100 - discount_percent) / 100) * (tax_percent / 100)
  ).toFixed(0);

  return (
    <div className="table-text-amount-input-box" style={{ width: "100%" }}>
      <div className="table-text-amount-number" style={{ textAlign: "center" }}>
        {Number(price).toLocaleString()}
      </div>
    </div>
  );
};

export const LineDeleteBtnCell = ({ params, table_name }) => {
  const { id, colDef = {} } = params;

  const { edit_mode, parent_id } = colDef;

  const apiRef = useGridApiContext();

  if (id === Infinity) {
    return null;
  }

  const handleDeleteBtn = async () => {
    const requestDeleteRow = await deleteRowData(table_name, parent_id, id);

    const { success, message } = requestDeleteRow;

    if (success) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    } else {
      return create_alert_fn("warning", message, () => {});
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={!edit_mode}
      onClick={handleDeleteBtn}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export const PermLineDeleteBtnCell = ({
  params,
  table_name,
  line_table_name,
}) => {
  const { id, colDef = {} } = params;

  const { edit_mode, parent_id } = colDef;

  const apiRef = useGridApiContext();

  if (id === Infinity) {
    return null;
  }

  const handleDeleteBtn = async () => {
    const requestDeleteRow = await permDeleteRowData(
      table_name,
      line_table_name,
      parent_id,
      id
    );

    const { success, message } = requestDeleteRow;

    if (success) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    } else {
      return create_alert_fn("warning", message, () => {});
    }
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={!edit_mode}
      onClick={handleDeleteBtn}
    >
      <DeleteIcon />
    </IconButton>
  );
};

export const RechargeConditionCell = ({
  params,
  onClose,
  refresh,

  newcomerStartOptionsList = [],
  underDecimalOptionsList = [],
}) => {
  const setRechargeConditionStatus = useSetRecoilState(RechargeConditionStatus);

  const { id, row = {} } = params;

  const {
    annual_leave_recharge_condition_name,
    create_startdate_option,
    recharge_days,
    newcomer_start_option,
    default_annual_leave_count,
    annual_leave_additional_count,
    annual_leave_additional_year,
    annual_leave_additional_cycle,
    under_decimal_option,
  } = row;

  if (id === Infinity) {
    return null;
  }

  const openRechargeConditionModal = () => {
    setRechargeConditionStatus((prev) => {
      return {
        ...prev,
        open: true,
        action_type: "update",
        title: "Recharge Condition Setting",
        onClose: onClose,
        reFresh: refresh,
        id,
        initialAnnualLeaveRechargeConditionName:
          annual_leave_recharge_condition_name,
        initialCreateStartDateOption: create_startdate_option,
        initialRechargeDays: recharge_days,
        initialNewcomerStartOption: newcomer_start_option,
        initialDefaultAnnualLeaveCount: default_annual_leave_count,
        initialAnnualLeaveAdditionalCount: annual_leave_additional_count,
        initialAnnualLeaveAdditionalYear: annual_leave_additional_year,
        initialAnnualLeaveAdditionalCycle: annual_leave_additional_cycle,
        initialUnderDecimalOption: under_decimal_option,
        newcomerStartOptionsList: newcomerStartOptionsList,
        underDecimalOptionsList: underDecimalOptionsList,
      };
    });
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      // disabled={!edit_mode}
      onClick={openRechargeConditionModal}
    >
      <DetailViewIcon />
    </IconButton>
  );
};

export const QRCodeCreateBtnCell = ({ params, table_name, query_key }) => {
  const [dubleClick, setDubleClick] = useState(true);
  const { id, row } = params;

  const { status = "Draft", qr_code } = row;

  if (id === Infinity) {
    return null;
  }

  const button_disabled = () => {
    if (status !== "Approved") {
      return true;
    }

    if (qr_code !== null) {
      return true;
    }

    return false;
  };

  const createQRCode = async () => {
    if (!dubleClick) return;
    setDubleClick(false);

    let alertMsg = {
      type: "warning",
      message: "",
    };

    try {
      const requestCreateQrCode = await userPermAxios.get(
        `/${id}/create_qr_code`
      );

      const { success } = requestCreateQrCode;

      if (success) {
        alertMsg.type = "success";
        alertMsg.message = "Successfully Create QR Code Image";
      }
    } catch (err) {
    } finally {
      setDubleClick(true);
      return create_alert_fn(alertMsg.type, alertMsg.message);
    }
  };

  return (
    <Tooltip
      title="Create QR Code"
      placement="top"
      sx={{
        fontSize: 14,
        zIndex: 9999999,
      }}
    >
      {button_disabled() ? (
        <div
          className="line-delete-cell-btn"
          style={{ display: "flex", alignItems: "center" }}
        >
          <QrCode2OutlinedIcon sx={{ color: "#d0d5dd" }} />
        </div>
      ) : (
        <IconButton
          className="line-delete-cell-btn"
          disabled={button_disabled()}
          onClick={createQRCode}
        >
          <QrCode2OutlinedIcon fontSize="small" />
        </IconButton>
      )}
    </Tooltip>
  );
};

export const QRCodeDownLoadBtnCell = ({ params, table_name, query_key }) => {
  const { id, value, row } = params;

  const { pl_no } = row;

  const { canvasRef, generateQRCode } = useQRCodeGenerator(pl_no, value);

  if (id === Infinity) {
    return null;
  }

  return (
    <Tooltip
      title="Download QR Code"
      placement="top"
      sx={{
        fontSize: 14,
        zIndex: 999,
      }}
    >
      {value === null ? (
        <div
          className="line-delete-cell-btn"
          style={{ display: "flex", alignItems: "center" }}
        >
          <FileDownloadOutlinedIcon sx={{ color: "#d0d5dd" }} />
        </div>
      ) : (
        <IconButton
          className="line-delete-cell-btn"
          disabled={value === null}
          onClick={() => generateQRCode(value)}
        >
          <canvas ref={canvasRef} style={{ display: "none" }} />
          <FileDownloadOutlinedIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export const CustomCheckBoxCell = ({
  params,
  table_name,
  disabled = false,
}) => {
  const { id, field, value, colDef = {} } = params;

  const { parent_id } = colDef;

  const apiRef = useGridApiContext();

  if (id === Infinity) {
    return null;
  }

  const updateRow = async () => {
    try {
      const updateValue = value === 1 ? 0 : 1;

      const requestUpdateCellData = await updateCellData(
        id,
        field,
        updateValue,
        table_name,
        "string",
        parent_id
      );

      const { success } = requestUpdateCellData;

      if (success) {
        apiRef.current.updateRows([{ id, field, value: updateValue }]);
      }
    } catch (err) {}
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CustomCheckBox
        checked={value === 1 ? true : false}
        onChange={updateRow}
        disabled={disabled}
      />
    </div>
  );
};

export const CustomReceivedCheckBoxCell = ({
  params,
  table_name,
  disabled = false,
}) => {
  const { id, field, value, colDef = {}, row } = params;

  if (id === Infinity) {
    return null;
  }

  const receive_qnty = Number(row?.receive_qnty ?? 0);
  const stock_update_qnty = Number(row?.stock_update_qnty ?? 0);
  const disqualified_qnty = Number(row?.disqualified_qnty ?? 0);

  let checkValue = false;

  if (field === "qnty_inspection") {
    checkValue = receive_qnty === stock_update_qnty ? true : false;
  }

  if (field === "disq_inspection") {
    checkValue = disqualified_qnty === 0 ? true : false;
  }

  if (field === "has_issue") {
    checkValue = value === 1 ? true : false;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CustomCheckBox
        checked={checkValue}
        // onChange={updateRow}
        disabled={disabled}
      />
    </div>
  );
};

export const DataConsolidationCell = ({
  params,
  table_name,
  prev_table_name,
}) => {
  const setDataConsolidationModalStatus = useSetRecoilState(
    DataConsolidationModalStatus
  );
  const { id, row } = params;

  const { status, lead, sales_id } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!lead || !sales_id) {
      return create_alert_fn(
        "warning",
        "Please select the target project first.",
        () => {}
      );
    } else {
      return setDataConsolidationModalStatus((prev) => ({
        ...prev,
        open: true,
        target_id: id,
        sales_id: sales_id,
        table_name,
        prev_table_name,
      }));
    }
  };

  const button_disabled = () => {
    if (
      status === "Approved" ||
      status === "Inspected" ||
      status === "Return"
    ) {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const PackingListDataConsolidationCell = ({
  params,
  table_name,
  prev_table_name,
}) => {
  const setDataConsolidationModalStatus = useSetRecoilState(
    PackingListDataConsolidationModalStatus
  );
  const { id, row } = params;

  const { status, lead, sales_id } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!lead || !sales_id) {
      return create_alert_fn(
        "warning",
        "Please select the target project first.",
        () => {}
      );
    } else {
      return setDataConsolidationModalStatus((prev) => ({
        ...prev,
        open: true,
        target_id: id,
        sales_id: sales_id,
        table_name,
        prev_table_name,
      }));
    }
  };

  const button_disabled = () => {
    if (
      status === "Approved" ||
      status === "Inspected" ||
      status === "Return"
    ) {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const ReleaseDataConsolidationCell = ({ params }) => {
  const setDataConsolidationModalStatus = useSetRecoilState(
    ReleaseDataConsolidationModalStatus
  );
  const { id, row } = params;

  const { status, lead, sales_id } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!lead || !sales_id) {
      return create_alert_fn(
        "warning",
        "Please select the target project first.",
        () => {}
      );
    } else {
      return setDataConsolidationModalStatus((prev) => ({
        ...prev,
        open: true,
        target_id: id,
        sales_id: sales_id,
      }));
    }
  };

  const button_disabled = () => {
    if (status === "Approved") {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const RequestDataConsolidationCell = ({ params }) => {
  const setDataConsolidationModalStatus = useSetRecoilState(
    RequestDataConsolidationModalStatus
  );
  const { id, row } = params;

  const { status, lead, sales_id } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!lead || !sales_id) {
      return create_alert_fn(
        "warning",
        "Please select the target project first.",
        () => {}
      );
    } else {
      return setDataConsolidationModalStatus((prev) => ({
        ...prev,
        open: true,
        target_id: id,
        sales_id: sales_id,
      }));
    }
  };

  const button_disabled = () => {
    if (
      status === "Approved" ||
      status === "Inspected" ||
      status === "Return"
    ) {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const QtyControlDataConsolidationCell = ({ params }) => {
  const setDataConsolidationModalStatus = useSetRecoilState(
    QtyControlDataConsolidationModalStatus
  );
  const { id, row } = params;

  const { status, inv_name } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!inv_name) {
      return create_alert_fn(
        "warning",
        "Please select the target inventory first.",
        () => {}
      );
    } else {
      return setDataConsolidationModalStatus((prev) => ({
        ...prev,
        open: true,
        target_id: id,
        inv_name,
      }));
    }
  };

  const button_disabled = () => {
    if (
      status === "Approved" ||
      status === "Inspected" ||
      status === "Return"
    ) {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const RequestSalesJobNumberCell = ({ params, query_key }) => {
  const setJobNumber = useSetRecoilState(JobNumberStatus);
  const { id, row } = params;

  const { sales_stage, lead } = row;

  if (id === Infinity) {
    return null;
  }

  const openDataConsolidationModal = () => {
    if (!lead) {
      return create_alert_fn(
        "warning",
        "Please select the target project first.",
        () => {}
      );
    } else {
      return setJobNumber((prev) => ({
        ...prev,
        open: true,
        sales_id: id,
        query_key,
      }));
    }
  };

  const button_disabled = () => {
    if (
      sales_stage === "On Bid" ||
      sales_stage === "Won" ||
      sales_stage === "Lost"
    ) {
      return true;
    }

    return false;
  };

  return (
    <IconButton
      className="line-delete-cell-btn"
      disabled={button_disabled()}
      onClick={openDataConsolidationModal}
    >
      <DataConsolidationIcon fontSize="small" />
    </IconButton>
  );
};

export const CustomSelectListCell = ({ params, table_name }) => {
  const { value, colDef = {} } = params;
  const { list = [] } = colDef;

  const findName = list.find((c) => c.value === value);

  return (
    <div className="table-text-input-box">
      <div>{findName?.name}</div>
    </div>
  );
};

export const CustomEditSelectListCell = ({ params, table_name }) => {
  const { id, field, value, hasFocus, colDef = {} } = params;
  const { list = [] } = colDef;

  const [open, setOpen] = useState(false);

  const apiRef = useGridApiContext();

  useLayoutEffect(() => {
    if (hasFocus) {
      setOpen(true);
    }
  }, [hasFocus]);

  const onClose = () => {
    const cellMode = apiRef.current.getCellMode(id, field);

    if (cellMode === "edit") {
      apiRef.current.stopCellEditMode({ id, field });
    }
    setOpen(false);
  };

  const handleChange = async (e) => {
    const targetValue = e.target.value;

    try {
      const requestUpdate = await updateCellData(
        id,
        field,
        targetValue,
        table_name,
        "string"
      );

      const { success } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({ id, field, value: targetValue });
      }
    } catch (err) {
    } finally {
      return onClose();
    }
  };

  return (
    <div className="table-text-input-box">
      <ThemeProvider theme={labelSelectTheme}>
        <Select
          id="demo-controlled-open-select"
          open={open}
          onClose={onClose}
          onOpen={() => setOpen(true)}
          value={value}
          onChange={handleChange}
          sx={{
            border: "none",
          }}
        >
          {list.map((c, i) => {
            return (
              <MenuItem value={c.value} key={i}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export const CustomEditDropDownListCell = ({ params, table_name }) => {
  const { id, value, field, hasFocus, colDef = {} } = params;
  const { drop_down_list = [] } = colDef;

  const [open, setOpen] = useState(false);

  const apiRef = useGridApiContext();

  useLayoutEffect(() => {
    if (hasFocus) {
      setOpen(true);
    }
  }, [hasFocus]);

  const onClose = () => {
    const cellMode = apiRef.current.getCellMode(id, field);

    if (cellMode === "edit") {
      apiRef.current.stopCellEditMode({ id, field });
    }
    setOpen(false);
  };

  const handleChange = async (e) => {
    const targetValue = e.target.value;

    try {
      const requestUpdate = await updateCellData(
        id,
        field,
        targetValue,
        table_name,
        "string"
      );

      const { success, message } = requestUpdate;

      if (success) {
        apiRef.current.setEditCellValue({ id, field, value: targetValue });
      } else {
        create_alert_fn("warning", message);
      }
    } catch (err) {
    } finally {
      onClose();
    }
  };

  return (
    <Select
      value={value ?? ""}
      onChange={handleChange}
      open={open}
      onClose={onClose}
      fullWidth
      inputProps={{ "aria-label": "Without label" }}
      sx={{
        fontSize: 14,
        fontFamily: "Inter",
        fontWeight: "500",
        textAlign: "center",
      }}
    >
      {drop_down_list.map((c, i) => {
        const { label, value } = c;

        return (
          <MenuItem
            value={value}
            key={i}
            sx={{
              fontSize: 14,
              fontFamily: "Inter",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const CustomDetailPanelToggle = (props) => {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  if (contentCache === undefined || id === Infinity) {
    return null;
  }

  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      // size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
      sx={{ width: "100%", height: "100%" }}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 270}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
          width: "24px",
          height: "24px",
          color: "#344054",
        }}
      />
    </IconButton>
  );
};

export const CustomPermUserAddMenuToggle = ({ params }) => {
  const { id, row } = params;

  const { username = null } = row;

  const setUserAddMenuModalStatus = useSetRecoilState(UserAddMenuModalStatus);

  if (id === Infinity) {
    return null;
  }

  return (
    <IconButton
      sx={{ padding: 0, width: "28px", height: "28px" }}
      onClick={() => {
        setUserAddMenuModalStatus((prev) => {
          return {
            ...prev,
            open: true,
            target_id: id,
            user_id: id,
            username,
          };
        });
      }}
    >
      <PermissuonBtnIcon />
    </IconButton>
  );
};

export const CustomPermMenuAddUserToggle = ({ params }) => {
  const { id, row } = params;

  const { menu_column_name = null, menu_name = null } = row;

  const setMenuAddUserModalStatus = useSetRecoilState(MenuAddUserModalStatus);

  if (id === Infinity) {
    return null;
  }

  return (
    <IconButton
      sx={{ padding: 0, width: "28px", height: "28px" }}
      onClick={() => {
        setMenuAddUserModalStatus((prev) => {
          return {
            ...prev,
            open: true,
            target_id: id,
            menu_url: menu_column_name,
            menu_name,
          };
        });
      }}
    >
      <UserCreateIcon />
    </IconButton>
  );
};

export const CustomPermProjectAddUserToggle = ({ params }) => {
  const { id, row } = params;

  const { lead = null } = row;

  const setProjectAddUserModalStatus = useSetRecoilState(
    ProjectAddUserModalStatus
  );

  if (id === Infinity) {
    return null;
  }

  return (
    <IconButton
      sx={{ padding: 0, width: "28px", height: "28px" }}
      onClick={() => {
        setProjectAddUserModalStatus((prev) => {
          return { ...prev, open: true, sales_id: id, sales_lead: lead };
        });
      }}
    >
      <UserCreateIcon />
    </IconButton>
  );
};

const createUserIconList = (arr, width) => {
  return arr
    .reduce((acc, cur, i) => {
      const arr = [...acc];
      const isGroupNeeds = (i + 1) * 18 + 30 > width;

      if (i === 0) {
        arr.push(cur);

        return arr;
      }

      if (isGroupNeeds) {
        const lastData = arr.at(-1);

        let groupArr = [];

        if (Array.isArray(lastData)) {
          lastData.push(cur);
          groupArr.push(...lastData);
          arr[arr.length - 1] = groupArr;
        } else {
          groupArr.push(cur);

          arr.push(groupArr);
        }
      } else {
        arr.push(cur);
      }

      return arr;
    }, [])
    .map((c) => {
      const arrBool = Array.isArray(c);

      if (arrBool) {
        if (c.length === 1) {
          return c[0];
        } else {
          return c;
        }
      } else {
        return c;
      }
    });
};

const DataConsolidationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.33946 2.23825L10.322 3.99645C10.4928 4.14796 10.4925 4.39388 10.3217 4.54539C10.1509 4.6969 9.87387 4.6969 9.70306 4.54539L8.34975 3.34488V7.0077C8.74414 7.49564 9.37242 8.16746 10.3862 8.37897L10.3925 8.38028C10.5863 8.42429 10.8948 8.44232 11.139 8.45655C11.2077 8.46055 11.2713 8.46426 11.3258 8.46816C11.3455 8.46954 11.3661 8.47078 11.3883 8.47213C11.5018 8.47902 11.6459 8.48776 11.7904 8.52117C12.506 8.66892 13.2091 9.23493 13.4788 10.1006C13.5709 10.3884 13.5908 10.6559 13.6085 10.8944L13.6135 10.9598L12.8863 11.0157L12.8823 10.9622C12.8631 10.7101 12.849 10.5247 12.7839 10.3219L12.7828 10.3186C12.5934 9.70921 12.102 9.32882 11.6397 9.23469L11.6288 9.23248C11.5481 9.21346 11.4746 9.2086 11.3633 9.20148C11.3361 9.19975 11.3066 9.19787 11.2743 9.1956C11.2408 9.1932 11.1948 9.19059 11.1409 9.18755C10.9016 9.17402 10.4959 9.15109 10.2343 9.09222C9.40657 8.91883 8.79314 8.50013 8.34975 8.08495V10.6786H7.65085V8.0843C7.20741 8.49969 6.59377 8.91874 5.76563 9.09222C5.50396 9.15109 5.09836 9.17401 4.85906 9.18754C4.80526 9.19058 4.75956 9.19317 4.72614 9.19556C4.69378 9.19783 4.66382 9.19975 4.63663 9.20148C4.5253 9.2086 4.45181 9.2133 4.37108 9.23231L4.36026 9.23486C3.89791 9.32898 3.40646 9.7092 3.21704 10.3186L3.21603 10.3219C3.15091 10.5247 3.1368 10.7101 3.11764 10.9622L3.11356 11.0157L2.38644 10.9598L2.39136 10.8944C2.40909 10.6559 2.42897 10.3884 2.52115 10.1005C2.79086 9.23492 3.49389 8.66893 4.20945 8.52118C4.35402 8.48776 4.49813 8.47902 4.61157 8.47213C4.63383 8.47078 4.65491 8.4695 4.67458 8.46812C4.72912 8.46422 4.79219 8.46055 4.86086 8.45655C5.1051 8.44232 5.41362 8.42435 5.60737 8.38034L5.61366 8.37891C6.62807 8.16728 7.2565 7.49485 7.65085 7.00684V3.33922L6.29753 4.53973C6.12673 4.69124 5.84969 4.69124 5.67888 4.53973C5.50808 4.38822 5.50808 4.14256 5.67888 3.99105L7.68969 2.20738C7.86049 2.05587 8.13753 2.05587 8.30834 2.20738C8.3194 2.21742 8.32969 2.2277 8.33946 2.23825Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74997 13.9061C1.78508 13.9061 1 13.121 1 12.1561C1 11.1912 1.78508 10.4061 2.74997 10.4061C3.71486 10.4061 4.49994 11.1912 4.49994 12.1561C4.49994 13.121 3.71486 13.9061 2.74997 13.9061ZM2.74997 11.1061C2.17093 11.1061 1.69994 11.5771 1.69994 12.1561C1.69994 12.7351 2.17093 13.2061 2.74997 13.2061C3.329 13.2061 3.8 12.7351 3.8 12.1561C3.8 11.5771 3.329 11.1061 2.74997 11.1061Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 13.9061C12.2851 13.9061 11.5 13.121 11.5 12.1561C11.5 11.1912 12.2851 10.4061 13.25 10.4061C14.2149 10.4061 14.9999 11.1912 14.9999 12.1561C14.9999 13.121 14.2149 13.9061 13.25 13.9061ZM13.25 11.1061C12.6709 11.1061 12.1999 11.5771 12.1999 12.1561C12.1999 12.7351 12.6709 13.2061 13.25 13.2061C13.829 13.2061 14.3 12.7351 14.3 12.1561C14.3 11.5771 13.829 11.1061 13.25 11.1061Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 12.1561C6.25 13.121 7.03508 13.9061 7.99997 13.9061C8.96486 13.9061 9.74994 13.121 9.74994 12.1561C9.74994 11.1912 8.96486 10.4061 7.99997 10.4061C7.03508 10.4061 6.25 11.1912 6.25 12.1561ZM6.94994 12.1561C6.94994 11.5771 7.42093 11.1061 7.99997 11.1061C8.579 11.1061 9.05 11.5771 9.05 12.1561C9.05 12.7351 8.579 13.2061 7.99997 13.2061C7.42093 13.2061 6.94994 12.7351 6.94994 12.1561Z"
        fill="#344054"
      />
    </svg>
  );
};

const UserCreateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.25 24.5C22.1495 24.5 24.5 22.1495 24.5 19.25C24.5 16.3505 22.1495 14 19.25 14C16.3505 14 14 16.3505 14 19.25C14 22.1495 16.3505 24.5 19.25 24.5ZM20 17V18.5H21.5C21.9142 18.5 22.25 18.8358 22.25 19.25C22.25 19.6642 21.9142 20 21.5 20H20V21.5C20 21.9142 19.6642 22.25 19.25 22.25C18.8358 22.25 18.5 21.9142 18.5 21.5V20H17C16.5858 20 16.25 19.6642 16.25 19.25C16.25 18.8358 16.5858 18.5 17 18.5H18.5V17C18.5 16.5858 18.8358 16.25 19.25 16.25C19.6642 16.25 20 16.5858 20 17Z"
        fill="#344054"
      />
      <path
        d="M17 8C17 10.4853 14.9853 12.5 12.5 12.5C10.0147 12.5 8 10.4853 8 8C8 5.51472 10.0147 3.5 12.5 3.5C14.9853 3.5 17 5.51472 17 8ZM12.5 11C14.1569 11 15.5 9.65685 15.5 8C15.5 6.34315 14.1569 5 12.5 5C10.8431 5 9.5 6.34315 9.5 8C9.5 9.65685 10.8431 11 12.5 11Z"
        fill="#344054"
      />
      <path
        d="M12.8841 21.5C12.7147 21.0208 12.5979 20.5167 12.5406 19.9948H5C5.00214 19.6246 5.23063 18.5157 6.24815 17.4982C7.22653 16.5198 9.06632 15.5 12.5 15.5C12.8907 15.5 13.2608 15.5132 13.6114 15.5381C13.9491 15.026 14.3547 14.5628 14.8154 14.161C14.1156 14.0573 13.346 14 12.5 14C5 14 3.5 18.5 3.5 20C3.5 21.5 5 21.5 5 21.5H12.8841Z"
        fill="#344054"
      />
    </svg>
  );
};

const query_fetch_user_list_data = async () => {
  const result = { list: [] };

  try {
    const requestUserList = await userPermAxios.post("/users/pick-row", {
      autopopulation: ["id", "email", "username"],
    });

    const { success, data } = requestUserList;

    if (success) {
      result.list = data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

const query_fetch_picker_data = async (
  picker_table_name,
  picker_column_list,
  release_picker,
  userTargetValue,
  parent_lead_id
) => {
  const result = { list: [] };

  if (!userTargetValue) {
    return result;
  }

  try {
    let request_url = `/${picker_table_name}/${
      parent_lead_id ?? userTargetValue.project_code
    }`;

    if (release_picker) {
      request_url += "/release/pick-row";
    } else {
      request_url += "/pick-row";
    }

    const requestUserList = await userPermAxios.post(request_url, {
      autopopulation: picker_column_list,
    });

    const { success, data } = requestUserList;

    if (success) {
      result.list = data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};

const query_fetch_sales_picker_data = async (
  picker_table_name,
  userTargetValue,
  parent_lead_id,
  target_id,
  target_tablename
) => {
  const result = { list: [] };

  if (!userTargetValue) {
    return result;
  }

  try {
    let request_url = `/${picker_table_name}/${target_tablename}/${target_id}/${
      parent_lead_id ?? userTargetValue.project_code
    }/sales-pick-row`;

    const requestUserList = await userPermAxios.get(request_url);

    const { success, data } = requestUserList;

    if (success) {
      result.list = data;
    }
  } catch (err) {
    console.log(err);
  } finally {
    return result;
  }
};
