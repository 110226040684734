import React from "react";
import MonthPicker from "@components/MonthPicker";
import ApprovalCard from "./ApprovalCard";
import {
  WorkTypeIcon,
  AnnualLeaveIcon,
  CostIcon,
  ManpowerIcon,
  MaterialIcon,
} from "./Icons";

const ApprovalMenu = ({
  dateValue,
  handleDateValue,
  approvalList = [],
  modalType,
  approval_list_refresh,
}) => {
  return (
    <div className="app-manage-tab-menu-body">
      <div className="app-manage-tab-menu-picker-area">
        <MonthPicker value={dateValue} onChange={handleDateValue} />
      </div>
      {approvalList.map((c) => {
        const { approval_type } = c;

        if (approval_type === "hr") {
          const {
            id,
            approval_date,
            policy_table_name,
            username,
            org_setting_name,
            lv_name,
            fn_name,
            approved_status,
            policy_name,
            status_color,
            need_approval,
          } = c;

          const icon = createCardIcon(policy_table_name);

          const sub_title = `${policy_name}・Department:${org_setting_name}・Job Grade:${lv_name}・Job Position:${fn_name}`;

          return (
            <ApprovalCard
              key={`${approval_type}_${id}`}
              id={id}
              icon={icon}
              title={username}
              sub_title={sub_title}
              title_date={approval_date}
              status_name={approved_status}
              status_color={status_color}
              modal_type={modalType}
              approval_list_refresh={approval_list_refresh}
              need_approval={need_approval}
              approval_type={approval_type}
            />
          );
        }

        if (approval_type === "cost") {
          const {
            id,
            approval_date,
            username,
            org_setting_name,
            lv_name,
            fn_name,
            approved_status,
            approval_item,
            status_color,
            need_approval,
            table_name,
          } = c;

          const sub_title = `${approval_item}・Department:${org_setting_name}・Job Grade:${lv_name}・Job Position:${fn_name}`;

          return (
            <ApprovalCard
              key={`${approval_type}_${id}`}
              id={id}
              icon={<CostIcon />}
              title={username}
              sub_title={sub_title}
              title_date={approval_date}
              status_name={approved_status}
              status_color={status_color}
              modal_type={modalType}
              approval_list_refresh={approval_list_refresh}
              need_approval={need_approval}
              approval_type={approval_type}
              cost_table_name={table_name}
            />
          );
        }

        if (approval_type === "material") {
          const {
            id,
            approval_date,
            username,
            org_setting_name,
            lv_name,
            fn_name,
            approved_status,
            approval_item,
            status_color,
            need_approval,
            table_name,
          } = c;

          const sub_title = `${approval_item}・Department:${org_setting_name}・Job Grade:${lv_name}・Job Position:${fn_name}`;

          return (
            <ApprovalCard
              key={`${approval_type}_${id}`}
              id={id}
              icon={<MaterialIcon />}
              title={username}
              sub_title={sub_title}
              title_date={approval_date}
              status_name={approved_status}
              status_color={status_color}
              modal_type={modalType}
              approval_list_refresh={approval_list_refresh}
              need_approval={need_approval}
              approval_type={approval_type}
              cost_table_name={table_name}
            />
          );
        }

        if (approval_type === "manpower") {
          const {
            id,
            approval_date,
            username,
            org_setting_name,
            lv_name,
            fn_name,
            approved_status,
            approval_item,
            status_color,
            need_approval,
            table_name,
          } = c;

          const sub_title = `${approval_item}・Department:${org_setting_name}・Job Grade:${lv_name}・Job Position:${fn_name}`;

          return (
            <ApprovalCard
              key={`${approval_type}_${id}`}
              id={id}
              icon={<ManpowerIcon />}
              title={username}
              sub_title={sub_title}
              title_date={approval_date}
              status_name={approved_status}
              status_color={status_color}
              modal_type={modalType}
              approval_list_refresh={approval_list_refresh}
              need_approval={need_approval}
              approval_type={approval_type}
              cost_table_name={table_name}
            />
          );
        }

        return null;
      })}
    </div>
  );
};

export default ApprovalMenu;

const createCardIcon = (policy_name) => {
  if (policy_name === "work_type_policy") {
    return <WorkTypeIcon />;
  }

  if (
    policy_name === "annual_leave_policy" ||
    policy_name === "dayoff_policy"
  ) {
    return <AnnualLeaveIcon />;
  }

  return undefined;
};

// const sampleData = [
//   "initial_budget",
//   "budget_transfer",
//   "budget_change",
//   "expenses",
//   "change_order",
//   "contract_award",
// ].map((c, i) => {
//   return {
//     id: i,
//     table_name: c,
//     modal_type: "approval",
//     approval_type: "cost",
//     approval_item: c,
//   };
// });
