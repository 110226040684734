import React from "react";
import "./dayOffSettingModals.scss";

const PolicyModalBtn = ({
  icon = <DefaultIcon />,
  label = "",
  onClick = () => {},
  disabled = false,
  width,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className="policy-modal-btn-body"
      style={width ? { width } : {}}
    >
      {icon && <div className="policy-modal-btn-icon">{icon}</div>}
      <div className="policy-modal-btn-label">{label}</div>
    </button>
  );
};

export default PolicyModalBtn;

const DefaultIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M14.25 18C16.3211 18 18 16.3211 18 14.25C18 12.1789 16.3211 10.5 14.25 10.5C12.1789 10.5 10.5 12.1789 10.5 14.25C10.5 16.3211 12.1789 18 14.25 18ZM14.7857 12.6429V13.7143H15.8571C16.153 13.7143 16.3929 13.9541 16.3929 14.25C16.3929 14.5459 16.153 14.7857 15.8571 14.7857H14.7857V15.8571C14.7857 16.153 14.5459 16.3929 14.25 16.3929C13.9541 16.3929 13.7143 16.153 13.7143 15.8571V14.7857H12.6429C12.347 14.7857 12.1071 14.5459 12.1071 14.25C12.1071 13.9541 12.347 13.7143 12.6429 13.7143H13.7143V12.6429C13.7143 12.347 13.9541 12.1071 14.25 12.1071C14.5459 12.1071 14.7857 12.347 14.7857 12.6429Z"
        fill="#0080DB"
      />
      <path
        d="M12.6429 6.21429C12.6429 7.98949 11.2038 9.42857 9.42857 9.42857C7.65337 9.42857 6.21429 7.98949 6.21429 6.21429C6.21429 4.43908 7.65337 3 9.42857 3C11.2038 3 12.6429 4.43908 12.6429 6.21429ZM9.42857 8.35714C10.612 8.35714 11.5714 7.39775 11.5714 6.21429C11.5714 5.03082 10.612 4.07143 9.42857 4.07143C8.2451 4.07143 7.28571 5.03082 7.28571 6.21429C7.28571 7.39775 8.2451 8.35714 9.42857 8.35714Z"
        fill="#0080DB"
      />
      <path
        d="M9.70292 15.8571C9.58194 15.5148 9.49851 15.1548 9.45759 14.782H4.07143C4.07295 14.5176 4.23617 13.7255 4.96296 12.9987C5.66181 12.2998 6.97594 11.5714 9.42856 11.5714C9.70767 11.5714 9.97203 11.5809 10.2224 11.5986C10.4637 11.2329 10.7534 10.902 11.0824 10.615C10.5826 10.5409 10.0328 10.5 9.42857 10.5C4.07143 10.5 3 13.7143 3 14.7857C3 15.8571 4.07143 15.8571 4.07143 15.8571H9.70292Z"
        fill="#0080DB"
      />
    </svg>
  );
};
