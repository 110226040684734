import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { SalaryAdditionalModalStatus } from "@/atoms/modalStatus";
import _ from "lodash";
import SelectTable from "./SelectTable";
import { PlusIcon, MinusIcon } from "./Icon";
import Btn from "../CustomIconBtn";
import ModalBody from "../ModalBody";
import AddDetailModal from "./AddDetailModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import { createAdditionalColumns } from "./Columns";

const AdditionalModal = ({ refresh }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [dubleClick, setDubleClick] = useState(true);
  const [addModal, setAddModal] = useState(false);

  const { open, onClose, title, confirm, year, month, user_id, type, btn_use } =
    useRecoilValue(SalaryAdditionalModalStatus);

  const query_key = [
    "deduction_list",
    "additional",
    user_id,
    year,
    month,
    type,
  ];

  const query_deduction_list = useQuery(query_key, () => {
    return query_fetch_data("additional", user_id, year, month, type);
  });

  const [detailFresh] = useRefreshReactQuery(query_key);

  const { data = {}, isLoading } = query_deduction_list;

  const { list: deduction_list = [] } = data;

  const deleteItems = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestDeleteItems = await userPermAxios.post(
        `/payroll/${type}/delete_detail_salary_list`,
        {
          type: "additional",
          ids: selectionModel,
          user_id,
          year,
          month,
        }
      );

      const { success, message } = requestDeleteItems;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        detailFresh();
      }
    }
  };

  const confirmDelete = () => {
    if (!dubleClick) {
      return;
    }

    const msg = "Are you sure you want to delete selection items?";

    return create_alert_fn("info", msg, () => deleteItems(), true);
  };

  const handleSelectionChange = (select) => {
    setSelectionModel(select);
  };

  const handleAddModal = () => {
    setAddModal((prev) => !prev);
  };

  const { add_btn_disabled, delete_btn_disabled } = useMemo(() => {
    let result = {
      add_btn_disabled: false,
      delete_btn_disabled: false,
    };

    if (confirm === 1) {
      result = {
        add_btn_disabled: true,
        delete_btn_disabled: true,
      };
    }

    if (selectionModel.length === 0) {
      result.delete_btn_disabled = true;
    }

    return result;
  }, [confirm, selectionModel]);

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title={title}
      modalWidth={840}
      modalHeight={468}
    >
      <div className="payroll-detail-modal-contents-body">
        <div className="payroll-detail-modal-contents-box">
          <div className="payroll-detail-modal-title-box">
            <div className="payroll-detail-modal-title">
              Additional Item Details
            </div>
            {btn_use && (
              <div className="payroll-detail-modal-title-btn-box">
                <Btn
                  label="Add"
                  width={90}
                  icon={<PlusIcon />}
                  disabled={add_btn_disabled}
                  onClick={handleAddModal}
                />
                <Btn
                  label="Delete"
                  width={90}
                  icon={<MinusIcon />}
                  disabled={delete_btn_disabled}
                  onClick={confirmDelete}
                />
              </div>
            )}
          </div>
          <div className="payroll-detail-modal-table-box">
            <SelectTable
              rows={deduction_list}
              columns={createAdditionalColumns()}
              checkboxSelection={confirm === 0 ? true : false}
              selectModel={selectionModel}
              handleSelectionChange={handleSelectionChange}
            />
          </div>
          <div className="payroll-detail-modal-result-box">
            <div className="payroll-detail-modal-result">
              Total Deductions :
            </div>
            <div className="payroll-detail-modal-result blue">
              {_.sumBy(deduction_list, "addition_price").toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      {addModal && (
        <AddDetailModal
          type="additional"
          modal_type={type}
          open={addModal}
          onClose={handleAddModal}
          user_id={user_id}
          confirm={confirm}
          year={year}
          month={month}
          refresh={refresh}
          detailFresh={detailFresh}
        />
      )}
    </ModalBody>
  );
};

export default AdditionalModal;

const query_fetch_data = async (type, user_id, year, month, modal_type) => {
  let result = { list: [] };

  try {
    const requestDeductionList = await userPermAxios.get(
      `/payroll/${modal_type}/get_detail_salary_list?type=${type}&user_id=${user_id}&year=${year}&month=${month}`
    );

    const { success, data } = requestDeductionList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
