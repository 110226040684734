import React, { useState } from "react";
import OrgSettingIcon from "../Icons/OrgSettingIcon";
import OrgSelectModal from "../userAddPermModal/OrgSelectModal";
import "./orgSettingModal.scss";

const OrgSelectBtn = ({ list = [], value, setValue }) => {
  const [orgModalStatus, setOrgModalStatus] = useState(false);

  const btnLabel = list.find((c) => c.id === value);

  const handleOrgModalStatus = () => {
    setOrgModalStatus((prev) => !prev);
  };

  return (
    <>
      <div className="org-select-btn-body" onClick={handleOrgModalStatus}>
        <OrgSettingIcon width="20" height="20" />

        <div className="org-select-btn-value">{btnLabel?.name ?? []}</div>
      </div>
      {orgModalStatus && (
        <OrgSelectModal
          list={list}
          value={value}
          setValue={setValue}
          open={orgModalStatus}
          onClose={handleOrgModalStatus}
        />
      )}
    </>
  );
};

export default OrgSelectBtn;
