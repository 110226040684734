import React from "react";

const CollapseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.32056 7.3212L3.5595 7.49938C3.35521 7.50986 3.19806 7.35264 3.20854 7.14826C3.21902 6.94388 3.39188 6.77094 3.59617 6.76046L6.12624 6.63992L2.08756 2.59941C1.96184 2.4684 1.97232 2.25353 2.11375 2.11204C2.25518 1.97054 2.47519 1.96006 2.6009 2.09107L6.63959 6.13158L6.76007 3.59513C6.77054 3.39599 6.9434 3.2178 7.1477 3.21256C7.35199 3.20208 7.50913 3.3593 7.49866 3.56368L7.32056 7.32644V7.3212Z"
        fill="#344054"
      />
      <path
        d="M12.5021 3.56369C12.4917 3.3593 12.6488 3.20208 12.8531 3.21256C13.0574 3.2178 13.2302 3.39599 13.2407 3.59513L13.3612 6.13158L17.3999 2.09107C17.5308 1.96006 17.7456 1.97054 17.887 2.11204C18.0285 2.25353 18.0389 2.47364 17.908 2.59941L13.8693 6.63992L16.3994 6.76046C16.6037 6.77094 16.7765 6.94388 16.787 7.14826C16.7975 7.35264 16.6403 7.50986 16.436 7.49938L12.675 7.3212L12.4969 3.55844L12.5021 3.56369Z"
        fill="#344054"
      />
      <path
        d="M7.49826 16.4361C7.50874 16.6405 7.35159 16.7977 7.1473 16.7872C6.94301 16.7819 6.77015 16.6038 6.75967 16.4046L6.63919 13.8682L2.60051 17.9087C2.46955 18.0397 2.25479 18.0292 2.11335 17.8877C1.97192 17.7462 1.96145 17.5261 2.0924 17.4003L6.13108 13.3598L3.60101 13.2393C3.39672 13.2288 3.22386 13.0559 3.21338 12.8515C3.20291 12.6471 3.36006 12.4899 3.56435 12.5004L7.3254 12.6786L7.5035 16.4413L7.49826 16.4361Z"
        fill="#344054"
      />
      <path
        d="M12.6746 12.6786L16.4357 12.5004C16.6399 12.4899 16.7971 12.6471 16.7866 12.8515C16.7761 13.0559 16.6033 13.2288 16.399 13.2393L13.8689 13.3598L17.9076 17.4003C18.0386 17.5314 18.0281 17.7462 17.8866 17.8877C17.7452 18.0292 17.5252 18.0397 17.3995 17.9087L13.3608 13.8682L13.2403 16.4046C13.2299 16.6038 13.057 16.7819 12.8527 16.7872C12.6484 16.7977 12.4913 16.6405 12.5017 16.4361L12.6798 12.6733L12.6746 12.6786Z"
        fill="#344054"
      />
    </svg>
  );
};

export default CollapseIcon;
