import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { WorkTypePolicyStatus } from "@/atoms/modalStatus";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localizaedFormat from "dayjs/plugin/localizedFormat";
import PolicyModalBody from "../PolicyModalBody";
import SwicthBtn from "@components/SwicthBtn";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";
import "./dayOffSettingModals.scss";

dayjs.extend(weekday);
dayjs.extend(localizaedFormat);

const WorkTypePolicyModal = ({ refresh }) => {
  const {
    open,
    id,
    type = "create",
    initialWorkTypeName = "Work Type Poliy",
    initialOvertimeUse = false,
    initialOffdayCountUse = false,
    initialApproveUse = false,
    title,
    onClose,
  } = useRecoilValue(WorkTypePolicyStatus);

  const button_disabled = type === "view" || type === "" ? true : false;

  const [policyName, setPolicyName] = useState(initialWorkTypeName);
  const [overtimeUse, setOvertimeUse] = useState(initialOvertimeUse);
  const [offdayCountUse, setOffdayCountUse] = useState(initialOffdayCountUse);
  const [approveUse, setApproveUse] = useState(initialApproveUse);
  const [dubleClick, setDubleClick] = useState(true);

  const handleSwicthBtnValue = (setValue) => {
    setValue((prev) => !prev);
  };

  const createWorkTypePolicy = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const submitData = {
        policyName,
        overtimeUse,
        offdayCountUse,
        approveUse,
      };

      if (type === "update") {
        submitData.id = id;
      }

      const requestCreateWorkTypePolicy = await userPermAxios.post(
        `/dayoff/${type}_work_type_policy`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreateWorkTypePolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      refresh();

      if (msgStatus.status === "success") {
        onClose();
      }
    }
  };

  const saveWorkTypePolicy = () => {
    if (!dubleClick) {
      return;
    }

    let message;

    if (type === "create") {
      message = "Do you want to create a new work type policy?";
    }

    if (type === "update") {
      message = "Do you want to update a work type policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await createWorkTypePolicy();
      },
      true
    );
  };

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={500}
      button_disabled={button_disabled}
      dubleClick={dubleClick}
      saveFn={saveWorkTypePolicy}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Allow Overtime
            </div>
            <SwicthBtn
              checked={overtimeUse}
              onClick={() => {
                handleSwicthBtnValue(setOvertimeUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Allow Holiday Work
            </div>
            <SwicthBtn
              checked={offdayCountUse}
              onClick={() => {
                handleSwicthBtnValue(setOffdayCountUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval Required
            </div>
            <SwicthBtn
              checked={approveUse}
              onClick={() => {
                handleSwicthBtnValue(setApproveUse);
              }}
              disabled={button_disabled}
            />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default WorkTypePolicyModal;
