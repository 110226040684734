import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Btn from "@/components/CustomIconBtn";
import ActivityLogTable from "@/components/activityLogTable/ActivityLogTable";
import { createLoginActivityLogColumn } from "@/components/activityLogTable/Column";
import DayPicker from "@/components/DatePicker";
import { userPermAxios } from "@utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import "../menu.scss";
import VerticalSolidLine from "@/components/VerticalSolidLine";

const LogPage = () => {
  const { menu } = useParams();

  const [startDate, setStartDate] = useState(dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(dayjs());

  const query_admin_log_data = useQuery(
    [
      "admin_log_data",
      menu,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
    ],
    async () => {
      return await query_fetch_data(
        menu,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
    },
    {
      initialData: { log_data: [] },
    }
  );

  const { log_data } = query_admin_log_data.data;

  const [refresh] = useRefreshReactQuery([
    "admin_log_data",
    menu,
    startDate.format("YYYY-MM-DD"),
    endDate.format("YYYY-MM-DD"),
  ]);

  const table_info = useMemo(() => {
    if (menu === "login_log") {
      return {
        table_name: "login_activities",
        title: "Login Activity Logs",
        columns: [],
        lineDataUse: false,
      };
    }

    if (menu === "record_log") {
      return {
        table_name: "record_activities",
        title: "Record Activity Logs",
        columns: [],
        lineDataUse: false,
      };
    }
  }, [menu]);

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">
          {table_info?.title ?? ""}
        </div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />
          <VerticalSolidLine height={24} />
          <DayPicker
            width={180}
            value={startDate}
            onChange={(e) => setStartDate(e)}
          />
          <div>-</div>
          <DayPicker
            width={180}
            value={endDate}
            onChange={(e) => setEndDate(e)}
          />
        </div>
      </div>
      <div className="company-setting-contents-body">
        <ActivityLogTable
          rows={log_data}
          columns={createLoginActivityLogColumn()}
        />
      </div>
    </div>
    // <div className="admin-user-create-total-body">
    //   <div className="admin-user-create-title-box">
    //     <div className="admin-user-create-title">{table_info?.title ?? ""}</div>
    //   </div>
    //   <div className="admin-user-create-table-box">
    //     <ActivityLogTable
    //       rows={log_data}
    //       columns={createLoginActivityLogColumn()}
    //     />
    //   </div>
    // </div>
  );
};

export default LogPage;

const query_fetch_data = async (menu, startDate, endDate) => {
  const result = { log_data: [] };

  try {
    const request_log_data = await userPermAxios.get(
      `/${menu}/get-logs-data?startDate=${startDate}&endDate=${endDate}`
    );

    const { success, data } = request_log_data;

    if (success) {
      result.log_data = data.map((c) => {
        if (menu === "login_log") {
          const { device_info } = c;
          return {
            ...c,
            device_info: device_info ? JSON.parse(device_info) : "",
          };
        }

        return c;
      });
    }
  } catch (err) {
  } finally {
    return result;
  }
};
