import React, { useState } from "react";
import { useQuery } from "react-query";
import _ from "lodash";
import {
  annualLeaveAdditionalColumn,
  annualLeaveUsageColumn,
  workTypeUsageColumn,
  dayOffUsageColumn,
} from "./Column";
import AnnualLeaveTable from "./AnnualLeaveTable";
import AdditionalModal from "./AdditionalModal";
import UsingAnnualLeaveModal from "./UsingAnnualLeaveModal";
import Btn from "../CustomIconBtn";
import PlusIcon from "../Icons/PlusIcon";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

const AnnaulLeaveLine = ({ params, startMonth, endMonth, apiRef }) => {
  const [additionalModalToggle, setAdditionalModalToggle] = useState(false);
  const [usingAnnualLeaveModalToggle, setUsingAnnualLeaveModalToggle] =
    useState(false);

  const { id, row = {} } = params;

  const { remain_annal_leave_count = 0 } = row;

  const query_annual_leave_line_log_data = useQuery(
    [
      "annual_leave_line_log_data",
      id,
      startMonth.format("YYYY-MM"),
      endMonth.format("YYYY-MM"),
    ],
    () => {
      return query_fetch_data(id, startMonth, endMonth);
    },
    {
      onSuccess: (result) => {
        const { additional_list, using_annual_leave_list } = result;

        const additional_list_sum = _.sumBy(
          additional_list,
          "additional_count"
        );
        const using_annual_leave_list_sum = _.sumBy(
          using_annual_leave_list,
          "using_count"
        );

        const remainAnnualLeave =
          additional_list_sum - using_annual_leave_list_sum;

        if (apiRef?.current) {
          apiRef.current.updateRows([
            {
              id,
              annual_leave_count: additional_list_sum,
              using_annual_leave_count: using_annual_leave_list_sum,
              remain_annal_leave_count: remainAnnualLeave,
            },
          ]);
        }
      },
    }
  );

  const { data = {}, isLoading } = query_annual_leave_line_log_data;

  const {
    additional_list = [],
    using_annual_leave_list = [],
    using_work_type_list = [],
    using_dayoff_list = [],
    policy_table_id = null,
    half_dayoff_use = null,
  } = data;

  const [refresh] = useRefreshReactQuery([
    "annual_leave_line_log_data",
    id,
    startMonth.format("YYYY-MM"),
    endMonth.format("YYYY-MM"),
  ]);

  const handleToggle = (setValue) => {
    setValue((prev) => !prev);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="annual-leave-line-body">
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">
            Granted Annual Leave Log
          </div>
          <div className="annual-leave-line-btn-box">
            <Btn
              icon={<PlusIcon />}
              onClick={() => handleToggle(setAdditionalModalToggle)}
            />
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={additional_list}
            column={annualLeaveAdditionalColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">Annual Leave Log</div>
          <div className="annual-leave-line-btn-box">
            {/* {policy_table_id !== null && half_dayoff_use !== null && (
              <Btn
                icon={<PlusIcon />}
                onClick={() => handleToggle(setUsingAnnualLeaveModalToggle)}
              />
            )} */}
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_annual_leave_list}
            column={annualLeaveUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">Work Type Log</div>
          <div className="annual-leave-line-btn-box">
            {/* <Btn icon={<PlusIcon />} /> */}
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_work_type_list}
            column={workTypeUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>

      <div className="annual-leave-line-box">
        <div className="annual-leave-line-title-box">
          <div className="annual-leave-line-title">DayOff Log</div>
          <div className="annual-leave-line-btn-box">
            {/* <Btn icon={<PlusIcon />} /> */}
          </div>
        </div>
        <div className="annual-leave-line-table-box">
          <AnnualLeaveTable
            rows={using_dayoff_list}
            column={dayOffUsageColumn}
            rowHeight={28}
            lineUse={false}
          />
        </div>
      </div>
      {additionalModalToggle && (
        <AdditionalModal
          open={additionalModalToggle}
          onClose={() => handleToggle(setAdditionalModalToggle)}
          refresh={refresh}
          user_id={id}
        />
      )}
      {usingAnnualLeaveModalToggle && (
        <UsingAnnualLeaveModal
          open={usingAnnualLeaveModalToggle}
          onClose={() => handleToggle(setUsingAnnualLeaveModalToggle)}
          refresh={refresh}
          user_id={id}
          policy_table_id={policy_table_id}
          half_dayoff_use={half_dayoff_use}
          remain_annal_leave_count={remain_annal_leave_count}
        />
      )}
    </div>
  );
};

export default AnnaulLeaveLine;

const query_fetch_data = async (id, startDate, endDate) => {
  let result = {
    additional_list: [],
    using_annual_leave_list: [],
    using_work_type_list: [],
    using_dayoff_list: [],
    policy_table_id: null,
    half_dayoff_use: null,
  };

  try {
    const startYear = startDate.format("YYYY");
    const startMonth = startDate.format("MM");
    const endYear = endDate.format("YYYY");
    const endMonth = endDate.format("MM");

    const requestAnnualLeaveLineLog = await userPermAxios.get(
      `/dayoff/get_annual_leave_line_log_data?user_id=${id}&start_year=${startYear}&start_month=${startMonth}&end_year=${endYear}&end_month=${endMonth}`
    );

    const { success, data } = requestAnnualLeaveLineLog;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
