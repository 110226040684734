import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { CostApprovalModalStatus } from "@/atoms/modalStatus";
import { UserTargetProject } from "@/atoms/userInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import _ from "lodash";
import Btn from "@/components/CustomIconBtn";
import CostApprovalModal from "@components/approvalManagement/Modal/CostApprovalModal";
import PlusIcon from "@/components/Icons/PlusIcon";
import VerticalSolidLine from "@/components/VerticalSolidLine";
import BudgetChange from "@/components/budgetChange/BudgetChange";
import AddBudgetChangeModal from "@/components/budgetChange/AddBudgetChangeModal";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

const BudgetChangePage = () => {
  const [addModalToggle, setAddModalToggle] = useState(false);
  const { project_code, project_name } = useRecoilValue(UserTargetProject);
  const resetModalStatus = useResetRecoilState(CostApprovalModalStatus);
  const { open: modal_open } = useRecoilValue(CostApprovalModalStatus);

  useEffect(() => {
    if (project_code === "overall") {
      window.location.replace("/home/budget_sheet");
    }
  }, [project_code]);

  useEffect(() => {
    return () => resetModalStatus();
    // eslint-disable-next-line
  }, []);

  const query_budget_change_data = useQuery(
    ["budget_change_data", project_code],
    () => {
      return query_fetch_data(project_code);
    }
  );

  const query_approval_user_list_data = useQuery(
    "approval_user_list_data",
    () => {
      return query_approval_fetch_data();
    }
  );

  const query_cost_code_data = useQuery(
    ["use_cost_code_data", project_code],
    () => {
      return query_cost_code_fetch_data(project_code);
    }
  );

  const [refresh] = useRefreshReactQuery(["budget_change_data", project_code]);

  const { data: bcData = {}, isLoading: bcDataLoading } =
    query_budget_change_data;

  const { data: userListData = {}, isLoading: userListLoading } =
    query_approval_user_list_data;

  const { data: costCodeListData = {}, isLoading: costCodeLoading } =
    query_cost_code_data;

  const {
    contract_amount = [],
    budget_change_list = [],
    add_button_use = false,
  } = bcData;

  const { list: userList = [] } = userListData;
  const { list: costCodeList = [] } = costCodeListData;

  const handleAddModal = () => {
    setAddModalToggle((prev) => !prev);
  };

  if (bcDataLoading || userListLoading || costCodeLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Budget Change</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />

          {add_button_use && (
            <>
              <VerticalSolidLine height={24} />
              <Btn
                label="Add Initial Budget"
                icon={<PlusIcon />}
                onClick={handleAddModal}
                width={175}
              />
            </>
          )}
        </div>
      </div>
      <div className="company-setting-contents-body">
        <BudgetChange
          contract_amount={contract_amount}
          budget_change_list={budget_change_list}
        />
      </div>
      {modal_open && <CostApprovalModal />}
      {addModalToggle && (
        <AddBudgetChangeModal
          open={addModalToggle}
          onClose={handleAddModal}
          refresh={refresh}
          project_code={project_code}
          project_name={project_name}
          approval_user_list={userList}
          cost_code_list={costCodeList}
        />
      )}
    </div>
  );
};

export default BudgetChangePage;

const query_fetch_data = async (project_code) => {
  let result = {
    contract_amount: [],
    budget_transfer_list: [],
    add_button_use: false,
  };

  try {
    const requestBudgetTransferData = await userPermAxios.get(
      `/cost/get_budget_change_data?project_code=${project_code}`
    );

    const { success, data } = requestBudgetTransferData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_approval_fetch_data = async () => {
  let result = {
    list: [],
  };

  try {
    const requestApprovalUserList = await userPermAxios.get(
      "/cost/get_approval_user_list"
    );

    const { success, data } = requestApprovalUserList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_cost_code_fetch_data = async (project_code) => {
  let result = {
    list: [],
  };

  try {
    const requestCostCodeList = await userPermAxios.get(`/cost/get_cost_code`);

    const requestUsingCostCodeList = await userPermAxios.get(
      `/cost/get_using_cost_code?project_code=${project_code}`
    );

    const { success: normalSuccess, data: normalData } = requestCostCodeList;
    const { success: usingSuccess, data: usingData } = requestUsingCostCodeList;

    let costCodeList = [];

    if (normalSuccess) {
      costCodeList.push(
        ...normalData.list.map((c) => {
          return { ...c, type: "Not Using" };
        })
      );
    }

    if (usingSuccess) {
      costCodeList.push(
        ...usingData.list.map((c) => {
          return { ...c, type: "Using" };
        })
      );
    }

    result.list = _.orderBy(
      _(costCodeList)
        .groupBy("id")
        .map((objs, key) => {
          const usingCode = objs.find((c) => c.type === "Using");
          const notUsingCode = objs.find((c) => c.type === "Not Using");

          if (usingCode) {
            return { ...usingCode };
          } else {
            return { ...notUsingCode };
          }
        })
        .value(),
      ["type"],
      ["desc"]
    );
  } catch (err) {
  } finally {
    return result;
  }
};
