import React, { useMemo, useState } from "react";
import {
  Modal,
  IconButton,
  Button,
  InputLabel,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import LabelSelect from "@components/LabelSelect";
import { userPermAxios } from "@/utils/customAxios";
import { labelInputTheme } from "@styles/muiThemes";
import "@styles/components.scss";
import { create_alert_fn } from "@/utils/createAlert";

const AddJobNumberModal = ({ open, setAddModalToggle, refresh_fn }) => {
  const [category, setCategory] = useState("");
  const [scopeName, setScopeName] = useState("");
  const [scopeDetailName, setScopeDetailName] = useState("");
  const [scopeCode, setScopeCode] = useState("");
  const [dubleClick, setDubleClick] = useState(true);

  const onClose = () => {
    setAddModalToggle(false);
  };

  const button_disabled = useMemo(() => {
    return ![category, scopeName, scopeDetailName, scopeCode]
      .map((c) => {
        return c.replaceAll(" ", "");
      })
      .every((c) => c.length > 0);
  }, [category, scopeName, scopeDetailName, scopeCode]);

  const onSubmit = async () => {
    if (dubleClick) return;
    setDubleClick(false);

    const result = {
      status: "",
      message: "",
      fn: () => {},
    };

    try {
      const request_add_job_number = await userPermAxios.post(
        `/create_job_number`,
        {
          category,
          scopeName,
          scopeDetailName,
          scopeCode,
        }
      );

      const { success, message } = request_add_job_number;

      if (success) {
        result.status = "success";
        result.message = message;
        result.fn = () => {
          onClose();
          refresh_fn();
        };
      }
    } catch (err) {
      result.status = "warning";
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.status, result.message, result.fn);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="contact-point-add-modal-total-body"
        style={{ width: 720 }}
      >
        <div className="contact-point-add-modal-main-box">
          <div className="contact-point-add-modal-main">
            <LabelSelect
              label="Category"
              value={category}
              setValue={setCategory}
              list={[
                { name: "External", value: "External" },
                { name: "Internal", value: "Internal" },
              ]}
            />
            <LabelInput
              label="Scope Name"
              value={scopeName}
              placeholder="PIPING"
              onChange={(value) => setScopeName(value.toUpperCase())}
            />
            <LabelInput
              label="Scope Detail Name"
              value={scopeDetailName}
              placeholder="Shop Fab Piping"
              onChange={(value) => setScopeDetailName(value)}
            />
            <LabelInput
              label="Scope Code"
              value={scopeCode}
              placeholder="20"
              maxLength={2}
              onChange={(value) => setScopeCode(value)}
            />
          </div>
        </div>
        <div className="contact-point-add-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="contact-point-add-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            className="contact-point-add-modal-update-btn"
            disabled={button_disabled}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddJobNumberModal;

const LabelInput = ({
  label,
  value,
  onChange,
  helperText,
  placeholder,
  error,
  maxLength,
  disabled = false,
}) => {
  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme}>
        <InputLabel
          htmlFor={`label-input-${label}`}
          className="label-input-title"
        >
          {label}
        </InputLabel>
        <TextField
          id={`label-input-${label}`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          disabled={disabled}
          inputProps={
            maxLength
              ? {
                  maxLength: 2,
                }
              : {}
          }
        />
      </ThemeProvider>
    </div>
  );
};
