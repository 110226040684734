import React, { useEffect, useState, useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  HrWorkPolicyData,
  HrCalendarUsingModalStatus,
  ApprovalLineModalStatus,
} from "@/atoms/modalStatus";
import { Tabs, Tab, Box, Button } from "@mui/material";
import _ from "lodash";
import ModalBody from "../ModalBody";
import WorkTypeMenu from "./WorkTypeMenu";
import AnnualLeaveMenu from "./AnnualLeaveMenu";
import DayoffMenu from "./DayoffMenu";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

const HRCalendarUsingModal = () => {
  const {
    annual_leave_approve_use,
    work_type_policy_list,
    dayoff_policy_list,
    approval_user_list,
    annual_leave_policy_id,
  } = useRecoilValue(HrWorkPolicyData);

  const { open, onClose, query_value } = useRecoilValue(
    HrCalendarUsingModalStatus
  );

  const [refresh] = useRefreshReactQuery(query_value);

  const [tabValue, setTabValue] = useState(0);
  const [annualLeaveData, setAnnualLeaveData] = useState({
    list: [],
    remaing_count: 0,
  });
  const [workTypeData, setWorkTypeData] = useState({
    list: [],
  });
  const [dayoffData, setDayoffData] = useState({
    list: [],
    remaing_count: 0,
    attachment_list: null,
  });

  const [workTypePolicyId, setWorkTypePolicyId] = useState("");
  const [dayOffPolicyId, setDayOffPolicyId] = useState("");
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });
  const [dubleClick, setDubleClick] = useState(true);

  const selectWorkTypePolicy = useMemo(() => {
    if (work_type_policy_list.length === 0 || workTypePolicyId === "") {
      return { policySetting: false };
    }

    return {
      policySetting: true,
      policy: work_type_policy_list.find((c) => c.id === workTypePolicyId),
    };
  }, [workTypePolicyId, work_type_policy_list]);

  const selectdayoffPolicy = useMemo(() => {
    if (dayoff_policy_list.length === 0 || dayOffPolicyId === "") {
      return { policySetting: false };
    }

    return {
      policySetting: true,
      policy: dayoff_policy_list.find((c) => c.id === dayOffPolicyId),
    };
  }, [dayOffPolicyId, dayoff_policy_list]);

  const { policy: workTypePolicy = {} } = selectWorkTypePolicy;

  const { approve_use: work_type_approve_use } = workTypePolicy;

  const { policy: dayoffPolicy = {} } = selectdayoffPolicy;

  const {
    approve_use: dayoff_approve_use,
    active_after_annualleave,
    attachment_required,
  } = dayoffPolicy;

  const button_disabled = useMemo(() => {
    if (tabValue === 0) {
      if (workTypePolicyId === "") {
        return true;
      }

      if (work_type_approve_use) {
        if (approvalLineData.approval_list.length === 0) {
          return true;
        }
      }

      if (workTypeData.list.length === 0) {
        return true;
      }
    }

    if (tabValue === 1) {
      if (annualLeaveData.list.length === 0) {
        return true;
      }

      const requestAnnualLeaveCount = _.sumBy(
        annualLeaveData.list.map((c) => {
          const { afternoon_off, morning_off } = c;

          if (afternoon_off || morning_off) {
            return 0.5;
          }

          return 1;
        })
      );

      if (requestAnnualLeaveCount > annualLeaveData.remaing_count) {
        return true;
      }

      if (annual_leave_approve_use) {
        if (approvalLineData.approval_list.length === 0) {
          return true;
        }
      }
    }

    if (tabValue === 2) {
      if (dayoff_approve_use) {
        if (approvalLineData.approval_list.length === 0) {
          return true;
        }
      }

      if (active_after_annualleave) {
        if (dayoffData.remaing_count > 0) {
          return true;
        }
      }

      if (dayoffData.list.length === 0) {
        return true;
      }

      if (attachment_required) {
        if (dayoffData.attachment_list.length === 0) {
          return true;
        }
      }
    }

    return false;
  }, [
    annual_leave_approve_use,
    tabValue,
    workTypePolicyId,
    work_type_approve_use,
    workTypeData,
    annualLeaveData,
    dayoff_approve_use,
    dayoffData,
    approvalLineData,
    active_after_annualleave,
    attachment_required,
  ]);

  useEffect(() => {
    setApprovalLineData((prev) => {
      return { ...prev, approval_list: [], carbon_copy_list: [] };
    });
  }, [tabValue]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const resetApprovalLineData = () => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: [],
        carbon_copy_list: [],
      };
    });
  };

  const createApplication = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const submitData = {
        list: [],
        policy_id: null,
        approve_use: false,
        approval_list: approvalLineData.approval_list,
        carbon_copy_list: approvalLineData.carbon_copy_list,
      };

      const formData = new FormData();

      const apiEndPoint =
        tabValue === 0
          ? "work_type_record"
          : tabValue === 1
          ? "annual_leave_record"
          : tabValue === 2
          ? "dayoff_record"
          : null;

      if (!apiEndPoint) {
        msgStatus.status = "warning";
        msgStatus.message = "This is not a valid tab menu.";
        return null;
      }

      if (tabValue === 0) {
        submitData.list = workTypeData.list;
        submitData.policy_id = workTypePolicyId;
        submitData.approve_use = work_type_approve_use;
      }

      if (tabValue === 1) {
        submitData.list = annualLeaveData.list;
        submitData.policy_id = annual_leave_policy_id;
        submitData.approve_use = annual_leave_approve_use;
      }

      if (tabValue === 2) {
        submitData.list = dayoffData.list;
        submitData.policy_id = dayOffPolicyId;
        submitData.approve_use = dayoff_approve_use;
        submitData.remaing_count = dayoffData.remaing_count;

        if (dayoffData.attachment_list) {
          for (const file in dayoffData.attachment_list) {
            if (file !== "length" && file !== "item") {
              const targetFile = dayoffData.attachment_list[file];

              formData.append(`files`, targetFile);
            }
          }
        }

        formData.append("additionalData", JSON.stringify(submitData));
      }

      if (tabValue === 0 || tabValue === 1) {
        const requestCreatePolicy = await userPermAxios.post(
          `/hr/create_${apiEndPoint}`,
          {
            ...submitData,
          }
        );

        const { success, message } = requestCreatePolicy;

        if (success) {
          msgStatus.status = "success";
        } else {
          msgStatus.status = "warning";
        }

        msgStatus.message = message;
      }

      if (tabValue === 2) {
        const requestCreatePolicy = await userPermAxios.post(
          `/hr/create_${apiEndPoint}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const { success, message } = requestCreatePolicy;

        if (success) {
          msgStatus.status = "success";
        } else {
          msgStatus.status = "warning";
        }

        msgStatus.message = message;
      }
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const saveApplication = () => {
    if (!dubleClick) {
      return;
    }

    create_alert_fn(
      "info",
      "Are you sure you want to create a application?",
      async () => {
        await createApplication();
      },
      true
    );
  };

  return (
    <ModalBody
      open={open}
      title="Application"
      onClose={onClose}
      disableEscapeKeyDown={true}
      modalWidth={792}
      modalHeight={600}
    >
      <div className="hr-calendar-using-modal-contents-body">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Work Type"
                {...a11yProps(0)}
                sx={{ textTransform: "none" }}
              />
              <Tab
                label="Annual Leave"
                {...a11yProps(1)}
                sx={{ textTransform: "none" }}
              />

              <Tab
                label="Dayoff"
                {...a11yProps(2)}
                sx={{ textTransform: "none" }}
              />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <WorkTypeMenu
                workTypeData={workTypeData}
                setWorkTypeData={setWorkTypeData}
                workTypePolicyId={workTypePolicyId}
                setWorkTypePolicyId={setWorkTypePolicyId}
                approvalLineData={approvalLineData}
                confirmApprovalLineData={confirmApprovalLineData}
                resetApprovalLineData={resetApprovalLineData}
                approval_user_list={approval_user_list}
                selectWorkTypePolicy={selectWorkTypePolicy}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <AnnualLeaveMenu
                annualLeaveData={annualLeaveData}
                setAnnualLeaveData={setAnnualLeaveData}
                approvalLineData={approvalLineData}
                confirmApprovalLineData={confirmApprovalLineData}
                resetApprovalLineData={resetApprovalLineData}
                approval_user_list={approval_user_list}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
              <DayoffMenu
                dayoffData={dayoffData}
                setDayoffData={setDayoffData}
                dayOffPolicyId={dayOffPolicyId}
                setDayOffPolicyId={setDayOffPolicyId}
                approvalLineData={approvalLineData}
                confirmApprovalLineData={confirmApprovalLineData}
                resetApprovalLineData={resetApprovalLineData}
                approval_user_list={approval_user_list}
                selectdayoffPolicy={selectdayoffPolicy}
              />
            </CustomTabPanel>
          </Box>
        </Box>
      </div>
      <div className="hr-calendar-using-modal-btn-body">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="hr-calendar-using-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            saveApplication();
          }}
          className="hr-calendar-using-modal-update-btn"
          disabled={button_disabled}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default HRCalendarUsingModal;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={index === 2 ? {} : { height: "100%" }}
    >
      {value === index && children}
    </div>
  );
}
