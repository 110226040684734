import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { ApprovalModalStatus } from "@/atoms/modalStatus";
import ModalBody from "@components/ModalBody";
import FileDownloadBtn from "@/components/FileDownloadBtn";
import ApprovalCards from "./ApprovalCards";
import CarbonCopyCards from "./CarbonCopyCards";
import WorkTypeList from "./WorkTypeList";
import AnnualList from "./AnnualList";
import DayoffList from "./DayoffList";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const ApprovalModal = () => {
  const [dubleClick, setDubleClick] = useState(true);
  const { id, onClose, open, list_refresh, count_query_key, modal_type } =
    useRecoilValue(ApprovalModalStatus);

  const [approval_count_refresh] = useRefreshReactQuery(count_query_key);

  const query_approval_detail_data = useQuery(
    ["approval_detail_data", id, modal_type],
    () => {
      return query_fetch_data(id, modal_type);
    }
  );

  const { data = {}, isLoading } = query_approval_detail_data;

  const {
    policy_table_name = "",
    username = "",
    org_setting_name = "",
    lv_name = "",
    fn_name = "",
    policy_name = "",
    items = [],
    approval_line_list = [],
    carborn_copy_list = [],
    attachment_list = [],
    action_button_disabled = true,
  } = data;

  const dateRangeText = useMemo(() => {
    if (items.length === 0) {
      return "";
    }

    const start = items[0];
    const end = items.at(-1);

    const {
      date_year: start_work_date_year,
      date_month: start_work_date_month,
      date_day: start_work_date_day,
    } = start;

    const {
      date_year: end_work_date_year,
      date_month: end_work_date_month,
      date_day: end_work_date_day,
    } = end;

    return `${start_work_date_year}-${start_work_date_month}-${start_work_date_day} ~ ${end_work_date_year}-${end_work_date_month}-${end_work_date_day} (${items.length} days)`;
  }, [items]);

  const requestApprovalAction = async (action) => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestUpdateApprovalStatus = await userPermAxios.post(
        `/approval/update_approval_status`,
        {
          id,
          action,
          modal_type,
        }
      );

      const { success, message } = requestUpdateApprovalStatus;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        approval_count_refresh();
        list_refresh();
        onClose();
      }
    }
  };

  const approval_action = (action) => {
    if (!dubleClick) {
      return;
    }
    let msg;

    if (action === "Canceled") {
      msg = "Are you sure you want to cancel?";
    }

    if (action === "Rejected") {
      msg = "Are you sure you want to reject?";
    }

    if (action === "Approved") {
      msg = "Are you sure you want to approve?";
    }

    if (!msg) {
      return;
    }

    return create_alert_fn(
      "info",
      msg,
      () => requestApprovalAction(action),
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Approval"
      disableEscapeKeyDown={true}
      modalHeight={800}
      modalWidth={840}
    >
      <div className="approval-modal-contents-body">
        <div className="approval-modal-contents-box">
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Approver</div>
            </div>
            <div className="approval-modal-user-info-box">
              <div className="approval-modal-user-info-detail-box">
                <div className="approval-modal-info-body">
                  <div className="approval-modal-info-title-box">
                    <div className="approval-modal-info-title">User Name</div>
                  </div>
                  <div className="approval-modal-info-contents-box">
                    <div className="approval-modal-info-contents">
                      {username}
                    </div>
                  </div>
                </div>
                <div className="approval-modal-info-body">
                  <div className="approval-modal-info-title-box">
                    <div className="approval-modal-info-title">Department</div>
                  </div>
                  <div className="approval-modal-info-contents-box">
                    <div className="approval-modal-info-contents">
                      {org_setting_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="approval-modal-user-info-detail-box">
                <div className="approval-modal-info-body">
                  <div className="approval-modal-info-title-box">
                    <div className="approval-modal-info-title">
                      Job Position
                    </div>
                  </div>
                  <div className="approval-modal-info-contents-box">
                    <div className="approval-modal-info-contents">
                      {lv_name}
                    </div>
                  </div>
                </div>
                <div className="approval-modal-info-body">
                  <div className="approval-modal-info-title-box">
                    <div className="approval-modal-info-title">Job Grade</div>
                  </div>
                  <div className="approval-modal-info-contents-box">
                    <div className="approval-modal-info-contents">
                      {fn_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Approval Date</div>
            </div>
            <div className="approval-modal-info-contents-box">
              <div className="approval-modal-info-contents">
                {dateRangeText}
              </div>
            </div>
          </div>
          <div className="approval-modal-info-body">
            <div className="approval-modal-info-title-box">
              <div className="approval-modal-info-title">Approval Item</div>
            </div>
            <div className="approval-modal-info-contents-box">
              <div className="approval-modal-info-contents">{policy_name}</div>
            </div>
          </div>
          {itemGroups(policy_table_name, items)}
          {attachment_list.length > 0 && (
            <div className="approval-modal-column-body">
              <div className="approval-modal-item-title-box">
                <div className="approval-modal-item-title">Attachment</div>
              </div>
              <div className="approval-modal-row-wrop-body">
                {attachment_list.map((c) => {
                  const { id: attachment_id, filename, url } = c;

                  return (
                    <FileDownloadBtn
                      url={url}
                      filename={filename}
                      key={attachment_id}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {(approval_line_list.length > 0 || carborn_copy_list.length > 0) && (
            <div className="approval-modal-vertical-line" />
          )}

          {approval_line_list.length > 0 && (
            <div className="approval-modal-column-body">
              <div className="approval-modal-item-title-box">
                <div className="approval-modal-item-title">Approval Line</div>
              </div>
              <div className="approval-modal-row-scroll-body">
                <ApprovalCards approval_list={approval_line_list} />
              </div>
            </div>
          )}
          {carborn_copy_list.length > 0 && (
            <div className="approval-modal-column-body">
              <div className="approval-modal-item-title-box">
                <div className="approval-modal-item-title">Carbon Copy</div>
              </div>
              <div className="approval-modal-row-scroll-body">
                <CarbonCopyCards carbon_copy_list={carborn_copy_list} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="approval-modal-btn-body">
        {buttonGroups(
          modal_type,
          onClose,
          action_button_disabled,
          approval_action
        )}
      </div>
    </ModalBody>
  );
};

export default ApprovalModal;

const query_fetch_data = async (id, modal_type) => {
  let result = {
    policy_table_name: "",
    approval_date: "",
    approved_status: "",
    username: "",
    org_setting_name: "",
    lv_name: "",
    fn_name: "",
    policy_name: "",
    cancel_use: null,
    attachment_required: null,
    items: [],
    approval_line_list: [],
    carborn_copy_list: [],
    attachment_list: [],
    action_button_disabled: true,
  };

  try {
    const requestDetailApprovalData = await userPermAxios.get(
      `/approval/get_detail_approval?id=${id}&modal_type=${modal_type}`
    );

    const { success, data } = requestDetailApprovalData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const buttonGroups = (
  modal_type,
  onClose = () => {},
  disabled = false,
  onClick = () => {}
) => {
  if (modal_type === "approval") {
    return (
      <>
        <button
          className="approval-modal-btn color"
          onClick={() => onClick("Approved")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Approval</div>
        </button>
        <button
          className="approval-modal-btn normal"
          onClick={() => onClick("Rejected")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Rejection</div>
        </button>
        <button className="approval-modal-btn normal" onClick={onClose}>
          <div className="approval-modal-btn-label">Close</div>
        </button>
      </>
    );
  }
  if (modal_type === "carbon_copy") {
    return (
      <button className="approval-modal-btn normal" onClick={onClose}>
        <div className="approval-modal-btn-label">Close</div>
      </button>
    );
  }
  if (modal_type === "draft") {
    return (
      <>
        <button
          className="approval-modal-btn color"
          onClick={() => onClick("Canceled")}
          disabled={disabled}
        >
          <div className="approval-modal-btn-label">Draft Cancel</div>
        </button>
        <button className="approval-modal-btn normal" onClick={onClose}>
          <div className="approval-modal-btn-label">Close</div>
        </button>
      </>
    );
  }

  return;
};

const itemGroups = (policy_table_name, items) => {
  if (policy_table_name === "work_type_policy") {
    return <WorkTypeList items={items} />;
  }

  if (policy_table_name === "annual_leave_policy") {
    return <AnnualList items={items} />;
  }

  if (policy_table_name === "dayoff_policy") {
    return <DayoffList items={items} />;
  }
  return;
};
