import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.5083 12.1974C14.2496 11.1881 14.6875 9.94206 14.6875 8.59375C14.6875 5.22826 11.9592 2.5 8.59375 2.5C5.22826 2.5 2.5 5.22826 2.5 8.59375C2.5 11.9592 5.22826 14.6875 8.59375 14.6875C9.94242 14.6875 11.1888 14.2494 12.1982 13.5077L12.1974 13.5083C12.225 13.5458 12.2558 13.5817 12.2898 13.6156L15.8996 17.2254C16.2657 17.5915 16.8593 17.5915 17.2254 17.2254C17.5915 16.8593 17.5915 16.2657 17.2254 15.8996L13.6156 12.2898C13.5817 12.2558 13.5458 12.225 13.5083 12.1974ZM13.75 8.59375C13.75 11.4415 11.4415 13.75 8.59375 13.75C5.74603 13.75 3.4375 11.4415 3.4375 8.59375C3.4375 5.74603 5.74603 3.4375 8.59375 3.4375C11.4415 3.4375 13.75 5.74603 13.75 8.59375Z"
        fill="#344054"
      />
    </svg>
  );
};

export default SearchIcon;
