import CustomCheckBox from "@/components/CustomCheckBox";
import React, { useMemo, useState } from "react";
import { Button, TextField, InputLabel } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { labelInputTheme2 } from "@styles/muiThemes";
import dayjs from "dayjs";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import "../menu.scss";
import YearPicker from "@/components/YearPicker";
import MonthPicker from "@/components/MonthPicker";
import LoadingModal from "@/components/LoadingModal";

const TaxReportPage = () => {
  const [yearValue, setYearValue] = useState(dayjs());
  const [durationType, setDurationType] = useState(duration_type_initial_data);
  const [importType, setImportType] = useState(import_type_initial_data);
  const [exportType, setExportType] = useState(export_type_initial_data);
  const [summaryType, setSummaryType] = useState(summary_check_initial_data);
  const [taxNumber, setTaxNumber] = useState("");
  const [reportNumber, setReportNumber] = useState("");
  const [accountMonth, setAccountMonth] = useState(dayjs());
  const [dubleClick, setDubleClick] = useState(true);

  const import_export_arr = [
    {
      title: "2. Import Type",
      setValue: setImportType,
      value: importType,
    },
    {
      title: "3. Export Type",
      setValue: setExportType,
      value: exportType,
    },
  ];

  const changeYearValue = (e) => {
    setYearValue(e);
  };

  const changeMonthValue = (e) => {
    setAccountMonth(e);
  };

  const button_disabled = useMemo(() => {
    const common_data = ![
      durationType,
      importType,
      exportType,
      summaryType,
    ].every((c) => {
      return (
        c.every((c2) => {
          const { checked } = c2;
          return checked === false;
        }) === false
      );
    });

    if (common_data) {
      return true;
    }

    if (taxNumber.length === 0 || reportNumber === 0) {
      return true;
    }

    const findSummaryType = summaryType.findIndex((c) => c.checked);

    if (findSummaryType > 0) {
      const targetSummaryType = summaryType[findSummaryType].data;

      if (targetSummaryType.length === 0) {
        return true;
      }
    }

    return false;
  }, [
    durationType,
    importType,
    exportType,
    taxNumber,
    reportNumber,
    summaryType,
  ]);

  const createReport = async () => {
    if (!dubleClick) return;

    setDubleClick(false);

    let result = {
      type: "warning",
      message: "",
      fn: () => {},
    };

    try {
      const targetDurationType = durationType.find((c) => c.checked);
      const targetImportType = importType.find((c) => c.checked);
      const targetExportType = exportType.find((c) => c.checked);
      const targetSummaryType = summaryType.find((c) => c.checked);

      const requestCreateReport = await userPermAxios.post(
        "/create-tax-report",
        {
          yearValue: yearValue.format("YYYY"),
          durationType: targetDurationType.label,
          importType: targetImportType.label,
          exportType: targetExportType.label,
          summaryType: targetSummaryType,
          taxNumber,
          reportNumber,
          accountMonth: accountMonth.format("MM/YYYY"),
        },
        {
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([requestCreateReport], {
        type: "application/pdf",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "Reports.zip");

      document.body.appendChild(link);

      result.type = "success";
      result.message = "Successfully DownLoad Tax Report";
      result.fn = () => {};

      link.click();
      link.remove();
    } catch (err) {
      console.log(err);
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.type, result.message, result.fn);
    }
  };

  return (
    <>
      <div className="tax-report-total-body">
        <div className="tax-report-total-top-box">
          <div className="tax-report-total-title">Tax Report</div>
        </div>
        <div className="tax-report-total-bottom-box">
          <div className="tax-report-selction-body">
            <div className="tax-report-selction-box">
              <div className="tax-report-selction-title">
                1. Report Duration
              </div>
              <div className="tax-report-selction-contents">
                <div className="tax-report-selction-content">
                  <YearPicker value={yearValue} onChange={changeYearValue} />
                </div>
                {durationType.map((c) => {
                  const { label, checked } = c;
                  return (
                    <CheckBoxContents
                      key={label}
                      label={label}
                      checked={checked}
                      onChange={() => {
                        handleCheckBoxType(setDurationType, label);
                      }}
                    />
                  );
                })}
              </div>
            </div>
            {import_export_arr.map((c) => {
              const { title, setValue, value } = c;
              return (
                <div className="tax-report-selction-box" key={title}>
                  <div className="tax-report-selction-title">{title}</div>
                  <div className="tax-report-selction-contents">
                    {value.map((c2) => {
                      const { label, checked } = c2;
                      return (
                        <CheckBoxContents
                          key={label}
                          label={label}
                          checked={checked}
                          onChange={() => {
                            handleCheckBoxType(setValue, label);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="tax-report-selction-box">
              <div className="tax-report-selction-title">4. Summary Data</div>
              <div className="tax-report-selction-contents column">
                <div
                  className="tax-report-selction-contents-detail"
                  style={{ marginLeft: 16 }}
                >
                  <LabelInput
                    label="Tax ID Number"
                    value={taxNumber}
                    onChange={(e) => {
                      setTaxNumber(e.target.value);
                    }}
                  />
                  <LabelInput
                    label="Report Number"
                    value={reportNumber}
                    onChange={(e) => {
                      setReportNumber(e.target.value);
                    }}
                  />
                  <div className="months-picker-box">
                    <div className="months-picker-label">Accounting Period</div>
                    <MonthPicker
                      value={accountMonth}
                      onChange={changeMonthValue}
                    />
                  </div>
                </div>
                <div className="tax-report-selction-contents-detail">
                  {summaryType.map((c, i) => {
                    const { label, checked, data } = c;

                    if (i === 0) {
                      return (
                        <CheckBoxContents
                          key={label}
                          label={label}
                          checked={checked}
                          onChange={() => {
                            handleCheckBoxType(setSummaryType, label);
                          }}
                        />
                      );
                    }

                    return (
                      <CheckBoxContentsText
                        key={label}
                        label={label}
                        checked={checked}
                        data={data}
                        onChange={() => {
                          handleCheckBoxType(setSummaryType, label);
                        }}
                        onChangeText={(e) => {
                          const value = e.target.value;
                          handleCheckBoxTextType(setSummaryType, label, value);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="tax-report-btn-body">
            <Button
              onClick={createReport}
              className="tax-report-btn"
              disabled={button_disabled}
            >
              Create Report
            </Button>
          </div>
        </div>
      </div>
      {!dubleClick && <LoadingModal msg="Generating report, please wait." />}
    </>
  );
};

export default TaxReportPage;

const CheckBoxContents = ({ checked, label, onChange }) => {
  return (
    <div className="tax-report-selction-content">
      <CustomCheckBox checked={checked} onChange={onChange} />
      <div className="tax-report-selction-content-name" onClick={onChange}>
        {label}
      </div>
    </div>
  );
};

const CheckBoxContentsText = ({
  checked,
  label,
  data,
  onChange,
  onChangeText,
}) => {
  return (
    <div className="tax-report-selction-content">
      <CustomCheckBox checked={checked} onChange={onChange} />
      <LabelInput
        disabled={!checked}
        label={label}
        value={data}
        onChange={onChangeText}
      />
    </div>
  );
};

const LabelInput = ({ label, value, onChange, disabled = false }) => {
  return (
    <div className="label-input-box">
      <ThemeProvider theme={labelInputTheme2}>
        <InputLabel
          htmlFor={`label-input-${label}`}
          className="label-input-title"
        >
          {label}
        </InputLabel>
        <TextField
          id={`label-input-${label}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
          sx={{
            width: 250,
          }}
        />
      </ThemeProvider>
    </div>
  );
};

const handleCheckBoxType = (setValue, label) => {
  setValue((prev) => {
    return prev.map((c) => {
      const { label: prev_label, checked: prev_checked } = c;

      if (label === prev_label) {
        return { ...c, checked: !prev_checked, data: "" };
      }

      return {
        ...c,
        checked: false,
        data: "",
      };
    });
  });
};

const handleCheckBoxTextType = (setValue, label, value) => {
  setValue((prev) => {
    return prev.map((c) => {
      const { label: prev_label } = c;
      if (label === prev_label) {
        return { ...c, data: value };
      }
      return {
        ...c,
        data: "",
      };
    });
  });
};

const duration_type_initial_data = [
  {
    label: "1st half",
    checked: false,
  },
  {
    label: "2nd half",
    checked: false,
  },
];

const import_type_initial_data = [
  {
    label: "Imported from abroad",
    checked: false,
  },
  {
    label: "Received transfer from Duty-Free Zone / Free Zone",
    checked: false,
  },
];

const export_type_initial_data = [
  {
    label: "Exported abroad",
    checked: false,
  },
  {
    label: "Paid tax",
    checked: false,
  },
  {
    label: "Transferred out",
    checked: false,
  },
  {
    label: "Destroyed/Donated",
    checked: false,
  },
];

const summary_check_initial_data = [
  {
    label: "Currently in the process of settling the tax",
    data: "",
    checked: false,
  },
  {
    label: "Tax has been fully paid as per receipt number",
    data: "",
    checked: false,
  },
  {
    label: "Others",
    data: "",
    checked: false,
  },
];
