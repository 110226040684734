import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { UserSetting } from "@/atoms/userInfo";
import {
  ManpowerRequestApprovalModalStatus,
  EditManpowerRequestModalStatus,
  CandidateAddModalStatus,
  CandidateListModalStatus,
} from "@/atoms/modalStatus";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import dayjs from "dayjs";
import Btn from "@/components/CustomIconBtn";
import PlusIcon from "@/components/Icons/PlusIcon";
import MonthPicker from "@/components/MonthPicker";
import ManpowerRequest from "@/components/manpowerRequest/ManpowerRequest";
import VerticalSolidLine from "@/components/VerticalSolidLine";
import ManpowerRequestAddModal from "@/components/manpowerRequest/ManpowerRequestAddModal";
import EditManPowerRequestModal from "@/components/manpowerRequest/EditManPowerRequestModal";
import CandidateAddModal from "@/components/manpowerRequest/CandidateAddModal";
import ManpowerReqApprovalModal from "@/components/approvalManagement/Modal/ManpowerReqApprovalModal";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import CandidateListModal from "@/components/manpowerRequest/CandidateListModal";

const ManpowerRequestPage = () => {
  const { open: approvalModalOpen } = useRecoilValue(
    ManpowerRequestApprovalModalStatus
  );

  const { open: editModalOpen } = useRecoilValue(
    EditManpowerRequestModalStatus
  );

  const [{ open: candidateModalOpen }, setCandidateModalToggle] =
    useRecoilState(CandidateAddModalStatus);
  const { open: candidateListModalOpen } = useRecoilValue(
    CandidateListModalStatus
  );

  const closeCandidateModal = useResetRecoilState(CandidateAddModalStatus);

  const [tabValue, setTabValue] = useState(0);
  const [dateValue, setDateValue] = useState(dayjs());
  const [manpowerModalToggle, setManpowerModalToggle] = useState(false);

  const { userId } = useRecoilValue(UserSetting);

  const list_query_key = useMemo(() => {
    return ["manpower_request_data", dateValue.format("YYYY-MM")];
  }, [dateValue]);

  const query_manpower_request_data = useQuery(list_query_key, () => {
    return query_fetch_data(dateValue.format("YYYY"), dateValue.format("MM"));
  });

  const query_approval_user_list_data = useQuery(
    ["approval_user_list_data", userId],
    () => {
      return query_approval_fetch_data(userId);
    }
  );

  const query_job_list_data = useQuery("job_list_data", () => {
    return query_job_fetch_data();
  });

  const { data: mrData = {}, isLoading: mrDataLoading } =
    query_manpower_request_data;

  const { data: userListData = {}, isLoading: userListLoading } =
    query_approval_user_list_data;

  const { data: jobListData = {}, isLoading: jobListLoading } =
    query_job_list_data;

  const {
    manpower_request_list = [],
    candidate_list = [],
    add_button_use = true,
  } = mrData;

  const { user_list = [], approval_user_list = [] } = userListData;

  const {
    org_list = [],
    job_position_list = [],
    job_grade_list = [],
  } = jobListData;

  const [refresh] = useRefreshReactQuery(list_query_key);

  const handleDateValue = (e) => {
    setDateValue(e);
  };

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddModal = () => {
    if (tabValue === 0) {
      setManpowerModalToggle(true);
    }

    if (tabValue === 1) {
      setCandidateModalToggle((prev) => {
        return {
          ...prev,
          open: true,
          modal_type: "create",
          year: dateValue.format("YYYY"),
          month: dateValue.format("MM"),
          onClose: closeCandidateModal,
        };
      });
    }
  };

  if (mrDataLoading || userListLoading || jobListLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Manpower Request</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />
          <VerticalSolidLine height={24} />
          <MonthPicker value={dateValue} onChange={handleDateValue} />
          {add_button_use && (
            <>
              <VerticalSolidLine height={24} />
              <Btn
                label={tabValue === 0 ? "Add Request" : "Add Candidate"}
                icon={<PlusIcon />}
                width={180}
                onClick={handleAddModal}
              />
            </>
          )}
        </div>
      </div>
      <div className="company-setting-contents-body">
        <ManpowerRequest
          tabValue={tabValue}
          handleTabValue={handleTabValue}
          manpower_request_logs={manpower_request_list}
          candidate_record_list={candidate_list}
          add_button_use={add_button_use}
          list_query_key={list_query_key}
        />
      </div>
      {manpowerModalToggle && (
        <ManpowerRequestAddModal
          open={manpowerModalToggle}
          onClose={() => setManpowerModalToggle(false)}
          table_refresh={refresh}
          user_list={user_list}
          approval_user_list={approval_user_list}
          org_list={org_list}
          job_position_list={job_position_list}
          job_grade_list={job_grade_list}
        />
      )}
      {candidateModalOpen && <CandidateAddModal />}
      {approvalModalOpen && <ManpowerReqApprovalModal />}
      {editModalOpen && <EditManPowerRequestModal />}
      {candidateListModalOpen && <CandidateListModal />}
    </div>
  );
};

export default ManpowerRequestPage;

const query_fetch_data = async (year, month) => {
  let result = {
    manpower_request_list: [],
    candidate_list: [],
    add_button_use: true,
  };

  try {
    const requestBudgetUsgaeData = await userPermAxios.get(
      `/hr/get_manpower_request_data?year=${year}&month=${month}`
    );
    const { success, data } = requestBudgetUsgaeData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_approval_fetch_data = async (userId) => {
  let result = {
    user_list: [],
    approval_user_list: [],
  };

  try {
    const requestApprovalUserList = await userPermAxios.get(
      "/cost/get_approval_user_list?type=allUser"
    );

    const { success, data } = requestApprovalUserList;

    if (success) {
      const { list = [] } = data;
      result.user_list = list;
      result.approval_user_list = list.filter((c) => c.id !== userId);
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_job_fetch_data = async () => {
  let result = {
    org_list: [],
    job_position_list: [],
    job_grade_list: [],
  };

  try {
    const requestOrgList = await userPermAxios.get(
      "/hr/organization_setting_data"
    );

    const requestJobLevelList = await userPermAxios.get(
      "/hr/level/get_job_level_fn_setting_data"
    );

    const requestJobFnList = await userPermAxios.get(
      "/hr/fn/get_job_level_fn_setting_data"
    );

    const { success: orgListSuccess, data: orgListData } = requestOrgList;
    const { success: JobLevelListSuccess, data: JobLevelListData } =
      requestJobLevelList;
    const { success: JobFnListSuccess, data: JobFnListData } = requestJobFnList;

    if (orgListSuccess) {
      result.org_list = orgListData.map((c) => {
        const { id, name } = c;
        return { name, value: id };
      });
    }

    if (JobLevelListSuccess) {
      result.job_position_list = JobLevelListData.map((c) => {
        const { id, name } = c;
        return { name, value: id };
      });
    }

    if (JobFnListSuccess) {
      result.job_grade_list = JobFnListData.map((c) => {
        const { id, name } = c;
        return { name, value: id };
      });
    }
  } catch (err) {
  } finally {
    return result;
  }
};
