import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { CandidateAddModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ModalBody from "@components/ModalBody";
import DayPicker from "@components/DatePicker";
import LabelSelect from "@components/LabelSelect";
import LabelInput from "@components/LabelInput";
import LabelTextArea from "@components/LabelTextArea";
import CustomTimePicker from "@components/CustomTimePicker";
import FileDownloadBtn from "@components/FileDownloadBtn";
import ManpowerReqSelectModal from "./ManpowerReqSelectModal";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

dayjs.extend(customParseFormat);

const CandidateAddModal = () => {
  const {
    open,
    onClose,
    list_query_key = [],
    id,
    modal_type,
    year,
    month,
  } = useRecoilValue(CandidateAddModalStatus);

  const [attachmentList, setAttachmentList] = useState(null);

  const [refresh] = useRefreshReactQuery(list_query_key);
  const [dubleClick, setDubleClick] = useState(true);
  const [selectedManpowerReqToggle, setSelectedManpowerReqToggle] =
    useState(false);
  const [selectedManpowerReq, setSelectedManpowerReq] = useState([]);
  const [candidateUser, setCandidateUser] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePhone, setCandidatePhone] = useState("");
  const [dateofBirth, setDateofBirth] = useState(dayjs());
  const [expectedDateofJoining, setExpectedDateofJoining] = useState(null);
  const [interviewStatus, setInterViewStatus] = useState("Not Yet Scheduled");
  const [interviewDate, setInterViewDate] = useState(null);
  const [interviewTime, setInterViewTime] = useState(null);
  const [interviewResult, setInterViewResult] = useState("Pending");
  const [interviewRemark, setInterViewRemark] = useState("");
  const [existingAttList, setExistingAttList] = useState([]);
  const [attDeleteList, setAttDeleteList] = useState([]);

  const query_candidate_detail_data = useQuery(
    ["candidate_detail_data", id, modal_type, year, month],
    () => query_fetch_data(id, modal_type, year, month),
    {
      onSuccess: (result) => {
        const { candidate_data, existing_attachment_list } = result;

        const {
          manpower_request_id = null,
          username = null,
          email = null,
          phone = null,
          joining_date = null,
          birth_date = null,
          interview_date = null,
          interview_time = null,
          interview_status = "Not Yet Scheduled",
          interview_result = "Pending",
          interview_remark = null,
        } = candidate_data;

        setSelectedManpowerReq(
          manpower_request_id !== null ? [manpower_request_id] : []
        );
        setCandidateUser(username ?? "");
        setCandidateEmail(email ?? "");
        setCandidatePhone(phone ?? "");
        setDateofBirth(birth_date ? dayjs(birth_date, "YYYY-MM-DD") : null);
        setExpectedDateofJoining(
          joining_date ? dayjs(joining_date, "YYYY-MM-DD") : null
        );
        setInterViewStatus(interview_status);
        setInterViewDate(
          interview_date ? dayjs(interview_date, "YYYY-MM-DD") : null
        );
        setInterViewTime(
          interview_time ? dayjs(interview_time, "HH:mm") : null
        );
        setInterViewResult(interview_result);
        setInterViewRemark(interview_remark ?? "");

        setExistingAttList(existing_attachment_list);
      },
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const { data = {}, isLoading } = query_candidate_detail_data;

  const { manpower_request_list = [], edit_disabled = false } = data;

  const { nec_org_setting_name, nec_lv_name, nec_fn_name, reason_for_hiring } =
    useMemo(() => {
      if (selectedManpowerReq.length === 0) {
        return {
          nec_org_setting_name: "",
          nec_lv_name: "",
          nec_fn_name: "",
          reason_for_hiring: "",
        };
      }

      const findManpowerReq = manpower_request_list.find(
        (c) => c.id === selectedManpowerReq[0]
      );

      if (findManpowerReq) {
        const {
          reason_for_hiring = "",
          nec_org_setting_name = "",
          nec_lv_name = "",
          nec_fn_name = "",
        } = findManpowerReq;

        return {
          nec_org_setting_name,
          nec_lv_name,
          nec_fn_name,
          reason_for_hiring,
        };
      }

      return {
        nec_org_setting_name: "",
        nec_lv_name: "",
        nec_fn_name: "",
        reason_for_hiring: "",
      };
    }, [selectedManpowerReq, manpower_request_list]);

  const handleSelectModalToggle = () => {
    setSelectedManpowerReqToggle((prev) => !prev);
  };

  const handlehandleSelectValue = (value) => {
    setSelectedManpowerReq(value);
  };

  const handleExistingAttachment = (id) => {
    setAttDeleteList((prev) => {
      const prevCopy = [...prev];

      prevCopy.push(id);

      return prevCopy;
    });

    setExistingAttList((prev) => {
      return prev.filter((c) => c.id !== id);
    });
  };

  const requestUpdateStatus = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    const formData = new FormData();

    for (const file in attachmentList) {
      if (file !== "length" && file !== "item") {
        const targetFile = attachmentList[file];

        console.log(targetFile);

        formData.append(`files`, targetFile);
      }
    }

    const submitData = {
      selectedManpowerReq: selectedManpowerReq[0],
      candidateUser,
      candidateEmail,
      candidatePhone,
      dateofBirth: dateofBirth ? dateofBirth.format("YYYY-MM-DD") : null,
      expectedDateofJoining: expectedDateofJoining
        ? expectedDateofJoining.format("YYYY-MM-DD")
        : null,
      interviewStatus,
      interviewDate: interviewDate ? interviewDate.format("YYYY-MM-DD") : null,
      interviewTime: interviewTime ? interviewTime.format("HH:mm") : null,
      interviewResult,
      interviewRemark,
    };

    if (modal_type === "update") {
      submitData.id = id;
      submitData.attDeleteList = attDeleteList;
    }

    formData.append("additionalData", JSON.stringify(submitData));

    try {
      const requestUpdateStatus = await userPermAxios.post(
        `/hr/${modal_type}_candidate_records`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestUpdateStatus;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmSave = () => {
    if (!dubleClick) {
      return;
    }
    const msg = `Are you sure you want to ${modal_type} status?`;

    return create_alert_fn("info", msg, requestUpdateStatus, true);
  };

  const button_disabled = useMemo(() => {
    if (
      selectedManpowerReq.length === 0 ||
      candidateUser.length === 0 ||
      candidateEmail.length === 0 ||
      candidatePhone.length === 0 ||
      !dateofBirth
    ) {
      return true;
    }

    return edit_disabled;
  }, [
    selectedManpowerReq,
    candidateUser,
    candidateEmail,
    candidatePhone,
    dateofBirth,
    edit_disabled,
  ]);

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title={`${modal_type === "create" ? "Create" : "Update"} Candidate`}
      modalWidth={720}
    >
      {selectedManpowerReqToggle && (
        <ManpowerReqSelectModal
          open={selectedManpowerReqToggle}
          onClose={handleSelectModalToggle}
          manpower_request_list={[...manpower_request_list]}
          select_model={selectedManpowerReq}
          handlehandleSelectValue={handlehandleSelectValue}
        />
      )}
      <div className="work-policy-modal-contents-box">
        <div
          className="work-policy-modal-detail-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Manpower Request
            </div>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<SelectIcon />}
              sx={{ fontSize: 14, textTransform: "none", width: 200 }}
              onClick={handleSelectModalToggle}
              disabled={edit_disabled}
            >
              Select Request
            </Button>
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div style={{ display: "flex", gap: "8px", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Nec. Department
                </div>
                <LabelInput disabled value={nec_org_setting_name} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Nec. Job Position
                </div>
                <LabelInput disabled value={nec_lv_name} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "8px",
                }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Nec. Job Grade
                </div>
                <LabelInput disabled value={nec_fn_name} />
              </div>
            </div>
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Reason for Hiring
            </div>
            <LabelInput disabled value={reason_for_hiring} />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Candidate Name
            </div>
            <LabelInput
              value={candidateUser}
              setValue={setCandidateUser}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">Email</div>
            <LabelInput
              value={candidateEmail}
              setValue={setCandidateEmail}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">Phone</div>
            <LabelInput
              value={candidatePhone}
              setValue={setCandidatePhone}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Date of Birth
            </div>
            <DayPicker
              width={647}
              value={dateofBirth}
              onChange={(e) => setDateofBirth(e)}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Interview Status
            </div>
            <LabelSelect
              list={interviewStatusList}
              value={interviewStatus}
              setValue={setInterViewStatus}
              disabled={edit_disabled}
            />
          </div>
          <div className="work-policy-modal-time-picker-box">
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Interview Date
              </div>
              {/* <LabelSelect /> */}
              <DayPicker
                width={307.5}
                value={interviewDate}
                onChange={(e) => setInterViewDate(e)}
                disabled={edit_disabled}
              />
            </div>
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Necessary Job Position
              </div>
              <CustomTimePicker
                width={307.5}
                value={interviewTime}
                onChange={(e) => setInterViewTime(e)}
                disabled={edit_disabled}
              />
            </div>
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Result
            </div>
            <LabelSelect
              list={interviewResultList}
              value={interviewResult}
              setValue={setInterViewResult}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Expected Date of Joining
            </div>
            <DayPicker
              width={647}
              value={expectedDateofJoining}
              onChange={(e) => setExpectedDateofJoining(e)}
              disabled={edit_disabled}
            />
          </div>
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Detailed Information (Remark)
            </div>
            <LabelTextArea
              value={interviewRemark}
              setValue={setInterViewRemark}
              disabled={edit_disabled}
            />
          </div>
          <div className="approval-modal-vertical-line" />
          {existingAttList.length > 0 && (
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Existing Attachment
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {existingAttList.map((c) => {
                  const { url, filename, id } = c;
                  return (
                    <FileDownloadBtn
                      key={id}
                      url={url}
                      filename={filename}
                      delete_fn_use={!edit_disabled}
                      delete_fn={() => handleExistingAttachment(id)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div className="work-policy-modal-detail-contents-title">
              {`Additional Attachment (${
                attachmentList ? attachmentList.length : 0
              })`}
            </div>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<FileUploadOutlinedIcon fontSize="small" />}
              sx={{ fontSize: 14, textTransform: "none", width: 200 }}
              disabled={edit_disabled}
            >
              Upload File
              <input
                type="file"
                multiple
                style={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
                onChange={(e) => {
                  const fileCount = e.target.files.length;
                  if (fileCount > 0) {
                    setAttachmentList([...e.target.files]);
                  } else {
                    setAttachmentList(null);
                  }
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          disabled={button_disabled}
          onClick={confirmSave}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default CandidateAddModal;

const query_fetch_data = async (id, modal_type, year, month) => {
  let result = {
    manpower_request_list: [],
    candidate_data: {},
    existing_attachment_list: [],
    edit_disabled: false,
  };

  try {
    const request_candidate_data = await userPermAxios.get(
      `/hr/get_candidate_data?id=${id}&modal_type=${modal_type}&year=${year}&month=${month}`
    );
    const { success, data } = request_candidate_data;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const SelectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.70588 2.84835C8.57721 2.77972 8.42279 2.77972 8.29412 2.84835L1.73162 6.34835C1.58905 6.42438 1.5 6.5728 1.5 6.73438C1.5 6.89595 1.58905 7.04437 1.73162 7.1204L4.28906 8.48438L1.73162 9.84835C1.58905 9.92438 1.5 10.0728 1.5 10.2344C1.5 10.3959 1.58905 10.5444 1.73162 10.6204L8.29412 14.1204C8.42279 14.189 8.57721 14.189 8.70588 14.1204L15.2684 10.6204C15.4109 10.5444 15.5 10.3959 15.5 10.2344C15.5 10.0728 15.4109 9.92438 15.2684 9.84835L12.7109 8.48438L15.2684 7.1204C15.4109 7.04437 15.5 6.89595 15.5 6.73438C15.5 6.5728 15.4109 6.42438 15.2684 6.34835L8.70588 2.84835ZM11.7812 8.98021L14.1328 10.2344L8.5 13.2385L2.86719 10.2344L5.21875 8.98021L8.29412 10.6204C8.42279 10.689 8.57721 10.689 8.70588 10.6204L11.7812 8.98021ZM8.5 9.73854L2.86719 6.73438L8.5 3.73021L14.1328 6.73438L8.5 9.73854Z"
        fill="#0080DB"
      />
    </svg>
  );
};

const interviewStatusList = [
  { name: "Not Yet Scheduled", value: "Not Yet Scheduled" },
  { name: "Interview Scheduled", value: "Interview Scheduled" },
  { name: "No Show", value: "No Show" },
  { name: "Canceled", value: "Canceled" },
  { name: "Assessment/Test Scheduled", value: "Assessment/Test Scheduled" },
  { name: "Offer Discussion", value: "Offer Discussion" },
];

const interviewResultList = [
  { name: "Pending", value: "Pending" },
  { name: "Offer Extended", value: "Offer Extended" },
  { name: "Accepted Offer", value: "Accepted Offer" },
  { name: "Rejected", value: "Rejected" },
  { name: "Declined Offer", value: "Declined Offer" },
];
