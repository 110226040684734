import { CustomNormalTextTypeCell } from "@components/Cells";

export const costCodeColumn = [
  {
    field: "lv_0_name",
    headerName: "Lv.0",
    width: 200,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_1_name",
    headerName: "Lv.1",
    width: 200,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_2_name",
    headerName: "Lv.2",
    width: 200,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "code",
    headerName: "Cost Code",
    width: 150,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];
