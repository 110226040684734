import React from "react";

const ExpandIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.17375 2.1777L5.9348 1.99952C6.13909 1.98904 6.29624 2.14626 6.28577 2.35064C6.27529 2.55502 6.10243 2.72796 5.89814 2.73844L3.36807 2.85898L7.40675 6.89948C7.53771 7.0305 7.52723 7.24536 7.3858 7.38686C7.24436 7.52836 7.02436 7.53884 6.89864 7.40782L2.85996 3.36732L2.73948 5.90377C2.729 6.10291 2.55614 6.28109 2.35185 6.28633C2.14756 6.29682 1.99041 6.1396 2.00089 5.93521L2.17899 2.17246L2.17375 2.1777Z"
        fill="#344054"
      />
      <path
        d="M17.9944 5.93521C18.0048 6.1396 17.8477 6.29682 17.6434 6.28633C17.4391 6.28109 17.2663 6.10291 17.2558 5.90377L17.1353 3.36732L13.0966 7.40782C12.9657 7.53884 12.7509 7.52836 12.6095 7.38686C12.468 7.24536 12.4576 7.02526 12.5885 6.89948L16.6272 2.85898L14.0971 2.73844C13.8928 2.72796 13.72 2.55502 13.7095 2.35064C13.699 2.14626 13.8562 1.98904 14.0605 1.99952L17.8215 2.1777L17.9996 5.94045L17.9944 5.93521Z"
        fill="#344054"
      />
      <path
        d="M2.00524 14.0645C1.99476 13.8602 2.15191 13.7029 2.3562 13.7134C2.56049 13.7187 2.73335 13.8968 2.74383 14.096L2.86431 16.6324L6.90299 12.5919C7.03395 12.4609 7.24871 12.4714 7.39015 12.6129C7.53158 12.7544 7.54206 12.9745 7.4111 13.1003L3.37242 17.1408L5.90249 17.2613C6.10678 17.2718 6.27964 17.4447 6.29012 17.6491C6.30059 17.8535 6.14345 18.0107 5.93916 18.0002L2.1781 17.822L2 14.0593L2.00524 14.0645Z"
        fill="#344054"
      />
      <path
        d="M17.8214 17.822L14.0604 18.0002C13.8561 18.0107 13.6989 17.8535 13.7094 17.6491C13.7199 17.4447 13.8927 17.2718 14.097 17.2613L16.6271 17.1408L12.5884 13.1003C12.4627 12.9692 12.4732 12.7544 12.6146 12.6129C12.756 12.4714 12.976 12.4609 13.1018 12.5919L17.1404 16.6324L17.2609 14.096C17.2714 13.8968 17.4443 13.7187 17.6485 13.7134C17.8528 13.7029 18.01 13.8602 17.9995 14.0645L17.8214 17.8273V17.822Z"
        fill="#344054"
      />
    </svg>
  );
};

export default ExpandIcon;
