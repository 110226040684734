import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import ModalBody from "@components/ModalBody";
import { createUserSelectColumn } from "./Column";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const WorkPolicyUserSelectModal = ({
  title = "",
  open = true,
  onClose = () => {},
  tableRow = [],
  initialSelectList,
  onConfirm = () => {},
  disabled = false,
}) => {
  const [selectModel, setSelectModel] = useState(initialSelectList);

  const handleSelectionChange = (selection) => {
    if (!disabled) {
      setSelectModel(selection);
    }
  };

  return (
    <ModalBody title={title} open={open} onClose={onClose}>
      <div
        className="work-policy-modal-contents-body"
        style={{ maxHeight: "500px", boxSizing: "border-box" }}
      >
        <div
          className="select-data-table-table-box"
          style={{ height: "100%", boxSizing: "border-box" }}
        >
          <DataGridPro
            rows={tableRow}
            columns={createUserSelectColumn()}
            checkboxSelection={true}
            rowSelectionModel={selectModel}
            onRowSelectionModelChange={handleSelectionChange}
            rowHeight={28}
            columnHeaderHeight={28}
            getRowClassName={(params) => {
              const { row = {} } = params;

              const { material_use } = row;

              if (material_use === 1) {
                return "already-selection-row";
              }
              return "";
            }}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
              "& .already-selection-row": {
                backgroundColor: "#F9FAFB",
                color: "#D0D5DD",
              },
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
          />
        </div>
      </div>
      {!disabled && (
        <div className="work-policy-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="work-policy-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm(selectModel);
            }}
            className="work-policy-modal-update-btn"
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      )}
    </ModalBody>
  );
};

export default WorkPolicyUserSelectModal;
