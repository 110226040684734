import React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import useCostTableHeight from "@/hooks/useCostTableHeight";

const ActivityLogTable = ({ rows, columns }) => {
  const { height } = useCostTableHeight(false, false);
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
        <div className="cost-menu-table-body">
          <DataGridPremium
            rows={rows}
            columns={columns}
            disableRowSelectionOnClick
            rowHeight={40}
            columnHeaderHeight={40}
            sx={{
              ...materialTableStyle,
              ...TableScrollbarStyle,
            }}
            hideFooter
            disableColumnPinning
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityLogTable;
