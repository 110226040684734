import React from "react";
import BudgetUsageTable from "./BudgetUsageTable";
import { inVoiceistColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";

const VendorInvoice = ({ budget_usage_list }) => {
  const { height } = useCostTableHeight(false, true, true);
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>
        <BudgetUsageTable rows={budget_usage_list} column={inVoiceistColumn} />
      </div>
    </div>
  );
};

export default VendorInvoice;
