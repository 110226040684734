import React, { useState, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserSetting } from "@atoms/userInfo";
import { TargetComment } from "@atoms/commnet";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { IconButton, Tabs, Tab, Box } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CommentSheet from "./CommentSheet";
import AttachmentSheet from "./AttachmentSheet";
import RecordActivityLogSheet from "./RecordActivityLogSheet";
import useDragEvent from "@hooks/useDragEvent";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import "./updateComment.scss";

const UpdateComment = () => {
  const [width, handleMouseDown] = useDragEvent(500);
  const { height } = useWindowDimensions();
  const [targetComment, setTargetComment] = useRecoilState(TargetComment);
  const userSetting = useRecoilValue(UserSetting);

  const [tabValue, setTabValue] = useState(0);

  const { value, parent_lead_value } = targetComment;

  const closeComment = () => {
    setTargetComment(null);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tableHeight = useMemo(() => {
    return height - 40 - 20 - 24 - 20 - 49 - 40;
  }, [height]);

  return (
    <div className="update-comment-total-body" style={{ width }}>
      <div className="update-comment-drag-bar" onMouseDown={handleMouseDown}>
        <DragIndicatorOutlinedIcon fontSize="small" />
      </div>
      <div className="update-comment-main-body" style={{ width: width - 20 }}>
        <IconButton
          className="update-comment-title-close"
          size="small"
          onClick={closeComment}
        >
          <CloseOutlinedIcon />
        </IconButton>

        <div className="update-comment-title-box">
          {parent_lead_value && (
            <div className="update-comment-title-parent">
              {parent_lead_value}
            </div>
          )}
          <div className="update-comment-title">{value}</div>
        </div>
        <div className="update-comment-contents-box">
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Update"
                  {...a11yProps(0)}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Attachment"
                  {...a11yProps(1)}
                  sx={{ textTransform: "none" }}
                />
                {userSetting.admin_user && (
                  <Tab
                    label="Activity Log"
                    {...a11yProps(2)}
                    sx={{ textTransform: "none" }}
                  />
                )}
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              <CommentSheet
                targetComment={targetComment}
                tableHeight={tableHeight}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <AttachmentSheet
                targetComment={targetComment}
                tableHeight={tableHeight}
              />
            </CustomTabPanel>
            {userSetting.admin_user && (
              <CustomTabPanel value={tabValue} index={2}>
                <RecordActivityLogSheet
                  targetComment={targetComment}
                  tableHeight={tableHeight}
                />
              </CustomTabPanel>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default UpdateComment;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      // style={index === 2 ? {} : { height: "100%" }}
    >
      {value === index && children}
    </div>
  );
}
