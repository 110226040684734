import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { WorkPolicyStatus } from "@/atoms/modalStatus";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import localizaedFormat from "dayjs/plugin/localizedFormat";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomCheckBox from "@components/CustomCheckBox";
import CustomTimePicker from "@components/CustomTimePicker";
import WorkPolicyUserSelectModal from "./WorkPolicyUserSelectModal";
import PolicyModalBody from "../PolicyModalBody";
import PolicyModalBtn from "./PolicyModalBtn";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";

import "./dayOffSettingModals.scss";

dayjs.extend(weekday);
dayjs.extend(localizaedFormat);
dayjs.extend(customParseFormat);

const WorkPolicyModal = ({ refresh }) => {
  const {
    open,
    id,
    type = "create",
    initialPolicyName = "Policy Name",
    initialDayoffWeekData = [],
    initialWorkingTime = "08:00",
    initialAttendanceTime = "09:00",
    initialBreakTime = { start: "12:00", end: "13:00" },
    initialUsers = [],
    title,
    onClose,
  } = useRecoilValue(WorkPolicyStatus);

  const button_disabled = type === "view" || type === "" ? true : false;

  const query_work_policy_user_list = useQuery(
    "work_policy_user_list",
    () => {
      return query_fetch_data();
    },
    { initialData: { user_list: [] } }
  );

  const { data, isLoading } = query_work_policy_user_list;

  const { user_list } = data;

  const [policyName, setPolicyName] = useState(initialPolicyName);
  const [dayoffWeek, setDayoffWeek] = useState(
    initialDayoffWeekData.map((c, i) => {
      return {
        ...c,
        name: dayjs().weekday(i).format("ddd"),
      };
    })
  );
  const [workingTime, setWorkingTime] = useState(initialWorkingTime);
  const [attendanceTime, setAttendanceTime] = useState(initialAttendanceTime);
  const [breakTime, setBreakTime] = useState(initialBreakTime);
  const [useUsers, setUseUsers] = useState(initialUsers);
  const [userSelectModalStatus, setUserSelectModalStatus] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);

  const handleUserSelectModal = () => {
    setUserSelectModalStatus((prev) => !prev);
  };

  const handleDayoffWeek = (target_value) => {
    setDayoffWeek((prev) => {
      return prev.map((c) => {
        const { value, bool } = c;

        if (value === target_value) {
          return { ...c, bool: !bool };
        }

        return { ...c };
      });
    });
  };

  const handleTimeValue = (e, setValue) => {
    const value = e.format("HH:mm");

    setValue(value);
  };

  const handleBreakTime = (e, setValue, type) => {
    const value = e.format("HH:mm");

    let startValue;
    let endValue;

    if (type === "start") {
      startValue = dayjs(value, "HH:mm");
      endValue = dayjs(breakTime.end, "HH:mm");
    }

    if (type === "end") {
      startValue = dayjs(breakTime.start, "HH:mm");
      endValue = dayjs(value, "HH:mm");
    }

    if (endValue <= startValue) {
      create_alert_fn(
        "warning",
        "The break end time cannot be less than the break start time."
      );
      setValue((prev) => ({ ...prev, ...initialBreakTime }));
    } else {
      setValue((prev) => ({ ...prev, [type]: value }));
    }
  };

  const weekWorkingTime = useMemo(() => {
    const workingDayCount = dayoffWeek.filter((c) => c.bool).length;

    const [hour, min] = workingTime.split(":");

    const numHour = Number(hour);

    const numMin = Number(min) / 60;

    return workingDayCount * (numHour + numMin);
  }, [dayoffWeek, workingTime]);

  const selectUserList = (selectModel) => {
    setUseUsers(selectModel);
    handleUserSelectModal();
  };

  const createWorkPolicy = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const submitData = {
        policyName,
        dayoffWeek,
        workingTime,
        attendanceTime,
        breakTimeStart: breakTime.start,
        breakTimeEnd: breakTime.end,
        useUsers,
      };

      if (type === "update") {
        submitData.id = id;
      }

      const requestCreateWorkPolicy = await userPermAxios.post(
        `/dayoff/${type}_work_policy`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreateWorkPolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      refresh();

      if (msgStatus.status === "success") {
        onClose();
      }
    }
  };

  const saveWorkPolicy = () => {
    if (!dubleClick) {
      return;
    }

    let message;

    if (type === "create") {
      message = "Do you want to create a new Work Policy?";
    }

    if (type === "update") {
      message = "Do you want to update a Work Policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await createWorkPolicy();
      },
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={792}
      button_disabled={button_disabled}
      dubleClick={dubleClick}
      saveFn={saveWorkPolicy}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-contents-title">Working Cycle</div>
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-dayoff-week-box">
            <div className="work-policy-modal-detail-contents-title">
              Working Days
            </div>
            <div className="work-policy-modal-dayoff-week">
              {dayoffWeek.map((c) => {
                return (
                  <div className="work-policy-modal-check-box" key={c.value}>
                    <CustomCheckBox
                      checked={c.bool}
                      onChange={() => handleDayoffWeek(c.value)}
                      disabled={button_disabled}
                    />
                    <div className="work-policy-modal-check-box-label">
                      {c.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="work-policy-modal-time-picker-box">
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Working Time
                </div>
                <CustomTimePicker
                  value={dayjs(workingTime, "HH:mm")}
                  onChange={(e) => handleTimeValue(e, setWorkingTime)}
                  width="100%"
                  disabled={button_disabled}
                />
              </div>
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Attendance Time
                </div>
                <CustomTimePicker
                  value={dayjs(attendanceTime, "HH:mm")}
                  onChange={(e) => handleTimeValue(e, setAttendanceTime)}
                  width="100%"
                  disabled={button_disabled}
                />
              </div>
            </div>

            <div className="work-policy-modal-time-picker-box">
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Break Time Start
                </div>
                <CustomTimePicker
                  value={dayjs(breakTime.start, "HH:mm")}
                  onChange={(e) => handleBreakTime(e, setBreakTime, "start")}
                  width="100%"
                  disabled={button_disabled}
                />
              </div>

              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Break Time End
                </div>
                <CustomTimePicker
                  value={dayjs(breakTime.end, "HH:mm")}
                  onChange={(e) => handleBreakTime(e, setBreakTime, "end")}
                  width="100%"
                  disabled={button_disabled}
                />
              </div>
            </div>
          </LocalizationProvider>

          <div className="work-policy-modal-another-box">
            <div className="work-policy-modal-another">
              Weekly Working Hour: {weekWorkingTime}
            </div>
          </div>

          <div className="work-policy-modal-another-box">
            <div className="work-policy-modal-another">
              Policy Apply Member: {useUsers.length}
            </div>
            <PolicyModalBtn
              onClick={handleUserSelectModal}
              label="Assigned User"
              width={320}
            />
          </div>
        </div>
      </div>

      {userSelectModalStatus && (
        <WorkPolicyUserSelectModal
          title="Work Policy User List"
          open={userSelectModalStatus}
          onClose={handleUserSelectModal}
          tableRow={user_list}
          initialSelectList={useUsers}
          onConfirm={selectUserList}
          disabled={button_disabled}
        />
      )}
    </PolicyModalBody>
  );
};

export default WorkPolicyModal;

const query_fetch_data = async () => {
  const result = { user_list: [] };

  try {
    const requestUserList = await userPermAxios.get(
      "/dayoff/get_work_policy_user_list"
    );

    const { success, data } = requestUserList;

    if (success) {
      result.user_list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
