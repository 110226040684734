import React, { useState, useRef } from "react";
import { Button } from "@mui/material";
import ModalBody from "@components/ModalBody";
import EditPencilIcon from "@components/Icons/EditPencilIcon";
// import useExternalClick from "@/hooks/useExternalClick";

const PolicyModalBody = ({
  policyName,
  setPolicyName,
  children,
  open,
  title,
  onClose,
  disableEscapeKeyDown = false,
  width = 792,
  button_disabled = false,
  save_btn_disabled = false,
  saveFn = () => {},
}) => {
  const policyInputBoxRef = useRef(null);
  const [policyNameToggle, setPolicyNameToggle] = useState(false);
  const [policyNameValue, setPolicyNameValue] = useState(policyName);

  const handlePolicyName = (e) => {
    const value = e.target.value;

    setPolicyNameValue(value);
  };

  const handlePolicyNameConfirm = (e) => {
    if (e.key === "Enter") {
      setPolicyName(policyNameValue);
      setPolicyNameToggle(false);
    }

    if (e.key === "Escape") {
      setPolicyNameValue(policyName);
      setPolicyNameToggle(false);
    }
  };

  // const externalFn = () => {};

  // useExternalClick(policyInputBoxRef, () => {
  //   setPolicyName(policyNameValue);
  //   setPolicyNameToggle(false);
  // });

  return (
    <ModalBody
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <div className="work-policy-modal-contents-body" style={{ width }}>
        <div ref={policyInputBoxRef} style={{ width: "100%" }}>
          {!policyNameToggle ? (
            <div
              className="work-policy-modal-name-box"
              onDoubleClick={() => setPolicyNameToggle(true)}
            >
              <div className="work-policy-modal-name">{policyName}</div>
              <EditPencilIcon />
            </div>
          ) : (
            <input
              className="work-policy-modal-name-input"
              value={policyNameValue}
              onKeyDown={handlePolicyNameConfirm}
              onChange={handlePolicyName}
            />
          )}
        </div>

        {children}
      </div>

      {!button_disabled && (
        <div className="work-policy-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={onClose}
            className="work-policy-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveFn();
            }}
            className="work-policy-modal-update-btn"
            disabled={save_btn_disabled}
          >
            Save
          </Button>
        </div>
      )}
    </ModalBody>
  );
};

export default PolicyModalBody;
