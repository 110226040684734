import React, { useMemo, useState } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  ApprovalLineModalStatus,
  MaterialApprovalRequestModalStatus,
} from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import LoadingModal from "@components/LoadingModal";
import LabelTextArea from "@components/LabelTextArea";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const MaterialApprovalRequest = ({ approval_user_list = [] }) => {
  const { open, id, onClose, table_name } = useRecoilValue(
    MaterialApprovalRequestModalStatus
  );

  const [approvalName, setApprovalName] = useState(
    `Add ${modalTitle[table_name] ?? ""} Description`
  );

  const [remark, setRemark] = useState("");
  const [attachmentList, setAttachmentList] = useState(null);
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });
  const [dubleClick, setDubleClick] = useState(true);
  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const createMatApproval = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const formData = new FormData();

      for (const file in attachmentList) {
        if (file !== "length" && file !== "item") {
          const targetFile = attachmentList[file];

          formData.append(`files`, targetFile);
        }
      }

      formData.append(
        "additionalData",
        JSON.stringify({
          id,
          approvalName,
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        })
      );

      const requestCreateMaterialApproval = await userPermAxios.post(
        `/${table_name}/create_material_approval`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateMaterialApproval;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }
      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        onClose();
      }
    }
  };

  const confirmMatApproval = () => {
    if (!dubleClick) {
      return;
    }

    let message = `Do you want to request a material approval?`;

    return create_alert_fn(
      "info",
      message,
      async () => {
        createMatApproval();
      },
      true
    );
  };

  const button_disabled = useMemo(() => {
    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    return false;
  }, [approvalLineData, approvalName]);

  return (
    <>
      {!dubleClick && <LoadingModal />}
      <PolicyModalBody
        open={open}
        onClose={onClose}
        title={`${modalTitle?.[table_name] ?? ""} Approval Request`}
        policyName={approvalName}
        setPolicyName={setApprovalName}
        disableEscapeKeyDown={false}
        saveFn={confirmMatApproval}
        save_btn_disabled={button_disabled}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>
            <div className="work-policy-modal-swicth-btn-box">
              <div className="work-policy-modal-detail-contents-title">
                {`Attachment (${attachmentList ? attachmentList.length : 0})`}
              </div>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                sx={{ fontSize: 14, textTransform: "none", width: 300 }}
              >
                Upload File
                <input
                  type="file"
                  multiple
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    const fileCount = e.target.files.length;
                    if (fileCount > 0) {
                      setAttachmentList(e.target.files);
                    } else {
                      setAttachmentList(null);
                    }
                  }}
                />
              </Button>
            </div>
            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default MaterialApprovalRequest;

const modalTitle = {
  purchase_request: "Purchase Requisition",
  request_for_quotation: "Request for Quotation",
  purchase_order: "Purchase Order",
  packing_list: "Packing List",
  material_release: "Material Release",
  product_export: "Product Export",
  inventory_quantity_control: "Inventory Quantity Control",
};
