import React from "react";

const CarbonCopyCards = ({ carbon_copy_list = sampleData }) => {
  return (
    <>
      {carbon_copy_list.map((c) => {
        const { username, id } = c;
        return (
          <div className="carbon-copy-card-body" key={id}>
            <div className="carbon-copy-card-name">{username}</div>
          </div>
        );
      })}
    </>
  );
};

export default CarbonCopyCards;

const sampleData = [
  { id: 0, username: "Name" },
  { id: 1, username: "Name" },
];
