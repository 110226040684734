import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPageBody from "@pages/login/LoginPageBody";
import LoginTitle from "@components/LoginTitle";
import PwInput from "@components/PwInput";
import HorizontalDashLine from "@components/HorizontalDashLine";
import LoginPageBottomBox from "@components/LoginPageBottomBox";
import CustomCheckBox from "@components/CustomCheckBox";

import {
  // validateEmail,
  validatePassword,
} from "@utils/valueRegex";

import { defaultAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";

const ResetPWPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [pw, setPW] = useState("");
  const [repeatPw, setRepeartPW] = useState("");
  const [checkValue, setCheckValue] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);

  const token = new URLSearchParams(search).get("token");
  // const email = new URLSearchParams(search).get("username");

  // const emailCheck = useMemo(() => {
  //   if (email.length === 0) {
  //     return null;
  //   }

  //   return validateEmail(email);
  // }, [email]);

  const pwCheck = useMemo(() => {
    if (pw.length === 0) {
      return null;
    }

    return validatePassword(pw);
  }, [pw]);

  const repeatPwCheck = useMemo(() => {
    if (repeatPw.length === 0) {
      return null;
    }

    if (repeatPw === pw) {
      return null;
    }

    return "The passwords do not match.";
  }, [repeatPw, pw]);

  const buttonDisabled = useMemo(() => {
    if (
      checkValue &&
      pw.length > 0 &&
      repeatPw.length > 0 &&
      pwCheck === null &&
      repeatPwCheck === null
    ) {
      return false;
    }

    return true;
  }, [checkValue, pw, repeatPw, pwCheck, repeatPwCheck]);

  const resetPassword = async () => {
    if (!dubleClick) return;
    setDubleClick(false);

    const result = {
      success: true,
      message: "",
      fn: () => {
        setDubleClick(true);
        return null;
      },
    };

    try {
      const requestResetPw = await defaultAxios
        .post("/new-password", {
          password: pw,
          token,
        })
        .then((data) => {
          return { ...data.data, status: data.status };
        })
        .catch((err) => {
          const { response } = err;

          const { status, data } = response;

          return {
            ...data,
            status,
          };
        });

      const { success, message } = requestResetPw;

      if (success) {
        result.fn = () => {
          return;
        };
      }

      result.success = success;
      result.message = message;
    } catch (err) {
      result.success = false;
      result.message = "error";
    } finally {
      setDubleClick(true);
      if (result.success) {
        navigate("/login");
      }

      return create_alert_fn(
        result.success ? "success" : "warning",
        result.message,
        result.fn
      );
    }
  };

  return (
    <LoginPageBody>
      <LoginTitle title="Setup New Password" sub_title={null} />

      <div className="login-page-contents-box">
        <div className="login-page-contents-top">
          <PwInput
            label="Password"
            value={pw}
            setValue={setPW}
            placeholder="Password"
            error={pw.length === 0 || pwCheck === null ? false : true}
            helperText={
              pw.length === 0 || pwCheck === null
                ? "Use 8 or more characters with a mix of letters, numbers & symbols."
                : pwCheck
            }
          />

          <PwInput
            label=""
            value={repeatPw}
            setValue={setRepeartPW}
            placeholder="Repeat Password"
            error={repeatPwCheck === null ? false : true}
            helperText={repeatPwCheck}
          />

          <HorizontalDashLine />

          <div className="signup-page-check-button-box">
            <CustomCheckBox
              checked={checkValue}
              onChange={() => setCheckValue((prev) => !prev)}
            />

            <div className="includes-link-text-box">
              <div className="normal-text">I Agree to our </div>
              <div className="under-line-text">Terms of use</div>
              <div className="normal-text">and</div>
              <div className="under-line-text">Privacy Policy</div>
            </div>
          </div>
        </div>
        <LoginPageBottomBox
          button_label="Submit"
          button_fn={resetPassword}
          button_disabled={buttonDisabled}
          first_text="Have you already reset the password?"
          second_text="Sign in"
          second_text_fn={() => {
            return navigate("/login");
          }}
        />
      </div>
    </LoginPageBody>
  );
};

export default ResetPWPage;
