import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.005 3.75C9.61806 3.75 9.30556 4.06262 9.30556 4.44968L9.3006 9.29784H4.44941C4.0625 9.29784 3.75 9.61046 3.75 9.99752C3.75 10.3846 4.0625 10.6972 4.44941 10.6972L9.3006 10.6972L9.3006 15.5503C9.3006 15.9374 9.6131 16.25 10 16.25C10.3869 16.25 10.6994 15.9374 10.6994 15.5503V10.6972H15.5506C15.9375 10.6972 16.25 10.3846 16.25 9.99752C16.25 9.61046 15.9375 9.29784 15.5506 9.29784L10.6994 9.29784V4.44472C10.6994 4.06759 10.3819 3.75 10.005 3.75Z"
        fill="#344054"
      />
    </svg>
  );
};

export default PlusIcon;
