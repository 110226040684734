import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Btn from "@/components/CustomIconBtn";
import MaterialItemDetailModal from "./MaterialItemDetailModal";

import {
  purchase_request_column,
  purchase_request_lineitems_column,
  request_for_quotation_column,
  request_for_quotation_lineitems_column,
  purchase_order_column,
  purchase_order_lineitems_column,
  packing_list_column,
  packing_list_lineitems_column,
  material_release_column,
  material_release_lineitems_column,
  product_export_column,
  product_export_lineitems_column,
  inventory_quantity_control_column,
  inventory_quantity_control_lineitems_column,
} from "./Columns";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const MaterialItem = ({ items, table_name, type }) => {
  const { rows = [], detail_rows = [] } = items;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalStatus = () => {
    setModalOpen((prev) => !prev);
  };

  const {
    name = "",
    upper_column = [],
    line_column = [],
  } = modalTitle?.[table_name] ?? [];

  return (
    <div className="cost-approval-modal-contents-body">
      <div className="cost-approval-modal-contents-title-box">
        <div className="cost-approval-modal-contents-title">
          {`${name} Approval`}
        </div>
        <Btn
          width={100}
          label="View Detail"
          borderColor="#00A6FF"
          textColor="#0080DB"
          onClick={handleModalStatus}
        />
      </div>
      <div className="cost-approval-modal-contents-box">
        <DataGridPro
          rows={rows}
          columns={upper_column}
          rowHeight={28}
          columnHeaderHeight={28}
          sx={{
            ...materialTableStyle,
            ...TableScrollbarStyle,
          }}
          hideFooter
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
        />
      </div>
      {modalOpen && (
        <MaterialItemDetailModal
          title={`${name} Detail`}
          open={modalOpen}
          onClose={handleModalStatus}
          rows={detail_rows}
          columns={line_column}
        />
      )}
    </div>
  );
};

export default MaterialItem;

const modalTitle = {
  purchase_request: {
    name: "Purchase Requisition",
    upper_column: purchase_request_column,
    line_column: purchase_request_lineitems_column,
  },
  request_for_quotation: {
    name: "Request for Quotation",
    upper_column: request_for_quotation_column,
    line_column: request_for_quotation_lineitems_column,
  },
  purchase_order: {
    name: "Purchase Order",
    upper_column: purchase_order_column,
    line_column: purchase_order_lineitems_column,
  },
  packing_list: {
    name: "Packing List",
    upper_column: packing_list_column,
    line_column: packing_list_lineitems_column,
  },
  material_receive: {
    name: "Material Received",
  },
  material_release: {
    name: "Material Release",
    upper_column: material_release_column,
    line_column: material_release_lineitems_column,
  },
  product_export: {
    name: "Product Export",
    upper_column: product_export_column,
    line_column: product_export_lineitems_column,
  },
  inventory_quantity_control: {
    name: "Inventory Quantity Control",
    upper_column: inventory_quantity_control_column,
    line_column: inventory_quantity_control_lineitems_column,
  },
};
