import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle } from "@styles/muiThemes";
import { costCodeColumn } from "./Column";
import "./costCode.scss";

const CostCodeTable = ({ row = [] }) => {
  return (
    <div className="cost-code-table-body">
      <DataGridPro
        rows={row}
        columns={costCodeColumn}
        initialState={{}}
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
        }}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        hideFooter
      />
    </div>
  );
};

export default CostCodeTable;
