import React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const ManpowerRequestTable = ({
  rows = [],
  column = [],
  selectionModelUse = false,
  selectionModel = [],
  handleSelection = () => {},
  rowHeight = 40,
}) => {
  return (
    <div className="cost-menu-table-body" style={{ height: "100%" }}>
      <DataGridPremium
        rows={rows}
        columns={column}
        rowHeight={rowHeight}
        columnHeaderHeight={rowHeight}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        checkboxSelection={selectionModelUse}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelection}
      />
    </div>
  );
};

export default ManpowerRequestTable;
