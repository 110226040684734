import React from "react";
import AnnualLeaveTable from "./AnnualLeaveTable";
import { annualLeaveUpperColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";
import "./annualLeaveLog.scss";

const AnnualLeaveLog = ({ annual_leave_row = [], startMonth, endMonth }) => {
  const { height } = useCostTableHeight(false, false);
  return (
    <div className="cost-menu-body">
      {height > 0 && (
        <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
          <AnnualLeaveTable
            rows={annual_leave_row}
            column={annualLeaveUpperColumn}
            startMonth={startMonth}
            endMonth={endMonth}
          />
        </div>
      )}
    </div>
  );
};

export default AnnualLeaveLog;
