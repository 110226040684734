import React from "react";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import {
  CustomNormalTextTypeCell,
  CustomCheckBoxCell,
  CustomEditEmailTypeCell,
  CustomEditSelectCell,
  CustomEditNormalTextTypeCell,
  CustomEditSelectListCell,
  CustomSelectListCell,
  CustomStageTypeCell,
  LineDeleteBtnCell,
  CustomDetailPanelToggle,
  CustomPermProjectAddUserToggle,
  CustomAmountTypeCell,
  CustomDateTypeCell,
  CustomPermMenuAddUserToggle,
  CustomPermUserAddMenuToggle,
} from "@components/Cells";
import { salesPriorityColorList } from "@utils/colorList";
import { timeZoneList, timeFormatList, countries } from "@utils/userTimeZone";

export const createLoginActivityLogColumn = () => {
  return [
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
      minWidth: 300,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 300,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "ip_address",
      headerName: "IP Address",
      flex: 1,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "type",
      headerName: "Action",
      flex: 1,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "occurred_at",
      headerName: "Action Time",
      flex: 1,
      data_type: "date",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
    },
  ];
};
