import React from "react";

const JobFnSettingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M36.25 20C36.25 18.6875 32.7917 17.8542 32.4792 16.6458C32.1458 15.3958 34.7083 12.9583 34.0833 11.8542C33.4375 10.75 30.0417 11.75 29.1458 10.8542C28.25 9.95833 29.25 6.5625 28.1458 5.91667C27.0625 5.29167 24.6042 7.85417 23.3542 7.52083C22.1458 7.20833 21.3125 3.75 20 3.75C18.6875 3.75 17.8542 7.20833 16.6458 7.52083C15.3958 7.85417 12.9583 5.29167 11.8542 5.91667C10.75 6.5625 11.75 9.95833 10.8542 10.8542C9.95833 11.75 6.5625 10.75 5.91667 11.8542C5.29167 12.9375 7.85417 15.3958 7.52083 16.6458C7.20833 17.8542 3.75 18.6875 3.75 20C3.75 21.3125 7.20833 22.1458 7.52083 23.3542C7.85417 24.6042 5.29167 27.0417 5.91667 28.1458C6.5625 29.25 9.95833 28.25 10.8542 29.1458C11.75 30.0417 10.75 33.4375 11.8542 34.0833C12.9375 34.7083 15.3958 32.1458 16.6458 32.4792C17.8542 32.7917 18.6875 36.25 20 36.25C21.3125 36.25 22.1458 32.7917 23.3542 32.4792C24.6042 32.1458 27.0417 34.7083 28.1458 34.0833C29.25 33.4375 28.25 30.0417 29.1458 29.1458C30.0417 28.25 33.4375 29.25 34.0833 28.1458C34.7083 27.0625 32.1458 24.6042 32.4792 23.3542C32.7917 22.1458 36.25 21.3125 36.25 20ZM27.8542 15.75L17.6875 25.9167C17.4583 26.1458 17.1667 26.25 16.8542 26.25C16.8125 26.25 16.7917 26.25 16.75 26.25C16.4167 26.2292 16.1042 26.0417 15.8958 25.7708L11.9792 20.3125C11.6042 19.7917 11.7292 19.0625 12.25 18.6875C12.7708 18.3125 13.5 18.4375 13.875 18.9583L16.9792 23.2917L26.1667 14.1042C26.625 13.6458 27.375 13.6458 27.8333 14.1042C28.2917 14.5625 28.2917 15.3125 27.8333 15.7708L27.8542 15.75Z"
        fill="#344054"
      />
      <path
        d="M17.0208 23.2708L13.9167 18.9375C13.5417 18.4167 12.8125 18.2917 12.2917 18.6667C11.7708 19.0417 11.6458 19.7708 12.0208 20.2917L15.9375 25.75C16.1458 26.0208 16.4583 26.2083 16.7917 26.2292C16.8333 26.2292 16.8542 26.2292 16.8958 26.2292C17.2083 26.2292 17.5 26.1042 17.7292 25.8958L27.8958 15.7292C28.3542 15.2708 28.3542 14.5208 27.8958 14.0625C27.4375 13.6042 26.6875 13.6042 26.2292 14.0625L17.0208 23.2708Z"
        fill="white"
      />
    </svg>
  );
};

export default JobFnSettingIcon;
