import React from "react";

const IncreaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5894 10.1346C11.7983 9.92569 11.7983 9.58889 11.5894 9.37999L8.04665 5.83722C7.88039 5.67096 7.6118 5.67096 7.44553 5.83722L3.90277 9.37999C3.69387 9.58889 3.69387 9.92569 3.90277 10.1346C4.11167 10.3435 4.44847 10.3435 4.65736 10.1346L7.74823 7.04799L10.8391 10.1388C11.0437 10.3435 11.3848 10.3435 11.5894 10.1346Z"
        fill="#344054"
      />
    </svg>
  );
};

export default IncreaseIcon;
