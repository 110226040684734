import React from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { ApprovalLineModalStatus } from "@/atoms/modalStatus";
import ApprovalLineModal from "./ApprovalLineModal";
import "./hrBigCalendar.scss";

const ApprovalLineBtn = ({
  approvalLineData = {
    approval_list: [],
    carbon_copy_list: [],
  },
  confirmApprovalLineData,
  approval_user_list,
}) => {
  const [approvalLineModalStatus, setApprovalLineModalStatus] = useRecoilState(
    ApprovalLineModalStatus
  );
  const closeModal = useResetRecoilState(ApprovalLineModalStatus);
  const openModal = () => {
    setApprovalLineModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: closeModal,
        onConfirm: confirmApprovalLineData,
        user_list: approval_user_list,
      };
    });
  };

  return (
    <div className="approval-line-btn-body">
      <div className="approval-line-btn-label">
        {`Select Approval Line・Carbon Copy (approval: ${approvalLineData.approval_list.length}, carbon copy: ${approvalLineData.carbon_copy_list.length})`}
      </div>
      <div className="approval-line-btn" onClick={openModal}>
        <BtnIcon />
      </div>
      {approvalLineModalStatus.open && (
        <ApprovalLineModal
          approvalLineData={approvalLineData}
          confirmApprovalLineData={confirmApprovalLineData}
        />
      )}
    </div>
  );
};

export default ApprovalLineBtn;

const BtnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M10.8008 17.8353C11.1141 18.1486 11.6193 18.1486 11.9327 17.8353L17.2468 12.5212C17.4962 12.2718 17.4962 11.8689 17.2468 11.6195L11.9327 6.30532C11.6193 5.99198 11.1141 5.99198 10.8008 6.30532C10.4874 6.61867 10.4874 7.12387 10.8008 7.43722L15.4307 12.0735L10.7944 16.7098C10.4874 17.0168 10.4874 17.5283 10.8008 17.8353Z"
        fill="#344054"
      />
    </svg>
  );
};
