import React from "react";
import { contractAmountColumn, budgetChangeListColumn } from "./Column";
import BudgetChangeTable from "./BudgetChangeTable";
import useCostTableHeight from "@/hooks/useCostTableHeight";
import "./budgetChange.scss";

const BudgetChange = ({ contract_amount = [], budget_change_list = [] }) => {
  const { height } = useCostTableHeight(true, true);
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-contract-amount-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Summary</div>
        </div>
        <BudgetChangeTable
          rows={contract_amount}
          column={contractAmountColumn}
        />
      </div>
      <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>
        <BudgetChangeTable
          rows={budget_change_list}
          column={budgetChangeListColumn}
        />
      </div>
    </div>
  );
};

export default BudgetChange;
