import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import { TargetComment } from "@atoms/commnet";
import { UserTargetProject, UserSetting } from "@atoms/userInfo";
import {
  MaterialApprovalRequestModalStatus,
  MaterialApprovalModalStatus,
} from "@/atoms/modalStatus";
import { useQuery } from "react-query";
import _ from "lodash";
import { IconButton } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MaterialTable from "@components/materialTable/MaterialTable";
import SelectRowControlMenu from "@components/materialTable/SelectRowControlMenu";
import UpdateComment from "@components/comment/UpdateComment";
import IconInput from "@/components/IconInput";
import ExcelExportBtn from "@/components/ExcelImportBtn/ExcelExportBtn";
import SalesTable from "@/components/materialTable/SalesTable";
import MaterialApprovalRequest from "@/components/materialApprovalRequest/MaterialApprovalRequest";
import MaterialApprovalModal from "@/components/approvalManagement/Modal/MaterialApprovalModal";
import DayPicker from "@/components/DatePicker";
import useRefreshReactQuery from "@hooks/useRefreshReactQuery";
import { groupColorList } from "@utils/colorList";
import {
  createTableColumn,
  createTableLineColumn,
  materialTablePinnedColumn,
  materialTableSearchItems,
} from "@utils/tableInitialData";
import { userPermAxios } from "@utils/customAxios";

import "../menu.scss";
import dayjs from "dayjs";

const MaterialMenu = () => {
  const { menu } = useParams();
  const userTargetProject = useRecoilValue(UserTargetProject);
  const userSetting = useRecoilValue(UserSetting);

  const admin_user = userSetting?.admin_user;
  const approver = userSetting?.approver;

  const { open: matApprovalReqModalOpen } = useRecoilValue(
    MaterialApprovalRequestModalStatus
  );
  const { open: matApprovalModalOpen } = useRecoilValue(
    MaterialApprovalModalStatus
  );

  const [targetComment, setTargetComment] = useRecoilState(TargetComment);
  const [selectionModel, setSelectionModel] = useState(null);
  const [tableRefs, setTableRefs] = useState({});
  const [soketData, setSoketData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [groupRow, setGroupRow] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "months"));
  const [endDate, setEndDate] = useState(dayjs());
  const webSocket = useRef(null);

  const query_key = useMemo(
    () => [
      "table_data",
      menu,
      userTargetProject,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD"),
    ],
    [menu, userTargetProject, startDate, endDate]
  );

  const query_approval_user_list_data = useQuery(
    "approval_user_list_data",
    () => {
      return query_approval_fetch_data();
    }
  );

  const { data: userListData = {}, isLoading: userListLoading } =
    query_approval_user_list_data;

  const { list: userList = [] } = userListData;

  const [refresh] = useRefreshReactQuery(query_key);

  const query_table_data = useQuery(
    query_key,
    () =>
      query_fetch_data(
        menu,
        userTargetProject,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      ),
    {
      initialData: {
        rows: null,
        group_rows: {},
        lineitem_use: false,
        user_perm: null,
      },
      onSuccess: (data) => {
        setIsLoading(false);
        setGroupRow(data.group_rows);
      },
    }
  );

  const { lineitem_use, user_perm } = query_table_data.data;

  useEffect(() => {
    setSelectionModel(null);
  }, [userTargetProject]);

  useEffect(() => {
    setGroupRow({});
    setSelectionModel(null);
    setTableRefs(null);
    setTargetComment(null);
    setSoketData(null);
    setIsLoading(true);
    // eslint-disable-next-line
  }, [menu]);

  useEffect(() => {
    setSelectionModel(null);
  }, [searchValue]);

  const reFreshTable = () => {
    setGroupRow({});
    setSelectionModel(null);
    setTableRefs(null);
    setTargetComment(null);
    setSoketData(null);
    setIsLoading(true);
    refresh();
  };

  const selectControlMenuBool = useMemo(() => {
    if (!selectionModel) {
      return { status: false, count: 0 };
    }

    const total = [];

    for (const item in selectionModel) {
      total.push(...selectionModel[item]);
    }

    if (total.length === 0) {
      return { status: false, count: 0 };
    } else {
      return { status: true, count: total.length };
    }
  }, [selectionModel]);

  useEffect(() => {
    if (Object.keys(groupRow).length > 0) {
      const newRefs = {};

      Object.keys(groupRow).forEach((c) => {
        newRefs[c] = React.createRef();
      });

      setTableRefs(newRefs);
    }
  }, [groupRow, menu]);

  useEffect(() => {
    webSocket.current = new WebSocket(
      `${process.env.NODE_ENV === "development" ? "ws" : "wss"}://${
        process.env.REACT_APP_SOKET_URI
      }`
    );

    if (webSocket) {
      webSocket.current.onopen = () => {
        console.log("WebSocket 연결!");
      };

      webSocket.current.onclose = (error) => {};

      webSocket.current.onerror = (error) => {
        console.log("WebSocket 실패!", error);
      };

      webSocket.current.onmessage = (event) => {
        const { data } = event;

        const dataParse = JSON.parse(data);

        const { action, tablename } = dataParse;

        const { group_name, addRow } = dataParse.data;

        const api = tableRefs[group_name]?.current?.apiRef;

        setSoketData(dataParse);

        if (menu === tablename) {
          if (action === "delete-row" || action === "update-cell-group") {
            reFreshTable();
          }

          if (action === "copy-row") {
            const group = _(dataParse.data)
              .groupBy("group_name")
              .map((objs, keys) => {
                return { objs, keys };
              })
              .value();

            group.forEach((c) => {
              const { objs, keys } = c;

              const group_api = tableRefs?.[keys]?.current?.apiRef;

              if (group_api) {
                group_api.updateRows(objs);
              }
            });
          }

          if (action === "new-material" && tablename === "material_master") {
            const material_api =
              tableRefs?.["Material Master"]?.current?.apiRef;
            if (material_api) {
              material_api.updateRows([{ ...addRow }]);
            }
          }

          if (api && action === "update-job-number") {
            api.updateRows([{ ...addRow }]);
          }

          if (api && action === "picker-update-cell") {
            const updateData = dataParse?.data?.row_data ?? [];
            const updateData2 = updateData?.[0] ?? {};

            api.updateRows([{ ...updateData2 }]);
          }

          if (api && (action === "qr-new-row" || action === "row-advance")) {
            api.updateRows([{ ...dataParse.data }]);
          }

          if (api && !tablename.includes("lineitems")) {
            if (action === "new-row") {
              api.updateRows([{ ...addRow }]);
            }

            if (action === "update-cell") {
              api.updateRows([{ ...addRow }]);
            }
          }
        }
      };
      return () => {
        webSocket.current?.close();
      };
    }
    // eslint-disable-next-line
  }, [webSocket, tableRefs, refresh]);

  const columns = useMemo(() => {
    const statusPerm = admin_user ? true : approver ? true : false;

    const table_column = createTableColumn(
      menu,
      query_key,
      statusPerm,
      admin_user,
      user_perm
    );

    return table_column.filter((c) => {
      const { field } = c;

      if (admin_user) {
        return true;
      }

      if (!user_perm || user_perm === "read") {
        if (
          field === "data_consolidation_cell" ||
          field === "qr_code_create_cell"
        ) {
          return false;
        }
      }

      return true;
    });
  }, [menu, query_key, admin_user, user_perm, approver]);

  const upperTablePinnedColumns = useMemo(() => {
    return materialTablePinnedColumn(menu);
  }, [menu]);

  const line_columns = useMemo(() => {
    const tableLineColumn = createTableLineColumn(menu, query_key);

    return tableLineColumn.filter((c) => {
      const { field } = c;

      if (admin_user) {
        return true;
      }

      if (!user_perm || user_perm === "read") {
        if (field === "delete_cell") {
          return false;
        }
      }

      return true;
    });
  }, [menu, query_key, admin_user, user_perm]);

  const createNewTableGroup = () => {
    const groupList = Object.keys(groupRow);

    let newGroupName;

    if (groupList.includes("New Group")) {
      const findGroupName = groupList.find((c) => c.includes("New Group"));

      newGroupName = findGroupName + 1;
    } else {
      newGroupName = "New Group";
    }

    setGroupRow((prev) => ({ [newGroupName]: [], ...prev }));
  };

  const tableGroupHeight = useMemo(() => {
    if (menu === "material_master") {
      return "calc(100vh - 104px - 48px - 8px - 16px)";
    }

    return "calc(100vh - 104px - 48px - 8px - 40px - 32px - 10px)";
  }, [menu]);

  const addGroupBtnBool = useMemo(() => {
    if (menu === "material_master") {
      return false;
    }

    if (admin_user) {
      return true;
    }

    if (!user_perm || user_perm === "read") {
      return false;
    }

    return true;
  }, [admin_user, user_perm, menu]);

  const filterItems = useMemo(() => {
    return materialTableSearchItems(menu, searchValue);
  }, [menu, searchValue]);

  if (isLoading || userListLoading) {
    return null;
  }

  return (
    <div className="memu-total-body">
      {addGroupBtnBool && (
        <div className="menu-button-box">
          <div className="menu-control-botton-group">
            <IconInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search Here"
              rightIcon={
                searchValue.length > 0 ? (
                  <IconButton onClick={() => setSearchValue("")}>
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                ) : null
              }
            />
          </div>
          <div className="menu-button-box-vertical-bar" />
          <IconButton
            className="menu-new-group-botton"
            onClick={createNewTableGroup}
          >
            <AddOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            Add New Group
          </IconButton>
          <div className="menu-button-box-vertical-bar" />
          <ExcelExportBtn table_name={menu} />
          {dateFilterMenuList.includes(menu) && (
            <>
              <div className="menu-button-box-vertical-bar" />
              <DayPicker
                value={startDate}
                width={190}
                onChange={(e) => setStartDate(e)}
              />
              <div>-</div>
              <DayPicker
                value={endDate}
                width={190}
                onChange={(e) => setEndDate(e)}
              />
            </>
          )}
          <div className="menu-button-box-vertical-bar" />
          <IconButton className="menu-new-group-botton" onClick={reFreshTable}>
            <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </div>
      )}

      <div
        className="layout-table-group-box"
        style={{ height: tableGroupHeight }}
      >
        {columns.length > 0 &&
          groupRow &&
          Object.keys(tableRefs ?? {}).length > 0 &&
          _.sortBy(Object.keys(groupRow ?? {})).map((c, i) => {
            const colorIndex = i % groupColorList.length;

            return menu === "sales" ? (
              <SalesTable
                key={c}
                admin_user={admin_user}
                user_perm={user_perm}
                table_name={menu}
                line_table_name={`${menu}_lineitems`}
                rows={groupRow?.[c] ?? []}
                columns={columns.map((c2) => ({
                  ...c2,
                  table_group_name: c,
                }))}
                lineColumns={line_columns.map((c2) => ({
                  ...c2,
                  table_group_name: c,
                }))}
                group_list={Object.keys(groupRow)}
                group_name={c}
                selectionModel={selectionModel?.[c] ?? []}
                setSelectionModel={setSelectionModel}
                ref={tableRefs[c]}
                soketData={soketData}
                isLoading={isLoading}
                lineDataUse={lineitem_use}
                group_color={groupColorList[colorIndex]}
                upperTablePinnedColumns={upperTablePinnedColumns}
                filter_items={filterItems}
                searchValue={searchValue}
              />
            ) : (
              <MaterialTable
                key={c}
                admin_user={admin_user}
                user_perm={user_perm}
                table_name={menu}
                line_table_name={`${menu}_lineitems`}
                rows={groupRow?.[c] ?? []}
                columns={columns.map((c2) => ({
                  ...c2,
                  table_group_name: c,
                }))}
                lineColumns={line_columns.map((c2) => ({
                  ...c2,
                  table_group_name: c,
                }))}
                group_list={Object.keys(groupRow)}
                group_name={c}
                selectionModel={selectionModel?.[c] ?? []}
                setSelectionModel={setSelectionModel}
                ref={tableRefs[c]}
                soketData={soketData}
                isLoading={isLoading}
                lineDataUse={lineitem_use}
                group_color={groupColorList[colorIndex]}
                upperTablePinnedColumns={upperTablePinnedColumns}
                filter_items={filterItems}
                searchValue={searchValue}
              />
            );
          })}
      </div>

      {selectControlMenuBool.status && (
        <SelectRowControlMenu
          table_name={menu}
          group_list={Object.keys(groupRow)}
          table_rows={Object.values(groupRow).flat()}
          totalCount={selectControlMenuBool.count}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
          refs={tableRefs}
          columns={columns}
          admin_user={admin_user}
          user_perm={user_perm}
        />
      )}

      {targetComment && <UpdateComment />}
      {matApprovalReqModalOpen && (
        <MaterialApprovalRequest approval_user_list={userList} />
      )}
      {matApprovalModalOpen && <MaterialApprovalModal />}
    </div>
  );
};

export default MaterialMenu;

const query_fetch_data = async (
  table_name,
  user_project,
  startDate,
  endDate
) => {
  const result = {
    rows: null,
    group_rows: {},
    lineitem_use: false,
    user_perm: null,
  };

  if (!user_project) {
    return result;
  }

  try {
    let requestRowsData = { data: [], lineitem_use: false };

    if (dateFilterMenuList.includes(table_name)) {
      requestRowsData = await userPermAxios.get(
        `/${table_name}/${user_project?.project_code}/rows?startDate=${startDate}&endDate=${endDate}`
      );
    } else {
      requestRowsData = await userPermAxios.get(
        `/${table_name}/${user_project?.project_code}/rows`
      );
    }

    const { data, lineitem_use } = requestRowsData;

    const data_group = _.groupBy(data, "group_name");

    const requestUserPermStatus = await userPermAxios.get(
      `/${table_name}/get-user-menu-perm-status`
    );

    const { permStatus } = requestUserPermStatus;

    result.group_rows = data_group;
    result.lineitem_use = lineitem_use;
    result.user_perm = permStatus;
  } catch (err) {
  } finally {
    return result;
  }
};

const query_approval_fetch_data = async () => {
  let result = {
    list: [],
  };

  try {
    const requestApprovalUserList = await userPermAxios.get(
      "/cost/get_approval_user_list"
    );

    const { success, data } = requestApprovalUserList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const dateFilterMenuList = [
  "purchase_request",
  "request_for_quotation",
  "purchase_order",
  "packing_list",
  "material_receive",
  "material_release",
  "product_export",
  "inventory_quantity_control",
];
