import React, { useMemo, useState } from "react";
import { useResetRecoilState } from "recoil";
import { ApprovalLineModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import LoadingModal from "@components/LoadingModal";
import LabelTextArea from "@components/LabelTextArea";
import LabelSelect from "@components/LabelSelect";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const ManpowerRequestAddModal = ({
  open,
  onClose = () => {},
  user_list = [],
  approval_user_list = [],
  org_list = [],
  job_position_list = [],
  job_grade_list = [],
  table_refresh = () => {},
}) => {
  const [approvalName, setApprovalName] = useState(
    `Add Manpower Request Description`
  );
  const [requestUser, setRequestUser] = useState("");
  const [reasonForHiring, setReasonForHiring] = useState("New Position");
  const [orgId, setOrgId] = useState("");
  const [jobLevelId, setJobLevelId] = useState("");
  const [jobFnId, setJobFnId] = useState("");
  const [reqPerson, setReqPerson] = useState(0);
  const [remark, setRemark] = useState("");
  const [attachmentList, setAttachmentList] = useState(null);
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });
  const [dubleClick, setDubleClick] = useState(true);

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const createManpowerApproval = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const formData = new FormData();

      for (const file in attachmentList) {
        if (file !== "length" && file !== "item") {
          const targetFile = attachmentList[file];

          formData.append(`files`, targetFile);
        }
      }

      formData.append(
        "additionalData",
        JSON.stringify({
          approvalName,
          requestUser,
          reasonForHiring,
          orgId,
          jobLevelId,
          jobFnId,
          reqPerson,
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        })
      );

      const requestCreateMaterialApproval = await userPermAxios.post(
        `/hr/create_manpower_approval`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateMaterialApproval;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }
      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        table_refresh();
        onClose();
      }
    }
  };

  const confirmManpowerApproval = () => {
    if (!dubleClick) {
      return;
    }

    let message = `Do you want to request a manpower approval?`;

    return create_alert_fn(
      "info",
      message,
      async () => {
        createManpowerApproval();
      },
      true
    );
  };

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^\d+$/;

    if (isValidNumber.test(value)) {
      if (value === "-") {
        setValue(value);
      } else {
        setValue(Number(value));
      }
    }
  };

  const button_disabled = useMemo(() => {
    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    if (requestUser === "") {
      return true;
    }

    if (reasonForHiring === "") {
      return true;
    }

    if (orgId === "") {
      return true;
    }

    if (jobLevelId === "") {
      return true;
    }

    if (jobFnId === "") {
      return true;
    }

    if (reqPerson <= 0) {
      return true;
    }

    return false;
  }, [
    approvalLineData,
    approvalName,
    requestUser,
    reasonForHiring,
    orgId,
    jobLevelId,
    jobFnId,
    reqPerson,
  ]);

  return (
    <>
      {!dubleClick && <LoadingModal />}
      <PolicyModalBody
        open={open}
        onClose={onClose}
        title="Manpower Request Approval"
        policyName={approvalName}
        setPolicyName={setApprovalName}
        disableEscapeKeyDown={false}
        saveFn={confirmManpowerApproval}
        save_btn_disabled={button_disabled}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Request User
              </div>
              <LabelSelect
                value={requestUser}
                setValue={setRequestUser}
                list={[
                  { name: "", value: "" },
                  ...user_list.map((c) => {
                    const { id, username, fn_name, lv_name, org_setting_name } =
                      c;

                    return {
                      name: `${org_setting_name} / ${lv_name} / ${fn_name} / ${username}`,
                      value: id,
                    };
                  }),
                ]}
              />
            </div>
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Reason for Hiring
              </div>
              <LabelSelect
                value={reasonForHiring}
                setValue={setReasonForHiring}
                list={reason_for_hiring_list}
              />
            </div>
            <div className="work-policy-modal-time-picker-box">
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Necessary Department
                </div>
                <LabelSelect
                  value={orgId}
                  setValue={setOrgId}
                  list={[{ name: "", value: "" }, ...org_list]}
                />
              </div>
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Necessary Job Position
                </div>
                <LabelSelect
                  value={jobLevelId}
                  setValue={setJobLevelId}
                  list={[{ name: "", value: "" }, ...job_position_list]}
                />
              </div>
            </div>
            <div className="work-policy-modal-time-picker-box">
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Necessary Job Grade
                </div>
                <LabelSelect
                  value={jobFnId}
                  setValue={setJobFnId}
                  list={[{ name: "", value: "" }, ...job_grade_list]}
                />
              </div>
              <div className="work-policy-modal-time-picker-detail">
                <div className="work-policy-modal-detail-contents-title">
                  Necessary Personnel
                </div>
                <NumberInput
                  value={reqPerson}
                  onChange={(e) => handleNumberInputValue(e, setReqPerson)}
                />
              </div>
            </div>
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>

            <div className="work-policy-modal-swicth-btn-box">
              <div className="work-policy-modal-detail-contents-title">
                {`Attachment (${attachmentList ? attachmentList.length : 0})`}
              </div>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                sx={{ fontSize: 14, textTransform: "none", width: 300 }}
              >
                Upload File
                <input
                  type="file"
                  multiple
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    const fileCount = e.target.files.length;
                    if (fileCount > 0) {
                      setAttachmentList(e.target.files);
                    } else {
                      setAttachmentList(null);
                    }
                  }}
                />
              </Button>
            </div>
            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default ManpowerRequestAddModal;

const NumberInput = ({
  value,
  setValue,
  onChange,
  disabled = false,
  width = "100%",
}) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{
        width,
        boxSizing: "border-box",
        marginTop: 4,
        height: 40,
        // borderColor: "rgb(118, 118, 118)",
      }}
    />
  );
};

const reason_for_hiring_list = [
  ...[
    { name: "Replacement", value: "Replacement" },
    { name: "New Position", value: "New Position" },
    {
      name: "Workforce Expansion",
      value: "Workforce Expansion",
    },
    { name: "Project_Based", value: "Project_Based" },
    { name: "Seasonal Hire", value: "Seasonal Hire" },
    { name: "Backfill", value: "Backfill" },
    {
      name: "Promotion/Transfers",
      value: "Promotion/Transfers",
    },
    {
      name: "Compliance Requirement",
      value: "Compliance Requirement",
    },
    {
      name: "Urgent Opertional Need",
      value: "Urgent Opertional Need",
    },
  ],
];
