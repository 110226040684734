import React from "react";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-premium";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const annualLeaveUpperColumn = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    width: 40,
    renderCell: (params) => (
      <CustomDetailPanelToggle id={params.id} value={params.value} />
    ),
  },
  {
    field: "id",
    headerName: "User No.",
    width: 100,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "User Name",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "org_setting_name",
    headerName: "Department",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_name",
    headerName: "Job Position",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "annual_leave_count",
    headerName: "Annual Leave Count",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "using_annual_leave_count",
    headerName: "Using Annual Leave Count",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remain_annal_leave_count",
    headerName: "Remain Annal Leave Count",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const annualLeaveAdditionalColumn = [
  {
    field: "date",
    headerName: "Additional Date",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "additional_count",
    headerName: "Additional Count",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const annualLeaveUsageColumn = [
  {
    field: "date",
    headerName: "Using Date",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "using_count",
    headerName: "Using Count",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const workTypeUsageColumn = [
  {
    field: "policy_name",
    headerName: "Name",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "date",
    headerName: "Using Date",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "working_time",
    headerName: "Working",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const dayOffUsageColumn = [
  {
    field: "date",
    headerName: "Using Date",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "dayoff_policy_name",
    headerName: "Name",
    flex: 1,
    data_type: "string",
    editable: false,
    filterable: true,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

const CustomDetailPanelToggle = (props) => {
  const { id, value: isExpanded } = props;

  return (
    <IconButton
      // size="small"
      tabIndex={-1}
      // disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
      sx={{ width: "100%", height: "100%" }}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 270}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
          width: "24px",
          height: "24px",
          color: "#344054",
        }}
      />
    </IconButton>
  );
};
