import React from "react";

const JobLvSettingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 7.4375C8.4 7.4375 7.5 8.3375 7.5 9.4375V12.9625H6.15C5.525 12.9625 5 13.4625 5 14.1125V15.8375H35V14.1125C35 13.4875 34.475 12.9625 33.85 12.9625H32.5V9.4375C32.5 8.3375 31.6 7.4375 30.5 7.4375H9.5ZM30 12.9625H10V10.2125C10 9.9625 10.2 9.7625 10.45 9.7625H29.55C29.8 9.7625 30 9.9625 30 10.2125V12.9625Z"
        fill="#333F53"
      />
      <path
        d="M35 19.2875H5V30.5625C5 31.6625 5.9 32.5625 7 32.5625H33C34.1 32.5625 35 31.6625 35 30.5625V19.2875Z"
        fill="#333F53"
      />
    </svg>
  );
};

export default JobLvSettingIcon;
