import { IconButton } from "@mui/material";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import { CostApprovalModalStatus } from "@/atoms/modalStatus";
import { DocumentIcon } from "./Icons";
import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const contractAmountColumn = [
  {
    field: "initial_contract_amount",
    headerName: "Initial Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "change_order_amount",
    headerName: "Change Order Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "current_contract_amount",
    headerName: "Current Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
];

export const changeOrderListColumn = [
  {
    field: "item_no",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "data_consolidation_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DetailViewCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "change_order_cost",
    headerName: "Change Order Amount",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "date",
    headerName: "Draft Date",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Drafter",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approved_status",
    headerName: "Status",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

const DetailViewCell = ({ params }) => {
  const { id } = params;
  const setCostApprovalModalStatus = useSetRecoilState(CostApprovalModalStatus);
  const onCloseModal = useResetRecoilState(CostApprovalModalStatus);

  const openModal = () => {
    setCostApprovalModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        modal_type: "carbon_copy",
        list_refresh: () => {},
        table_name: "change_order",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <DocumentIcon />
    </IconButton>
  );
};
