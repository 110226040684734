import React, { useState } from "react";
import { useQuery } from "react-query";
import Btn from "@components/CustomIconBtn";
import ProjectListModal from "./ProjectListModal";
import TransferHistoryModal from "./TransferHistoryModal";
import {
  UserIcon,
  EmailIcon,
  CalendarIcon,
  IdCardIcon,
  EarthIcon,
  PhoneIcon,
  BankIcon,
  OrganizationIcon,
  JobPositionIcon,
  JobGradeIcon,
  TransferHistoryIcon,
  ProjectListIcon,
} from "./Icons";
import "./myInfo.scss";
import { userPermAxios } from "@/utils/customAxios";

const MyInfo = () => {
  const query_my_information_data = useQuery("my_information_data", () => {
    return query_fetch_data();
  });

  const { data = {}, isLoading } = query_my_information_data;

  const {
    username,
    user_id,
    email,
    joining_date,
    resignation_date,
    birth_date,
    country,
    phone,
    bank_account,
    department,
    job_position,
    job_function,
    project_list,
    transfer_history,
  } = data;

  const [projectListToggle, setProjectListToggle] = useState(false);
  const [historyToggle, setHistoryToggle] = useState(false);

  const handleModal = (setValue) => {
    setValue((prev) => !prev);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="my-info-body">
      {projectListToggle && (
        <ProjectListModal
          open={projectListToggle}
          rows={project_list}
          onClose={() => handleModal(setProjectListToggle)}
        />
      )}
      {historyToggle && (
        <TransferHistoryModal
          open={historyToggle}
          rows={transfer_history}
          onClose={() => handleModal(setHistoryToggle)}
        />
      )}
      <div className="my-info-top-body">
        <div className="my-info-title-box">
          <div className="my-info-contents-title">Basic Information</div>
        </div>
        <div className="my-info-contents-box">
          <div className="my-info-contents-detail-left-box">
            <div className="my-info-contents-left-title-box">
              <UserIcon />
              <div className="my-info-contents-left-title">Name</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <EmailIcon />
              <div className="my-info-contents-left-title">Email</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <CalendarIcon />
              <div className="my-info-contents-left-title">Employment Info</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <IdCardIcon />
              <div className="my-info-contents-left-title">Date of Birth</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <EarthIcon />
              <div className="my-info-contents-left-title">Country</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <PhoneIcon />
              <div className="my-info-contents-left-title">Phone Number</div>
            </div>
            <div className="my-info-contents-left-title-box">
              <BankIcon />
              <div className="my-info-contents-left-title">Bank Account</div>
            </div>
          </div>
          <div className="my-info-contents-detail-box">
            <div className="my-info-contents-right-title-box2">
              <div className="my-info-contents-right-title-group">
                <div className="my-info-contents-left-title padding">
                  User Name
                </div>
                <div className="my-info-contents-right-title">{username}</div>
              </div>
              <div className="my-info-contents-right-title-group">
                <div className="my-info-contents-left-title padding">
                  User Number
                </div>
                <div className="my-info-contents-right-title">{user_id}</div>
              </div>
            </div>

            <div className="my-info-contents-right-title-box">
              <div className="my-info-contents-right-title">{email}</div>
            </div>

            <div className="my-info-contents-right-title-box2">
              <div className="my-info-contents-right-title-group">
                <div className="my-info-contents-left-title padding">
                  Date of Joining
                </div>
                <div className="my-info-contents-right-title">
                  {joining_date}
                </div>
              </div>
              <div className="my-info-contents-right-title-group">
                <div className="my-info-contents-left-title padding">
                  Date of Resignation
                </div>
                <div className="my-info-contents-right-title">
                  {resignation_date}
                </div>
              </div>
            </div>

            <div className="my-info-contents-right-title-box">
              <div className="my-info-contents-right-title">{birth_date}</div>
            </div>
            <div className="my-info-contents-right-title-box">
              <div className="my-info-contents-right-title">{country}</div>
            </div>
            <div className="my-info-contents-right-title-box">
              <div className="my-info-contents-right-title">{phone}</div>
            </div>
            <div className="my-info-contents-right-title-box">
              <div className="my-info-contents-right-title">{bank_account}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-info-bottom-body">
        <div className="my-info-bottom-left-body">
          <div className="my-info-top-body">
            <div className="my-info-title-box">
              <div className="my-info-contents-title">
                Employment Contract Information
              </div>
            </div>
            <div className="my-info-contents-box">
              <div className="my-info-contents-detail-left-box">
                <div className="my-info-contents-left-title-box">
                  <CalendarIcon />
                  <div className="my-info-contents-left-title">
                    Contract Duration
                  </div>
                </div>
                {/* <div className="my-info-contents-left-title-box">
                  <CalendarIcon />
                  <div className="my-info-contents-left-title">
                    Probation Period
                  </div>
                </div> */}
              </div>
              <div className="my-info-contents-detail-box">
                <div className="my-info-contents-right-title-box">
                  <div className="my-info-contents-right-title">
                    {`${joining_date} ~ ${
                      resignation_date === "-"
                        ? "Full-time Employee"
                        : resignation_date
                    }`}
                  </div>
                </div>

                {/* <div className="my-info-contents-right-title-box">
                  <div className="my-info-contents-right-title">Department</div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="my-info-title-box">
            <div className="my-info-contents-title">Assigned Project</div>
            <div>
              <Btn
                label="Project List"
                width={142}
                icon={<ProjectListIcon />}
                onClick={() => handleModal(setProjectListToggle)}
              />
            </div>
          </div>
        </div>
        <div className="my-info-bottom-right-body">
          <div className="my-info-title-box">
            <div className="my-info-contents-title">HR Information</div>
            <div>
              <Btn
                label="Transfer History"
                width={160}
                icon={<TransferHistoryIcon />}
                onClick={() => handleModal(setHistoryToggle)}
              />
            </div>
          </div>
          <div className="my-info-contents-box">
            <div className="my-info-contents-detail-left-box">
              <div className="my-info-contents-left-title-box">
                <OrganizationIcon />
                <div className="my-info-contents-left-title">Department</div>
              </div>
              <div className="my-info-contents-left-title-box">
                <JobPositionIcon />
                <div className="my-info-contents-left-title">Job Position</div>
              </div>
              <div className="my-info-contents-left-title-box">
                <JobGradeIcon />
                <div className="my-info-contents-left-title">Job Grade</div>
              </div>
            </div>
            <div className="my-info-contents-detail-box">
              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">{department}</div>
              </div>

              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">
                  {job_position}
                </div>
              </div>
              <div className="my-info-contents-right-title-box">
                <div className="my-info-contents-right-title">
                  {job_function}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;

const query_fetch_data = async () => {
  let result = {
    username: "-",
    user_id: "-",
    email: "-",
    joining_date: "-",
    resignation_date: "-",
    birth_date: "-",
    country: "-",
    phone: "-",
    bank_account: "-",
    department: "-",
    job_position: "-",
    job_function: "-",
    project_list: [],
    transfer_history: [],
  };

  try {
    const requestMyInformation = await userPermAxios.get(
      "/hr/get_my_information"
    );

    const { success, data } = requestMyInformation;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
