import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { ReleaseDataConsolidationModalStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SelectDataTable from "@components/SelectDataTable";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { createMRTableColumn } from "./Column";

import "./releaseDataConsolidationModal.scss";

const ReleaseDataConsolidationModal = () => {
  const { width, height } = useWindowDimensions();

  const [modalStatus, setModalStatus] = useRecoilState(
    ReleaseDataConsolidationModalStatus
  );

  const { open, target_id, sales_id } = modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);

  const query_inv_material_list = useQuery(
    ["inv_material_list", target_id, sales_id],
    () => {
      return query_fetch_data(target_id, sales_id);
    },
    {
      initialData: { material_list: [] },
    }
  );

  const { material_list } = query_inv_material_list.data;

  useEffect(() => {
    setSelectionModel([]);
  }, [open]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      sales_id: null,
    }));
  };

  const button_disabled = useMemo(() => {
    if (!target_id || !sales_id || selectionModel.length === 0) {
      return true;
    }

    return false;
  }, [selectionModel, target_id, sales_id]);

  const createReleaseDataConsolidationListItem = async () => {
    try {
      const requestDataConsolidation = await userPermAxios.post(
        `/material_release/${sales_id}/${target_id}/consolidation-lineitem`,
        {
          lineData: selectionModel.map((c) => {
            const findLineData = material_list.find((c2) => c2.id === c);

            return findLineData;
          }),
        }
      );

      const { success } = requestDataConsolidation;

      if (success) {
        closeModal();
      }
    } catch (err) {
      create_alert_fn("warning", "Data Consolidation Error", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;
    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await createReleaseDataConsolidationListItem();
      },
      true
    );
  };

  const modalHeight = height * 0.9;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="release-data-consolidation-modal-total-body"
        style={{ width: width * 0.9, height: modalHeight }}
      >
        <div className="release-data-consolidation-modal-header-box">
          <div className="release-data-consolidation-modal-header-title">
            Data Consolidation
          </div>
          <IconButton
            onClick={closeModal}
            className="release-data-consolidation-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div
          className="release-data-consolidation-modal-main-box"
          style={{ height: modalHeight - 70 - 76 - 32 }}
        >
          <SelectDataTable
            title="Material Received List"
            rows={material_list}
            columns={createMRTableColumn()}
            selectModel={selectionModel}
            setSelectModel={setSelectionModel}
            table_height={modalHeight - 70 - 76 - 32 - 40}
            isRowSelectableField="material_use"
          />
        </div>

        <div className="release-data-consolidation-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="release-data-consolidation-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="release-data-consolidation-modal-update-btn"
            disabled={button_disabled}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseDataConsolidationModal;

const query_fetch_data = async (target_id, sales_id) => {
  const result = { material_list: [] };

  try {
    if (!sales_id) {
      return result;
    }

    const request_material_list = await userPermAxios.get(
      `/${target_id}/${sales_id}/get_release_material_list`
    );

    const { success, data } = request_material_list ?? {};

    if (success) {
      result.material_list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
