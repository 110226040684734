import React, { useEffect, useCallback } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { AnnualIncomeAddModalStatus } from "@/atoms/modalStatus";
import { useQuery } from "react-query";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { userPermAxios } from "@utils/customAxios";
import { materialTableStyle } from "@styles/muiThemes";
import Btn from "../CustomIconBtn";
import PlusIcon from "../Icons/PlusIcon";
import AnnualIncomeModal from "./AnnualIncomeModal";

const AnnualIncomeTable = ({
  rows,
  lineColumns,
  line_table_name,
  parent_id,
  parent_row,
  soketData,
}) => {
  const [{ open }, setAnnualIncomeAddModalStatus] = useRecoilState(
    AnnualIncomeAddModalStatus
  );

  const closeAnnualIncomeAddModal = useResetRecoilState(
    AnnualIncomeAddModalStatus
  );

  const lineApiRef = useGridApiRef();

  const query_perm_line_item_data = useQuery(
    ["perm_line_item_data", line_table_name, parent_id, parent_row],
    () => {
      return query_fetch_data(line_table_name, parent_id, parent_row);
    },
    {
      initialData: { row: [] },
    }
  );

  const { row } = query_perm_line_item_data.data;

  const handleCellClick = useCallback(
    (params) => {
      const cellMode = lineApiRef.current.getCellMode(params.id, params.field);

      if (cellMode === "view" && params?.colDef?.editable) {
        lineApiRef.current.startCellEditMode({
          id: params.id,
          field: params.field,
        });
      }
    },
    [lineApiRef]
  );

  const openAnnualIncomeAddModal = () => {
    setAnnualIncomeAddModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: closeAnnualIncomeAddModal,
        query_key: [
          "perm_line_item_data",
          line_table_name,
          parent_id,
          parent_row,
        ],
        user_id: parent_id,
      };
    });
  };

  return (
    <div
      style={{ backgroundColor: "#F9FAFB", padding: "20px", maxHeight: 408 }}
    >
      <div
        style={{
          display: "flex",
          gap: 20,
          alignItems: "center",
          paddingBottom: 20,
        }}
      >
        <h3 style={{ marginTop: "0", marginBottom: 0 }}>
          Annual Salary History
        </h3>
        <Btn
          icon={<PlusIcon />}
          label="Add Annual Salary"
          width={200}
          onClick={openAnnualIncomeAddModal}
        />
      </div>

      <DataGridPro
        rows={row}
        columns={lineColumns.map((c) => ({
          ...c,
        }))}
        initialState={{}}
        hideFooter
        columnHeaderHeight={40}
        disableRowSelectionOnClick
        apiRef={lineApiRef}
        rowHeight={40}
        sx={{
          ...materialTableStyle,
        }}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        onCellClick={handleCellClick}
      />
      {open && <AnnualIncomeModal />}
    </div>
  );
};

export default AnnualIncomeTable;

const query_fetch_data = async (table_name, record_id, parent_row) => {
  const result = { row: [] };

  try {
    const requestRowData = await userPermAxios.get(
      `/${table_name}/${record_id}/get-project-perm-user/${parent_row?.menu_column_name}`
    );
    const { data } = requestRowData;

    result.row = data;
  } catch (err) {
  } finally {
    return result;
  }
};
