import React from "react";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import {
  ApprovalModalStatus,
  CostApprovalModalStatus,
  MaterialApprovalModalStatus,
  ManpowerRequestApprovalModalStatus,
} from "@/atoms/modalStatus";
import { AnnualLeaveIcon } from "./Icons";

const ApprovalCard = ({
  id,
  icon = <AnnualLeaveIcon />,
  title = "Name",
  title_date = "YYYY-MM-DD",
  sub_title = "Remote Work・Department:Department Name・Job Grade:Grade・Job Position:Position",
  status_color = "#039855",
  status_name = "Approve",
  modal_type,
  approval_list_refresh,
  need_approval,
  approval_type = "hr",
  cost_table_name,
}) => {
  const setHrApprovalModalStatus = useSetRecoilState(ApprovalModalStatus);
  const setCostApprovalModalStatus = useSetRecoilState(CostApprovalModalStatus);
  const setMaterialApprovalModalStatus = useSetRecoilState(
    MaterialApprovalModalStatus
  );
  const setManpowerRequestApprovalModalStatus = useSetRecoilState(
    ManpowerRequestApprovalModalStatus
  );
  const closeHrApprovalModal = useResetRecoilState(ApprovalModalStatus);
  const closeCostApprovalModal = useResetRecoilState(CostApprovalModalStatus);
  const closeMaterialApprovalModalStatus = useResetRecoilState(
    MaterialApprovalModalStatus
  );
  const closeManpowerRequestApprovalModalStatus = useResetRecoilState(
    ManpowerRequestApprovalModalStatus
  );

  const openModal = () => {
    if (approval_type === "hr") {
      setHrApprovalModalStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: closeHrApprovalModal,
          id,
          modal_type,
          list_refresh: approval_list_refresh,
        };
      });
    }

    if (approval_type === "cost") {
      setCostApprovalModalStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: closeCostApprovalModal,
          id,
          modal_type,
          list_refresh: approval_list_refresh,
          table_name: cost_table_name,
        };
      });
    }

    if (approval_type === "material") {
      setMaterialApprovalModalStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: closeMaterialApprovalModalStatus,
          id,
          modal_type,
          table_name: cost_table_name,
          list_refresh: approval_list_refresh,
        };
      });
    }

    if (approval_type === "manpower") {
      setManpowerRequestApprovalModalStatus((prev) => {
        return {
          ...prev,
          open: true,
          onClose: closeManpowerRequestApprovalModalStatus,
          id,
          modal_type,
          table_name: cost_table_name,
          list_refresh: approval_list_refresh,
        };
      });
    }
  };

  return (
    <div
      className={`approval-card-body ${
        modal_type === "approval" && !need_approval ? "already-approval" : ""
      }`}
      onClick={openModal}
    >
      <div className="approval-card-left-body">
        {icon}
        <div className="approval-card-title-body">
          <div className="approval-card-title-box">
            <div className="approval-card-title">{title}</div>
            <div className="approval-card-title-date">{title_date}</div>
          </div>
          <div className="approval-card-sub-title">{sub_title}</div>
        </div>
      </div>
      <div className="approval-card-right-body">
        <div className="approval-card-status-body">
          <div
            className="approval-card-status-dot"
            style={{ backgroundColor: status_color }}
          />
          <div className="approval-card-status-name">{status_name}</div>
        </div>
        <div className="approval-card-btn">
          <RightArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default ApprovalCard;

const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M17.6665 30.5497C18.1887 31.072 19.0307 31.072 19.553 30.5497L28.4099 21.6928C28.8255 21.2771 28.8255 20.6057 28.4099 20.19L19.553 11.3331C19.0307 10.8108 18.1887 10.8108 17.6665 11.3331C17.1442 11.8553 17.1442 12.6973 17.6665 13.2196L25.383 20.9467L17.6558 28.6739C17.1442 29.1855 17.1442 30.0381 17.6665 30.5497Z"
        fill="#344054"
      />
    </svg>
  );
};
