import React, { useState } from "react";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import ModalBody from "@components/ModalBody";
import DayPicker from "@components/DatePicker";
import SwicthBtn from "@components/SwicthBtn";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const UsingAnnualLeaveModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  user_id,
  policy_table_id,
  half_dayoff_use,
  remain_annal_leave_count = 0,
}) => {
  const [additionalDate, setAdditionalDate] = useState(dayjs());
  const [morningOff, setMorningOff] = useState(false);
  const [afternoonOff, setAfternoonOff] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);

  const handleHalfUse = (type) => {
    if (type === "morning") {
      setMorningOff((prev) => {
        const value = !prev;

        if (value && afternoonOff) {
          setAfternoonOff(false);
        }

        return value;
      });
    }

    if (type === "afternoon") {
      setAfternoonOff((prev) => {
        const value = !prev;

        if (value && morningOff) {
          setMorningOff(false);
        }

        return value;
      });
    }
  };

  const createAdditional = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const requestCreateAnnualLeaveCount = await userPermAxios.post(
        `/dayoff/create_admin_annual_leave`,
        {
          user_id,
          policy_table_id,
          annualLeaveYear: additionalDate.format("YYYY"),
          annualLeaveMonth: additionalDate.format("MM"),
          annualLeaveDay: additionalDate.format("DD"),
          morningOff,
          afternoonOff,
        }
      );

      const { success, message } = requestCreateAnnualLeaveCount;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmAdditional = () => {
    if (!dubleClick) {
      return;
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to create annual leave?",
      createAdditional,
      true
    );
  };

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      modalWidth={400}
      title="Add Annual Leave"
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div
            className="work-policy-modal-time-picker-detail"
            style={{ width: "100%" }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Annual Leave Date
            </div>
            <DayPicker
              width={318}
              value={additionalDate}
              onChange={(e) => setAdditionalDate(e)}
            />
          </div>
          {half_dayoff_use === 1 && (
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Detail Annual Leave Type
              </div>
              <div
                className="using-menu-work-list-range-box"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div>
                  <SwicthBtn
                    checked={morningOff}
                    label={`Morning ${morningOff ? "ON" : "OFF"}`}
                    onClick={() => handleHalfUse("morning")}
                  />
                </div>
                <div>
                  <SwicthBtn
                    checked={afternoonOff}
                    label={`Afternoon ${afternoonOff ? "ON" : "OFF"}`}
                    onClick={() => handleHalfUse("afternoon")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="work-policy-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="work-policy-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          className="work-policy-modal-update-btn"
          onClick={confirmAdditional}
          disabled={
            policy_table_id === null ||
            half_dayoff_use === null ||
            remain_annal_leave_count <= 0
          }
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default UsingAnnualLeaveModal;
