export const WorkTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M3 13.988C3 13.3932 3.48215 12.9111 4.07692 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H4.07692C3.48215 34.4495 3 33.9674 3 33.3726V13.988Z"
        fill="#E0BE87"
      />
      <path
        d="M31.8424 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H31.8424V12.9111Z"
        fill="#D4B178"
      />
      <path
        d="M3 30.7515H38V33.3726C38 33.9674 37.5178 34.4495 36.9231 34.4495H4.07692C3.48215 34.4495 3 33.9674 3 33.3726V30.7515Z"
        fill="#D4B178"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1073 12.9111H4.07692C3.48215 12.9111 3 13.3932 3 13.988V19.6418H38V13.988C38 13.3932 37.5178 12.9111 36.9231 12.9111H27.8937C27.6946 11.969 27.272 10.9104 26.5139 9.9703C25.3403 8.51493 23.4196 7.41602 20.5005 7.41602C17.5815 7.41602 15.6608 8.51493 14.4872 9.9703C13.7291 10.9104 13.3065 11.969 13.1073 12.9111ZM14.7691 12.9111H26.2319C26.0567 12.2782 25.7486 11.5947 25.2564 10.9843C24.4108 9.93569 22.9661 9.0314 20.5005 9.0314C18.035 9.0314 16.5903 9.93569 15.7446 10.9843C15.2525 11.5947 14.9443 12.2782 14.7691 12.9111Z"
        fill="#B88E4B"
      />
      <path
        d="M31.8424 12.9111H36.9231C37.5178 12.9111 38 13.3932 38 13.988V19.6418H31.8424V12.9111Z"
        fill="#B88E4B"
      />
      <path
        d="M17.1346 18.8341C17.1346 18.5368 17.3757 18.2957 17.6731 18.2957H23.3269C23.6243 18.2957 23.8654 18.5368 23.8654 18.8341V21.2572C23.8654 21.5546 23.6243 21.7957 23.3269 21.7957H17.6731C17.3757 21.7957 17.1346 21.5546 17.1346 21.2572V18.8341Z"
        fill="#E8E8E8"
      />
      <path
        d="M23.3269 18.2957H23.2396V20.8973H17.1346V21.2572C17.1346 21.5546 17.3757 21.7957 17.6731 21.7957H23.3269C23.6243 21.7957 23.8654 21.5546 23.8654 21.2572V18.8341C23.8654 18.5368 23.6243 18.2957 23.3269 18.2957Z"
        fill="#D8D8D8"
      />
    </svg>
  );
};
export const AnnualLeaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M3.00098 10.8608C3.00098 10.0937 3.62278 9.47193 4.38982 9.47193H36.6099C37.377 9.47193 37.9988 10.0937 37.9988 10.8608V34.2477C37.9988 35.0147 37.377 35.6365 36.6099 35.6365H4.38982C3.62278 35.6365 3.00098 35.0147 3.00098 34.2477V10.8608Z"
        fill="#FCFCFD"
      />
      <path
        d="M3.00098 9.80626C3.00098 9.03922 3.62278 8.41741 4.38982 8.41741H36.611C37.3781 8.41741 37.9999 9.03922 37.9999 9.80626V13.9782H3.00098V9.80626Z"
        fill="#FDA29B"
      />
      <path
        d="M36.611 8.41741H36.4211V12.3754H3.00208L3.00098 13.9782H37.9999L38.001 12.3754L37.9999 9.80626C37.9999 9.03922 37.3781 8.41741 36.611 8.41741Z"
        fill="#F18181"
      />
      <path
        d="M8.00027 6.78796C8.00027 6.48114 8.249 6.23242 8.55581 6.23242H10.778C11.0848 6.23242 11.3335 6.48114 11.3335 6.78796V12.6211C11.3335 12.9279 11.0848 13.1766 10.778 13.1766H8.55581C8.249 13.1766 8.00027 12.9279 8.00027 12.6211V6.78796Z"
        fill="#F2F4F7"
      />
      <path
        d="M10.778 6.23242H10.5365V12.3788H8.00027V12.6211C8.00027 12.9279 8.249 13.1766 8.55581 13.1766H10.778C11.0848 13.1766 11.3335 12.9279 11.3335 12.6211V6.78796C11.3335 6.48114 11.0848 6.23242 10.778 6.23242Z"
        fill="#D5DAE0"
      />
      <path
        d="M29.6662 6.78796C29.6662 6.48114 29.915 6.23242 30.2218 6.23242H32.4439C32.7508 6.23242 32.9995 6.48114 32.9995 6.78796V12.6211C32.9995 12.9279 32.7508 13.1766 32.4439 13.1766H30.2218C29.915 13.1766 29.6662 12.9279 29.6662 12.6211V6.78796Z"
        fill="#F2F4F7"
      />
      <path
        d="M32.4439 6.23242H32.2025V12.3788H29.6662V12.6211C29.6662 12.9279 29.915 13.1766 30.2218 13.1766H32.4439C32.7508 13.1766 32.9995 12.9279 32.9995 12.6211V6.78796C32.9995 6.48114 32.7508 6.23242 32.4439 6.23242Z"
        fill="#D5DAE0"
      />
      <g filter="url(#filter0_i_2444_95862)">
        <path
          d="M28.3337 18.5202C28.8261 19.2549 28.6135 20.1135 28.2886 20.7403C27.9534 21.387 27.4124 21.9764 26.9046 22.3478L23.6069 24.7602L24.5318 31.2765C24.6227 31.9169 24.3595 32.556 23.8519 32.9273L22.6172 33.8306C22.4858 33.9266 22.3207 33.9574 22.1657 33.9149C22.0106 33.8723 21.8814 33.7606 21.8123 33.6095L19.1872 27.8695L16.7962 28.8343L16.5863 31.0833C16.548 31.3102 16.3827 31.4907 16.1673 31.5406C15.952 31.5904 15.7291 31.4999 15.6026 31.3111L12.4746 26.644C12.348 26.4553 12.3428 26.2055 12.4613 26.0111C12.5799 25.8168 12.7988 25.7161 13.016 25.7562L15.0182 26.125L16.7962 24.302L12.5311 19.7617C12.4188 19.6422 12.3626 19.4771 12.3773 19.3103C12.392 19.1436 12.4762 18.992 12.6076 18.8959L13.8423 17.9927C14.3499 17.6214 15.0148 17.5815 15.5597 17.8898L21.1045 21.0265L24.4022 18.6142C24.91 18.2427 25.6251 17.9131 26.3215 17.8053C26.9963 17.7007 27.8414 17.7856 28.3337 18.5202Z"
          fill="#F2F4F7"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2444_95862"
          x="12.375"
          y="17.6836"
          width="16.25"
          height="16.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.555538" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.557731 0 0 0 0 0.553937 0 0 0 0 0.553937 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2444_95862"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const AttachmentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.0936 6.00043C11.0931 5.94613 11.104 5.89226 11.1258 5.84199C11.1475 5.79172 11.1797 5.74605 11.2204 5.70765C11.261 5.66925 11.3094 5.63889 11.3627 5.61835C11.4159 5.5978 11.4729 5.58749 11.5305 5.588C11.6474 5.58895 11.7593 5.63324 11.842 5.71133C11.9248 5.78943 11.9717 5.89507 11.9727 6.00551L12 11.1336C11.8638 12.1556 11.478 12.9487 10.8369 13.4957C10.0287 14.1843 9.1371 14.4728 8.1483 14.4986C7.14023 14.5248 6.10573 14.1809 5.25806 13.3806C4.60753 12.7664 4.22401 12.0473 4.11514 11.1907L4 3.88836C4.06944 3.22636 4.34498 2.67772 4.82213 2.26191C5.42876 1.73484 6.05018 1.50346 6.69086 1.50007C7.44041 1.49542 8.12903 1.70904 8.74418 2.28983C9.23342 2.75175 9.51613 3.33761 9.5914 4.06687L9.66174 10.4648C9.67966 10.9894 9.58692 11.3984 9.35932 11.6911C9.20816 11.8872 9.0085 12.0454 8.77774 12.1519C8.54698 12.2585 8.29213 12.3101 8.03539 12.3024C7.5448 12.2893 7.12814 12.0887 6.806 11.7169C6.58647 11.4631 6.46147 11.1564 6.42966 10.7795L6.35439 4.88792C6.35355 4.83369 6.36416 4.77985 6.38559 4.72953C6.40702 4.67921 6.43885 4.63342 6.47922 4.59484C6.51958 4.55625 6.56769 4.52564 6.62071 4.50479C6.67374 4.48393 6.73064 4.47326 6.78808 4.47338C6.90506 4.47366 7.01724 4.51731 7.10045 4.59492C7.18367 4.67254 7.23126 4.77792 7.23297 4.88835L7.3069 10.7508C7.32258 10.9348 7.38172 11.0795 7.48342 11.1966C7.64471 11.3828 7.81989 11.4669 8.04928 11.4729C8.16597 11.4766 8.28184 11.4531 8.38658 11.4044C8.49133 11.3557 8.58169 11.2833 8.64964 11.1937C8.74194 11.0752 8.79525 10.8379 8.78315 10.472L8.71416 4.10156C8.66129 3.59734 8.4664 3.19336 8.12634 2.8723C7.69892 2.46876 7.23835 2.32578 6.70699 2.32917C6.2836 2.33128 5.86918 2.48568 5.42115 2.87484C5.10439 3.15106 4.92294 3.51273 4.87634 3.93236L4.9897 11.1433C5.07124 11.766 5.3629 12.3138 5.875 12.7973C6.53898 13.4242 7.33602 13.6886 8.11335 13.6687C8.90726 13.6484 9.60081 13.4233 10.2428 12.8764C10.7177 12.472 11.0157 11.8591 11.1259 11.0731L11.0936 6.00043Z"
        fill="#0080DB"
      />
    </svg>
  );
};

export const CostIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
    >
      <path
        d="M10.3994 3.07597C10.3994 1.92082 11.3359 0.984375 12.491 0.984375H33.4071C34.5623 0.984375 35.4987 1.92081 35.4987 3.07597V32.358C35.4987 33.5132 34.5622 34.4496 33.4071 34.4496H12.491C11.3358 34.4496 10.3994 33.5132 10.3994 32.358V3.07597Z"
        fill="#98A2B3"
      />
      <path
        d="M12.494 4.9665C12.494 4.50444 12.8685 4.12986 13.3306 4.12986H32.5734C33.0354 4.12986 33.41 4.50444 33.41 4.9665V9.56795C33.41 10.03 33.0354 10.4046 32.5734 10.4046H13.3306C12.8685 10.4046 12.494 10.03 12.494 9.56795V4.9665Z"
        fill="#E8FDFF"
      />
      <path
        d="M28.0738 9.21458C28.0738 9.04131 28.2143 8.90084 28.3875 8.90084H32.2149C32.3882 8.90084 32.5287 9.04131 32.5287 9.21458C32.5287 9.38785 32.3882 9.52832 32.2149 9.52832H28.3875C28.2143 9.52832 28.0738 9.38785 28.0738 9.21458Z"
        fill="#98A2B3"
      />
      <path
        d="M20.8972 9.21459C20.8972 9.04132 21.0377 8.90085 21.2109 8.90085H27.0911C27.2644 8.90085 27.4049 9.04132 27.4049 9.21459C27.4049 9.38786 27.2644 9.52832 27.0911 9.52832H21.2109C21.0377 9.52832 20.8972 9.38786 20.8972 9.21459Z"
        fill="#98A2B3"
      />
      <path
        d="M12.4901 12.8206C12.4901 12.3585 12.8646 11.984 13.3267 11.984H17.9282C18.3903 11.984 18.7649 12.3585 18.7649 12.8206V14.9122C18.7649 15.3742 18.3903 15.7488 17.9282 15.7488H13.3267C12.8646 15.7488 12.4901 15.3742 12.4901 14.9122V12.8206Z"
        fill="#F9FAFB"
      />
      <path
        d="M19.8107 12.8206C19.8107 12.3585 20.1853 11.984 20.6473 11.984H25.2489C25.7109 11.984 26.0855 12.3585 26.0855 12.8206V14.9122C26.0855 15.3742 25.7109 15.7488 25.2489 15.7488H20.6473C20.1853 15.7488 19.8107 15.3742 19.8107 14.9122V12.8206Z"
        fill="#F9FAFB"
      />
      <path
        d="M27.1313 12.8206C27.1313 12.3585 27.5059 11.984 27.9679 11.984H32.5695C33.0315 11.984 33.4061 12.3585 33.4061 12.8206V14.9122C33.4061 15.3742 33.0315 15.7488 32.5695 15.7488H27.9679C27.5059 15.7488 27.1313 15.3742 27.1313 14.9122V12.8206Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.4901 18.2587C12.4901 17.7967 12.8646 17.4221 13.3267 17.4221H17.9282C18.3903 17.4221 18.7649 17.7967 18.7649 18.2587V20.3503C18.7649 20.8123 18.3903 21.1869 17.9282 21.1869H13.3267C12.8646 21.1869 12.4901 20.8123 12.4901 20.3503V18.2587Z"
        fill="#F9FAFB"
      />
      <path
        d="M19.8107 18.2587C19.8107 17.7967 20.1853 17.4221 20.6473 17.4221H25.2489C25.7109 17.4221 26.0855 17.7967 26.0855 18.2587V20.3503C26.0855 20.8123 25.7109 21.1869 25.2489 21.1869H20.6473C20.1853 21.1869 19.8107 20.8123 19.8107 20.3503V18.2587Z"
        fill="#F9FAFB"
      />
      <path
        d="M27.1313 18.2587C27.1313 17.7967 27.5059 17.4221 27.9679 17.4221H32.5695C33.0315 17.4221 33.4061 17.7967 33.4061 18.2587V20.3503C33.4061 20.8123 33.0315 21.1869 32.5695 21.1869H27.9679C27.5059 21.1869 27.1313 20.8123 27.1313 20.3503V18.2587Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.4901 23.6968C12.4901 23.2348 12.8646 22.8602 13.3267 22.8602H17.9282C18.3903 22.8602 18.7649 23.2348 18.7649 23.6968V25.7884C18.7649 26.2505 18.3903 26.625 17.9282 26.625H13.3267C12.8646 26.625 12.4901 26.2505 12.4901 25.7884V23.6968Z"
        fill="#F9FAFB"
      />
      <path
        d="M19.8107 23.6968C19.8107 23.2348 20.1853 22.8602 20.6473 22.8602H25.2489C25.7109 22.8602 26.0855 23.2348 26.0855 23.6968V25.7884C26.0855 26.2505 25.7109 26.625 25.2489 26.625H20.6473C20.1853 26.625 19.8107 26.2505 19.8107 25.7884V23.6968Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.4901 29.1349C12.4901 28.6729 12.8646 28.2983 13.3267 28.2983H17.9282C18.3903 28.2983 18.7649 28.6729 18.7649 29.1349V31.2265C18.7649 31.6886 18.3903 32.0631 17.9282 32.0631H13.3267C12.8646 32.0631 12.4901 31.6886 12.4901 31.2265V29.1349Z"
        fill="#F9FAFB"
      />
      <path
        d="M19.8107 29.1349C19.8107 28.6729 20.1853 28.2983 20.6473 28.2983H25.2489C25.7109 28.2983 26.0855 28.6729 26.0855 29.1349V31.2265C26.0855 31.6886 25.7109 32.0631 25.2489 32.0631H20.6473C20.1853 32.0631 19.8107 31.6886 19.8107 31.2265V29.1349Z"
        fill="#F9FAFB"
      />
      <path
        d="M27.1313 23.6968C27.1313 23.2348 27.5059 22.8602 27.9679 22.8602H32.5695C33.0315 22.8602 33.4061 23.2348 33.4061 23.6968V31.2265C33.4061 31.6886 33.0315 32.0631 32.5695 32.0631H27.9679C27.5059 32.0631 27.1313 31.6886 27.1313 31.2265V23.6968Z"
        fill="#F9FAFB"
      />
      <path
        d="M12.349 18.616C12.349 18.616 18.987 19.3332 21.9814 25.0963C24.9758 30.8595 22.4304 34.3539 19.7361 35.6266C17.0418 36.8993 10.2527 37.3483 5.83668 36.3012C1.42062 35.2542 0.316069 33.0834 0.522506 30.9871C0.822585 27.9268 3.66589 26.0455 3.66589 26.0455C3.66589 26.0455 3.55309 22.2658 6.48578 19.812C9.0886 17.6327 12.349 18.616 12.349 18.616Z"
        fill="#FFE9B5"
      />
      <path
        d="M5.00186 23.0212C4.77627 22.8659 4.01862 23.134 3.8058 24.6174C3.70152 25.3431 3.66959 26.0454 3.66959 26.0454C3.66959 26.0454 4.56345 25.2431 4.85927 24.6791C5.23171 23.9662 5.37643 23.2766 5.00186 23.0212Z"
        fill="#F7CF72"
      />
      <path
        d="M5.64592 18.8907C4.21788 18.5736 2.35782 17.1562 2.07476 16.4582C1.90876 16.0496 2.73664 15.4111 2.95797 15.1536C3.46449 14.5662 3.26018 14.2768 3.06439 13.5766C2.84092 12.7807 3.37085 11.9017 4.12211 11.557C4.87337 11.2122 5.77148 11.3207 6.527 11.657C7.28252 11.9932 7.92737 12.5338 8.55946 13.0659C9.02128 12.7743 9.75764 11.4867 10.9494 12.3231C11.7731 12.8999 11.8476 14.1555 11.7411 15.1579C11.4921 17.4712 10.9218 18.6715 9.84703 18.995C8.48922 19.4036 6.9867 19.1887 5.64592 18.8907Z"
        fill="#FFE9B5"
      />
      <path
        d="M3.32666 12.2352C3.50755 15.353 6.54878 17.4216 7.99172 18.3324L7.0553 19.1369C7.0553 19.1369 6.46153 19.1497 5.64855 18.89C4.25456 18.4474 2.21785 17.196 2.07739 16.4575C1.87095 15.3828 2.97337 15.6169 3.22237 14.7677C3.3522 14.3187 2.59029 13.4695 3.32666 12.2352ZM8.55946 13.0659C8.55946 13.0659 9.10052 12.5118 9.50276 12.2586C9.70281 12.6353 9.8454 13.0439 9.91563 13.4653C10.0412 14.2059 9.91563 15.2572 9.61981 16.0404C9.57299 16.1617 9.39848 16.1425 9.37932 16.0148C9.12393 14.3314 8.55946 13.0659 8.55946 13.0659Z"
        fill="#F7CF72"
      />
      <path
        d="M8.15576 17.2963C6.35954 17.8815 5.96156 19.5054 6.09138 19.948C6.24674 20.4673 8.23663 18.4455 11.1949 18.5668C12.2292 18.6094 11.9291 17.9752 11.4502 17.6091C10.8182 17.126 9.75618 16.777 8.15576 17.2963Z"
        fill="#039855"
      />
      <path
        d="M16.7062 26.8233C14.575 25.9797 12.9987 26.612 12.21 26.3228C11.7801 26.1655 11.4531 25.9439 11.2899 25.5294C11.1167 25.0888 11.4249 24.5373 11.7505 24.2585C12.1294 23.9316 13.1694 23.3876 14.492 23.7078C14.8054 23.7824 14.9041 24.1235 15.0529 24.394C15.3691 24.9757 16.5106 25.0588 16.8975 24.1965C17.2851 23.3313 16.1563 22.5552 15.4268 22.2834C14.8757 22.0781 13.2008 21.8926 11.6604 22.6001C11.2271 22.7987 9.43231 23.508 9.52573 25.8746C9.55226 26.5548 10.1787 27.874 12.2314 28.1093C12.6606 28.1588 14.6774 28.0511 15.4074 28.3153C16.6715 28.7724 16.6096 29.8526 16.083 30.2942C14.6468 31.4969 13.2428 31.155 12.8016 30.7781C12.4871 30.51 12.4017 30.0727 12.0639 29.8451C11.717 29.6104 11.3181 29.6687 11.0381 29.9753C10.1202 30.982 11.1876 32.0049 12.0757 32.394C13.0266 32.8108 13.9331 32.8698 14.8068 32.6848C18.0673 31.9943 18.5718 29.8206 18.2052 28.6536C17.9375 27.7982 17.5402 27.1527 16.7062 26.8233Z"
        fill="#039855"
      />
      <path
        d="M11.7134 20.7207C14.4098 26.4796 14.8738 33.7516 14.8738 33.7516"
        stroke="#039855"
        strokeWidth="0.446208"
        strokeMiterlimit="10"
      />
      <path
        d="M8.15578 17.2967C6.35956 17.8819 5.96158 19.5057 6.09141 19.9484C6.24677 20.4677 8.23665 18.4459 11.1949 18.5672C12.2292 18.6098 11.9291 17.9756 11.4503 17.6095C10.8182 17.1264 9.75621 16.7774 8.15578 17.2967Z"
        fill="#039855"
      />
    </svg>
  );
};

export const ManpowerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M12.3103 12.7254H1.28645C0.851564 12.7254 0.499023 12.3729 0.499023 11.938V10.2487C0.499023 10.0882 0.548576 9.92933 0.643142 9.79969C4.07442 5.0957 9.81327 5.51177 12.954 9.80053C13.0488 9.93 13.0978 10.0882 13.0978 10.2487V11.938C13.0978 12.3729 12.7452 12.7254 12.3103 12.7254Z"
        fill="#AFDDF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79883 12.7254H12.3107C12.7456 12.7254 13.0981 12.3728 13.0981 11.9379V10.2487C13.0981 10.0882 13.0491 9.92996 12.9543 9.80049C11.3587 7.62158 9.0924 6.44227 6.79883 6.42615V12.7254Z"
        fill="#7CC4EE"
      />
      <path
        d="M6.79926 7.2135C8.75622 7.2135 10.3427 5.62707 10.3427 3.67011C10.3427 1.71314 8.75622 0.126709 6.79926 0.126709C4.84229 0.126709 3.25586 1.71314 3.25586 3.67011C3.25586 5.62707 4.84229 7.2135 6.79926 7.2135Z"
        fill="#FFEDDC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79883 7.2135C6.79903 7.2135 6.79923 7.2135 6.79943 7.2135C8.7564 7.2135 10.3428 5.62707 10.3428 3.67011C10.3428 1.71314 8.7564 0.126709 6.79943 0.126709C6.79923 0.126709 6.79903 0.126709 6.79883 0.126709V7.2135Z"
        fill="#FDE7D2"
      />
      <circle cx="12.3499" cy="10.7235" r="3.14968" fill="#D0D5DD" />
      <path
        d="M15.4995 10.9336L12.3957 8.89783L11.7201 10.3446C11.6821 10.4259 11.6191 10.4929 11.5403 10.5358L10.1758 11.278L12.5598 13.8733C14.6596 13.6634 15.4995 11.7735 15.4995 10.9336Z"
        fill="#B3BAC7"
      />
      <path
        d="M12.6648 9.35881C12.6648 9.06889 12.4297 8.83386 12.1398 8.83386C11.8499 8.83386 11.6149 9.06889 11.6149 9.35881L11.6149 10.4087H10.565C10.2751 10.4087 10.04 10.6437 10.04 10.9336C10.04 11.2236 10.2751 11.4586 10.565 11.4586H11.6149L11.6149 12.5085C11.6149 12.7984 11.8499 13.0334 12.1398 13.0334C12.4297 13.0334 12.6648 12.7984 12.6648 12.5085V11.4586H13.7147C14.0046 11.4586 14.2396 11.2236 14.2396 10.9336C14.2396 10.6437 14.0046 10.4087 13.7147 10.4087H12.6648V9.35881Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};

export const MaterialIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="37"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3.14288L7.00025 -0.000244141L0.000494003 3.14288L7.00025 6.14328L14 3.14288Z"
        fill="#FFEA94"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99976 6.14277L14 3.14294V10.8979L6.99927 14.0002L6.99976 6.14277Z"
        fill="#EFB73F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00098 14.0002L0.000247002 10.8979V3.14294L7.00049 6.14277L7.00098 14.0002Z"
        fill="#FFD769"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7373 1.67208L3.73837 4.74349L3.36998 4.58562L10.3774 1.5105L10.7373 1.67208Z"
        fill="#EFB73F"
      />
      <path
        opacity="0.65"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.668 2.09624L4.83393 5.21522L2.231 4.09955L9.31247 1.03857L11.668 2.09624Z"
        fill="white"
      />
      <path
        opacity="0.65"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83301 5.21522L2.23203 4.09827V7.42002L4.83301 8.68006V5.21522Z"
        fill="white"
      />
    </svg>
  );
};
