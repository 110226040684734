import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M17.1694 29.6161C17.6916 30.1384 18.5336 30.1384 19.0559 29.6161L27.9128 20.7592C28.3285 20.3435 28.3285 19.6721 27.9128 19.2564L19.0559 10.3995C18.5336 9.87725 17.6916 9.87725 17.1694 10.3995C16.6471 10.9217 16.6471 11.7637 17.1694 12.286L24.8859 20.0131L17.1587 27.7403C16.6471 28.2519 16.6471 29.1045 17.1694 29.6161Z"
        fill="#344054"
      />
    </svg>
  );
};

export default RightArrowIcon;
