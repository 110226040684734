import React from "react";
import { contractAmountColumn, initialBudgetListColumn } from "./Column";
import InitialBudgetTable from "./InitialBudgetTable";
// import "./initialBudget.scss";

const InitialBudget = ({ contract_amount = [], initial_budget_list = [] }) => {
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-contract-amount-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Summary</div>
        </div>
        <InitialBudgetTable
          rows={contract_amount}
          column={contractAmountColumn}
        />
      </div>
      <div className="cost-menu-detail-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>
        <InitialBudgetTable
          rows={initial_budget_list}
          column={initialBudgetListColumn}
        />
      </div>
    </div>
  );
};

export default InitialBudget;
