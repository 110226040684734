import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

import Expenses from "./Expenses";
import VendorInvoice from "./VendorInvoice";

const BudgetUsage = ({
  tabValue,
  handleTabValue,
  project_code,
  project_name,
  approval_user_list,
  cost_code_list,
  budget_usage_list,
  vendor_invoice_list,
}) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabValue}
          aria-label="basic tabs example"
        >
          <Tab
            label="Budget Usage"
            {...a11yProps(0)}
            sx={{ textTransform: "none" }}
          />
          {project_code !== "overall" && (
            <Tab
              label="Vendor Invoice"
              {...a11yProps(1)}
              sx={{ textTransform: "none" }}
            />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Expenses budget_usage_list={budget_usage_list} />
      </CustomTabPanel>
      {project_code !== "overall" && (
        <CustomTabPanel value={tabValue} index={1}>
          <VendorInvoice budget_usage_list={vendor_invoice_list} />
        </CustomTabPanel>
      )}
    </Box>
  );
};

export default BudgetUsage;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        height: "calc(100% - 69px)",
        marginTop: "20px",
        // boxSizing: "border-box",
      }}
    >
      {value === index && children}
    </div>
  );
}
