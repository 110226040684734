import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle } from "@styles/muiThemes";

const PayrollTable = ({
  row = [],
  columns = [],
  checkboxSelection = true,
  selectionModel = [],
  setSelecttionModel,
}) => {
  const handleSelectionChange = (selection) => {
    if (checkboxSelection) {
      setSelecttionModel(selection);
    }
  };

  return (
    <div className="payroll-table-body" style={{ height: "100%" }}>
      <DataGridPro
        rows={row}
        columns={columns}
        initialState={{}}
        rowHeight={checkboxSelection ? 40 : 28}
        columnHeaderHeight={checkboxSelection ? 40 : 28}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelectionChange}
        sx={{
          ...materialTableStyle,
        }}
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        hideFooter
      />
    </div>
  );
};

export default PayrollTable;
