import React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const InitialBudgetTable = ({
  rows = [],
  column = [],
  row_group_model = [],
  columnVisibilityModel = {},
  selectionModelUse = false,
  selectionModel = [],
  handleSelection = () => {},
  rowHeight = 40,
}) => {
  const initialState =
    row_group_model.length === 0
      ? {}
      : {
          pinnedColumns: { left: [""] },
          columns: {
            columnVisibilityModel: {
              ...columnVisibilityModel,
            },
          },
          rowGrouping: {
            model: row_group_model,
          },
        };

  return (
    <div className="cost-menu-table-body">
      <DataGridPremium
        rows={rows}
        columns={column}
        rowHeight={rowHeight}
        columnHeaderHeight={rowHeight}
        getRowClassName={(params) => {
          const { id, row = {}, isFirstVisible } = params;

          const { notAccept } = row;

          if (typeof id === "string") {
            if (isFirstVisible) {
              return "top-level-group";
            } else {
              return "next-level-group";
            }
          }

          if (notAccept) {
            return "not-accept-row";
          }

          return "";
        }}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
          ...{
            ".table-text-input-box": { width: "auto" },
          },
          "& .not-accept-row": {
            backgroundColor: "#F9FAFB",
            color: "#D0D5DD",
          },
          "& .top-level-group": {
            backgroundColor: "#F4FEFF",
          },
          "& .next-level-group": {
            backgroundColor: "#FFFFEA",
          },
        }}
        initialState={{ ...initialState }}
        groupingColDef={{ width: 250 }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        checkboxSelection={selectionModelUse}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelection}
        rowSelectionPropagation={{ parents: true, descendants: true }}
        defaultGroupingExpansionDepth={-1}
        // rowSelectionPropagation={{ parents: true, descendants: true }}
      />
    </div>
  );
};

export default InitialBudgetTable;
