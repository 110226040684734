import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-premium";
import { IconButton } from "@mui/material";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import {
  CostApprovalModalStatus,
  InitialBudgetRowModalStatus,
} from "@/atoms/modalStatus";

import { DocumentIcon } from "./Icons";
import { CustomNormalTextTypeCell, CustomNumberTypeCell } from "../Cells";

export const contractAmountColumn = [
  {
    field: "initial_contract_amount",
    headerName: "Initial Contract Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "initial_budget_amount",
    headerName: "Initial Budget Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "rate",
    headerName: "Rate(%)",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const initialBudgetListColumn = [
  {
    field: "item_no",
    headerName: "No.",
    width: 50,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "data_consolidation_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return <DetailViewCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "initial_budget_amount",
    headerName: "Initial Budget Amount",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "date",
    headerName: "Draft Date",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "username",
    headerName: "Drafter",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "approved_status",
    headerName: "Status",
    width: 150,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
];

export const initialBudgetTableColumn = (topLvGroupSum) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 40,
    renderCell: (params) => {
      return <CustomDetailCheckRow params={params} />;
    },
  },
  {
    field: "lv_0_code",
    headerName: "Lv.0 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_1_code",
    headerName: "Lv.1 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "lv_2_code",
    headerName: "Lv.2 Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "costcode",
    headerName: "Cost Code",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "budget_cost",
    headerName: "Budget Amount",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return (
          <CustomGroupNumberTypeCell
            params={params}
            topLvGroupSum={topLvGroupSum}
          />
        );
      }
      return <CustomNumberTypeCell params={params} />;
    },
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
    data_type: "string",
    editable: false,
    renderCell: (params) => {
      return <CustomNormalTextTypeCell params={params} />;
    },
  },
  {
    field: "row_fixed_cell",
    headerName: "",
    width: 40,
    editable: false,
    resizable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      if (params.rowNode && params.rowNode.type === "group") {
        return null;
      }

      return <FiexedCell params={params} />;
    },
  },
];

const DetailViewCell = ({ params }) => {
  const { id } = params;
  const setCostApprovalModalStatus = useSetRecoilState(CostApprovalModalStatus);
  const onCloseModal = useResetRecoilState(CostApprovalModalStatus);

  const openModal = () => {
    setCostApprovalModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        modal_type: "carbon_copy",
        list_refresh: () => {},
        table_name: "initial_budget",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <DocumentIcon />
    </IconButton>
  );
};

const FiexedCell = ({ params }) => {
  const { id, row = {} } = params;

  const { costcode = "", remark = "", budget_cost = 0 } = row;

  const setInitialBudgetRowModalStatus = useSetRecoilState(
    InitialBudgetRowModalStatus
  );
  const onCloseModal = useResetRecoilState(InitialBudgetRowModalStatus);

  const openModal = () => {
    setInitialBudgetRowModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: onCloseModal,
        id,
        cost_code: costcode,
        budget_cost: budget_cost,
        remark: remark,
        modal_type: "fixed",
      };
    });
  };

  return (
    <IconButton className="line-delete-cell-btn" onClick={openModal}>
      <EditIcon />
    </IconButton>
  );
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};

const CustomGroupNumberTypeCell = ({ params, topLvGroupSum }) => {
  const { id } = params;

  if (id === Infinity) {
    return null;
  }

  const idSplit = id.split("/");

  if (idSplit.length === 2) {
    const topLvCode = idSplit.at(-1);
    const targetTotalAmount = topLvGroupSum.find((c) => c.key === topLvCode);
    return (
      <div className="table-text-input-box" style={{ fontWeight: "bold" }}>
        <div>
          {Number(targetTotalAmount?.totalAmount ?? 0).toLocaleString()}
        </div>
      </div>
    );
  }

  const total = params.rowNode.children.reduce((sum, childId) => {
    const childRow = params.api.getRow(childId); // 자식 데이터 가져오기
    return sum + (childRow?.budget_cost || 0); // budgetAmount 합산
  }, 0);

  return (
    <div className="table-text-input-box" style={{ fontWeight: "bold" }}>
      <div>{Number(total).toLocaleString()}</div>
    </div>
  );
};

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M4.8 14.9004V17.9H7.79959L16.6464 9.0532L13.6468 6.05361L4.8 14.9004ZM18.9661 6.73352C19.0402 6.65952 19.0991 6.57162 19.1392 6.47485C19.1793 6.37809 19.2 6.27435 19.2 6.16959C19.2 6.06483 19.1793 5.9611 19.1392 5.86434C19.0991 5.76757 19.0402 5.67967 18.9661 5.60567L17.0943 3.73392C17.0203 3.65977 16.9324 3.60094 16.8357 3.5608C16.7389 3.52066 16.6352 3.5 16.5304 3.5C16.4256 3.5 16.3219 3.52066 16.2251 3.5608C16.1284 3.60094 16.0405 3.65977 15.9665 3.73392L14.5027 5.19772L17.5023 8.19732L18.9661 6.73352Z"
        fill="#344054"
      />
      <path d="M3 19.7H21V21.5H3V19.7Z" fill="#344054" />
    </svg>
  );
};
