import React from "react";
import { useRecoilState } from "recoil";
import { OrgSettingStatus, JobLevelSettingStatus } from "@/atoms/modalStatus";
import OrgSettingModal from "@/components/orgSettingModal/OrgSettingModal";
import JobLevelSettingModal from "@/components/jobLevelSettingModal/JobLevelSettingModal";
import SettingMenuCard from "@/components/settingMenuCard/SettingMenuCard";
import OrgSettingIcon from "@/components/Icons/OrgSettingIcon";
import JobLvSettingIcon from "@/components/Icons/JobLvSettingIcon";
import JobFnSettingIcon from "@/components/Icons/JobFnSettingIcon";

const OrganizationSettingPage = () => {
  const [orgSettingStatus, setOrgSettingStatus] =
    useRecoilState(OrgSettingStatus);

  const [jobLevelSettingStatus, setJobLevelSettingStatus] = useRecoilState(
    JobLevelSettingStatus
  );

  const openModal = (setValue, type) => {
    setValue((prev) => ({ ...prev, open: true, type }));
  };

  return (
    <>
      <div className="work-policy-setting-page-body">
        <div className="work-policy-setting-page-title">
          Organization Setting
        </div>

        <SettingMenuCard
          icon={<OrgSettingIcon />}
          title="Organization Chart"
          subtitle="Description for Organization Chart"
          onClick={() => openModal(setOrgSettingStatus)}
        />
        <SettingMenuCard
          icon={<JobLvSettingIcon />}
          title="Job Position"
          subtitle="Description for Job Position"
          onClick={() => openModal(setJobLevelSettingStatus, "level")}
        />
        <SettingMenuCard
          icon={<JobFnSettingIcon />}
          title="Job Grade"
          subtitle="Description for Job Grade"
          onClick={() => openModal(setJobLevelSettingStatus, "fn")}
        />
      </div>

      {orgSettingStatus.open && <OrgSettingModal />}
      {jobLevelSettingStatus.open && <JobLevelSettingModal />}
    </>
  );
};

export default OrganizationSettingPage;
