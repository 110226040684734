import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
} from "react";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import LineItemTable from "./LineItemTable";
import AnnualIncomeTable from "./AnnualIncomeTable";
import "./userPermTable.scss";

const UserPermTable = forwardRef(
  (
    {
      table_name,
      line_table_name,
      rows = [],
      columns = [],
      lineDataUse,
      lineColumns = [],
      soketData,
    },
    ref
  ) => {
    const apiRef = useGridApiRef();

    useImperativeHandle(ref, () => {
      return apiRef.current;
    });

    const lineDataRef = useMemo(() => {
      if (!lineDataUse) {
        return {
          getDetailPanelContent: () => null,
          getDetailPanelHeight: () => 0,
        };
      }

      return {
        getDetailPanelContent: (props) => {
          const { id, row } = props;

          if (line_table_name === "annual_income") {
            return (
              <AnnualIncomeTable
                line_table_name={line_table_name}
                lineColumns={lineColumns}
                parent_id={id}
                parent_row={row}
                parent_table_name={table_name}
                parent_api_ref={apiRef}
                soketData={soketData}
              />
            );
          }

          return (
            <LineItemTable
              line_table_name={line_table_name}
              lineColumns={lineColumns}
              parent_id={id}
              parent_row={row}
              parent_table_name={table_name}
              parent_api_ref={apiRef}
              soketData={soketData}
            />
          );
        },

        getDetailPanelHeight: ({ row }) => {
          const { lineData } = row;

          const height = (lineData ?? []).length * 50 + 58;

          if (height < 408) {
            return "auto";
          }

          return 408;
        },
      };
      // eslint-disable-next-line
    }, [lineDataUse, soketData, apiRef]);

    const handleCellEditStart = (params, event) => {
      if (event.type === "keydown") {
        event.defaultMuiPrevented = true;
      }
    };

    const initialStateValue = useMemo(() => {
      if (table_name === "permission_menu") {
        return {
          initialState: {
            pinnedColumns: {
              left: ["username", "email"],
            },
          },
        };
      }

      return {};
    }, []);

    const handleCellClick = useCallback(
      (params) => {
        const cellMode = apiRef.current.getCellMode(params.id, params.field);

        if (cellMode === "view" && params?.colDef?.editable) {
          apiRef.current.startCellEditMode({
            id: params.id,
            field: params.field,
          });
        }
      },
      [apiRef]
    );

    return (
      <div className="user-perm-table-total-body">
        <DataGridPro
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          disableRowSelectionOnClick
          {...lineDataRef}
          {...initialStateValue}
          rowHeight={40}
          columnHeaderHeight={40}
          sx={{
            ...materialTableStyle,
            ...TableScrollbarStyle,
          }}
          onCellEditStart={handleCellEditStart}
          hideFooter
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
          onCellClick={handleCellClick}
        />
      </div>
    );
  }
);

export default UserPermTable;
