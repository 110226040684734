import React, { useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Btn from "@/components/CustomIconBtn";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import InitialBudgetDetailModal from "./InitialBudgetDetailModal";

const InitialBudget = ({ items, column, type }) => {
  const { rows = [], detail_rows = [] } = items;

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalStatus = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <div className="cost-approval-modal-contents-body">
      <div className="cost-approval-modal-contents-title-box">
        <div className="cost-approval-modal-contents-title">
          {type === "initial_budget"
            ? "Initial Budget Approval"
            : "Vendor Invoice Approval"}
        </div>
        <Btn
          width={100}
          label="View Detail"
          borderColor="#00A6FF"
          textColor="#0080DB"
          onClick={handleModalStatus}
        />
      </div>
      <div className="cost-approval-modal-contents-box">
        <DataGridPro
          rows={rows}
          columns={column}
          rowHeight={28}
          columnHeaderHeight={28}
          sx={{
            ...materialTableStyle,
            ...TableScrollbarStyle,
          }}
          hideFooter
          disableColumnPinning
          disableColumnSelector
          disableDensitySelector
        />
      </div>
      {modalOpen && (
        <InitialBudgetDetailModal
          title={
            type === "initial_budget"
              ? "Initial Budget Detail"
              : "Vendor Invoice Detail"
          }
          open={modalOpen}
          onClose={handleModalStatus}
          rows={detail_rows}
          type={type}
        />
      )}
    </div>
  );
};

export default InitialBudget;
