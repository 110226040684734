import React from "react";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";
import AnnaulLeaveLine from "./AnnaulLeaveLine";

const AnnualLeaveTable = ({
  rows = [],
  column = [],
  selectionModelUse = false,
  selectionModel = [],
  handleSelection = () => {},
  rowHeight = 40,
  startMonth,
  endMonth,
  lineUse = true,
}) => {
  const apiRef = useGridApiRef();

  const lineStatus = !lineUse
    ? {}
    : {
        getDetailPanelContent: (params) => {
          return (
            <AnnaulLeaveLine
              params={params}
              startMonth={startMonth}
              endMonth={endMonth}
              apiRef={apiRef}
            />
          );
        },
        getDetailPanelHeight: () => {
          return 500;
        },
      };

  return (
    <div className="cost-menu-table-body">
      <DataGridPremium
        rows={rows}
        columns={column}
        rowHeight={rowHeight}
        columnHeaderHeight={rowHeight}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        {...lineStatus}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        checkboxSelection={selectionModelUse}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleSelection}
        apiRef={apiRef}
      />
    </div>
  );
};

export default AnnualLeaveTable;
