import React, { useMemo, useState } from "react";
import { useResetRecoilState } from "recoil";
import { ApprovalLineModalStatus } from "@/atoms/modalStatus";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import LoadingModal from "../LoadingModal";
import LabelTextArea from "../LabelTextArea";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const AddInterimPaymentModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  approval_user_list = [],
  project_name = "",
  project_code,
}) => {
  const [approvalName, setApprovalName] = useState(
    "Add Interim Payment Description"
  );
  const [interimPaymentAmount, setInterimPaymentAmount] = useState(0);
  const [remark, setRemark] = useState("");
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });
  const [attachmentList, setAttachmentList] = useState(null);
  const [dubleClick, setDubleClick] = useState(true);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*(\.\d{0,2})?$/;

    // if (value === "") {
    //   setValue(0);
    //   return;
    // }

    if (isValidNumber.test(value)) {
      setValue(value);
    }
  };

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const createInterimPayment = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const formData = new FormData();

      for (const file in attachmentList) {
        if (file !== "length" && file !== "item") {
          const targetFile = attachmentList[file];

          formData.append(`files`, targetFile);
        }
      }

      formData.append(
        "additionalData",
        JSON.stringify({
          project_code,
          approvalName,
          interimPaymentAmount,
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        })
      );

      const requestCreateInterimPayment = await userPermAxios.post(
        "/cost/create_interim_payment",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateInterimPayment;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);

      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmInterimPayment = () => {
    if (!dubleClick) {
      return;
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to create a new interim payment?",
      async () => {
        await createInterimPayment();
      },
      true
    );
  };

  const button_disabled = useMemo(() => {
    if (project_code === "overall") {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    if (interimPaymentAmount === 0 || interimPaymentAmount === "") {
      return true;
    }

    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (!attachmentList) {
      return true;
    }

    return false;
  }, [
    interimPaymentAmount,
    approvalLineData,
    project_code,
    attachmentList,
    approvalName,
  ]);

  return (
    <>
      {!dubleClick && <LoadingModal />}
      <PolicyModalBody
        policyName={approvalName}
        setPolicyName={setApprovalName}
        open={open}
        onClose={onClose}
        title={`Add ${project_name} Interim Payment`}
        save_btn_disabled={button_disabled}
        saveFn={confirmInterimPayment}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div className="work-policy-modal-swicth-btn-box">
              <div className="work-policy-modal-detail-contents-title">
                Interim Payment Amount
              </div>
              <NumberInput
                value={interimPaymentAmount}
                setValue={setInterimPaymentAmount}
                onChange={handleNumberInputValue}
              />
            </div>

            <div
              className="work-policy-modal-swicth-btn-box"
              style={{
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>

            <div className="work-policy-modal-swicth-btn-box">
              <div className="work-policy-modal-detail-contents-title">
                {`Required Attachment (${
                  attachmentList ? attachmentList.length : 0
                })`}
              </div>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                sx={{ fontSize: 14, textTransform: "none", width: 300 }}
              >
                Upload File
                <input
                  type="file"
                  multiple
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    const fileCount = e.target.files.length;
                    if (fileCount > 0) {
                      setAttachmentList(e.target.files);
                    } else {
                      setAttachmentList(null);
                    }
                  }}
                />
              </Button>
            </div>
            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default AddInterimPaymentModal;

const NumberInput = ({ value, setValue, onChange, disabled = false }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{ width: 167, boxSizing: "border-box" }}
    />
  );
};
