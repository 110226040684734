import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const SelectTable = ({
  rows = [],
  columns = [],
  selectModel = [],
  setSelectModel,
  checkboxSelection = true,
}) => {
  const handleSelectionChange = (selection) => {
    if (checkboxSelection) {
      setSelectModel(selection);
    }
  };

  return (
    <div
      className="select-data-table-table-box"
      style={{
        width: "100%",
        maxHeight: 400,
        height: "100%",
        boxSizing: "border-box",
        overflow: "auto",
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectModel}
        onRowSelectionModelChange={handleSelectionChange}
        rowHeight={28}
        columnHeaderHeight={28}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default SelectTable;
