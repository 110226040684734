import React from "react";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import dayjs from "dayjs";

const HRBigCalendarToolbar = (props, setTargetDate, setCalendarView) => {
  const { label, onView, view } = props;

  const handleView = (btnView) => {
    onView(btnView);
  };

  const viewList = [
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
  ];

  const handleDateMonth = (type) => {
    let addTarget = "month";
    let value = 1;

    if (view === "week") {
      value = 7;
    }

    if (view === "week" || view === "day") {
      addTarget = "day";
    }

    if (type === "PREV") {
      value = value * -1;
    }

    setTargetDate((prev) => {
      const nextValue = dayjs(prev).add(value, addTarget).format("YYYY-MM-DD");

      return new Date(nextValue);
    });

    // setCalendarView((prev) => {
    //   if (prev !== view) {
    //     return prev;
    //   }
    //   return view;
    // });
  };

  return (
    <div className="big-calendar-toolbar-total-body">
      <div className="big-calendar-toolbar-nav-btn-box">
        <ButtonGroup
          variant="outlined"
          className="big-calendar-toolbar-nav-detail-btn-box"
        >
          <Button
            className="big-calendar-toolbar-nav-detail-btn"
            onClick={() => handleDateMonth("PREV")}
          >
            <PrevBtnIcon />
          </Button>
          <Button
            className="big-calendar-toolbar-nav-detail-btn"
            onClick={() => handleDateMonth("NEXT")}
          >
            <NextBtnIcon />
          </Button>
        </ButtonGroup>

        <div className="big-calendar-toolbar-nav-today-btn-box">
          <Button
            variant="outlined"
            className="big-calendar-toolbar-nav-today-btn"
            onClick={() => {
              setTargetDate(new Date());
            }}
            sx={{ width: 124 }}
          >
            <div className="big-calendar-toolbar-btn-label">Today</div>
          </Button>
        </div>
      </div>
      <div className="big-calendar-toolbar-label-box">
        <div className="big-calendar-toolbar-label">{label}</div>
      </div>
      <ButtonGroup className="big-calendar-toolbar-view-btn-box">
        {viewList.map((c) => {
          const { label: btnLabel, value: btnValue } = c;

          return (
            <Button
              key={btnValue}
              className="big-calendar-toolbar-btn"
              onClick={() => handleView(btnValue)}
              sx={{ width: 110 }}
            >
              <div className="big-calendar-toolbar-btn-label">{btnLabel}</div>
            </Button>
          );
        })}
      </ButtonGroup>
    </div>
  );
};

export default HRBigCalendarToolbar;

const PrevBtnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M17.2865 20.7317C16.9209 21.0973 16.3315 21.0973 15.9659 20.7317L9.76608 14.5318C9.47511 14.2409 9.47511 13.7708 9.76608 13.4799L15.9659 7.28004C16.3315 6.91447 16.9209 6.91447 17.2865 7.28004C17.652 7.64561 17.652 8.23501 17.2865 8.60058L11.8849 14.0096L17.2939 19.4186C17.652 19.7767 17.652 20.3736 17.2865 20.7317Z"
        fill="#0080DB"
      />
    </svg>
  );
};

const NextBtnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M12.0173 20.7317C12.3829 21.0973 12.9723 21.0973 13.3379 20.7317L19.5377 14.5318C19.8287 14.2409 19.8287 13.7708 19.5377 13.4799L13.3379 7.28004C12.9723 6.91447 12.3829 6.91447 12.0173 7.28004C11.6517 7.64561 11.6517 8.23501 12.0173 8.60058L17.4189 14.0096L12.0098 19.4186C11.6517 19.7767 11.6517 20.3736 12.0173 20.7317Z"
        fill="#0080DB"
      />
    </svg>
  );
};
