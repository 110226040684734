import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { RechargeConditionStatus } from "@/atoms/modalStatus";
import PolicyModalBody from "../PolicyModalBody";
import LabelSelect from "@components/LabelSelect";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";

const RechargeConditionModal = () => {
  const {
    open,
    action_type,
    title,
    id,
    onClose,
    initialAnnualLeaveRechargeConditionName,
    initialCreateStartDateOption,
    initialNewcomerStartOption,
    initialRechargeDays,
    initialDefaultAnnualLeaveCount,
    initialAnnualLeaveAdditionalCount,
    initialAnnualLeaveAdditionalYear,
    initialAnnualLeaveAdditionalCycle,
    initialUnderDecimalOption,
    newcomerStartOptionsList = [],
    underDecimalOptionsList = [],
    reFresh,
  } = useRecoilValue(RechargeConditionStatus);

  const [policyName, setPolicyName] = useState(
    initialAnnualLeaveRechargeConditionName
  );

  const [startdateOption, setStartdateOption] = useState(
    initialCreateStartDateOption
  );
  const [newcomerStartOption, setNewcomerStartOption] = useState(
    initialNewcomerStartOption
  );

  const [rechargeDays, setRechargeDays] = useState(initialRechargeDays);

  const [defaultAnnualLeaveCount, setDefaultAnnualLeaveCount] = useState(
    initialDefaultAnnualLeaveCount
  );
  const [annualLeaveAdditionalCount, setAnnualLeaveAdditionalCount] = useState(
    initialAnnualLeaveAdditionalCount
  );
  const [annualLeaveAdditionalYear, setAnnualLeaveAdditionalYear] = useState(
    initialAnnualLeaveAdditionalYear
  );
  const [annualLeaveAdditionalCycle, setAnnualLeaveAdditionalCycle] = useState(
    initialAnnualLeaveAdditionalCycle
  );
  const [underDecimalOption, setUnderDecimalOption] = useState(
    initialUnderDecimalOption
  );

  const [dubleClick, setDubleClick] = useState(true);

  const saveBtnDisabled = useMemo(() => {
    if (
      policyName === "" ||
      startdateOption === "" ||
      newcomerStartOption === "" ||
      underDecimalOption === "" ||
      annualLeaveAdditionalYear <= 0 ||
      annualLeaveAdditionalCycle <= 0 ||
      defaultAnnualLeaveCount <= 0 ||
      annualLeaveAdditionalCount <= 0 ||
      rechargeDays <= 0
    ) {
      return true;
    }

    return false;
  }, [
    policyName,
    startdateOption,
    newcomerStartOption,
    rechargeDays,
    defaultAnnualLeaveCount,
    annualLeaveAdditionalCount,
    annualLeaveAdditionalYear,
    annualLeaveAdditionalCycle,
    underDecimalOption,
  ]);

  const handleNumberInputValue = (e, setValue) => {
    const value = e.target.value.replace(/\D/g, "");
    setValue(Number(value));
  };

  const sumbmitRechargeCondition = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const submitData = {
        policyName,
        startdateOption,
        newcomerStartOption,
        rechargeDays,
        defaultAnnualLeaveCount,
        annualLeaveAdditionalCount,
        annualLeaveAdditionalYear,
        annualLeaveAdditionalCycle,
        underDecimalOption,
      };

      if (action_type === "update") {
        submitData.id = id;
      }

      const requestCreateWorkPolicy = await userPermAxios.post(
        `/dayoff/${action_type}_recharge_condition`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreateWorkPolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      reFresh();

      if (msgStatus.status === "success") {
        onClose();
      }
    }
  };

  const saveRechargeCondition = () => {
    if (!dubleClick) {
      return;
    }

    let message;

    if (action_type === "create") {
      message = "Do you want to create a new annual leave policy?";
    }

    if (action_type === "update") {
      message = "Do you want to update a annual leave policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await sumbmitRechargeCondition();
      },
      true
    );
  };

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={792}
      button_disabled={false}
      dubleClick={dubleClick}
      save_btn_disabled={!dubleClick ? true : saveBtnDisabled}
      saveFn={saveRechargeCondition}
    >
      <div
        className="work-policy-modal-contents-box"
        style={{ maxHeight: 500, overflow: "auto" }}
      >
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-time-select-detail">
            <div className="work-policy-modal-detail-contents-title">
              Whether it is the accounting date or the date of joining
            </div>
            <LabelSelect
              value={startdateOption}
              setValue={setStartdateOption}
              list={create_startdate_option_list}
            />
          </div>
          <div className="work-policy-modal-time-select-detail">
            <div className="work-policy-modal-detail-contents-title">
              Annual Leave Expires after
            </div>
            <LabelSelect
              value={newcomerStartOption}
              setValue={setNewcomerStartOption}
              list={newcomerStartOptionsList}
            />
          </div>

          <div className="work-policy-modal-time-select-detail">
            <div className="work-policy-modal-detail-contents-title">
              Recharge Days
            </div>
            <NumberInput
              value={rechargeDays}
              setValue={setRechargeDays}
              onChange={handleNumberInputValue}
            />
          </div>

          <div className="work-policy-modal-time-picker-box">
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Base Annual Leave Granted
              </div>
              <NumberInput
                value={defaultAnnualLeaveCount}
                setValue={setDefaultAnnualLeaveCount}
                onChange={handleNumberInputValue}
              />
            </div>
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Additional Annual Leave Days Granted
              </div>
              <NumberInput
                value={annualLeaveAdditionalCount}
                setValue={setAnnualLeaveAdditionalCount}
                onChange={handleNumberInputValue}
              />
            </div>
          </div>

          <div className="work-policy-modal-time-picker-box">
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Years of Service for Additional Annual Leave
              </div>
              <NumberInput
                value={annualLeaveAdditionalYear}
                setValue={setAnnualLeaveAdditionalYear}
                onChange={handleNumberInputValue}
              />
            </div>
            <div className="work-policy-modal-time-picker-detail">
              <div className="work-policy-modal-detail-contents-title">
                Cycle for Additional Annual Leave Granting (Year)
              </div>
              <NumberInput
                value={annualLeaveAdditionalCycle}
                setValue={setAnnualLeaveAdditionalCycle}
                onChange={handleNumberInputValue}
              />
            </div>
          </div>
          <div className="work-policy-modal-time-select-detail">
            <div className="work-policy-modal-detail-contents-title">
              Rounding Rules for Decimals
            </div>
            <LabelSelect
              value={underDecimalOption}
              setValue={setUnderDecimalOption}
              list={underDecimalOptionsList}
            />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default RechargeConditionModal;

const create_startdate_option_list = [
  { name: "Accounting Date", value: "accounting date" },
  { name: "Date of Joining", value: "date of joining" },
];

const NumberInput = ({ value, setValue, onChange }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
    />
  );
};

const createMonthDayArr = (length) => {
  return Array.from({ length }).map((c, i) => {
    const value = i + 1 < 10 ? `0${i + 1}` : i + 1;

    return {
      name: value.toString(),
      value: value.toString(),
    };
  });
};
