import React, { useMemo, useState, forwardRef } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { OrgSettingStatus } from "@/atoms/modalStatus";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { RichTreeViewPro } from "@mui/x-tree-view-pro/RichTreeViewPro";
import { useTreeViewApiRef, useTreeItem2Utils } from "@mui/x-tree-view";
import { TreeItem2, TreeItem2Label } from "@mui/x-tree-view/TreeItem2";
import { TreeItem2LabelInput } from "@mui/x-tree-view/TreeItem2LabelInput";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalBody from "@components/ModalBody";
import IconInput from "@components/IconInput";
import PlusIcon from "@components/Icons/PlusIcon";
import ExpandIcon from "@components/Icons/ExpandIcon";
import CollapseIcon from "@components/Icons/CollapseIcon";
import SearchIcon from "@components/Icons/SearchIcon";
import MinusIcon from "@components/Icons/MinusIcon";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";
import "./orgSettingModal.scss";

const OrgSettingModal = () => {
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchItems, setSearchItems] = useState(0);
  const [searchToggle, setSearchToggle] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);
  const [updateNumber, setUpdateNumber] = useState(0);
  const [modalStatus, setModalStatus] = useRecoilState(OrgSettingStatus);

  const [flatData, setFlatData] = useState([]);
  const apiRef = useTreeViewApiRef();

  const query_org_setting_data = useQuery(
    ["org_setting_data", updateNumber],
    () => {
      return query_fetch_data();
    },
    {
      onSuccess: (result) => {
        const { data } = result;
        setFlatData(data);
        setSearchItems(data.length);
      },
    }
  );

  const { isLoading } = query_org_setting_data;

  const treeData = useMemo(() => {
    return createTreeData(flatData);
  }, [flatData, searchValue, expandedItems]);

  const onClose = () => {
    setModalStatus((prev) => ({ ...prev, open: false }));
  };

  const addNewOrg = (parent_id) => {
    let newId;
    let newOrder;

    if (flatData.length === 0) {
      newId = "0";
      newOrder = 1;
    } else {
      const ids = flatData.map((c) => Number(c.id));
      const orders = flatData.map((c) => Number(c.order));

      newId = (Math.max(...ids) + 1).toString();
      newOrder = Math.max(...orders);
    }

    setFlatData([
      ...flatData,
      {
        id: newId,
        parent_id: parent_id ?? newId,
        label: "New Item " + newId,
        order: newOrder,
        adding: true,
      },
    ]);
  };

  const deleteOrg = (itemId) => {
    const { getItemTree } = apiRef.current;
    const deleteTreeData = deleteParentAndChildItem(getItemTree(), itemId);

    const deleteFlatData = flattenTree(deleteTreeData);

    setFlatData(
      deleteFlatData.map((c) => {
        const { id, parent_id } = c;

        return { ...c, parent_id: parent_id ?? id };
      })
    );
  };

  const handleLabel = (itemId, value) => {
    setFlatData((prev) => {
      return prev.map((c) => {
        const { id, label } = c;

        if (id === itemId) {
          const result = { ...c, label: value };

          if (label !== value) {
            result.editing = true;
          }

          return { ...result };
        }

        return { ...c };
      });
    });
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    const expandedIds = flatData.filter((c) => !c.deleting).map((c) => c.id);

    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0 ? expandedIds : []
    );
  };

  const searchFieldToggle = () => {
    setSearchToggle((prev) => !prev);
  };

  const handleSearchValue = (e) => {
    const { getItemTree } = apiRef.current;
    const value = e.target.value;

    setSearchValue(value);

    const expandedIds = findParentIdsByLabel(getItemTree(), value);

    setExpandedItems(expandedIds);
  };

  const sumbmittedOrgData = async (updateData, addData, deleteData) => {
    if (!dubleClick) {
      return;
    }

    let msgStatus = {
      status: "success",
      message: "",
    };

    setDubleClick(false);

    try {
      const requestUpdateOrgData = await userPermAxios.post(
        "/hr/update_org_chart_setting",
        {
          updateData,
          addData,
          deleteData,
        }
      );

      const { success, message } = requestUpdateOrgData;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);
      setUpdateNumber((prev) => prev + 1);
    }
  };

  const saveOrgData = () => {
    const { getItemTree } = apiRef.current;

    const flatTreeData = flattenTreeData(updateParentAndOrder(getItemTree()));

    const itemByType = flatData.reduce(
      (acc, cur) => {
        const { id, adding, deleting, label } = cur;

        if (adding && !deleting) {
          acc.addData.push({ ...cur, name: label });
        }

        if (!adding && deleting) {
          acc.deleteData.push({ ...cur, name: label });
        }

        if (!adding && !deleting) {
          const findTarget = flatTreeData.find((c) => c.id === id);
          acc.updateData.push({
            ...cur,
            name: label,
            parent_id: findTarget.parent_id,
          });
        }

        return acc;
      },
      {
        updateData: [],
        addData: [],
        deleteData: [],
      }
    );

    const { updateData, addData, deleteData } = itemByType;

    if (
      updateData.length === 0 &&
      addData.length === 0 &&
      deleteData.length === 0
    ) {
      return create_alert_fn("info", "No information has changed.");
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to save it?",
      async () => {
        await sumbmittedOrgData(updateData, addData, deleteData);
      },
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      title="Organization Chart Setting"
      open={modalStatus.open}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <div className="org-setting-modal-contents-body">
        <div className="org-setting-modal-control-menu">
          <div className="org-setting-modal-control-menu-total-box">
            <div className="org-setting-modal-control-menu-title">
              {searchValue.length === 0 ? "All" : "Search Result"}
            </div>
            <div className="org-setting-modal-control-menu-title">
              ({searchItems})
            </div>
          </div>
          <div className="org-setting-modal-control-menu-btn-box">
            {searchToggle ? (
              <IconButton
                onClick={searchFieldToggle}
                className="org-setting-modal-control-menu-btn"
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <IconInput
                value={searchValue}
                onChange={handleSearchValue}
                placeholder="Search Here"
                rightIcon={
                  <IconButton
                    onClick={() => {
                      searchFieldToggle();
                      setSearchValue("");
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                }
              />
            )}
            <Tooltip title="To add top-level items" placement="top">
              <IconButton
                onClick={() => addNewOrg()}
                className="org-setting-modal-control-menu-btn"
              >
                <PlusIcon />
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={() => handleExpandClick()}
              className="org-setting-modal-control-menu-btn"
            >
              {expandedItems.length === 0 ? <ExpandIcon /> : <CollapseIcon />}
              {expandedItems.length === 0 ? (
                <div className="org-setting-modal-control-menu-btn-text">
                  Expand all
                </div>
              ) : (
                <div className="org-setting-modal-control-menu-btn-text">
                  Collapse all
                </div>
              )}
            </IconButton>
          </div>
        </div>
        <Box sx={{ height: 544, width: "100%", overflow: "auto" }}>
          <RichTreeViewPro
            apiRef={apiRef}
            items={treeData}
            experimentalFeatures={{
              indentationAtItemLevel: true,
              itemsReordering: true,
              labelEditing: true,
            }}
            itemsReordering
            isItemEditable
            slots={{ item: CustomTreeItem }}
            slotProps={{
              item: {
                apiRef,
                handleLabel,
                addNewOrg,
                deleteOrg,
                expandedItems,
                handleExpandedItemsChange,
              },
            }}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
          />
        </Box>
      </div>
      <div className="org-setting-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="org-setting-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            saveOrgData();
          }}
          className="org-setting-modal-update-btn"
          disabled={flatData.length === 0}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default OrgSettingModal;

function CustomLabelInput(props) {
  const { error, ...other } = props;

  return (
    <React.Fragment>
      <TreeItem2LabelInput
        {...other}
        sx={{
          display: "flex",
          padding: "4px 12px",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
          background: "#FFF",
          color: "#344054",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px,",
        }}
      />
      {/* {error ? (
        <Tooltip title={ERRORS[error]}>
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      ) : (
        <Tooltip title="All good!">
          <CheckCircleOutlineIcon color="success" />
        </Tooltip>
      )} */}
    </React.Fragment>
  );
}

const CustomLabel = (props) => {
  const {
    mainProps,
    addNewOrg,
    deleteOrg,
    apiRef,
    expandedItems,
    handleExpandedItemsChange,
    ...others
  } = props;
  const { itemId } = mainProps;

  const addChildrenItem = () => {
    addNewOrg(itemId);
    handleExpandedItemsChange(null, [...expandedItems, itemId]);
  };

  const deleteOrgItem = () => {
    deleteOrg(itemId);
  };

  return (
    <React.Fragment>
      <TreeItem2Label
        {...others}
        sx={{
          color: "#344054",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px,",
        }}
      />
      <Tooltip title="To add sub-items" placement="top">
        <IconButton
          onClick={addChildrenItem}
          className="org-setting-modal-control-menu-btn"
        >
          <PlusIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={deleteOrgItem}
        className="org-setting-modal-control-menu-btn"
      >
        <MinusIcon />
      </IconButton>
    </React.Fragment>
  );
};

const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
  const [error, setError] = useState(null);
  const {
    apiRef,
    handleLabel,
    addNewOrg,
    deleteOrg,
    expandedItems,
    handleExpandedItemsChange,
    ...othersProps
  } = props;
  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const validateLabel = (label) => {
    if (!label) {
      setError("REQUIRED");
    }
    // else if (/\d/.test(label)) {
    //   setError("INVALID");
    // }
    else {
      setError(null);
    }
  };

  const handleInputBlur = (event) => {
    if (error) {
      event.defaultMuiPrevented = true;
    }
    handleLabel(props.itemId, props.label);
  };

  const handleInputKeyDown = (event) => {
    event.defaultMuiPrevented = true;
    const target = event.target;

    if (event.key === "Enter" && target.value) {
      if (error) {
        return;
      }
      setError(null);
      interactions.handleSaveItemLabel(event, target.value);
      handleLabel(props.itemId, target.value);
    } else if (event.key === "Escape") {
      setError(null);
      handleLabel(props.itemId, target.value);
      interactions.handleCancelItemLabelEditing(event);
    }
  };

  const handleInputChange = (event) => {
    validateLabel(event.target.value);
  };

  return (
    <TreeItem2
      {...othersProps}
      ref={ref}
      slots={{ labelInput: CustomLabelInput, label: CustomLabel }}
      slotProps={{
        labelInput: {
          onBlur: handleInputBlur,
          onKeyDown: handleInputKeyDown,
          onChange: handleInputChange,
          error,
        },
        label: {
          mainProps: othersProps,
          apiRef: apiRef,
          expandedItems,
          handleExpandedItemsChange,
          addNewOrg,
          deleteOrg,
        },
      }}
    />
  );
});

const query_fetch_data = async () => {
  let result = {
    data: [],
  };

  try {
    const requestOrgSettingData = await userPermAxios.get(
      "/hr/organization_setting_data"
    );

    const { success, data } = requestOrgSettingData;

    if (success) {
      result.data = data.map((c) => {
        return {
          ...c,
          id: c.id.toString(),
          parent_id:
            c.parent_id === null ? c.id.toString() : c.parent_id.toString(),
          label: c.name,
        };
      });
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const createTreeData = (data) => {
  const map = {};
  const roots = [];

  // 각 아이템을 map에 추가
  data.forEach((item) => {
    if (!item.deleting) {
      map[item.id] = { ...item, children: [] };
    }
  });

  // 부모-자식 관계 설정
  data.forEach((item) => {
    if (!item.deleting) {
      if (item.parent_id !== item.id) {
        // 부모 아이템에 자식 아이템을 추가
        map[item.parent_id].children.push(map[item.id]);
      } else {
        // 부모가 없는 경우 최상위 루트로 설정
        roots.push(map[item.id]);
      }
    }
  });

  // 자식들을 order 필드로 정렬
  Object.values(map).forEach((item) => {
    item.children.sort((a, b) => a.order - b.order);
  });

  return roots.sort((a, b) => a.order - b.order);
};

//order 재지정
const updateParentAndOrder = (data, parentId = null) => {
  data.forEach((node, index) => {
    // 부모 ID와 order 값 수정
    node.parent_id = parentId;
    node.order = index + 1; // 1부터 시작하는 order 값 설정

    // children이 있으면 재귀적으로 호출
    if (node.children && node.children.length > 0) {
      updateParentAndOrder(node.children, node.id); // 현재 노드의 id를 부모 id로 설정
    }
  });
  return data;
};

//delte
const deleteParentAndChildItem = (data, targetId) => {
  data.forEach((item) => {
    // 대상 ID와 매칭되면 item.deleting 추가
    if (item.id === targetId) {
      markItemAndChildrenAsDeleted(item); // 부모와 자식 모두 처리
    } else if (item.children && item.children.length > 0) {
      // 대상이 아닌 경우에도 children이 있으면 탐색
      deleteParentAndChildItem(item.children, targetId);
    }
  });
  return data;
};

// 아이템과 하위 children 모두 item.deleting = true로 설정
function markItemAndChildrenAsDeleted(item) {
  item.deleting = true; // 해당 아이템 삭제 표시
  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
      markItemAndChildrenAsDeleted(child); // 재귀적으로 모든 자식 처리
    });
  }
}

function flattenTree(data) {
  let flatData = [];

  function recursiveFlatten(items, parentId = null) {
    items.forEach((item) => {
      const { children, ...rest } = item; // children 속성 제거
      flatData.push({ ...rest, parent_id: parentId }); // parent_id 추가

      // children이 있으면 재귀적으로 호출하여 평탄화
      if (children && children.length > 0) {
        recursiveFlatten(children, item.id);
      }
    });
  }

  recursiveFlatten(data);
  return flatData;
}

function findParentIdsByLabel(tree, label) {
  let parentIds = [];

  if (label.length === 0) {
    return parentIds;
  }

  function recursiveFind(node, parents) {
    if (node.label.includes(label)) {
      parentIds = [...parents];
      return true; // 찾으면 더 이상 순회하지 않음
    }

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        if (recursiveFind(child, [...parents, node.id])) {
          return true;
        }
      }
    }
    return false;
  }

  for (let rootNode of tree) {
    if (recursiveFind(rootNode, [])) {
      break;
    }
  }

  return parentIds;
}

const flattenTreeData = (tree) => {
  let flatData = [];

  const traverse = (node, parentId = null) => {
    // 현재 노드 추가
    flatData.push({
      id: node.id,
      parent_id: parentId,
      label: node.label,
      order: node.order,
    });

    // 자식 노드가 있으면 재귀적으로 탐색
    if (node.children && node.children.length > 0) {
      node.children.forEach((child, index) => {
        traverse(child, node.id); // 부모 id로 현재 노드 id 전달
      });
    }
  };

  // 트리의 각 루트 노드를 순회
  tree.forEach((rootNode) => {
    traverse(rootNode);
  });

  return flatData;
};
