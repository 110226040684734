import React from "react";

const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 8.75C9.6131 8.75 9.60125 8.75006 9.30556 8.75006H4.44941C4.0625 8.75006 3.75 9.16893 3.75 9.68753C3.75 10.2061 4.0625 10.625 4.44941 10.625L9.3006 10.625C10 10.625 9.6131 10.625 10 10.625C10.3869 10.625 7.40524 10.625 10.6994 10.625H15.5506C15.9375 10.625 16.25 10.2061 16.25 9.68753C16.25 9.16893 15.9375 8.75006 15.5506 8.75006L10.6994 8.75006C9.43935 8.75003 10.377 8.75 10 8.75Z"
        fill="#344054"
      />
    </svg>
  );
};

export default MinusIcon;
