import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  DataGridPremium,
  GridLogicOperator,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const MaterialMasterTable = forwardRef(
  (
    {
      table_name,
      rows,
      columns,
      isLoading,
      group_name,
      group_color,
      upperTablePinnedColumns = { upperTablePinnedColumns },
      filter_items = [],
    },
    ref
  ) => {
    const [filterModel, setFilterModel] = useState({
      items: [],
      logicOperator: GridLogicOperator.Or,
    });

    const [tableToggle, setTableToggle] = useState(true);

    const apiRef = useGridApiRef();

    // useImperativeHandle을 사용하여 부모 컴포넌트에 apiRef를 노출
    useImperativeHandle(ref, () => {
      return {
        apiRef: apiRef.current,
      };
    });

    useEffect(() => {
      setFilterModel((prev) => {
        return {
          ...prev,
          items: filter_items,
          logicOperator: GridLogicOperator.Or,
        };
      });
    }, [filter_items]);

    const tableMaxHeight = useMemo(() => {
      if (table_name === "material_master") {
        return "calc(100vh - 104px - 48px - 8px - 40px - 32px)";
      }

      return "500px";
    }, [table_name]);

    return (
      <div className="admin-table-body">
        <div className="admin-table-title">
          <IconButton
            tabIndex={-1}
            onClick={() => setTableToggle((prev) => !prev)}
          >
            <ExpandMoreIcon
              sx={{
                transform: `rotateZ(${tableToggle ? 360 : 180}deg)`,
                transition: (theme) =>
                  theme.transitions.create("transform", {
                    duration: theme.transitions.duration.shortest,
                  }),
                width: "24px",
                height: "24px",
                color: group_color,
              }}
            />
          </IconButton>

          <div
            style={{ color: group_color, cursor: "pointer" }}
            className="admin-table-title"
          >
            {group_name}
          </div>
        </div>
        <div className="admin-table-box">
          <div
            className="admin-table-color-line"
            style={{ backgroundColor: group_color }}
          />

          <div style={{ width: "calc(100% - 2px)", maxHeight: tableMaxHeight }}>
            <DataGridPremium
              rows={rows}
              // row={initialRows}
              columns={columns}
              hideFooter
              apiRef={apiRef}
              loading={isLoading}
              rowHeight={40}
              columnHeaderHeight={40}
              sx={{
                ...materialTableStyle,
                ...TableScrollbarStyle,
                ...{ ".table-text-input-box": { width: "auto" } },
              }}
              defaultGroupingExpansionDepth={tableToggle ? 1 : 0}
              disableColumnPinning
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pinnedColumns: { left: [""] },
                columns: {
                  columnVisibilityModel: {
                    mat_category: false,
                  },
                },
                rowGrouping: {
                  model: ["mat_category"],
                },
              }}
              filterModel={filterModel}
              onFilterModelChange={(e) => setFilterModel(e)}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default MaterialMasterTable;
