import React, { useMemo, useState, forwardRef } from "react";
import { Box } from "@mui/material";
import { RichTreeViewPro } from "@mui/x-tree-view-pro/RichTreeViewPro";
import { useTreeViewApiRef, useTreeItem2Utils } from "@mui/x-tree-view";
import { TreeItem2, TreeItem2Label } from "@mui/x-tree-view/TreeItem2";
import { TreeItem2LabelInput } from "@mui/x-tree-view/TreeItem2LabelInput";

import ModalBody from "@components/ModalBody";

const OrgSelectModal = ({ list, value, setValue, open, onClose }) => {
  const apiRef = useTreeViewApiRef();

  const treeData = useMemo(() => {
    return createTreeData(
      list.map((c) => {
        return {
          ...c,
          id: c.id.toString(),
          parent_id:
            c.parent_id === null ? c.id.toString() : c.parent_id.toString(),
          label: c.name,
        };
      })
    );
  }, [list]);

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      modalWidth={500}
      title="Select Organization"
    >
      <Box sx={{ height: 544, width: "100%", overflow: "auto" }}>
        <RichTreeViewPro
          apiRef={apiRef}
          items={treeData}
          checkboxSelection
          selectedItems={value.toString()}
          onSelectedItemsChange={(e, id) => setValue(Number(id))}
          experimentalFeatures={{
            indentationAtItemLevel: false,
            itemsReordering: false,
            labelEditing: false,
          }}
          itemsReordering
          isItemEditable
          slots={{ item: CustomTreeItem }}
          slotProps={{
            item: {
              apiRef,
            },
          }}
          expandedItems={list.map((c) => c.id.toString())}
        />
      </Box>
    </ModalBody>
  );
};

export default OrgSelectModal;

function CustomLabelInput(props) {
  const { error, ...other } = props;

  return (
    <React.Fragment>
      <TreeItem2LabelInput
        {...other}
        sx={{
          display: "flex",
          padding: "4px 12px",
          alignItems: "center",
          gap: "8px",
          alignSelf: "stretch",
          borderRadius: "8px",
          border: "1px solid #D0D5DD",
          background: "#FFF",
          color: "#344054",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px,",
        }}
      />
      {/* {error ? (
        <Tooltip title={ERRORS[error]}>
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      ) : (
        <Tooltip title="All good!">
          <CheckCircleOutlineIcon color="success" />
        </Tooltip>
      )} */}
    </React.Fragment>
  );
}

const CustomLabel = (props) => {
  const {
    mainProps,
    addNewOrg,
    deleteOrg,
    apiRef,
    expandedItems,
    handleExpandedItemsChange,
    ...others
  } = props;

  return (
    <React.Fragment>
      <TreeItem2Label
        {...others}
        sx={{
          color: "#344054",
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px,",
        }}
      />
    </React.Fragment>
  );
};

const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
  const { apiRef, ...othersProps } = props;

  return (
    <TreeItem2
      {...othersProps}
      ref={ref}
      slots={{ label: CustomLabel }}
      slotProps={{
        label: {
          mainProps: othersProps,
          apiRef: apiRef,
        },
      }}
    />
  );
});

const createTreeData = (data) => {
  const map = {};
  const roots = [];

  // 각 아이템을 map에 추가
  data.forEach((item) => {
    map[item.id] = { ...item, children: [] };
  });

  // 부모-자식 관계 설정
  data.forEach((item) => {
    if (item.parent_id !== item.id) {
      // 부모 아이템에 자식 아이템을 추가
      map[item.parent_id].children.push(map[item.id]);
    } else {
      // 부모가 없는 경우 최상위 루트로 설정
      roots.push(map[item.id]);
    }
  });

  // 자식들을 order 필드로 정렬
  Object.values(map).forEach((item) => {
    item.children.sort((a, b) => a.order - b.order);
  });

  return roots.sort((a, b) => a.order - b.order);
};

//order 재지정
const updateParentAndOrder = (data, parentId = null) => {
  data.forEach((node, index) => {
    // 부모 ID와 order 값 수정
    node.parent_id = parentId;
    node.order = index + 1; // 1부터 시작하는 order 값 설정

    // children이 있으면 재귀적으로 호출
    if (node.children && node.children.length > 0) {
      updateParentAndOrder(node.children, node.id); // 현재 노드의 id를 부모 id로 설정
    }
  });
  return data;
};

//delte
const deleteParentAndChildItem = (data, targetId) => {
  data.forEach((item) => {
    // 대상 ID와 매칭되면 item.deleting 추가
    if (item.id === targetId) {
      markItemAndChildrenAsDeleted(item); // 부모와 자식 모두 처리
    } else if (item.children && item.children.length > 0) {
      // 대상이 아닌 경우에도 children이 있으면 탐색
      deleteParentAndChildItem(item.children, targetId);
    }
  });
  return data;
};

// 아이템과 하위 children 모두 item.deleting = true로 설정
function markItemAndChildrenAsDeleted(item) {
  item.deleting = true; // 해당 아이템 삭제 표시
  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
      markItemAndChildrenAsDeleted(child); // 재귀적으로 모든 자식 처리
    });
  }
}

function flattenTree(data) {
  let flatData = [];

  function recursiveFlatten(items, parentId = null) {
    items.forEach((item) => {
      const { children, ...rest } = item; // children 속성 제거
      flatData.push({ ...rest, parent_id: parentId }); // parent_id 추가

      // children이 있으면 재귀적으로 호출하여 평탄화
      if (children && children.length > 0) {
        recursiveFlatten(children, item.id);
      }
    });
  }

  recursiveFlatten(data);
  return flatData;
}

function findParentIdsByLabel(tree, label) {
  let parentIds = [];

  if (label.length === 0) {
    return parentIds;
  }

  function recursiveFind(node, parents) {
    if (node.label.includes(label)) {
      parentIds = [...parents];
      return true; // 찾으면 더 이상 순회하지 않음
    }

    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        if (recursiveFind(child, [...parents, node.id])) {
          return true;
        }
      }
    }
    return false;
  }

  for (let rootNode of tree) {
    if (recursiveFind(rootNode, [])) {
      break;
    }
  }

  return parentIds;
}

const flattenTreeData = (tree) => {
  let flatData = [];

  const traverse = (node, parentId = null) => {
    // 현재 노드 추가
    flatData.push({
      id: node.id,
      parent_id: parentId,
      label: node.label,
      order: node.order,
    });

    // 자식 노드가 있으면 재귀적으로 탐색
    if (node.children && node.children.length > 0) {
      node.children.forEach((child, index) => {
        traverse(child, node.id); // 부모 id로 현재 노드 id 전달
      });
    }
  };

  // 트리의 각 루트 노드를 순회
  tree.forEach((rootNode) => {
    traverse(rootNode);
  });

  return flatData;
};
