import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const DayoffList = ({ items }) => {
  return (
    <div className="approval-modal-items-body">
      <div className="approval-modal-items-title-box">
        <div className="approval-modal-items-title">Detailed Schedule</div>
      </div>
      <div className="approval-modal-items-box">
        {items.map((c, i) => {
          const { id, date_year, date_month, date_day } = c;

          const date = `${date_year}-${date_month}-${date_day}`;

          const week = dayjs(date).format("ddd");

          return (
            <div
              key={id}
              className="approval-modal-items-work-list-card align-center"
            >
              <div className="using-menu-default-text">{`${
                i + 1
              }. ${date} (${week})`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DayoffList;
