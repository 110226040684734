import React, { useMemo, useState } from "react";
import PolicyModalBody from "../PolicyModalBody";
import LabelInput from "@components/LabelInput";
import LabelSelect from "@components/LabelSelect";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";

const DailyWageModal = ({
  open = false,
  onClose = () => {},
  year,
  month,
  refresh = () => {},
}) => {
  const [name, setName] = useState("Daily Wage User Name");
  const [paymentDate, setPaymentDate] = useState("01");
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [price, setPrice] = useState(0);
  const [dubleClick, setDubleClick] = useState(true);

  const handleNumberInputValue = (e, setValue) => {
    let value = e.target.value;

    const isValidNumber = /^-?\d*\.?\d*$/;

    // if (value === "") {
    //   setValue(0);
    //   return;
    // }

    if (isValidNumber.test(value)) {
      setValue(value);
    }
  };

  const save_btn_disabled = useMemo(() => {
    if (
      name.length === 0 ||
      paymentDate.length === 0 ||
      bankName.length === 0 ||
      bankAccountNumber.length === 0 ||
      price <= 0 ||
      price === ""
    ) {
      return true;
    }

    return false;
  }, [name, paymentDate, bankName, bankAccountNumber, price]);

  const createDailyWageItem = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestCreateDailyWageItem = await userPermAxios.post(
        `/payroll/create_daily_wage_item`,
        {
          name,
          year,
          month,
          paymentDate,
          bankName,
          bankAccountNumber,
          price,
        }
      );

      const { success, message } = requestCreateDailyWageItem;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmAddItem = () => {
    if (!dubleClick) {
      return;
    }

    let message = "Do you want to create a new daily wage item?";

    return create_alert_fn(
      "info",
      message,
      async () => {
        await createDailyWageItem();
      },
      true
    );
  };

  return (
    <PolicyModalBody
      policyName={name}
      setPolicyName={setName}
      title="Add Daily Wage User"
      open={open}
      onClose={onClose}
      save_btn_disabled={save_btn_disabled}
      saveFn={confirmAddItem}
      disableEscapeKeyDown={false}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-swicth-btn-box">
            <LabelInput
              label="Bank Name"
              value={bankName}
              setValue={setBankName}
              width="100px"
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <LabelInput
              label="Bank Account Number"
              value={bankAccountNumber}
              setValue={setBankAccountNumber}
              width="100px"
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Payment Day
            </div>
            <LabelSelect
              value={paymentDate}
              setValue={setPaymentDate}
              list={Array.from({ length: 31 }).map((c, i) => {
                return { name: (i + 1).toString(), value: (i + 1).toString() };
              })}
              width="167px"
            />
          </div>
          <div
            className="work-policy-modal-swicth-btn-box"
            style={{ height: 44 }}
          >
            <div className="work-policy-modal-detail-contents-title">
              Daily Wage
            </div>
            <NumberInput
              value={price}
              setValue={setPrice}
              onChange={handleNumberInputValue}
            />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default DailyWageModal;

const NumberInput = ({ value, setValue, onChange, disabled = false }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
      disabled={disabled}
      style={{ width: 167, boxSizing: "border-box" }}
    />
  );
};
