import React, { useMemo, useState } from "react";
import { useResetRecoilState, useRecoilState } from "recoil";
import {
  ApprovalLineModalStatus,
  BudgetChangeRowModalStatus,
} from "@/atoms/modalStatus";
import _ from "lodash";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import LoadingModal from "../LoadingModal";
import ImportModal from "./ImportModal";
import EditRowModal from "./EditRowModal";
import PlusIcon from "../Icons/PlusIcon";
import MinusIcon from "../Icons/MinusIcon";
import Btn from "../CustomIconBtn";
import { Button } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { budgetChangeTableColumn } from "./Column";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import VerticalSolidLine from "../VerticalSolidLine";
import BudgetChangeTable from "./BudgetChangeTable";
import LabelTextArea from "../LabelTextArea";

const AddBudgetChangeModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  approval_user_list = [],
  cost_code_list = [],
  project_name = "",
  project_code,
}) => {
  const [importModalStatus, setImportModalStatus] = useState(false);
  const [approvalName, setApprovalName] = useState(
    "Add Budget Change Description"
  );
  const [budgetChangeRow, setBudgetChangeRow] = useState([]);
  const [remark, setRemark] = useState("");
  const [attachmentList, setAttachmentList] = useState(null);
  const [selectionModel, setSelectModel] = useState([]);
  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });
  const [dubleClick, setDubleClick] = useState(true);
  const [budgetChangeRowModalStatus, setBudgetChangeRowModalStatus] =
    useRecoilState(BudgetChangeRowModalStatus);

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);
  const closeRowEditModal = useResetRecoilState(BudgetChangeRowModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const handleImportModal = () => {
    setImportModalStatus((prev) => !prev);
  };

  const handleRowAddModal = () => {
    const maxId = _.maxBy(budgetChangeRow, "id")?.id ?? 0;

    setBudgetChangeRowModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: closeRowEditModal,
        id: maxId + 1,
        cost_code: "",
        budget_change_cost: 0,
        remark: "",
        modal_type: "create",
      };
    });
  };

  const handleRowSelection = (e) => {
    setSelectModel(e);
  };

  const deleteRow = () => {
    const selectionItems = selectionModel.filter((c) => typeof c === "number");

    setBudgetChangeRow((prev) => {
      return prev.filter((c) => {
        const { id } = c;

        return !selectionItems.includes(id);
      });
    });
  };

  const button_disabled = useMemo(() => {
    if (budgetChangeRow.filter((c) => !c.notAccept).length === 0) {
      return true;
    }

    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    if (!attachmentList) {
      return true;
    }

    return false;
  }, [budgetChangeRow, approvalLineData, approvalName, attachmentList]);

  const createBudgetChange = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const formData = new FormData();

      for (const file in attachmentList) {
        if (file !== "length" && file !== "item") {
          const targetFile = attachmentList[file];

          formData.append(`files`, targetFile);
        }
      }

      formData.append(
        "additionalData",
        JSON.stringify({
          project_code,
          approvalName,
          budgetChangeRow,
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        })
      );

      const requestCreateBudgetTransfer = await userPermAxios.post(
        `/cost/create_budget_change`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const { success, message } = requestCreateBudgetTransfer;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }
      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirmBudgetChange = () => {
    if (!dubleClick) {
      return;
    }

    let message = "Do you want to create a new budget change?";

    return create_alert_fn(
      "info",
      message,
      async () => {
        createBudgetChange();
      },
      true
    );
  };

  return (
    <>
      {!dubleClick && <LoadingModal />}
      {importModalStatus && (
        <ImportModal
          open={importModalStatus}
          onClose={handleImportModal}
          setBudgetChangeRow={setBudgetChangeRow}
          cost_code_list={cost_code_list}
        />
      )}
      {budgetChangeRowModalStatus.open && (
        <EditRowModal
          setBudgetChangeRow={setBudgetChangeRow}
          cost_code_list={cost_code_list}
        />
      )}
      <PolicyModalBody
        policyName={approvalName}
        setPolicyName={setApprovalName}
        open={open}
        onClose={onClose}
        title={`Add ${project_name} Budget Change`}
        width={1140}
        disableEscapeKeyDown={true}
        save_btn_disabled={button_disabled}
        saveFn={confirmBudgetChange}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                gap: 8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Budget Change
                </div>
                <div
                  style={{
                    gap: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Btn
                    icon={<img src="/icons/excel_icon.png" alt="excel icon" />}
                    width={150}
                    onClick={handleImportModal}
                    label="Excel Import"
                  />
                  <VerticalSolidLine height="24px" />
                  <Btn
                    icon={<PlusIcon />}
                    width={40}
                    onClick={handleRowAddModal}
                  />
                  <Btn
                    icon={<MinusIcon />}
                    width={40}
                    disabled={selectionModel.length === 0}
                    onClick={deleteRow}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "300px",
                }}
              >
                <BudgetChangeTable
                  rowHeight={28}
                  rows={budgetChangeRow}
                  column={budgetChangeTableColumn}
                  selectionModelUse={true}
                  selectionModel={selectionModel}
                  handleSelection={handleRowSelection}
                />
              </div>
            </div>
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>
            <div className="work-policy-modal-swicth-btn-box">
              <div className="work-policy-modal-detail-contents-title">
                {`Required Attachment (${
                  attachmentList ? attachmentList.length : 0
                })`}
              </div>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                sx={{ fontSize: 14, textTransform: "none", width: 300 }}
              >
                Upload File
                <input
                  type="file"
                  multiple
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  onChange={(e) => {
                    const fileCount = e.target.files.length;
                    if (fileCount > 0) {
                      setAttachmentList(e.target.files);
                    } else {
                      setAttachmentList(null);
                    }
                  }}
                />
              </Button>
            </div>
            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default AddBudgetChangeModal;
