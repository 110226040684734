import React from "react";
import LabelSelect from "@components/LabelSelect";
import DayPicker from "@components/DatePicker";
import OrgSelectBtn from "../orgSettingModal/OrgSelectBtn";

const WorkPolicy = ({
  orgList = [],
  orgValue,
  jobPositionValue,
  jobPositionList = [],
  jobGradeValue,
  jobGradeList = [],
  reflectionDate,
  setOrgValue,
  setJobPositionValue,
  setJobGradeValue,
  setReflectionDate,
}) => {
  const handleReflectionDate = (e) => {
    const value = e;
    setReflectionDate(value);
  };

  return (
    <div className="user-work-policy-body">
      <div className="user-work-policy-contents-box">
        <div className="user-work-policy-contents-title">Department</div>
        <OrgSelectBtn list={orgList} value={orgValue} setValue={setOrgValue} />
      </div>

      <LabelSelect
        label="Job Position"
        value={jobPositionValue}
        setValue={setJobPositionValue}
        list={jobPositionList.map((c) => {
          return { name: c.name, value: c.id };
        })}
      />

      <LabelSelect
        label="Job Grade"
        value={jobGradeValue}
        setValue={setJobGradeValue}
        list={jobGradeList.map((c) => {
          return { name: c.name, value: c.id };
        })}
      />

      <div className="user-work-policy-contents-box">
        <div className="user-work-policy-contents-title">Reflection Date</div>
        <DayPicker
          value={reflectionDate}
          onChange={handleReflectionDate}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default WorkPolicy;
