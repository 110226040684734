import React from "react";
import PayrollManagement from "@/components/payrollManagement/PayrollManagement";

const PayrollManagementPage = () => {
  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title">Payroll Management</div>
      <div className="company-setting-contents-body">
        <PayrollManagement />
      </div>
    </div>
  );
};

export default PayrollManagementPage;
