import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { CustomNormalTextTypeCell, RechargeConditionCell } from "../Cells";

export const createUserSelectColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "policy_name",
      headerName: "Policy Name",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "org_setting_name",
      headerName: "Organization",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "lv_name",
      headerName: "Position",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "fn_name",
      headerName: "Grade",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "username",
      headerName: "Name",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const createRechargeColumn = (
  onClose,
  refresh,
  newcomerStartOptions,
  underDecimalOptions
) => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "detail_view_cell",
      headerName: "",
      width: 40,
      editable: false,
      resizable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <RechargeConditionCell
            params={params}
            onClose={onClose}
            refresh={refresh}
            newcomerStartOptionsList={newcomerStartOptions}
            underDecimalOptionsList={underDecimalOptions}
          />
        );
      },
    },
    {
      field: "annual_leave_recharge_condition_name",
      headerName: "Policy Name",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "create_startdate_option",
      headerName: "Accounting Date or Date of Joining",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "newcomer_start_option",
      headerName: "Annual Leave Expires after",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "under_decimal_option",
      headerName: "Rounding Rules for Decimals",
      flex: 1,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};
