import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

import {
  CustomNormalTextTypeCell,
  CustomDateTypeCell,
  CustomNumberTypeCell,
} from "../Cells";

export const createPRTableColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 200,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_description",
      headerName: "Description",
      flex: 1,
      minWidth: 350,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_category",
      headerName: "Category",
      data_type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_uom",
      headerName: "Unit",
      data_type: "string",
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const createPOTableColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "po_no",
      headerName: "PO Number",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "group_name",
      headerName: "Group Name",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "job_number",
      headerName: "Job Number",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "lead",
      headerName: "Project Name",
      flex: 1,
      minWidth: 150,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "vendor_name",
      headerName: "Vendor Name",
      width: 250,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "pr_date",
      headerName: "Date",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
    },
  ];
};

export const createPOLineTableColumn = () => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
      renderCell: (params) => {
        return <CustomDetailCheckRow params={params} />;
      },
    },
    {
      field: "po_no",
      headerName: "Po Number",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "mat_code",
      headerName: "Material Code",
      width: 200,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "mat_description",
      headerName: "Description",
      flex: 1,
      minWidth: 350,
      data_type: "string",
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },

    {
      field: "mat_uom",
      headerName: "Unit",
      data_type: "string",
      width: 100,
      editable: false,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "order_qnty",
      headerName: "Order Qty",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

const CustomDetailCheckRow = ({ params }) => {
  const { id, api } = params;

  if (id === Infinity) {
    return null;
  }

  return (
    <input
      type="checkbox"
      className="custom-check-box"
      checked={params.api.isRowSelected(params.id)}
      onChange={(event) => {
        if (event.target.checked) {
          api.selectRow(params.id, true);
        } else {
          api.selectRow(params.id, false);
        }
      }}
    />
  );
};
