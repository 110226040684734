import React, { useState } from "react";
import { IconButton } from "@mui/material";
import DecreaseIcon from "../Icons/DecreaseIcon";
import IncreaseIcon from "../Icons/IncreaseIcon";
import MinusIcon from "../Icons/MinusIcon";
import "./jobSettingCard.scss";
import DotIcon from "../Icons/DotIcon";
import { create_alert_fn } from "@/utils/createAlert";

const JobSettiingCard = ({
  card_id,
  label = "",
  inputMode,
  onChange,
  handleInputToggle,
  increaseOrder,
  decreaseOrder,
  deleteCard,
}) => {
  const [inputValue, setInputValue] = useState(label);

  const handleInputValue = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleInputKeyDown = (e) => {
    const { key } = e;

    if (key === "Escape") {
      setInputValue(label);
      handleInputToggle(card_id);
    }
    if (key === "Enter") {
      if (inputValue.length === 0) {
        setInputValue(label);
        return create_alert_fn("warning", "This is a required value.");
      }
      onChange(card_id, inputValue);
      handleInputToggle(card_id);
    }
  };

  return (
    <div className={`job-setting-card-body ${inputMode ? "focus" : ""}`}>
      <div className="job-setting-card-name-box">
        <DotIcon />
        {inputMode ? (
          <input
            value={inputValue}
            onChange={handleInputValue}
            onKeyDown={handleInputKeyDown}
            className="job-setting-card-name input"
          />
        ) : (
          <div
            className="job-setting-card-name"
            onDoubleClick={() => {
              handleInputToggle(card_id);
            }}
          >
            {label}
          </div>
        )}
      </div>
      <div className="job-setting-card-btn-box">
        <IconButton
          onClick={() => {
            increaseOrder(card_id);
          }}
        >
          <IncreaseIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            decreaseOrder(card_id);
          }}
        >
          <DecreaseIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            deleteCard(card_id);
          }}
        >
          <MinusIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default JobSettiingCard;
