import React from "react";
import { useQuery } from "react-query";
import OrgChartComponent from "@/components/orgChart/OrgChart";
import { userPermAxios } from "@/utils/customAxios";

const OrgChartMenu = () => {
  const query_organization_chart_data = useQuery(
    "organization_chart_data",
    () => {
      return query_fetch_data();
    },
    {
      initialData: {
        list: [],
      },
    }
  );

  const { isLoading, data } = query_organization_chart_data;

  const { list } = data;

  return (
    <div className="memu-total-body hr-menu-total-body">
      <div className="hr-menu-total-title-box">
        <div className="hr-menu-total-title">Organization Chart</div>
      </div>
      <div className="hr-menu-body">
        <OrgChartComponent data={list} />
      </div>
    </div>
  );
};

export default OrgChartMenu;

const query_fetch_data = async () => {
  const result = { list: [] };

  try {
    const request_org_chart_data = await userPermAxios.get(
      "/hr/organization_chart"
    );

    const { success, data } = request_org_chart_data;
    if (success) {
      result.list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
