import React, { useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { HrWorkPolicyData } from "@/atoms/modalStatus";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import HRBigCalendar from "@/components/hrBigCalendar/HRBigCalendar";
import { userPermAxios } from "@/utils/customAxios";

const HRWork = () => {
  const setHrWorkPolicyData = useSetRecoilState(HrWorkPolicyData);
  const [targetDate, setTargetDate] = useState(new Date());

  const queryValue = useMemo(() => {
    return ["hr_work_data", dayjs(targetDate).format("YYYY-MM")];
  }, [targetDate]);

  const query_hr_work_data = useQuery(
    queryValue,
    () => {
      return query_fetch_work_data(targetDate);
    },
    {
      initialData: {
        hoily_day_list: [],
        events_list: [],
      },
    }
  );

  const query_hr_policy_data = useQuery(
    "hr_policy_data",
    () => {
      return query_fetch_policy_data();
    },
    {
      onSuccess: (data) => {
        setHrWorkPolicyData((prev) => {
          return {
            ...prev,
            ...data,
          };
        });
      },
    }
  );

  const { data: hrWorkData = {} } = query_hr_work_data;

  const { isLoading: hrPolicyDataLoading } = query_hr_policy_data;

  const { hoily_day_list = [], events_list = [] } = hrWorkData;

  if (hrPolicyDataLoading) {
    return null;
  }

  return (
    <div
      className="memu-total-body"
      style={{ height: "calc(100vh - 104px - 48px - 8px - 16px)" }}
    >
      <HRBigCalendar
        targetDate={targetDate}
        setTargetDate={setTargetDate}
        query_value={queryValue}
        hoily_day_list={hoily_day_list}
        events_list={events_list}
      />
    </div>
  );
};

const query_fetch_work_data = async (targetDate) => {
  let result = {
    hoily_day_list: [],
    events_list: [],
  };

  try {
    const targetDateYear = dayjs(targetDate).format("YYYY");
    const targetDateMonth = dayjs(targetDate).format("MM");
    const targetDateDay = dayjs(targetDate).format("DD");

    const requestUserPolicy = await userPermAxios.get(
      `/hr/get_calendar_data?year=${targetDateYear}&month=${targetDateMonth}&day=${targetDateDay}`
    );

    const { success, data } = requestUserPolicy;

    if (success) {
      result = {
        ...data,
        day_off_weekday_list: JSON.parse(data.day_off_weekday_list ?? `[]`),
      };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_fetch_policy_data = async () => {
  let result = {
    work_policy_name: "Policy Name",
    working_time: "08:00:00",
    attendance_time: "09:00:00",
    break_time_start: "12:00:00",
    break_time_end: "13:00:00",
    day_off_weekday_list: [0, 6],
    annual_leave_approve_use: true,
    half_dayoff_use: true,
    work_type_policy_list: [],
    dayoff_policy_list: [],
    approval_user_list: [],
    annual_leave_policy_id: 0,
  };

  try {
    const requestUserPolicy = await userPermAxios.get("/hr/hr_work");

    const { success, data } = requestUserPolicy;

    if (success) {
      result = {
        ...data,
        day_off_weekday_list: JSON.parse(data.day_off_weekday_list ?? `[]`),
      };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

export default HRWork;
