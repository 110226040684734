import React from "react";

const DetailViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.7889 4.78577C3.62935 4.78577 3.5 4.91511 3.5 5.07467V5.50802C3.5 5.66758 3.62935 5.79693 3.7889 5.79693H4.22226C4.38181 5.79693 4.51116 5.66758 4.51116 5.50802V5.07467C4.51116 4.91511 4.38181 4.78577 4.22226 4.78577H3.7889Z"
        fill="#344054"
      />
      <path
        d="M5.86437 4.86256C5.67148 4.86256 5.51512 5.01613 5.51512 5.20556V5.37707C5.51512 5.5665 5.67148 5.72007 5.86437 5.72007H12.1508C12.3436 5.72007 12.5 5.5665 12.5 5.37707V5.20556C12.5 5.01613 12.3436 4.86256 12.1508 4.86256H5.86437Z"
        fill="#344054"
      />
      <path
        d="M5.86437 7.53954C5.67148 7.53954 5.51512 7.69311 5.51512 7.88255V8.05405C5.51512 8.24349 5.67148 8.39705 5.86437 8.39705H12.1508C12.3436 8.39705 12.5 8.24349 12.5 8.05405V7.88255C12.5 7.69311 12.3436 7.53954 12.1508 7.53954H5.86437Z"
        fill="#344054"
      />
      <path
        d="M5.51512 10.5912C5.51512 10.4017 5.67148 10.2482 5.86437 10.2482H12.1508C12.3436 10.2482 12.5 10.4017 12.5 10.5912V10.7627C12.5 10.9521 12.3436 11.1057 12.1508 11.1057H5.86437C5.67148 11.1057 5.51512 10.9521 5.51512 10.7627V10.5912Z"
        fill="#344054"
      />
      <path
        d="M3.5 7.78331C3.5 7.62375 3.62935 7.4944 3.7889 7.4944H4.22226C4.38181 7.4944 4.51116 7.62375 4.51116 7.78331V8.21666C4.51116 8.37622 4.38181 8.50556 4.22226 8.50556H3.7889C3.62935 8.50556 3.5 8.37622 3.5 8.21666V7.78331Z"
        fill="#344054"
      />
      <path
        d="M3.7889 10.203C3.62935 10.203 3.5 10.3324 3.5 10.4919V10.9253C3.5 11.0849 3.62935 11.2142 3.7889 11.2142H4.22226C4.38181 11.2142 4.51116 11.0849 4.51116 10.9253V10.4919C4.51116 10.3324 4.38181 10.203 4.22226 10.203H3.7889Z"
        fill="#344054"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.8C2 1.35817 2.35817 1 2.8 1H13.2C13.6418 1 14 1.35817 14 1.8V14.2C14 14.6418 13.6418 15 13.2 15H2V1.8ZM2.75 1.95C2.75 1.83954 2.83954 1.75 2.95 1.75H13.05C13.1605 1.75 13.25 1.83954 13.25 1.95V14.05C13.25 14.1605 13.1605 14.25 13.05 14.25H2.75V1.95Z"
        fill="#344054"
      />
    </svg>
  );
};

export default DetailViewIcon;
