import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "@styles/components.scss";

const CustomTimePicker = ({
  value = dayjs(new Date()),
  onChange = () => {},
  width = 300,
  disabled = false,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CustomInputTimePicker
        value={value}
        onChange={onChange}
        width={width}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;

const InputField = (props) => {
  const {
    open,
    setOpen,
    label,
    id,
    disabled = false,
    value,
    width = 300,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  const dateValue = dayjs(value).format("HH:mm");

  return (
    <div
      className="day-picker-input-body"
      id={id}
      ref={ref}
      onClick={() => {
        if (!disabled) {
          setOpen?.((prev) => !prev);
        }
      }}
      style={{ width }}
    >
      <ClockIcon />

      <div className="day-picker-input-value">{dateValue}</div>

      <div
        className="day-picker-input-btn-box"
        style={{
          rotate: open ? "180deg" : "360deg",
        }}
      >
        <UpDownIcon />
      </div>
    </div>
  );
};

const CustomInputTimePicker = (props) => {
  const { value, onChange, width, disabled = false } = props;
  const [open, setOpen] = useState(false);

  return (
    <TimePicker
      slots={{ ...props.slots, field: InputField }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, open, width, disabled, value },
      }}
      {...props}
      format="HH:mm"
      ampm={false}
      open={open}
      value={value}
      onChange={onChange}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

const ClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 4.625C8 4.41789 7.83211 4.25 7.625 4.25C7.41789 4.25 7.25 4.41789 7.25 4.625V8.75C7.25 8.88457 7.32211 9.00883 7.43895 9.07559L10.0639 10.5756C10.2438 10.6783 10.4728 10.6159 10.5756 10.4361C10.6783 10.2562 10.6159 10.0272 10.4361 9.92441L8 8.53238V4.625Z"
        fill="#344054"
      />
      <path
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.1005 13.25 2.75 10.8995 2.75 8C2.75 5.1005 5.1005 2.75 8 2.75C10.8995 2.75 13.25 5.1005 13.25 8Z"
        fill="#344054"
      />
    </svg>
  );
};

const UpDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.4858 7.33714C14.7469 7.59826 14.7469 8.01926 14.4858 8.28038L10.0573 12.7088C9.84951 12.9167 9.51377 12.9167 9.30594 12.7088L4.87748 8.28038C4.61636 8.01926 4.61636 7.59826 4.87748 7.33714C5.13861 7.07601 5.55961 7.07601 5.82073 7.33714L9.68431 11.1954L13.5479 7.33181C13.8037 7.07602 14.23 7.07601 14.4858 7.33714Z"
        fill="#344054"
      />
    </svg>
  );
};
