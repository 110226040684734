import React, { useState } from "react";
import { IconButton } from "@mui/material";
import LoadingModal from "@components/LoadingModal";
import { exportMatExcelFile } from "@utils/adminTableCellFn";
import { downloadBase64File } from "@/utils/downloadFns";
import { create_alert_fn } from "@/utils/createAlert";

const ExcelExportBtn = ({ table_name, disabled, ids = [], invValue }) => {
  const [dubleClick, setDubleClick] = useState(true);

  const exportExcel = async () => {
    if (!dubleClick) return;

    const result = {
      type: "warning",
      message: "",
      fn: () => {},
    };

    setDubleClick(false);

    try {
      const requsetExportExcel = await exportMatExcelFile(
        table_name,
        ids,
        invValue
      );

      const { success, data, message } = requsetExportExcel;

      if (success) {
        result.type = "success";
        result.message = message;
        downloadBase64File(data, `${table_name}.xlsx`);
      }
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        result.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(result.type, result.message, result.fn);
    }
  };

  return (
    <>
      <IconButton
        className="excel_import_export_btn"
        sx={{
          width: "134px",
          padding: "10px 16px",
          gap: "8px",
          color: "#98a2b3",
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #d0d5dd)",
          background: "var(--White, #fff)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          textTransform: "none",
          fontFamily: "Inter",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "20px,",
        }}
        onClick={exportExcel}
      >
        <img src="/icons/excel_icon.png" alt="export_icon" />
        Export
      </IconButton>
      {!dubleClick && <LoadingModal msg="Please wait a moment..." />}
    </>
  );
};

export default ExcelExportBtn;
