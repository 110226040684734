import React, { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { JobLevelSettingStatus } from "@/atoms/modalStatus";
import { Button, IconButton, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import _ from "lodash";
import ModalBody from "@components/ModalBody";
import IconInput from "@components/IconInput";
import PlusIcon from "@components/Icons/PlusIcon";
import SearchIcon from "@components/Icons/SearchIcon";
import JobSettiingCard from "@components/jobSettingCard/JobSettiingCard";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";

import "./jobLevelSettingModal.scss";
import { useQuery } from "react-query";

const JobLevelSettingModal = () => {
  const [list, setList] = useState(initialItems);
  const [searchValue, setSearchValue] = useState("");
  const [searchToggle, setSearchToggle] = useState(false);
  const [dubleClick, setDubleClick] = useState(true);
  const [updateNumber, setUpdateNumber] = useState(0);
  const [modalStatus, setModalStatus] = useRecoilState(JobLevelSettingStatus);

  const { open, type } = modalStatus;

  const query_job_level_fn_data = useQuery(
    ["job_level_fn_data", type, updateNumber],
    () => {
      return query_fetch_data(type);
    },
    {
      onSuccess: (result) => {
        const { data } = result;

        setList(data);
      },
    }
  );

  const { isLoading } = query_job_level_fn_data;

  const orderAndFilterList = useMemo(() => {
    return _.sortBy(
      list.filter((c) => {
        if (c.deleting) {
          return false;
        }

        if (searchValue.length === 0) {
          return true;
        }

        return c.name.includes(searchValue);
      }),
      "order"
    );
  }, [list, searchValue]);

  const searchFieldToggle = () => {
    setSearchToggle((prev) => !prev);
  };

  const handleSearchValue = (e) => {
    const value = e.target.value;

    setSearchValue(value);
  };

  const onClose = () => {
    setModalStatus((prev) => ({ ...prev, open: false }));
  };

  const addNewJobLevel = () => {
    let latestId = 0;

    let latestOrder = 0;

    if (list.length > 0) {
      latestId = Math.max(...list.map((c) => c.id));
      latestOrder = Math.max(...list.map((c) => c.order));
    }

    setList((prev) => [
      ...prev,
      {
        id: latestId + 1,
        name: "New Item " + (latestId + 1),
        order: latestOrder + 1,
        adding: true,
      },
    ]);
  };

  const deleteJobItem = (id) => {
    setList((prev) => {
      return prev.map((c) => {
        if (c.id === id) {
          return { ...c, deleting: true };
        }

        return c;
      });
    });
  };

  const handleInputToggle = (id) => {
    setList((prev) => {
      return prev.map((c) => {
        if (c.id === id) {
          return { ...c, inputToggle: !c.inputToggle };
        }

        return c;
      });
    });
  };

  const handleInputValue = (id, value) => {
    setList((prev) => {
      return prev.map((c) => {
        if (c.id === id) {
          return { ...c, name: value };
        }

        return c;
      });
    });
  };

  const increaseOrder = (id) => {
    const deleteItems = list.filter((c) => c.deleting);
    const activeItems = list.filter((c) => !c.deleting);

    const findTargetListIndex = activeItems.findIndex((c) => c.id === id);
    if (findTargetListIndex <= 0) {
      return;
    }
    const prevListIndex = findTargetListIndex - 1;

    const copy_list = _.sortBy([...activeItems], "order");

    const tempOrder = copy_list[findTargetListIndex].order;

    // Swap the orders
    copy_list[findTargetListIndex].order = copy_list[prevListIndex].order;
    copy_list[prevListIndex].order = tempOrder;

    // Update the state with the new list
    setList([..._.sortBy(copy_list, "order"), ...deleteItems]);
  };

  const decreaseOrder = (id) => {
    const deleteItems = list.filter((c) => c.deleting);
    const activeItems = list.filter((c) => !c.deleting);

    const findTargetListIndex = activeItems.findIndex((c) => c.id === id);

    if (
      findTargetListIndex < 0 ||
      findTargetListIndex === activeItems.length - 1
    ) {
      return;
    }
    const nextListIndex = findTargetListIndex + 1;

    const copy_list = _.sortBy([...activeItems], "order");

    const tempOrder = copy_list[findTargetListIndex].order;

    // Swap the orders
    copy_list[findTargetListIndex].order = copy_list[nextListIndex].order;
    copy_list[nextListIndex].order = tempOrder;

    setList([..._.sortBy(copy_list, "order"), ...deleteItems]);
  };

  const submittedSettingData = async (updateData, addData, deleteData) => {
    if (!dubleClick) {
      return;
    }

    let msgStatus = {
      status: "success",
      message: "",
    };

    setDubleClick(false);

    try {
      const requestUpdateOrgData = await userPermAxios.post(
        `/hr/${type}/update_job_level_fn_setting`,
        {
          updateData,
          addData,
          deleteData,
        }
      );

      const { success, message } = requestUpdateOrgData;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);
      setUpdateNumber((prev) => prev + 1);
    }
  };

  const saveSettingData = () => {
    const itemByType = list.reduce(
      (acc, cur) => {
        const { adding, deleting } = cur;

        if (adding && !deleting) {
          acc.addData.push({ ...cur });
        }

        if (!adding && deleting) {
          acc.deleteData.push({ ...cur });
        }

        if (!adding && !deleting) {
          acc.updateData.push({ ...cur });
        }

        return acc;
      },
      {
        updateData: [],
        addData: [],
        deleteData: [],
      }
    );

    const { updateData, addData, deleteData } = itemByType;

    if (
      updateData.length === 0 &&
      addData.length === 0 &&
      deleteData.length === 0
    ) {
      return create_alert_fn("info", "No information has changed.");
    }

    return create_alert_fn(
      "info",
      "Are you sure you want to save it?",
      async () => {
        await submittedSettingData(updateData, addData, deleteData);
      },
      true
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      title={type === "level" ? "Job Position Setting" : "Job Grade Setting"}
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={true}
    >
      <div className="job-level-setting-modal-contents-body">
        <div className="job-level-setting-modal-control-menu">
          <div className="job-level-setting-modal-control-menu-total-box">
            <div className="job-level-setting-modal-control-menu-title">
              {searchValue.length === 0 ? "All" : "Search Result"}
            </div>
            <div className="job-level-setting-modal-control-menu-title">
              ({orderAndFilterList.length})
            </div>
          </div>
          <div className="job-level-setting-modal-control-menu-btn-box">
            {searchToggle ? (
              <IconButton
                onClick={searchFieldToggle}
                className="job-level-setting-modal-control-menu-btn"
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <IconInput
                value={searchValue}
                onChange={handleSearchValue}
                placeholder="Search Here"
                rightIcon={
                  <IconButton
                    onClick={() => {
                      searchFieldToggle();
                      setSearchValue("");
                    }}
                  >
                    <CloseOutlinedIcon fontSize="small" />
                  </IconButton>
                }
              />
            )}
            <Tooltip title="To add new job level items" placement="top">
              <IconButton
                onClick={() => addNewJobLevel()}
                className="job-level-setting-modal-control-menu-btn"
              >
                <PlusIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="job-level-setting-modal-contents-box">
          {orderAndFilterList.map((c) => {
            return (
              <JobSettiingCard
                key={c.id}
                card_id={c.id}
                label={c.name}
                inputMode={c.inputToggle}
                handleInputToggle={handleInputToggle}
                onChange={handleInputValue}
                increaseOrder={increaseOrder}
                decreaseOrder={decreaseOrder}
                deleteCard={deleteJobItem}
              />
            );
          })}
        </div>
      </div>

      <div className="job-level-setting-modal-btn-box">
        <Button
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          onClick={onClose}
          className="job-level-setting-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            saveSettingData();
          }}
          className="job-level-setting-modal-update-btn"
          disabled={list.filter((c) => !c.deleting).length === 0}
        >
          Save
        </Button>
      </div>
    </ModalBody>
  );
};

export default JobLevelSettingModal;

const initialItems = [
  { id: 1, order: 1, name: "Item 1" },
  { id: 2, order: 2, name: "Item 2" },
  { id: 3, order: 3, name: "Item 3" },
  { id: 4, order: 4, name: "Item 4" },
];

const query_fetch_data = async (type) => {
  const result = { data: [] };

  try {
    const requestJobLevelFnData = await userPermAxios.get(
      `/hr/${type}/get_job_level_fn_setting_data`
    );

    const { success, data } = requestJobLevelFnData;

    if (success) {
      result.data = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
