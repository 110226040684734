import React from "react";

import SwicthBtn from "@components/SwicthBtn";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import "./settingMenuCard.scss";

const SettingMenuCard = ({
  icon,
  title = "",
  titlePart,
  subtitle = "",
  toggleUse = false,
  toggleValue = false,
  toggleFn = () => {},
  approvedReqUse = false,
  approvedReqValue = false,
  onClick = () => {},
}) => {
  const handleWwitchToggle = (e) => {
    e.stopPropagation();
    toggleFn();
  };

  const eventStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="hr-setting-menu-card-body" onClick={onClick}>
      <div className="hr-setting-menu-card-left-body">
        {icon}
        <div className="hr-setting-menu-card-title-box">
          <div className="hr-setting-menu-card-title-detail-box">
            <div className="hr-setting-menu-card-title">{title}</div>
            {titlePart && (
              <div className="hr-setting-menu-card-title-part">{titlePart}</div>
            )}
          </div>
          {subtitle && subtitle.length > 0 && (
            <div className="hr-setting-menu-card-subtitle">{subtitle}</div>
          )}
        </div>
      </div>
      <div className="hr-setting-menu-card-right-body">
        {toggleUse && (
          <SwicthBtn checked={toggleValue} onClick={handleWwitchToggle} />
        )}
        {approvedReqUse && (
          <div
            className="hr-setting-menu-card-approved-req-box"
            onClick={eventStopPropagation}
          >
            <div
              className={`hr-setting-menu-card-approved-req-dot ${
                approvedReqValue ? "use" : ""
              }`}
            />
            <div className="hr-setting-menu-card-approved-req">
              Approve Req.
            </div>
          </div>
        )}

        <RightArrowIcon />
      </div>
    </div>
  );
};

export default SettingMenuCard;
