import React from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { CandidateListModalStatus } from "@/atoms/modalStatus";
import ModalBody from "@components/ModalBody";
import ManpowerRequestTable from "./ManpowerRequestTable";
import { candidateListColumn } from "./Column";
import { userPermAxios } from "@/utils/customAxios";

const CandidateListModal = () => {
  const { open, onClose, id } = useRecoilValue(CandidateListModalStatus);

  const query_candidate_list_data = useQuery(["candidate_list_data", id], () =>
    query_fetch_data(id)
  );

  const { data = {}, isLoading } = query_candidate_list_data;

  const { list = [] } = data;

  if (isLoading) {
    return null;
  }

  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="Candidate List"
      modalWidth={1140}
      modalHeight={322}
    >
      <div
        className="work-policy-modal-contents-box"
        style={{ height: "100%" }}
      >
        <div
          className="work-policy-modal-detail-contents-box"
          style={{ height: "211px" }}
        >
          <ManpowerRequestTable
            rowHeight={28}
            rows={list}
            column={candidateListColumn}
          />
        </div>
      </div>
    </ModalBody>
  );
};

export default CandidateListModal;

const query_fetch_data = async (id) => {
  let result = {
    list: [],
  };

  try {
    const requestDetailData = await userPermAxios.get(
      `/hr/get_candidate_list_data?id=${id}`
    );
    const { success, data } = requestDetailData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
