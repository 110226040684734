import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { UserTargetProject, UserSetting } from "@atoms/userInfo";
import { ThemeProvider } from "@emotion/react";
import {
  PwSettingModalStatus,
  UserSettingModalStatus,
} from "@atoms/modalStatus";
import { useQuery } from "react-query";
import ChangePasswordModal from "@/components/userSetting/ChangePasswordModal";
import UserSettingModal from "@/components/userSetting/UserSettingModal";
import { userPermAxios } from "@utils/customAxios";
import allResetStates from "@/utils/allResetStates";
import { labelSelectTheme } from "@styles/muiThemes";

const Header = () => {
  const { pathname } = useLocation();
  const [, path] = pathname.split("/");
  const navigate = useNavigate();
  const setPwSettingModalStatus = useSetRecoilState(PwSettingModalStatus);
  const setUserSettingModalStatus = useSetRecoilState(UserSettingModalStatus);
  const userSetting = useRecoilValue(UserSetting);

  const name = userSetting?.name ?? "";
  const admin_user = userSetting?.admin_user ?? "";

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const dropdownRef = useRef(null); // Ref for the dropdown

  const query_key = useMemo(() => ["user_project_list"], []);

  const query_table_data = useQuery(query_key, () => query_fetch_data(), {
    initialData: { project_list: [] },
  });

  const query_approval_count = useQuery(
    "approval_count",
    () => {
      return query_fetch_approval_count_data();
    },
    { initialData: { approval_count: 0 } }
  );

  const { project_list } = query_table_data.data;

  const { approval_count } = query_approval_count.data;

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleLogoutClick = async () => {
    setDropdownVisible(false);

    await userPermAxios.get("/logout").catch((err) => console.log(err));

    allResetStates();

    window.location.replace("/login");
  };

  useEffect(() => {
    // Function to check if clicked outside of dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    // Adding click listener to document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const pageTransition = useMemo(() => {
    if (path === "admin") {
      return (
        <div
          className="layout-header-dropdown-item"
          onClick={() => {
            navigate("/home");
          }}
        >
          Home
        </div>
      );
    } else {
      return (
        <div
          className="layout-header-dropdown-item"
          onClick={() => {
            if (admin_user) {
              navigate("/admin");
            }
          }}
        >
          Admin Page
        </div>
      );
    }
  }, [path, admin_user, navigate]);

  return (
    <div className="layout-header-body">
      <ChangePasswordModal />
      <UserSettingModal />
      <a
        href="https://www.plantmodule.co.th/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="layout-header-logo"
          alt="Logo"
          src="/logo/logo-plant.png"
        />
      </a>
      <div className="layout-header-right-box">
        {path !== "admin" && <ProjectList project_list={project_list} />}
        {approval_count > 0 && (
          <div className="layout-header-approval-count-body">
            {approval_count}
          </div>
        )}
        <div ref={dropdownRef}>
          <div
            style={{ cursor: "pointer" }}
            onClick={toggleDropdown}
            className="layout-header-user-icon-box"
          >
            <div className="layout-header-user-icon">
              <UserIcon />
            </div>
            <div className="layout-header-user-name">{name}</div>
          </div>
          {isDropdownVisible && (
            <div className="layout-header-dropdown-box">
              {admin_user && pageTransition}
              <div
                className="layout-header-dropdown-item"
                onClick={() => {
                  navigate("/home/approve");
                }}
              >
                Approve
              </div>
              <div
                className="layout-header-dropdown-item"
                onClick={() => {
                  setUserSettingModalStatus((prev) => ({
                    ...prev,
                    open: true,
                  }));
                }}
              >
                Settings
              </div>
              <div
                className="layout-header-dropdown-item"
                onClick={() => {
                  setPwSettingModalStatus((prev) => ({ ...prev, open: true }));
                }}
              >
                Change Password
              </div>
              <div
                onClick={handleLogoutClick}
                className="layout-header-dropdown-item"
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

const query_fetch_data = async () => {
  const result = { project_list: [] };

  try {
    const requestProjectList = await userPermAxios.get(
      "/get-user-project-list"
    );

    const { success, data } = requestProjectList;
    if (success) {
      result.project_list = [
        { project_code: "overall", project_name: "Overall" },
        ...data,
      ];
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_fetch_approval_count_data = async () => {
  const result = { approval_count: 0 };

  try {
    const requestProjectList = await userPermAxios.get(
      "/approval/get_approval_count"
    );

    const { success, data } = requestProjectList;
    if (success) {
      result.approval_count = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const ProjectList = ({ project_list }) => {
  const [userTargetProject, setUserTargetProject] =
    useRecoilState(UserTargetProject);

  const handleChange = (event) => {
    const findValue = project_list.find(
      (c) => c.project_code === event.target.value
    );

    setUserTargetProject(findValue);
  };

  return (
    <ThemeProvider theme={labelSelectTheme}>
      <Select
        value={userTargetProject.project_code}
        onChange={handleChange}
        className="layout-header-project-list-selector"
        sx={{
          width: 220,
        }}
      >
        {project_list.map((option, id) => (
          <MenuItem key={id} value={option.project_code}>
            {option.project_name}
          </MenuItem>
        ))}
      </Select>
    </ThemeProvider>
  );
};

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M4.0415 15.75C4.0415 15.75 2.9165 15.75 2.9165 14.625C2.9165 13.5 4.0415 10.125 9.6665 10.125C15.2915 10.125 16.4165 13.5 16.4165 14.625C16.4165 15.75 15.2915 15.75 15.2915 15.75H4.0415Z"
        fill="#F9FAFB"
      />
      <path
        d="M9.6665 9C11.5305 9 13.0415 7.48896 13.0415 5.625C13.0415 3.76104 11.5305 2.25 9.6665 2.25C7.80254 2.25 6.2915 3.76104 6.2915 5.625C6.2915 7.48896 7.80254 9 9.6665 9Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
