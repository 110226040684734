import React from "react";
import BudgetSheetTable from "./BudgetSheetTable";
import { contractAmountColumn, budgetSheetTableColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";

const BudgetSheet = ({
  project_code = "overall",
  contract_amount = [],
  budget_sheet_list = [],
  top_level_group_sum = [],
}) => {
  const { height } = useCostTableHeight(project_code !== "overall", true);

  return (
    <div className="cost-menu-body">
      {project_code !== "overall" && (
        <div className="cost-menu-contract-amount-body">
          <div className="cost-menu-table-title-box">
            <div className="cost-menu-table-title">Summary</div>
          </div>
          <BudgetSheetTable
            rows={contract_amount}
            column={contractAmountColumn}
          />
        </div>
      )}
      <div className="cost-menu-detail-body">
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Sheet</div>
        </div>
        <BudgetSheetTable
          rows={budget_sheet_list}
          column={budgetSheetTableColumn(top_level_group_sum)}
          row_group_model={["lv_0_code", "lv_1_code"]}
          columnVisibilityModel={{
            lv_0_code: false,
            lv_1_code: false,
            lv_2_code: false,
          }}
        />
      </div>
    </div>
  );
};

export default BudgetSheet;
