import React from "react";
import ModalBody from "../ModalBody";
import { historyColumn } from "./Columns";
import PayrollTable from "./PayrollTable";

const AnnalSalaryModal = ({ open, onClose = () => {}, row = [] }) => {
  return (
    <ModalBody
      open={open}
      onClose={onClose}
      title="History"
      modalWidth={840}
      modalHeight={468}
    >
      <div className="payroll-detail-modal-contents-body">
        <div className="payroll-detail-modal-contents-box">
          <div className="payroll-detail-modal-title-box">
            <div className="payroll-detail-modal-title">
              Salary Details History
            </div>
          </div>
          <div className="payroll-detail-modal-table-box">
            <PayrollTable
              row={row}
              columns={historyColumn}
              checkboxSelection={false}
            />
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default AnnalSalaryModal;
