import React from "react";
import dayjs from "dayjs";
import CustomTimePicker from "@/components/CustomTimePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const WorkTypeList = ({ items = [] }) => {
  return (
    <div className="approval-modal-items-body">
      <div className="approval-modal-items-title-box">
        <div className="approval-modal-items-title">Working Schedule</div>
      </div>
      <div className="approval-modal-items-box">
        {items.map((c, i) => {
          const {
            id,
            work_date_year,
            work_date_month,
            work_date_day,
            attendance_time,
            end_time,
          } = c;

          const date = `${work_date_year}-${work_date_month}-${work_date_day}`;

          const week = dayjs(date).format("ddd");
          return (
            <div key={id} className="approval-modal-items-work-list-card">
              <div className="approval-modal-items-default-text">
                {`${i + 1}. ${date} (${week}) `}
              </div>
              <div className="approval-modal-items-work-list-range-box">
                <div>
                  <div className="approval-modal-items-default-text">
                    Start Time:
                  </div>
                  <CustomTimePicker
                    value={dayjs(attendance_time, "HH:mm")}
                    width={130}
                    onChange={() => {}}
                    disabled={true}
                  />
                </div>
                <div>
                  <div className="approval-modal-items-default-text">
                    Get Off Time:
                  </div>
                  <CustomTimePicker
                    value={dayjs(end_time, "HH:mm")}
                    width={130}
                    onChange={() => {}}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkTypeList;
