import React from "react";

const ApprovalCards = ({ approval_list = [] }) => {
  return (
    <table className="approval-cards-table">
      <tbody>
        <tr>
          {approval_list.map((c) => {
            const { id, lv_name } = c;
            return (
              <td key={id} className="approval-cards-body-cell header-cell">
                <div className="job-position-cell">{lv_name}</div>
              </td>
            );
          })}
        </tr>
        <tr>
          {approval_list.map((c) => {
            const {
              id,
              status_color,
              approved_status,
              approval_date,
              approval_time,
            } = c;

            const approval_date_text = approval_date
              ? `${approval_date} ${approval_time}`
              : null;
            return (
              <td key={id} className="approval-cards-body-cell">
                <div className="approval-cards-contents-cell">
                  <div className="approval-cards-contents">
                    <div
                      className="approval-cards-contents-dot"
                      style={{ background: status_color }}
                    />
                    <div className="approval-cards-contents-name">
                      {approved_status}
                    </div>
                  </div>
                  <div>
                    <div className="approval-date-cell">
                      {approval_date_text}
                    </div>
                  </div>
                </div>
              </td>
            );
          })}
        </tr>
        <tr>
          {approval_list.map((c) => {
            const { id, username } = c;
            return (
              <td key={id} className="approval-cards-body-cell">
                <div className="username-cell">{username}</div>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default ApprovalCards;
