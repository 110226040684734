import React from "react";

const ManpowerReqApproval = ({ items = {} }) => {
  const {
    request_username,
    req_org_setting_name,
    req_lv_name,
    req_fn_name,
    nec_org_setting_name,
    nec_lv_name,
    nec_fn_name,
    request_person,
    reason_for_hiring,
  } = items;

  return (
    <>
      <div className="approval-modal-info-body">
        <div className="approval-modal-info-title-box">
          <div className="approval-modal-info-title">Requester</div>
        </div>
        <div className="approval-modal-user-info-box">
          <div className="approval-modal-user-info-detail-box">
            <div className="approval-modal-info-body">
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">User Name</div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {request_username}
                </div>
              </div>
            </div>
            <div className="approval-modal-info-body">
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">Department</div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {req_org_setting_name}
                </div>
              </div>
            </div>
          </div>
          <div className="approval-modal-user-info-detail-box">
            <div className="approval-modal-info-body">
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">Job Position</div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {req_lv_name}
                </div>
              </div>
            </div>
            <div className="approval-modal-info-body">
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">Job Grade</div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {req_fn_name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="approval-modal-info-body">
        <div
          className="approval-modal-user-info-box"
          style={{ width: "100%", gap: 16 }}
        >
          <div className="approval-modal-user-info-detail-box">
            <div
              className="approval-modal-info-body"
              style={{ height: "100%" }}
            >
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">
                  Necessary Department
                </div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {nec_org_setting_name}
                </div>
              </div>
            </div>
            <div
              className="approval-modal-info-body"
              style={{ height: "100%" }}
            >
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">
                  Necessary Job Position
                </div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {nec_lv_name}
                </div>
              </div>
            </div>
          </div>
          <div className="approval-modal-user-info-detail-box">
            <div
              className="approval-modal-info-body"
              style={{ height: "100%" }}
            >
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">
                  Necessary Job Grade
                </div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {nec_fn_name}
                </div>
              </div>
            </div>
            <div
              className="approval-modal-info-body"
              style={{ height: "100%" }}
            >
              <div className="approval-modal-info-title-box">
                <div className="approval-modal-info-title">
                  Necessary Personnel
                </div>
              </div>
              <div className="approval-modal-info-contents-box">
                <div className="approval-modal-info-contents">
                  {request_person}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="approval-modal-info-body">
        <div className="approval-modal-info-title-box">
          <div className="approval-modal-info-title">Reason for Hiring</div>
        </div>
        <div className="approval-modal-info-contents-box">
          <div className="approval-modal-info-contents">
            {reason_for_hiring}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManpowerReqApproval;
