import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { materialTableStyle, TableScrollbarStyle } from "@styles/muiThemes";

const ChangeOrderTable = ({ rows = [], column = [] }) => {
  return (
    <div className="cost-menu-table-body">
      <DataGridPro
        rows={rows}
        columns={column}
        rowHeight={40}
        columnHeaderHeight={40}
        sx={{
          ...materialTableStyle,
          ...TableScrollbarStyle,
        }}
        hideFooter
        disableColumnPinning
        disableColumnSelector
        disableDensitySelector
      />
    </div>
  );
};

export default ChangeOrderTable;
