import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { CostApprovalModalStatus } from "@/atoms/modalStatus";
import { UserTargetProject } from "@/atoms/userInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import _ from "lodash";
import dayjs from "dayjs";
import Btn from "@/components/CustomIconBtn";
import MonthPicker from "@/components/MonthPicker";
import CostApprovalModal from "@components/approvalManagement/Modal/CostApprovalModal";
import BudgetUsage from "@/components/budgetUsage/BudgetUsage";
import VerticalSolidLine from "@/components/VerticalSolidLine";
import PlusIcon from "@/components/Icons/PlusIcon";
import AddUsageModal from "@/components/budgetUsage/AddUsageModal";
import AddVendorInvoiceModal from "@/components/budgetUsage/AddVendorInvoiceModal";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";

const ExpensesPage = () => {
  const [addModalToggle, setAddModalToggle] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [dateValue, setDateValue] = useState(dayjs());
  const { project_code, project_name } = useRecoilValue(UserTargetProject);
  const resetModalStatus = useResetRecoilState(CostApprovalModalStatus);
  const { open: modal_open } = useRecoilValue(CostApprovalModalStatus);

  useEffect(() => {
    setTabValue(0);
  }, [project_code]);

  useEffect(() => {
    return () => resetModalStatus();
    // eslint-disable-next-line
  }, []);

  const query_budget_usage_data = useQuery(
    ["budget_usage_data", project_code, dateValue.format("YYYY-MM")],
    () => {
      return query_fetch_data(
        project_code,
        dateValue.format("YYYY"),
        dateValue.format("MM")
      );
    }
  );

  const query_po_list_data = useQuery(["po_list", project_code], () => {
    return query_vendor_list_fetch_data(project_code);
  });

  const query_approval_user_list_data = useQuery(
    "approval_user_list_data",
    () => {
      return query_approval_fetch_data();
    }
  );

  const query_cost_code_data = useQuery(
    ["using_cost_code_data", project_code],
    () => {
      return query_cost_code_fetch_data(project_code);
    }
  );

  const [refresh] = useRefreshReactQuery([
    "budget_usage_data",
    project_code,
    dateValue.format("YYYY-MM"),
  ]);

  const { data: buData = {}, isLoading: buDataLoading } =
    query_budget_usage_data;

  const { data: poData = {}, isLoading: poDataLoading } = query_po_list_data;

  const { data: userListData = {}, isLoading: userListLoading } =
    query_approval_user_list_data;

  const { data: costCodeListData = {}, isLoading: costCodeLoading } =
    query_cost_code_data;

  const {
    budget_usage_list = [],
    vendor_invoice_list = [],
    add_button_use = true,
  } = buData;

  const { purchase_order_list = [] } = poData;
  const { list: userList = [] } = userListData;
  const { list: costCodeList = [] } = costCodeListData;

  const handleDateValue = (e) => {
    setDateValue(e);
  };

  const handleTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddModal = () => {
    setAddModalToggle((prev) => !prev);
  };

  if (buDataLoading || poDataLoading || userListLoading || costCodeLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Budget Usage</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />
          <VerticalSolidLine height={28} />
          <MonthPicker value={dateValue} onChange={handleDateValue} />
          {add_button_use && (
            <>
              <VerticalSolidLine height={24} />
              <Btn
                label="Add Budget Usage"
                icon={<PlusIcon />}
                onClick={handleAddModal}
                width={175}
              />
            </>
          )}
        </div>
      </div>
      <div className="company-setting-contents-body">
        <BudgetUsage
          tabValue={tabValue}
          handleTabValue={handleTabValue}
          project_code={project_code}
          project_name={project_name}
          approval_user_list={userList}
          cost_code_list={costCodeList}
          budget_usage_list={budget_usage_list}
          vendor_invoice_list={vendor_invoice_list}
        />
        {modal_open && <CostApprovalModal />}
        {addModalToggle && tabValue === 0 && (
          <AddUsageModal
            open={addModalToggle}
            onClose={handleAddModal}
            refresh={refresh}
            project_code={project_code}
            project_name={project_name}
            approval_user_list={userList}
            cost_code_list={costCodeList}
          />
        )}
        {addModalToggle && tabValue === 1 && (
          <AddVendorInvoiceModal
            open={addModalToggle}
            onClose={handleAddModal}
            refresh={refresh}
            project_code={project_code}
            project_name={project_name}
            approval_user_list={userList}
            cost_code_list={costCodeList}
            purchase_order_list={purchase_order_list}
          />
        )}
      </div>
    </div>
  );
};

export default ExpensesPage;

const query_fetch_data = async (project_code, year, month) => {
  let result = {
    budget_usage_list: [],
    vendor_invoice_list: [],
    purchase_order_list: [],
    add_button_use: true,
  };

  try {
    const requestBudgetUsgaeData = await userPermAxios.get(
      `/cost/get_budget_usage_data?project_code=${project_code}&year=${year}&month=${month}`
    );

    const { success, data } = requestBudgetUsgaeData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_vendor_list_fetch_data = async (project_code) => {
  let result = {
    vendor_invoice_list: [],
  };

  try {
    if (project_code === "overall") {
      return result;
    }

    const requestBudgetUsgaeData = await userPermAxios.get(
      `/cost/get_invoice_vendor_list?project_code=${project_code}`
    );

    const { success, data } = requestBudgetUsgaeData;
    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_approval_fetch_data = async () => {
  let result = {
    list: [],
  };

  try {
    const requestApprovalUserList = await userPermAxios.get(
      "/cost/get_approval_user_list"
    );

    const { success, data } = requestApprovalUserList;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_cost_code_fetch_data = async (project_code) => {
  let result = {
    list: [],
  };

  try {
    const requestCostCodeList = await userPermAxios.get(`/cost/get_cost_code`);

    const requestUsingCostCodeList = await userPermAxios.get(
      `/cost/get_using_cost_code?project_code=${project_code}`
    );

    const { success: normalSuccess, data: normalData } = requestCostCodeList;
    const { success: usingSuccess, data: usingData } = requestUsingCostCodeList;

    let costCodeList = [];

    if (normalSuccess) {
      costCodeList.push(
        ...normalData.list.map((c) => {
          return { ...c, type: "Not Using" };
        })
      );
    }

    if (usingSuccess) {
      costCodeList.push(
        ...usingData.list.map((c) => {
          return { ...c, type: "Using" };
        })
      );
    }

    result.list = _.orderBy(
      _(costCodeList)
        .groupBy("id")
        .map((objs, key) => {
          const usingCode = objs.find((c) => c.type === "Using");
          const notUsingCode = objs.find((c) => c.type === "Not Using");

          if (usingCode) {
            return { ...usingCode };
          } else {
            return { ...notUsingCode };
          }
        })
        .value(),
      ["type"],
      ["desc"]
    );
  } catch (err) {
  } finally {
    return result;
  }
};
