import React from "react";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import {
  CustomNormalTextTypeCell,
  CustomCheckBoxCell,
  CustomEditEmailTypeCell,
  CustomEditSelectCell,
  CustomEditNormalTextTypeCell,
  CustomEditSelectListCell,
  CustomSelectListCell,
  CustomStageTypeCell,
  CustomDetailPanelToggle,
  CustomPermProjectAddUserToggle,
  CustomAmountTypeCell,
  CustomDateTypeCell,
  CustomPermMenuAddUserToggle,
  CustomPermUserAddMenuToggle,
  CustomEditDayPickerTypeCell,
  PermLineDeleteBtnCell,
  CustomNumberTypeCell,
} from "@components/Cells";
import { salesPriorityColorList } from "@utils/colorList";
import { timeZoneList, timeFormatList, countries } from "@utils/userTimeZone";

export const createUserCreateColumn = (table_name) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      filterable: false,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: "add_menu_cell",
      headerName: "",
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <CustomPermUserAddMenuToggle
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "id",
      headerName: "User Number",
      width: 100,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "username",
      headerName: "User Name",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditEmailTypeCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      list: countries.map((c) => ({ name: c.label, value: c.label })),
      renderCell: (params) => {
        return <CustomSelectListCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "language",
      headerName: "Language",
      width: 100,
      data_type: "string",
      editable: true,
      filterable: true,
      list: [
        { value: "en", name: "English" },
        { value: "th", name: "เกาหลี" },
      ],
      renderCell: (params) => {
        return <CustomSelectListCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "time_zone",
      headerName: "Time Zone",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      list: timeZoneList.map((c) => ({ name: c.name, value: c.name })),
      renderCell: (params) => {
        return <CustomSelectListCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "time_format",
      headerName: "Time Format",
      width: 250,
      data_type: "string",
      editable: true,
      filterable: true,
      list: timeFormatList,
      renderCell: (params) => {
        return <CustomSelectListCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditSelectListCell params={params} table_name={table_name} />
        );
      },
    },
    {
      field: "birth_date",
      headerName: "Date of Birth",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDayPickerTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "joining_date",
      headerName: "Date of Joining",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDayPickerTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "resignation_date",
      headerName: "Date of Resignation",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditDayPickerTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "bank_account_number",
      headerName: "Bank Account Number",
      width: 200,
      data_type: "string",
      editable: true,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return (
          <CustomEditNormalTextTypeCell
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "approval_status",
      headerName: "User Approval",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomCheckBoxCell params={params} table_name={table_name} />;
      },
    },
    {
      field: "admin_user",
      headerName: "Admin User",
      data_type: "string",
      width: 150,
      editable: false,
      renderCell: (params) => {
        return <CustomCheckBoxCell params={params} table_name={table_name} />;
      },
    },
    // {
    //   field: "approver",
    //   headerName: "Approver",
    //   data_type: "string",
    //   width: 150,
    //   editable: false,
    //   renderCell: (params) => {
    //     return <CustomCheckBoxCell params={params} table_name={table_name} />;
    //   },
    // },
  ];
};

export const createUserAnnualIncomeColumn = (table_name, line_table_name) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      edit_mode: true,
      width: 40,
      editable: false,
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return (
          <PermLineDeleteBtnCell
            params={params}
            table_name={table_name}
            line_table_name={line_table_name}
          />
        );
      },
    },
    {
      field: "reflection_date",
      headerName: "Reflection Date",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
    },
    {
      field: "amount",
      headerName: "Salary",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNumberTypeCell params={params} />;
      },
    },
  ];
};

export const createPermMenuSummaryColumn = (table_name) => {
  return [
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "sales",
      headerName: "Sales",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "material_master",
      headerName: "Material Master",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "purchase_request",
      headerName: "Purchase Request",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "purchase_order",
      headerName: "Purchase Order",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "packing_list",
      headerName: "Packing List",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "material_receive",
      headerName: "Material Receive",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "material_release",
      headerName: "Material Release",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "material_inventory",
      headerName: "Material Inventory",
      width: 150,
      data_type: "string",
      editable: false,
      filterable: true,
      list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
  ];
};

export const createPermMenuColumn = (table_name) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      filterable: false,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: "add_user_cell",
      headerName: "",
      width: 40,
      editable: false,
      renderCell: (params) => {
        if (params.rowNode.type === "group") {
          return null;
        }

        return (
          <CustomPermMenuAddUserToggle
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    // {
    //   field: "top_menu",
    //   headerName: "Group Menu",
    //   width: 150,
    //   data_type: "string",
    //   editable: false,
    //   filterable: true,
    //   renderCell: (params) => {
    //     return <CustomNormalTextTypeCell params={params} />;
    //   },
    // },
    {
      field: "menu_name",
      headerName: "Menu Name",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

export const createPermMenuLineColumn = (table_name, line_table_name) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      edit_mode: true,
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <PermLineDeleteBtnCell
            params={params}
            table_name={table_name}
            line_table_name={line_table_name}
          />
        );
      },
    },
    {
      field: "username",
      headerName: "User Name",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "permission_level",
      headerName: "Permission Level",
      width: 150,
      data_type: "string",
      editable: true,
      filterable: true,
      list: menuPermStatusColorList,
      approval_list: menuPermStatusColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
      renderEditCell: (params) => {
        return <CustomEditSelectCell params={params} table_name={table_name} />;
      },
    },
  ];
};

export const createPermProjectColumn = (table_name) => {
  return [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 40,
      filterable: false,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: "add_user_cell",
      headerName: "",
      width: 40,
      editable: false,
      renderCell: (params) => {
        return (
          <CustomPermProjectAddUserToggle
            params={params}
            table_name={table_name}
          />
        );
      },
    },
    {
      field: "lead",
      headerName: "Lead",
      flex: 1,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 150,
      data_type: "string",
      editable: false,
      list: salesPriorityColorList,
      renderCell: (params) => {
        return <CustomStageTypeCell params={params} />;
      },
    },
    {
      field: "amount",
      headerName: "Volume",
      width: 150,
      data_type: "number",
      editable: false,
      renderCell: (params) => {
        return <CustomAmountTypeCell params={params} />;
      },
    },
    {
      field: "last_call_time",
      headerName: "Last Call Time",
      width: 150,
      data_type: "dateTime",
      editable: false,
      renderCell: (params) => {
        return <CustomDateTypeCell params={params} />;
      },
    },
  ];
};

export const createPermProjectLineColumn = (table_name, line_table_name) => {
  return [
    {
      field: "delete_cell",
      headerName: "",
      edit_mode: true,
      width: 40,
      editable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <PermLineDeleteBtnCell
            params={params}
            table_name={table_name}
            line_table_name={line_table_name}
          />
        );
      },
    },
    {
      field: "username",
      headerName: "User Name",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      data_type: "string",
      editable: false,
      filterable: true,
      renderCell: (params) => {
        return <CustomNormalTextTypeCell params={params} />;
      },
    },
  ];
};

const menuPermStatusColorList = [
  { name: "read", color: "#04C63A", fontColor: "#fff" },
  { name: "edit", color: "#0080DB", fontColor: "#fff" },
];
