import React from "react";
import { useRecoilValue } from "recoil";
import { MaterialCategory } from "@/atoms/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Select, MenuItem } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import DefaultDateRange from "@components/DefaultDateRange";
import { labelSelectTheme } from "@styles/muiThemes";
import IconInput from "../IconInput";

dayjs.extend(utc);
dayjs.extend(timezone);

const FilterCard = ({
  dateRange,
  setDateRange,
  category,
  setCategory,
  searchText,
  setSearchText,
}) => {
  const materialCategory = useRecoilValue(MaterialCategory);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div className="material-inventroy-filter-body">
      <DefaultDateRange dateRange={dateRange} setDateRange={setDateRange} />

      <ThemeProvider theme={labelSelectTheme}>
        <Select
          value={category}
          onChange={handleChange}
          // renderValue={(selected) => selected.name}
          sx={{
            width: "100%",
          }}
        >
          {[
            { name: "Overall", value: "overall" },
            ...materialCategory.map((c) => ({ ...c, value: c.name })),
          ].map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>

      <IconInput
        onChange={handleTextChange}
        value={searchText}
        rightIcon={false}
      />
    </div>
  );
};

export default FilterCard;
