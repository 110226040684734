import React from "react";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { UserTargetProject } from "@/atoms/userInfo";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Btn from "@/components/CustomIconBtn";
import { userPermAxios } from "@/utils/customAxios";
import useRefreshReactQuery from "@/hooks/useRefreshReactQuery";
import BudgetSheet from "@/components/budgetSheet/BudgetSheet";

const BudgetSheetPage = () => {
  const { project_code } = useRecoilValue(UserTargetProject);

  const query_budget_sheet_data = useQuery(
    ["budget_sheet_data", project_code],
    () => query_fetch_data(project_code)
  );

  const [refresh] = useRefreshReactQuery(["budget_sheet_data", project_code]);

  const { data: bsData = {}, isLoading: bsDataLoading } =
    query_budget_sheet_data;

  const {
    contract_amount = [],
    budget_sheet_list = [],
    top_level_group_sum = [],
  } = bsData;

  if (bsDataLoading) {
    return null;
  }

  return (
    <div className="work-policy-setting-page-body">
      <div className="work-policy-setting-page-title-box">
        <div className="work-policy-setting-page-title">Budget Sheet</div>
        <div className="work-policy-setting-page-btn-box">
          <Btn
            icon={
              <RefreshOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            }
            width={40}
            onClick={refresh}
          />
        </div>
      </div>
      <div className="company-setting-contents-body">
        <BudgetSheet
          project_code={project_code}
          contract_amount={contract_amount}
          budget_sheet_list={budget_sheet_list}
          top_level_group_sum={top_level_group_sum}
        />
      </div>
    </div>
  );
};

export default BudgetSheetPage;

const query_fetch_data = async (project_code) => {
  let result = {
    contract_amount: [],
    budget_sheet_list: [],
    top_level_group_sum: [],
  };

  try {
    const requestInitialBudgetData = await userPermAxios.get(
      `/cost/get_budget_sheet_data?project_code=${project_code}`
    );

    const { success, data } = requestInitialBudgetData;

    if (success) {
      result = { ...data };
    }
  } catch (err) {
  } finally {
    return result;
  }
};
