import React, { useState, useMemo } from "react";
import { createDataTree } from "@progress/kendo-react-data-tools";
import { OrgChart } from "@progress/kendo-react-orgchart";
import {
  OrgChartCard,
  GroupTitleCard,
  GroupSubTitleCard,
} from "./OrgChartCard";
import "./orgChart.scss";

//kendo 조직도에서는 id가 0부터 시작하면 삐꾸남
const OrgChartComponent = ({ data }) => {
  const [expand, setExpand] = useState({
    ids: [],
    idField: "id",
  });

  const onExpandChange = (event) => {
    let ids = expand.ids.slice();
    event.items?.forEach((item) => {
      const index = ids.indexOf(item.id);
      index === -1 ? ids.push(item.id) : ids.splice(index, 1);
    });
    setExpand({
      ids,
      idField: "id",
    });
  };

  const expandedData = useMemo(() => {
    const treeData = createDataTree(
      data,
      (i) => i.id,
      (i) => i.parentId,
      "children"
    );

    return updateExpandData(treeData, expand);
  }, [expand, data]);

  return (
    <OrgChart
      data={expandedData}
      style={{ height: "100%", padding: 0 }}
      navigatable={true}
      groupField="Department Name"
      idField="id"
      childrenField="children"
      expandField="expanded"
      cardHeight={98}
      cardWidth={372}
      onExpandChange={onExpandChange}
      itemRender={(props) => <OrgChartCard {...props} />}
      groupTitleRender={(props) => <GroupTitleCard {...props} />}
      groupSubtitleRender={(props) => <GroupSubTitleCard {...props} />}
      verticalLine={120}
    />
  );
};

export default OrgChartComponent;

const updateExpandData = (data, expand) => {
  const { ids, idField } = expand;

  const updateItem = (item) => {
    // If the item's id is in the expand.ids array, clone and expand it
    if (ids.includes(item[idField])) {
      item = { ...item, cloned: true, expanded: true };
    } else {
      delete item.cloned;
      delete item.expanded;
    }

    // If children array exists and has elements, apply the same operation recursively
    if (item.children && item.children.length > 0) {
      item.children = item.children.map(updateItem);
    } else {
      // If children array is empty, remove it
      delete item.children;
    }

    return item;
  };

  // Apply the updateItem function to all data entries
  return data.map(updateItem);
};
