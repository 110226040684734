import React, { useMemo, useState } from "react";
import { useResetRecoilState, useRecoilState } from "recoil";
import {
  ApprovalLineModalStatus,
  InitialBudgetRowModalStatus,
} from "@/atoms/modalStatus";
import _ from "lodash";
import PolicyModalBody from "@components/PolicyModalBody";
import ApprovalLineBtn from "@components/hrBigCalendar/ApprovalLineBtn";
import HorizontalSolidLine from "@components/HorizontalSolidLine";
import InitialBudgetTable from "./InitialBudgetTable";
import LoadingModal from "../LoadingModal";
import ImportModal from "./ImportModal";
import FixedRowModal from "./FixedRowModal";
import PlusIcon from "../Icons/PlusIcon";
import MinusIcon from "../Icons/MinusIcon";
import Btn from "../CustomIconBtn";
import { initialBudgetTableColumn } from "./Column";
import { userPermAxios } from "@/utils/customAxios";
import { create_alert_fn } from "@/utils/createAlert";
import VerticalSolidLine from "../VerticalSolidLine";
import LabelTextArea from "../LabelTextArea";

const AddInitialBudgetModal = ({
  open,
  onClose = () => {},
  refresh = () => {},
  approval_user_list = [],
  cost_code_list = [],
  project_name = "",
  project_code,
}) => {
  const [importModalStatus, setImportModalStatus] = useState(false);
  const [approvalName, setApprovalName] = useState(
    "Add Initial Budget Description"
  );
  const [initialBudgetRow, setInitialBudgetRow] = useState([]);
  const [remark, setRemark] = useState("");
  const [selectionModel, setSelectModel] = useState([]);

  const [approvalLineData, setApprovalLineData] = useState({
    approval_list: [],
    carbon_copy_list: [],
  });

  const [dubleClick, setDubleClick] = useState(true);
  const [initialBudgetRowModalStatus, setInitialBudgetRowModalStatus] =
    useRecoilState(InitialBudgetRowModalStatus);

  const closeApprovalLineModal = useResetRecoilState(ApprovalLineModalStatus);
  const closeRowEditModal = useResetRecoilState(InitialBudgetRowModalStatus);

  const confirmApprovalLineData = (approval_list, carbon_copy_list) => {
    setApprovalLineData((prev) => {
      return {
        ...prev,
        approval_list: approval_list,
        carbon_copy_list: carbon_copy_list,
      };
    });

    closeApprovalLineModal();
  };

  const handleImportModal = () => {
    setImportModalStatus((prev) => !prev);
  };

  const handleRowAddModal = () => {
    const maxId = _.maxBy(initialBudgetRow, "id")?.id ?? 0;

    setInitialBudgetRowModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        onClose: closeRowEditModal,
        id: maxId + 1,
        cost_code: "",
        budget_cost: 0,
        remark: "",
        modal_type: "create",
      };
    });
  };

  const handleRowSelection = (e) => {
    setSelectModel(e);
  };

  const deleteRow = () => {
    const selectionItems = selectionModel.filter((c) => typeof c === "number");

    setInitialBudgetRow((prev) => {
      return prev.filter((c) => {
        const { id } = c;

        return !selectionItems.includes(id);
      });
    });
  };

  const topLvGroupSum = _(initialBudgetRow)
    .groupBy("lv_0_code")
    .map((objs, key) => {
      const totalAmount = _.sumBy(objs, "budget_cost");
      return { key, totalAmount };
    })
    .value();

  const button_disabled = useMemo(() => {
    if (initialBudgetRow.filter((c) => c.notAccept).length > 0) {
      return true;
    }

    if (approvalLineData.approval_list.length === 0) {
      return true;
    }

    if (approvalName.length === 0) {
      return true;
    }

    return false;
  }, [initialBudgetRow, approvalLineData, approvalName]);

  const createInitialBudget = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };
    try {
      const requestCreateInitialBudget = await userPermAxios.post(
        `/cost/create_initial_budget`,
        {
          project_code,
          approvalName,
          initialBudgetRow,
          remark,
          approvalList: approvalLineData.approval_list,
          carbonCopyList: approvalLineData.carbon_copy_list,
        }
      );
      const { success, message } = requestCreateInitialBudget;
      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }
      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";
      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const confirnmInitialBudget = () => {
    if (!dubleClick) {
      return;
    }

    let message = "Do you want to create a new initial budget?";

    return create_alert_fn(
      "info",
      message,
      async () => {
        createInitialBudget();
      },
      true
    );
  };

  return (
    <>
      {!dubleClick && <LoadingModal />}
      {importModalStatus && (
        <ImportModal
          open={importModalStatus}
          onClose={handleImportModal}
          setInitialBudgetRow={setInitialBudgetRow}
          cost_code_list={cost_code_list}
        />
      )}
      {initialBudgetRowModalStatus.open && (
        <FixedRowModal
          setInitialBudgetRow={setInitialBudgetRow}
          cost_code_list={cost_code_list}
        />
      )}
      <PolicyModalBody
        policyName={approvalName}
        setPolicyName={setApprovalName}
        open={open}
        onClose={onClose}
        title={`Add ${project_name} Initial Budget`}
        width={1140}
        disableEscapeKeyDown={true}
        save_btn_disabled={button_disabled}
        saveFn={confirnmInitialBudget}
      >
        <div
          className="work-policy-modal-contents-box"
          style={{ maxHeight: 500, overflowY: "auto" }}
        >
          <div className="work-policy-modal-detail-contents-box">
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                gap: 8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                <div className="work-policy-modal-detail-contents-title">
                  Initial Budget
                </div>
                <div
                  style={{
                    gap: 16,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Btn
                    icon={<img src="/icons/excel_icon.png" alt="excel icon" />}
                    width={150}
                    onClick={handleImportModal}
                    label="Excel Import"
                  />
                  <VerticalSolidLine height="24px" />
                  <Btn
                    icon={<PlusIcon />}
                    width={40}
                    onClick={handleRowAddModal}
                  />
                  <Btn
                    icon={<MinusIcon />}
                    width={40}
                    disabled={selectionModel.length === 0}
                    onClick={deleteRow}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "300px",
                }}
              >
                <InitialBudgetTable
                  rowHeight={28}
                  rows={initialBudgetRow}
                  column={initialBudgetTableColumn(topLvGroupSum)}
                  row_group_model={["lv_0_code", "lv_1_code"]}
                  columnVisibilityModel={{
                    lv_0_code: false,
                    lv_1_code: false,
                    lv_2_code: false,
                  }}
                  selectionModelUse={true}
                  selectionModel={selectionModel}
                  handleSelection={handleRowSelection}
                />
              </div>
            </div>
            <div
              className="work-policy-modal-time-picker-detail"
              style={{ width: "100%" }}
            >
              <div className="work-policy-modal-detail-contents-title">
                Remark
              </div>
              <LabelTextArea value={remark} setValue={setRemark} />
            </div>

            <HorizontalSolidLine />
            <ApprovalLineBtn
              approvalLineData={approvalLineData}
              confirmApprovalLineData={confirmApprovalLineData}
              approval_user_list={approval_user_list}
            />
          </div>
        </div>
      </PolicyModalBody>
    </>
  );
};

export default AddInitialBudgetModal;
