import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";
import { QueryClient, QueryClientProvider } from "react-query";
import { LicenseInfo } from "@mui/x-license-pro";
import "@progress/kendo-theme-default/dist/all.css";
import "@styles/reset.scss";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PREMUIM_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    staleTime: 1000 * 60 * 30,
    cacheTime: 1000 * 60 * 60,
  },
});

root.render(
  <RecoilRoot>
    <RecoilNexus />
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </RecoilRoot>
);
