import React from "react";

const EditPencilIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4 12.0003V14.5H6.49966L13.872 7.12767L11.3723 4.62801L4 12.0003ZM15.8051 5.1946C15.8669 5.13293 15.9159 5.05968 15.9493 4.97904C15.9828 4.8984 16 4.81196 16 4.72466C16 4.63736 15.9828 4.55092 15.9493 4.47028C15.9159 4.38964 15.8669 4.31639 15.8051 4.25472L14.2453 2.69494C14.1836 2.63314 14.1104 2.58412 14.0297 2.55067C13.9491 2.51722 13.8626 2.5 13.7753 2.5C13.688 2.5 13.6016 2.51722 13.521 2.55067C13.4403 2.58412 13.3671 2.63314 13.3054 2.69494L12.0856 3.91477L14.5852 6.41443L15.8051 5.1946Z"
        fill="#98A2B3"
      />
      <path d="M2.5 16H17.5V17.5H2.5V16Z" fill="#98A2B3" />
    </svg>
  );
};

export default EditPencilIcon;
