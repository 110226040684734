import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { DayoffPolicyStatus } from "@/atoms/modalStatus";
import SwicthBtn from "@components/SwicthBtn";
import PolicyModalBody from "../PolicyModalBody";
import { create_alert_fn } from "@/utils/createAlert";
import { userPermAxios } from "@/utils/customAxios";
import "./dayOffSettingModals.scss";

const DayoffPolicyModal = ({ refresh }) => {
  const {
    open,
    id,
    origin_id,
    type = "create",
    initialDayoffPolicyName = "Dayoff Policy",
    initialApproveUse = false,
    initialCancelApproveUse = false,
    initialMaxDuration = 0,
    initialPayorNot = false,
    initialAttachmentRequired = false,
    initialActiveAfterAnnualleave = true,
    title,
    onClose,
  } = useRecoilValue(DayoffPolicyStatus);

  const button_disabled = type === "view" || type === "" ? true : false;

  const [policyName, setPolicyName] = useState(initialDayoffPolicyName);
  const [approveUse, setApproveUse] = useState(initialApproveUse);
  const [cancelApproveUse, setCancelApproveUse] = useState(
    initialCancelApproveUse
  );
  const [maxDuration, setMaxDuration] = useState(initialMaxDuration);
  const [payorNot, setPayorNot] = useState(initialPayorNot);
  const [attachmentRequired, setAttachmentRequired] = useState(
    initialAttachmentRequired
  );
  const [activeAfterAnnualleave, setActiveAfterAnnualleave] = useState(
    initialActiveAfterAnnualleave
  );

  const [dubleClick, setDubleClick] = useState(true);

  const handleSwicthBtnValue = (setValue) => {
    setValue((prev) => !prev);
  };

  const handleNumberInputValue = (e, setValue) => {
    const value = e.target.value.replace(/\D/g, "");
    setValue(Number(value));
  };

  const createDayoffPolicy = async () => {
    setDubleClick(false);

    let msgStatus = {
      status: "success",
      message: "",
    };

    try {
      const submitData = {
        policyName,
        approveUse,
        cancelApproveUse,
        maxDuration,
        payorNot,
        attachmentRequired,
        activeAfterAnnualleave,
      };

      if (type === "update") {
        submitData.id = id;
        submitData.origin_id = origin_id;
      }

      const requestCreatePolicy = await userPermAxios.post(
        `/dayoff/${type}_dayoff_policy`,
        {
          ...submitData,
        }
      );

      const { success, message } = requestCreatePolicy;

      if (success) {
        msgStatus.status = "success";
      } else {
        msgStatus.status = "warning";
      }

      msgStatus.message = message;
    } catch (err) {
      msgStatus.status = "warning";

      if (err.response && err?.response?.data?.message) {
        msgStatus.message = err?.response?.data?.message;
      }
    } finally {
      setDubleClick(true);
      create_alert_fn(msgStatus.status, msgStatus.message);

      if (msgStatus.status === "success") {
        refresh();
        onClose();
      }
    }
  };

  const saveDayoffPolicy = () => {
    if (!dubleClick) {
      return;
    }

    let message;

    if (type === "create") {
      message = "Do you want to create a new dayoff policy?";
    }

    if (type === "update") {
      message = "Do you want to update a dayoff policy?";
    }

    return create_alert_fn(
      "info",
      message,
      async () => {
        await createDayoffPolicy();
      },
      true
    );
  };

  return (
    <PolicyModalBody
      policyName={policyName}
      setPolicyName={setPolicyName}
      open={open}
      title={title}
      onClose={onClose}
      disableEscapeKeyDown={true}
      width={792}
      button_disabled={button_disabled}
      dubleClick={dubleClick}
      // save_btn_disabled={saveBtnDisabled}
      saveFn={saveDayoffPolicy}
    >
      <div className="work-policy-modal-contents-box">
        <div className="work-policy-modal-detail-contents-box">
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval required
            </div>
            <SwicthBtn
              checked={approveUse}
              onClick={() => {
                handleSwicthBtnValue(setApproveUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Approval required for cancellation
            </div>
            <SwicthBtn
              checked={cancelApproveUse}
              onClick={() => {
                handleSwicthBtnValue(setCancelApproveUse);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Amount of Holiday Granted
            </div>
            <NumberInput
              value={maxDuration}
              setValue={setMaxDuration}
              onChange={handleNumberInputValue}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Whether Paid Holiyday is Granted
            </div>
            <SwicthBtn
              checked={payorNot}
              onClick={() => {
                handleSwicthBtnValue(setPayorNot);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Attachment Required
            </div>
            <SwicthBtn
              checked={attachmentRequired}
              onClick={() => {
                handleSwicthBtnValue(setAttachmentRequired);
              }}
              disabled={button_disabled}
            />
          </div>
          <div className="work-policy-modal-swicth-btn-box">
            <div className="work-policy-modal-detail-contents-title">
              Paid after Exhaustion of Annual Leave
            </div>
            <SwicthBtn
              checked={activeAfterAnnualleave}
              onClick={() => {
                handleSwicthBtnValue(setActiveAfterAnnualleave);
              }}
              disabled={button_disabled}
            />
          </div>
        </div>
      </div>
    </PolicyModalBody>
  );
};

export default DayoffPolicyModal;

const NumberInput = ({ value, setValue, onChange }) => {
  return (
    <input
      className="work-policy-input"
      onChange={(e) => onChange(e, setValue)}
      value={value}
    />
  );
};
