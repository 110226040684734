import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

const useCostTableHeight = (summaryUse, titleUse, tabMenuUse) => {
  const { height } = useWindowDimensions();

  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    let result = height - 104 - 32 - 40 - 52 - 20;

    if (summaryUse) {
      result -= 128 + 20;
    }

    if (titleUse) {
      result -= 38 + 8;
    }

    if (!summaryUse && titleUse) {
      result += 45;
    }

    if (tabMenuUse) {
      result -= 48 + 20;
    }

    setTableHeight(result);
  }, [height, summaryUse, titleUse, tabMenuUse]);

  return { height: tableHeight };
};

export default useCostTableHeight;
