import React from "react";
import BudgetReallocationTable from "./BudgetReallocationTable";
import { budgetTransferListColumn } from "./Column";
import useCostTableHeight from "@/hooks/useCostTableHeight";

const BudgetReallocation = ({ budget_transfer_list = [] }) => {
  const { height } = useCostTableHeight(false, true);
  return (
    <div className="cost-menu-body">
      <div className="cost-menu-detail-body" style={{ maxHeight: height }}>
        <div className="cost-menu-table-title-box">
          <div className="cost-menu-table-title">Approval History</div>
        </div>
        <BudgetReallocationTable
          rows={budget_transfer_list}
          column={budgetTransferListColumn}
        />
      </div>
    </div>
  );
};

export default BudgetReallocation;
