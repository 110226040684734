import React from "react";

export const OrgChartCard = (props) => {
  const { item = {} } = props;
  const { username, lv_name, email, phone } = item;

  return (
    <div className="org-chart-card-body">
      <div className="org-chart-card-name-box">
        <div className="org-chart-card-name">{username}</div>
        <div className="org-chart-card-job">{lv_name}</div>
      </div>
      <div className="org-chart-card-info-box">
        <div className="org-chart-card-info-text">{email}</div>
        <div className="org-chart-card-info-text">{phone}</div>
      </div>
    </div>
  );
};

export const GroupTitleCard = (props) => {
  const { title, className } = props;
  return <div className={`${className} org-chart-group-title`}>{title}</div>;
};

export const GroupSubTitleCard = (props) => {
  const { subtitle, className } = props;
  return (
    <div className={`${className} org-chart-group-subtitle`}>{subtitle}</div>
  );
};
